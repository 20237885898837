import React, { useEffect } from "react";
import { Route, Switch, Redirect, Router, withRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect, useDispatch } from "react-redux";

import CookiesWarning from "@components/cookiesWarning";

import NotFound from "@pages/notFound";
import PolicyTerms from "@pages/policyTerms";

import * as utilitiesActions from "@store/modules/utilities/actions";

import { useAuth } from "@utils/hooks/useAuth";
import { useDefaultProvider } from "@utils/hooks/useDefaultProvider";
import * as cookie from "@utils/cookie";
import { DEFAULT_HOST_LOGIN } from "@services/constants";
import AuthRoutes from "./authRoutes";
import CandidateRoutes from "./candidateRoutes";
import CompanyRoutes from "./companyRoutes";
import HiddenVacancyRoutes from "./hiddenVacancyRoutes";
import Main from "./main";

function Routes({ history }) {
  const { token } = useAuth();
  const { slug } = useDefaultProvider();
  const dispatch = useDispatch();

  const companyRoutes = CompanyRoutes({ token, slug, history });
  const candidateRoutes = CandidateRoutes({ token, slug, history });
  const authRoutes = AuthRoutes({ token, slug, history });
  const hiddenVacancyRoutes = HiddenVacancyRoutes({ token, slug, history });

  useEffect(() => {
    const validRoute =
      companyRoutes ||
      candidateRoutes ||
      authRoutes ||
      hiddenVacancyRoutes ||
      companyRoutes;
    const HOST = window.location.href;

    if (validRoute && !HOST.includes(DEFAULT_HOST_LOGIN)) {
      cookie.set("origin", window.location.href);
    }

    if (!HOST.includes(DEFAULT_HOST_LOGIN)) {
      dispatch(utilitiesActions.showLoading(false));
    }
  }, [companyRoutes, candidateRoutes, authRoutes, hiddenVacancyRoutes]);

  return (
    <ConnectedRouter history={history}>
      <Router history={history}>
        <Main history={history}>
          <Switch>
            <Route
              path="/notFound"
              render={() => <NotFound history={history} />}
            />

            <Route path="/policyTerms" component={PolicyTerms} />

            {companyRoutes &&
              !candidateRoutes &&
              !authRoutes &&
              !hiddenVacancyRoutes &&
              companyRoutes}
            {!companyRoutes &&
              candidateRoutes &&
              !authRoutes &&
              !hiddenVacancyRoutes &&
              candidateRoutes}

            {!companyRoutes &&
              !candidateRoutes &&
              authRoutes &&
              !hiddenVacancyRoutes &&
              authRoutes}

            {!companyRoutes &&
              !candidateRoutes &&
              !authRoutes &&
              hiddenVacancyRoutes &&
              hiddenVacancyRoutes}

            {/* caso não atenda a nenhuma das regras aqui irá redirecionar para uma página de not found */}
            {!companyRoutes &&
              !candidateRoutes &&
              !authRoutes &&
              !hiddenVacancyRoutes && <Redirect to="/notFound" />}
          </Switch>
        </Main>
      </Router>
      <CookiesWarning
        callbackWarningCookies={() =>
          cookie.set("warningCookies", false, { expires: 365 })
        }
        display={cookie.get("warningCookies")}
      />
    </ConnectedRouter>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(Routes));
