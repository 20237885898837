const optionsBehavior = {
  stepOne: [
    {
      value: "Alegre",
      english: "Cheerful"
    },
    {
      value: "Animado",
      english: "Lively"
    },
    {
      value: "Anti-social",
      english: "Antisocial"
    },
    {
      value: "Arrogante",
      english: "Arrogant"
    },
    {
      value: "Ativo",
      english: "Active"
    },
    {
      value: "Audacioso (ousado)",
      english: "Audacious (bold)"
    },
    {
      value: "Auto-Disciplinado",
      english: "Self-Disciplined"
    },
    {
      value: "Auto-Suficiente",
      english: "Self-Reliant"
    },
    {
      value: "Barulhento",
      english: "Noisy"
    },
    {
      value: "Bem-humorado",
      english: "Good-tempered"
    },
    {
      value: "Bem-Quisto",
      english: "Well-liked"
    },
    {
      value: "Bom Companheiro",
      english: "A Good Friend"
    },
    {
      value: "Calculista",
      english: "Calculative"
    },
    {
      value: "Calmo",
      english: "Calm"
    },
    {
      value: "Compreensivo",
      english: "Understanding"
    },
    {
      value: "Comunicativo",
      english: "Communicative"
    },
    {
      value: "Conservador",
      english: "Conservative"
    },
    {
      value: "Contagiante",
      english: "Exciting"
    },
    {
      value: "Corajoso",
      english: "Brave"
    },
    {
      value: "Crítico",
      english: "Critical"
    },
    {
      value: "Cumpridor",
      english: "Achiever"
    },
    {
      value: "Decidido",
      english: "Decisive"
    },
    {
      value: "Dedicado",
      english: "Dedicated"
    },
    {
      value: "Depressivo",
      english: "Depressed"
    },
    {
      value: "Desconfiado",
      english: "Suspicious"
    },
    {
      value: "Desmotivado",
      english: "Demotivated"
    },
    {
      value: "Destacado",
      english: "Stood Out from others"
    },
    {
      value: "Discreto",
      english: "Discreet"
    },
    {
      value: "Eficiente",
      english: "Efficient"
    },
    {
      value: "Egocêntrico",
      english: "Egocentric"
    },
    {
      value: "Egoísta",
      english: "Selfish"
    },
    {
      value: "Empolgante",
      english: "Excited"
    },
    {
      value: "Enérgico",
      english: "Energetic"
    },
    {
      value: "Entusiasta",
      english: "Enthusiastic"
    },
    {
      value: "Equilibrado",
      english: "Well-Balanced"
    },
    {
      value: "Espalhafatoso",
      english: "Flamboyant"
    },
    {
      value: "Estimulante",
      english: "Stimulating"
    },
    {
      value: "Exagerado",
      english: "Exaggerated"
    },
    {
      value: "Exigente",
      english: "Demanding"
    },
    {
      value: "Extrovertido",
      english: "Extroverted"
    },
    {
      value: "Exuberante",
      english: "Exuberant"
    },
    {
      value: "Firme",
      english: "Firm"
    },
    {
      value: "Frio",
      english: "Cold"
    },
    {
      value: "Habilidoso",
      english: "Skilful"
    },
    {
      value: "Idealista",
      english: "Idealistic"
    },
    {
      value: "Impaciente",
      english: "Impatient"
    },
    {
      value: "Indeciso",
      english: "Indecisive"
    },
    {
      value: "Independente",
      english: "Independent"
    },
    {
      value: "Indisciplinado",
      english: "Undisciplined"
    },
    {
      value: "Inflexível",
      english: "Inflexible"
    },
    {
      value: "Influenciador",
      english: "Influencer"
    },
    {
      value: "Ingênuo",
      english: "Naive"
    },
    {
      value: "Inseguro",
      english: "Insecure"
    },
    {
      value: "Insensível",
      english: "Insensitive"
    },
    {
      value: "Intolerante",
      english: "Intolerant"
    },
    {
      value: "Introvertido",
      english: "Introvert"
    },
    {
      value: "Leal",
      english: "Loyal"
    },
    {
      value: "Líder",
      english: "Leader"
    },
    {
      value: "Medroso",
      english: "Fearful"
    },
    {
      value: "Metódico",
      english: "Methodical"
    },
    {
      value: "Minucioso",
      english: "Thorough"
    },
    {
      value: "Modesto",
      english: "Modest"
    },
    {
      value: "Orgulhoso",
      english: "Proud"
    },
    {
      value: "Otimista",
      english: "Optimistic"
    },
    {
      value: "Paciente",
      english: "Patient"
    },
    {
      value: "Perfeccionista",
      english: "Perfectionist"
    },
    {
      value: "Persistente",
      english: "Persistent"
    },
    {
      value: "Pessimista",
      english: "Pessimist"
    },
    {
      value: "Popular",
      english: "Popular"
    },
    {
      value: "Prático",
      english: "Practical"
    },
    {
      value: "Pretensioso",
      english: "Pretentious"
    },
    {
      value: "Procrastinador",
      english: "Procrastinator"
    },
    {
      value: "Racional",
      english: "Rational"
    },
    {
      value: "Reservado",
      english: "Reserved"
    },
    {
      value: "Resoluto (decidido)",
      english: "Determined"
    },
    {
      value: "Rotineiro",
      english: "Routine-based"
    },
    {
      value: "Sarcástico",
      english: "Sarcastic"
    },
    {
      value: "Sensível",
      english: "Sensitive"
    },
    {
      value: "Sentimental",
      english: "Sentimental"
    },
    {
      value: "Simpático",
      english: "Likeable"
    },
    {
      value: "Sincero",
      english: "Sincere"
    },
    {
      value: "Temeroso",
      english: "Fainthearted"
    },
    {
      value: "Teórico",
      english: "Theoretical"
    },
    {
      value: "Tranquilo",
      english: "Quiet"
    },
    {
      value: "Vaidoso",
      english: "Vain"
    },
    {
      value: "Vingativo",
      english: "Vindictive"
    }
  ]
};

export default optionsBehavior;
