import React, { useMemo } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import resolve from "@utils/resolve";
import { MessageError, Label } from "..";
import Option from "./components/option";
import DropdownIndicator from "./components/dropdownIndicator";
import MultiValueRemove from "./components/multiValueRemove";
import { Container, style } from "./styles";

function SelectField({
  name,
  options,
  isMulti,
  label,
  required,
  maxHeight,
  placeholder,
  closeMenuOnSelect,
  hideSelectedOptions,
  ...props
}) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const value = useWatch({
    control,
    name
  });

  const { message: error } = resolve(name, errors);

  const selected = useMemo(() => {
    const valueSelected = options.find((option) => option.value === value);
    return valueSelected || "";
  }, [options, value]);

  return (
    <Container isError={!!error}>
      {label && <Label required={required}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...props}
            {...field}
            placeholder={placeholder}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            options={options}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
              Option,
              MultiValueRemove
            }}
            IndicatorSeparator={false}
            styles={style(error, maxHeight)}
            value={isMulti ? value : selected}
            onChange={(item) => field.onChange(isMulti ? item : item.value)}
          />
        )}
      />
      {error && <MessageError message={error} />}
    </Container>
  );
}

SelectField.defaultProps = {
  isMulti: false,
  maxHeight: "230px",
  placeholder: "Selecione",
  closeMenuOnSelect: true,
  hideSelectedOptions: false
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  maxHeight: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool
};

export default SelectField;
