import React from "react";

import { Button } from "@components/index.js";
import * as Styled from "./styles";

function FloatingFooter({ buttons, children }) {
  return (
    <Styled.Container>
      {children}

      {buttons.length > 0 && (
        <Styled.ButtonsContainer>
          {buttons.map(({ text, isPrimary, blocked, ...rest }, index) => (
            <Styled.Button
              {...rest}
              blocked={blocked}
              disabled={blocked || undefined}
              isPrimary={isPrimary}
              key={`${index}_item_floating`}
            >
              {text}
            </Styled.Button>
          ))}
        </Styled.ButtonsContainer>
      )}
    </Styled.Container>
  );
}

FloatingFooter.defaultProps = {
  buttons: []
};

export default React.memo(FloatingFooter);
