import styled from "styled-components";

export const ContainerOption = styled.div`
  position: relative;
  cursor: pointer;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    margin-bottom: 0px;
  }

  ${({ isDropdown }) =>
    isDropdown ? `width: 100%; margin: 6px 0px;` : `width: auto; margin: 1px;`}

  ${({ isDropdown, typeList }) =>
    isDropdown === false &&
    typeList === "column" &&
    `
            margin-top: 5px;
        `}
`;

export const Label = styled.div`
  border-radius: 50px;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  :hover {
    background: #5f4a87;
    color: #efedf3;
  }

  ${({ isDropdown, typeList }) =>
    isDropdown
      ? ` font-family: "Roboto";
                font-size: 16px;
                line-height: 20px;
                color: #5f4a87;
                padding: 8px 30px;`
      : isDropdown === false && typeList === "row"
      ? ` font-family: "Roboto Condensed";
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #EFEDF3;
                padding: 10px 30px 10px 10px;
                text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.15);
            `
      : ` font-family: "Roboto Condensed";
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #EFEDF3;
                padding: 7px 30px 7px 15px;
                text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.15);`}

  ${({ selected }) =>
    selected &&
    `background: #5F4A87;
        color: #EFEDF3;`}

    ${({ isDropdown, showPicture }) =>
    isDropdown && showPicture
      ? `padding-left: 48px;`
      : isDropdown === false && showPicture
      ? `padding-left: 36px;`
      : ``}
`;

export const Close = styled.div`
  ${({ isDropdown, selected, typeList }) =>
    isDropdown && selected
      ? ` position: absolute;
                right: 12px;
                top: 9px;
                z-index: 1;
                color: #EFEDF3;

                i {
                    font-size: 18px;
                }`
      : isDropdown && selected === false
      ? `display: none;`
      : isDropdown === false && typeList === "column"
      ? ` position: absolute;
                right: 12px;
                top: 4px;
                z-index: 1;
                color: #EFEDF3;
                
                i {
                    font-size: 12px;
                }
            `
      : ` position: absolute;
                right: 12px;
                top: 7px;
                z-index: 1;
                color: #EFEDF3;
                
                i {
                    font-size: 12px;
                }
            `}
`;

export const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
