import axios from "axios";
import API from "../../../services/api";

export const getDynamicOptions = async (type, referenceId, vacancyId) => {
  if (referenceId) {
    return new Promise((resolve, reject) => {
      const api = API();
      api
        .get(`/dynamic-options/${type}`, {
          headers: {
            referenceId,
            vacancyId
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  return new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/dynamic-options/${type}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setDynamicOption = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .post(`/create-dynamic-option`, json)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAddress = async (cep) =>
  new Promise((resolve, reject) => {
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const newPicture = async (formData) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .post(`/applicant/send-photo-applicant`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const changePicture = async (formData) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .post(`/applicant/change-photo-applicant`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deletePicture = async () =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .delete(`/applicant/delete-photo-applicant`)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getCountryList = async () =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/list-countries`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const getStatesList = async () =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/list-states`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const getCitiesList = async (acronym) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/list-cities/${acronym}`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const syncCurriculum = async () =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .post("/applicant/synchronize")
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const saveCurriculum = async (json) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .post("/applicant", json)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
