import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Label,
  Select,
  Textarea,
  RadioGroup,
  DatePicker,
  InputPhone
} from "@components/form";
import {
  CurriculumSectionTemplate,
  Tooltip,
  Typography,
  Icon
} from "@components";

import { useAuth } from "@hooks/useAuth";
import useCurriculum from "@hooks/useCurriculum";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { useCurriculumSections } from "@hooks/useCurriculumSections";
import { useCurriculumValidation } from "@hooks/useCurriculumValidations";
import { personalDataSchema } from "@utils/formValidations/curriculumV2/index";
import { getDisabilities } from "@utils/getDisabilities";

import { TooltipEmail, TooltipCpf } from "../../tooltips";
import { getGenders, getMaritalStatus, getWhatsAppOptions } from "./utils";

import * as Styled from "../styles";

function PersonalData({ onSave, children }) {
  const { user } = useAuth();

  const { registerForm, handleSubmit } = useCurriculum();
  const { allSectionsValidation } = useCurriculumValidation();
  const { requiredFields, isRequiredField } = useApplyVacancy();
  const { control, setValue, watch, getValues } = useFormContext();
  const watchDisabilities = useWatch({
    control,
    name: "disabledPersonDescription"
  });
  const { sectionOptions } = useCurriculumSections();

  const [hasCpf, setHasCpf] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const whatsAppOptions = useMemo(() => getWhatsAppOptions(), []);
  const genderOptions = useMemo(() => getGenders(), []);
  const maritalStatusOptions = useMemo(() => getMaritalStatus(), []);
  const disabilitiesOptions = useMemo(() => getDisabilities(), []);
  const watchIsWhatsApp = watch("contacts.isWhatsapp");

  useEffect(() => {
    const schema = personalDataSchema(
      watchIsWhatsApp === "no" ? [...requiredFields] : requiredFields
    );
    const resolver = yupResolver(schema);

    registerForm({
      schema: { resolver },
      handleSubmit: { onSubmit }
    });

    if (getValues("isDisabledPerson") && watchDisabilities === null)
      setValue("disabledPersonDescription", [], { shouldDirty: true });
  }, [watchIsWhatsApp, requiredFields, allSectionsValidation, sectionOptions]);

  useEffect(() => {
    setHasCpf(!!user?.documents?.cpf);
    setHasEmail(!!user?.contacts?.email);
  }, [user]);

  useEffect(() => {
    if (!watchDisabilities?.length) setValue("disabledPersonAdaptation", "");
  }, [watchDisabilities]);

  useEffect(() => {
    if (watchIsWhatsApp === "yes") setValue("contacts.whatsapp", "");
  }, [watchIsWhatsApp]);

  const onSubmit = useCallback(
    (data, nextPath) => {
      const formattedData = {
        birthday: moment(data.birthday || "").format("YYYY-MM-DD"),
        gender: data.gender,
        maritalStatus: data.maritalStatus || "",
        disabledPersonAdaptation: data.disabledPersonAdaptation || "",
        contacts: {
          email: data.contacts.email,
          secondaryEmail: data.contacts.secondaryEmail || "",
          phone: data.contacts.phoneDDI + data.contacts.phone,
          whatsapp:
            data.contacts.isWhatsapp === "no"
              ? data.contacts.whatsappDDI + data.contacts.whatsapp
              : data.contacts.phoneDDI + data.contacts.phone
        },
        documents: {
          cpf: data.documents.cpf,
          rg: data.documents.rg || ""
        }
      };

      if (Array.isArray(data.disabledPersonDescription)) {
        formattedData.disabledPersonDescription =
          data.disabledPersonDescription?.map(
            (disability) => disability.value
          ) || [];
      }

      onSave(formattedData, nextPath);
    },
    [sectionOptions]
  );

  return (
    <CurriculumSectionTemplate title="Dados pessoais">
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.Row alignItems="flex-end">
          <Styled.Col>
            <Label required={!hasEmail}>
              E-mail login{" "}
              <Tooltip close content={TooltipEmail}>
                <Icon className="fas fa-question-circle" />
              </Tooltip>
            </Label>

            <Styled.Input
              name="contacts.email"
              maxLength="100"
              data-testid="contacts[email]"
              placeholder="nome@email.com"
              blocked={hasEmail}
              disabled={hasEmail}
            />
          </Styled.Col>

          <Styled.Col>
            <Label required={isRequiredField("secondaryEmail")}>
              E-mail secundário
            </Label>

            <Typography style={{ marginBottom: "0.5rem" }}>
              Adicione um segundo e-mail diferente, para contato
            </Typography>

            <Styled.Input
              name="contacts.secondaryEmail"
              maxLength="100"
              data-testid="contacts[secondaryEmail]"
              placeholder="nome@email.com"
            />
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col>
            <Label required={!hasCpf}>
              CPF{" "}
              {hasCpf && (
                <Tooltip close content={TooltipCpf}>
                  <Icon className="fas fa-question-circle" />
                </Tooltip>
              )}
            </Label>

            <Styled.Input
              name="documents.cpf"
              data-testid="documents[cpf]"
              placeholder="___.___.___-__"
              blocked={hasCpf}
              disabled={hasCpf}
              type="number"
              format="###.###.###-##"
            />
          </Styled.Col>

          <Styled.Col>
            <Styled.Input
              name="documents.rg"
              data-testid="documents[rg]"
              label="RG"
              placeholder="Número da carteira de identidade"
              required={isRequiredField("indentify")}
              type="number"
              maxLength={15}
            />
          </Styled.Col>
        </Styled.Row>

        <Styled.Row alignItems="flex-end">
          <Styled.Col style={{ minWidth: "230px" }}>
            <InputPhone
              name="contacts.phone"
              data-testid="contacts.phone"
              label="Número de celular"
              required
            />
          </Styled.Col>

          <Styled.Col>
            <Typography style={{ marginBottom: "0.25rem" }}>
              Este número é WhatsApp?
            </Typography>
            <div style={{ maxWidth: 122 }}>
              <RadioGroup
                responsive={false}
                options={whatsAppOptions}
                name="contacts.isWhatsapp"
              />
            </div>
          </Styled.Col>

          {watchIsWhatsApp === "no" && (
            <Styled.Col style={{ minWidth: "230px" }}>
              <InputPhone
                name="contacts.whatsapp"
                data-testid="contacts.whatsapp"
                label="Whatsapp"
              />
            </Styled.Col>
          )}
        </Styled.Row>

        <Styled.Row>
          <Styled.Col>
            <DatePicker
              dataTestid="birthday"
              name="birthday"
              label="Data de nascimento"
              placeholder="dd/mm/aa"
              id="birthday"
              openDirection="down"
              numberOfMonths={1}
              maxDate={new Date()}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              noBorder
              required
            />
          </Styled.Col>

          <Styled.Col>
            <Select
              options={genderOptions}
              placeholder="Selecione"
              name="gender"
              label="Gênero"
              required
            />
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col>
            <Select
              options={maritalStatusOptions}
              placeholder="Selecione"
              name="maritalStatus"
              label="Estado civil"
              required={isRequiredField("maritalStatus")}
            />
          </Styled.Col>

          <Styled.Col>
            <Label required={isRequiredField("specialNeedOrDisability")}>
              Pessoa com deficiência
            </Label>
            <Typography style={{ marginBottom: "0.8rem" }}>
              Caso seja uma pessoa com deficiência, selecione abaixo qual a sua
              deficiência.
            </Typography>
            <Select
              options={disabilitiesOptions}
              placeholder="Selecione"
              name="disabledPersonDescription"
              isMulti
              closeMenuOnSelect={false}
              valueSelected=""
              required={isRequiredField("specialNeedOrDisability")}
            />
          </Styled.Col>
        </Styled.Row>

        {watchDisabilities?.length > 0 && (
          <Styled.Row>
            <Styled.Col hideMobile />
            <Styled.Col>
              <Textarea
                rows={4}
                label="Você precisa de alguma adaptação? Se sim, qual?"
                maxLength={255}
                placeholder="Digite aqui."
                name="disabledPersonAdaptation"
                disabledResize
              />
            </Styled.Col>
          </Styled.Row>
        )}

        {children}
      </Styled.Form>
    </CurriculumSectionTemplate>
  );
}

export default React.memo(PersonalData);
