import React from "react";
import { Slider } from "antd";

import * as Styled from "./styles";

import "./style.scss";

const RangeSliderBar = ({
  options = [],
  label = "Iniciante",
  fallbackChangeLevel,
  value,
  marks = { 0: "", 50: "", 100: "" },
  ...rest
}) => (
  <Styled.RangeSlider>
    <Styled.Text>{label}</Styled.Text>
    <Styled.ContainerSlider>
      <Slider
        marks={marks}
        step={null}
        value={value || 0}
        tooltipVisible={false}
        onChange={(teste) => {
          fallbackChangeLevel(teste);
        }}
      />
    </Styled.ContainerSlider>
  </Styled.RangeSlider>
);

export default RangeSliderBar;
