import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { Container, Option, InputRadio } from "./styles";

const RadioGroup = ({ name, options, columns, responsive }) => {
  const { register } = useFormContext();

  return (
    <Container columns={columns} responsive={responsive}>
      {options.map((option) => (
        <Option key={option.value} disabled={!option.value}>
          <InputRadio {...register(name)} type="radio" value={option.value} />
          <span>{option.label}</span>
        </Option>
      ))}
    </Container>
  );
};

RadioGroup.defaultProps = {
  options: [],
  columns: 2,
  responsive: true
};

RadioGroup.propTypes = {
  columns: PropTypes.number,
  responsive: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};

export default RadioGroup;
