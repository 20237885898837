import styled from "styled-components";

export const ContentButton = styled.div`
  ${({ buttonGroup = false }) => buttonGroup && `width: auto;`}
  ${(props) =>
    props.small
      ? `height: 32px;
          font-size: 12px;
          line-height: 15px;`
      : props.medium
      ? `height: 40px;
          font-size: 14px;
          line-height: 17px;`
      : props.large
      ? `height: 58px;
          font-size: 16px;
          line-height: 19px;`
      : `height: 32px;
        font-size: 12px;
        line-height: 15px;`};
  ${({ blocked }) => !blocked && "cursor: pointer;"}
  user-select: none;
  position: relative;
  width: 190px;
`;

export const ButtonOutline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: "Roboto Condensed";
  font-style: normal;
  text-align: center;
  background: transparent;
  padding: 0 5px;
  border-style: solid;

  ${({ isUppercase }) => isUppercase && "text-transform: uppercase;"}
  font-weight: ${({ fontWeight }) => fontWeight || "bold"};
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  border-width: ${({ borderWidth }) => borderWidth || "2px"};
  border-color: ${({ borderColor, textColor }) =>
    borderColor || textColor || "#333333"};
  color: ${({ textColor }) => textColor || "#000000"};

  @media (max-width: 700px) {
    width: 100%;
  }

  ${(props) =>
    props.small
      ? `font-size: 12px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 14px;
        line-height: 16px;`
      : props.large
      ? `font-size: 16px;
        line-height: 18px;`
      : `font-size: 12px;
      line-height: 14px;`};

  :hover {
    border-color: ${({ borderColor, textColor }) =>
      textColor || borderColor || "#000000"};
  }
`;

export const IconArrow = styled.div`
  left: ${({ arrow }) => (arrow ? `${arrow}px` : "8px")};
  @media (max-width: 700px) {
    left: 150px;
  }
  svg {
    fill: #333333;
    width: 18px;

    path {
      fill: #333333;
    }
  }
`;

export const LeftPhoto = styled.div`
  position: absolute;
  left: 4px;
  max-width: 32px;
  max-height: 32px;
  color: #ffffff;
  background-color: transparent;
`;

export const LeftIcon = styled.div`
  ${({ cursor }) => cursor && "cursor: pointer;"}

  position: absolute;
  left: 1rem;
  top: auto;
  bottom: auto;

  svg {
    path {
      fill: ${({ iconColor }) => iconColor || "#ffffff"};
    }

    ${({ small, medium, large }) =>
      small
        ? `height: 14px; width: 14px;`
        : medium
        ? `height: 16px; width: 16px;`
        : large
        ? `height: 20px; width: 20px;`
        : `height: 16px; width: 16px;`}
  }
`;

// antigo

export const DropdownMenu = styled.div`
  ${(props) => (props.show === true ? "display: block" : "display: none")}
  width: 100%;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
`;

export const DropdownMenuItem = styled.span`
  display: block;
  width: 100%;
  padding: 0.6rem 0 0.6rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  z-index: 3;

  :hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
`;

export const ContentDropdown = styled.div`
  width: ${({ width = `30` }) => width}%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 3px;
  margin-left: ${({ left = "5" }) => left}px;
  margin-right: ${({ right = "5" }) => right}px;
  z-index: 3;
  cursor: pointer;
`;

export const Placeholder = styled.div`
  ${(props) =>
    props.small
      ? `height: 35px;`
      : props.medium
      ? `height: 40px;`
      : props.large
      ? `height: 50px;`
      : props.carometro
      ? `height: 20px;`
      : `height: 40px;`};
  ${({ type }) =>
    type === "primary"
      ? `background: #fff;`
      : type === "secondary"
      ? `background: #5f4a87;`
      : `background: transparent;`}
  width: 100%;
  color: #5f4a87;
  border-radius: 20px;
  ${(props) =>
    props.show === true
      ? "border-bottom-left-radius: 0 "
      : "border-bottom-left-radius: 20px"}

  ${(props) =>
    props.show === true
      ? "border-bottom-right-radius: 0 "
      : "border-bottom-right-radius: 20px"}

    ${({ type }) =>
    type === "primary" || type === "secondary"
      ? `border: 1px solid #5f4a87;`
      : `border: 0`}

    display: flex;
  align-items: center;
  z-index: 3;
  ${({ show }) =>
    show === true
      ? `   box-shadow: 0px 4px 6px rgba(47, 10, 119, 0.35),
        inset 0px 0px 0px rgba(0, 0, 0, 0.2);`
      : `   box-shadow: 0px 4px 6px rgba(47, 10, 119, 0.35),
        inset 0px -3px 0px rgba(0, 0, 0, 0.2);`}

  ${({ type }) =>
    type === "" &&
    `box-shadow: 0px 0px 0px rgba(47,10,119,0.35), inset 0px 0px 0px rgba(0,0,0,0.2);`}
    text-transform: uppercase;
`;

export const IconLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;

  svg {
    ${({ type }) =>
      type === "primary"
        ? `fill: #5f4a87;`
        : type === "secondary"
        ? `fill: #fff;`
        : `fill: #5f4a87`}
    width: 18px;

    path {
      ${({ type }) =>
        type === "primary"
          ? `fill: #5f4a87;`
          : type === "secondary"
          ? `fill: #fff;`
          : `fill: #5f4a87`}
    }
  }
`;

// export const ContentText = styled.div`
//   display: flex;
//   flex: 7;
//   justify-content: center;
// `;

export const Text = styled.span`
  display: flex;
  justify-self: center;
  font-weight: bold;
  font-family: "Roboto Condensed";
  ${({ type }) =>
    type === "primary"
      ? `color: #5f4a87;`
      : type === "secondary"
      ? `color: #fff;`
      : `color: #5f4a87`};
  font-size: 12px;
`;

export const List = styled.div`
  ${(props) => (props.show === true ? "display: block" : "display: none")}
  width: 100%;
  ${({ type }) => type === "" && `min-width: 250px;`}
  position: absolute;
  height: auto;
  ${(props) =>
    props.small
      ? `top: 25px`
      : props.medium
      ? `top: 30px`
      : props.large
      ? `top: 30px`
      : props.carometro
      ? `top: 13px;`
      : `top: 25px`};
  ${({ type }) =>
    type === "primary"
      ? `background: #fff;`
      : type === "secondary"
      ? `background: #5f4a87;`
      : `background: #fff;`}
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  ${({ type }) =>
    type === "primary"
      ? `border: 1px solid #efedf3;`
      : type === "secondary"
      ? `border: 1px solid #8872B2;`
      : `border: 1px solid #efedf3;`}

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top: 0;
  z-index: 3;
  margin-top: 13px;
  box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35);
  ${({ type }) =>
    type === "primary" &&
    ` border-left: 1px solid #8872B2;border-bottom: 1px solid #8872B2;border-right: 1px solid #8872B2;`}
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  position: relative;

  ${({ type, multiselect }) =>
    multiselect && type === "primary"
      ? `color: #fff; background: #5f4a87;`
      : multiselect && type === "secondary"
      ? `color: #5f4a87; background: #fff;`
      : multiselect === false && type === "primary"
      ? `color: #5f4a87; background: #fff;`
      : multiselect === false && type === "secondary"
      ? `color: #fff; background: #5f4a87;`
      : `color: #5f4a87; background: #fff;font-weight: bold;`}

  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 50px;
  margin-top: 3px;
  font-size: 13px;

  svg {
    fill: ${({ type, multiselect }) =>
      multiselect && type === "primary"
        ? "#fff"
        : multiselect && type === "secondary"
        ? "#5f4a87"
        : multiselect === false && type === "primary"
        ? "#5f4a87"
        : "#fff"};

    width: 17px;
    padding-right: 5px;

    path {
      fill: ${({ type, multiselect }) =>
        multiselect && type === "primary"
          ? "#fff"
          : multiselect && type === "secondary"
          ? "#5f4a87"
          : multiselect === false && type === "primary"
          ? "#5f4a87"
          : "#fff"};
    }
  }

  &:hover {
    ${({ type }) =>
      type === "primary"
        ? `color: #fff; background: #5f4a87;`
        : type === "secondary"
        ? `color: #5f4a87;background: #fff;`
        : `color: #fff; background: #5f4a87;`}

    svg {
      fill: ${({ type }) => (type === "primary" ? "#fff" : "#5f4a87")};
      width: 17px;
      padding-right: 5px;

      path {
        fill: ${({ type }) => (type === "primary" ? "#fff" : "#5f4a87")};
      }
    }
  }
`;

export const ListItemTitle = styled.div`
  font-family: "Roboto Condensed";
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #5f4a87;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 50px;
  margin-top: 3px;
  font-weight: bold;

  ${({ type }) =>
    type === "primary"
      ? `color: #5f4a87; background: #fff`
      : type === "secondary"
      ? `color: #fff;
                background: #5f4a87;`
      : `color: #5f4a87; background: #fff`}

  svg {
    fill: #5f4a87;
    width: 17px;
    padding-right: 5px;

    path {
      fill: #5f4a87;
    }
  }
`;

export const PlaceholderText = styled.span`
  color: #a79cbd;
`;

export const ValueText = styled.span`
  color: #5f4a87;
`;

export const Close = styled.div`
  position: absolute;
  left: 90%;
  top: 25%;
`;
