import styled, { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
    body {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        font-size: 12pt !important;
        font-family: "Roboto" !important;
        -webkit-print-color-adjust: exact !important;
    }
`;
export const Page = styled.div`
  background: #ffffff;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;

  @media print {
    html,
    body {
      width: ${(props) => props.theme.width};
      height: ${(props) => props.theme.height};
      margin: 3cm 2cm 2cm 3cm;
      /* box-shadow: 0; */
    }
  }
`;

export const Header = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 72px 0 64px;
`;

export const Photo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

export const DescriptionTitle = styled.span`
  font-size: 24px;
`;

export const DescriptionItem = styled.span`
  font-size: 12px;
  font-weight: ${({ weight }) => weight || "normal"};
  margin: 0 2px;
`;

export const HeaderItem = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #333333;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}

  ${DescriptionItem}:not(:first-child)::before {
    margin: 0 4px 0 0;
    content: "/";
  }
`;

export const Link = styled.a`
  font-size: 12px;
  text-decoration-line: underline;
  color: #333333;

  :hover {
    color: #000;
    text-decoration-line: underline;
  }
`;

export const Body = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 75px 72px 32px;
`;

export const SectionTitle = styled.div`
  height: 40px;
  background-color: #f2f2f2 !important;
  border-radius: 24px;
  padding: 10px 20px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.1px;
  color: #333333;
  text-transform: uppercase;
`;

export const SectionBody = styled.div`
  padding: 30px 25px 40px 34px;
`;

export const SectionBodyTitle = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #333333;
`;

export const SectionBodyText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
`;

export const NotFound = styled.span`
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ bottom }) => bottom || "20px"};
`;

export const Col = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || 1};
  flex-direction: column;
`;
