import styled from "styled-components";

export const Row = styled.div`
  margin-top: 32px;
  position: relative;

  label {
    font-size: 16px;
    min-height: auto;
    span {
      display: none;
    }
  }
  > div {
    margin: 28px 0;
    :not(:first-child) {
      margin-top: 22px;
    }
  }
  li {
  }
  svg {
    width: 15px;
    height: 15px;
    margin-left: 4px;
    &.success {
      fill: #177727;
    }
    &.error {
      width: 12px;
      height: 12px;
      fill: #ef3d4e;
    }
  }
`;

export const BackLogin = styled.div`
  padding: 18px 0px 0px 0px;
  text-align: center;
  color: #333333;

  a {
    font-weight: bold;
    margin-left: 5px;
    color: #333333;
    text-decoration: underline;
  }
`;

export const Register = styled.div`
  text-align: left;
  margin: 21px 0 6px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  a {
    font-size: 14px;
    padding-left: 4px;
  }
`;

export const Reset = styled(Register)`
  margin: 32px 0 0px;
  a {
    padding-left: 0;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Link = styled.a`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  color: #583f99;

  :hover {
    color: #583f99;
    text-decoration: underline;
  }
`;

export const Divider = styled.section`
  border-bottom: 1px solid #cac8c8;
  margin: 32px 0;
`;

export const ResponseRequest = styled.section`
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.875rem;
    color: #000;
    &.top {
      margin-top: 32px;
    }
  }
`;
