import React from "react";

import * as Styled from "./styles";

const TabHeader = ({ changeType, type }) => (
  <Styled.ContainerTabHeader>
    <Styled.TabItem
      active={type === "curriculum"}
      onClick={() => changeType("curriculum")}
    >
      Currículo
    </Styled.TabItem>
    <Styled.TabItem
      active={type === "profiler"}
      onClick={() => changeType("profiler")}
    >
      Profiler
    </Styled.TabItem>
    <Styled.TabItem
      active={type === "vacancy"}
      onClick={() => changeType("vacancy")}
    >
      Vagas
    </Styled.TabItem>
    <Styled.TabItem
      active={type === "company"}
      onClick={() => changeType("company")}
    >
      Empresas
    </Styled.TabItem>
  </Styled.ContainerTabHeader>
);

export default TabHeader;
