import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;

  overflow-y: auto;

  position: relative;

  /* less than 576px */
  padding: 30px 28px 10px;

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 50px 55px 0px;

    ${(props) =>
      props.footer &&
      `
            padding: 0px 55px 30px;
        `}
  }
`;

export const Logo = styled.div`
  position: absolute;
  right: 50px;
  top: 40px;

  svg {
    width: 90px;
  }

  @media screen and (min-width: 433px) {
    top: 20px;
  }

  @media screen and (max-width: 432px) {
    top: 35px;
  }

  @media screen and (max-width: 575px) {
    right: 0px;
  }
`;

export const Title = styled.h1`
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  font-weight: 700;

  @media screen and (min-width: 433px) and (max-width: 575px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled.h4`
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const List = styled.ul`
  margin-left: 15px;
`;

export const Item = styled.li``;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1em;
`;

export const Legend = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding-top: 40px;
`;
