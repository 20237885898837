import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #333333;
  margin-bottom: 60px;
`;

export const Header = styled.div`
  margin-bottom: 50px;
`;

export const Close = styled.div`
  visibility: visible;
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
    fill: #333333;
    cursor: pointer;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
`;

export const SubTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`;

export const SubTitleSpan = styled.span`
  font-weight: normal;
  text-transform: none;
`;

export const Body = styled.div`
  width: 100%;
`;

export const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #333333;
  margin-top: 50px;
`;

export const DetailsBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 0.5rem;
`;

export const DetailsBoxHeader = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 10px 0;
`;

export const DetailsBoxTitle = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  margin-left: 1rem;
`;

export const DetailsBoxBody = styled.div`
  width: 100%;
  padding-top: 15px;
`;

export const DetailsBoxItem = styled.div`
  padding-bottom: 40px;
  padding-left: 15px;
`;

export const DetailsBoxDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DetailsBoxDescriptionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-left: 30px;
  margin-bottom: 12px;
`;

export const DetailsBoxDescriptionLevel = styled.div`
  width: 100%;
  margin-left: auto;
  max-width: 120px;
  margin-bottom: 12px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 250px;
  align-items: center;
  text-aling: center;
`;
