import React from "react";

import * as Styled from "./styles";

const AlertBox = ({ type, content }) => (
  <Styled.Box $type={type}>
    <span>{content}</span>
  </Styled.Box>
);

export default AlertBox;
