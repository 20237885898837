/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { InputWithLabel } from "../../styles";

export default function InputArea({
  label,
  isValid,
  styleInput = {},
  value,
  error = false,
  onChange = () => {},
  maxLength = undefined,
  ...rest
}) {
  const length = (value || "").length || 0;
  return (
    <InputWithLabel className="label-float" error={error} {...rest}>
      <textarea
        style={styleInput}
        type="text"
        placeholder=" "
        rows="5"
        value={value}
        onChange={onChange}
        maxLength={maxLength || undefined}
        {...rest}
      >
        {value}
      </textarea>
      <label>{label}</label>
      {maxLength && (
        <span className="max-length">
          {length} / {maxLength}
        </span>
      )}
    </InputWithLabel>
  );
}
