import React from "react";
import * as Styled from "../styles";

const Facebook = ({
  onClick,
  text,
  isUppercase = true,
  children,
  leftIcon = false,
  rightIcon = false,
  ...rest
}) => (
  <Styled.ContentButton type="button" {...rest}>
    <Styled.ButtonFacebook
      {...rest}
      onClick={onClick}
      isUppercase={isUppercase}
    >
      {leftIcon !== false && (
        <Styled.Icon {...rest}>
          <i className="fab fa-facebook" />
        </Styled.Icon>
      )}

      {text}
      {children}

      {rightIcon !== false && (
        <Styled.RightIcon {...rest}>
          <i className="fab fa-facebook" />
        </Styled.RightIcon>
      )}
    </Styled.ButtonFacebook>
  </Styled.ContentButton>
);

export default Facebook;
