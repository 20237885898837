/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { ThemeProvider } from "styled-components";
import print from "../../../../utils/print";

import diffDate from "../../../../utils/getDiffDate";
import downloadPdf from "../../../../utils/downloadPdf";

import UserIcon from "../../../../assets/images/user.png";
import Image from "../../../../components/image";
import Groups from "./components/groups";

import * as Styled from "./styles";

const Curriculum = ({ currentUser, type }) => {
  const [birthday, setBirthday] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [courses, setCourses] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);
  const [address, setAdress] = useState("");
  const [ready, setReady] = useState(false);

  const { goBack } = useHistory();

  useEffect(() => {
    if (currentUser.id) {
      !!currentUser.birthday && setBirthday(moment(currentUser.birthday));

      const tempExperiences = [];
      const tempCourses = [];
      const tempLanguages = [];
      const tempSkills = [];

      moment.locale("pt-BR");
      if (currentUser.experiences) {
        currentUser.experiences.map((experience) => {
          let beginDate = "";
          let endDate = "";
          let totalTime = "";

          if (experience.startDate) {
            beginDate = moment(experience.startDate);
            experience.startMonth = beginDate.format("MM");
            experience.startYear = beginDate.year();
          }

          if (experience.endDate) {
            endDate = moment(experience.endDate);
            experience.endMonth = endDate.format("MM");
            experience.endYear = endDate.year();
          }

          if (!!experience.startDate && !!experience.current) {
            endDate = new moment();
            const duration = moment.duration(endDate.diff(beginDate));
            const numOfMonths = duration.asMonths();
            totalTime = diffDate(numOfMonths);
          } else if (!!experience.startDate && !!experience.endDate) {
            const duration = moment.duration(endDate.diff(beginDate));
            const numOfMonths = duration.asMonths();
            totalTime = diffDate(numOfMonths);
          }

          return tempExperiences.push({
            logo: null,
            title: experience.company,
            subTitle: totalTime,
            details: [
              {
                topic: experience.occupation,
                subTopic: `${experience.startMonth} de ${
                  experience.startYear
                } até ${
                  experience.current === true
                    ? "o momento"
                    : `${experience.endMonth} de ${experience.endYear}`
                } (${totalTime})`,
                description: experience.description
              }
            ]
          });
        });
      }

      if (currentUser.courses) {
        currentUser.courses.map((course) => {
          let beginDate = "";
          let endDate = "";
          let totalTime = "";

          if (course.startDate) {
            beginDate = moment(course.startDate);
            course.startMonth = beginDate.format("MM");
            course.startYear = beginDate.year();
          }

          if (course.endDate) {
            endDate = moment(course.endDate);
            course.endMonth = endDate.format("MM");
            course.endYear = endDate.year();
          }

          if (!!course.startDate && !!course.current) {
            endDate = new moment();
            const duration = moment.duration(endDate.diff(beginDate));
            const numOfMonths = duration.asMonths();
            totalTime = diffDate(numOfMonths);
          } else if (!!course.startDate && !!course.endDate) {
            const duration = moment.duration(endDate.diff(beginDate));
            const numOfMonths = duration.asMonths();
            totalTime = diffDate(numOfMonths);
          }

          return tempCourses.push({
            logo: null,
            title: course.college,
            subTitle: totalTime,
            details: [
              {
                topic: course.course,
                subTopic: `${course.startMonth} de ${course.startYear} até ${
                  course.current === true
                    ? "o momento"
                    : `${course.endMonth} de ${course.endYear}`
                } (${totalTime})`,
                description: course.description
              }
            ]
          });
        });
      }

      const levelName = "";
      if (currentUser.languages) {
        const levels = {
          1: "Iniciante",
          2: "Básico",
          3: "Intermediário",
          4: "Avançado",
          5: "Fluente",
          6: "Domínio Pleno"
        };

        currentUser.languages.forEach((lang) =>
          tempLanguages.push({
            logo: null,
            title: lang.name,
            subTitle: levels[lang.level] || levels[1],
            details: []
          })
        );
      }

      if (currentUser.skills) {
        const levels = {
          1: "Iniciante",
          2: "Intermediário",
          3: "Avançado"
        };

        currentUser.skills.forEach((skill) =>
          tempSkills.push({
            logo: null,
            title: skill.name,
            subTitle: levels[skill.level] || levels[1],
            details: []
          })
        );
      }

      if (currentUser.address.street) {
        let address = "";
        if (currentUser.address.street) address += currentUser.address.street;
        if (currentUser.address.number)
          address += `, Nº ${currentUser.address.number}`;
        if (currentUser.address.complement)
          address += ` / ${currentUser.address.complement}`;
        if (currentUser.address.district)
          address += `, ${currentUser.address.district}`;
        if (currentUser.address.city)
          address += `, ${currentUser.address.city}`;
        if (currentUser.address.state)
          address += ` / ${currentUser.address.state}`;
        if (currentUser.address.zipCode)
          address += `. CEP ${currentUser.address.zipCode}`;

        setAdress(address);
      }

      setExperiences(tempExperiences);
      setCourses(tempCourses);
      setLanguages(tempLanguages);
      setSkills(tempSkills);
      setReady(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (ready) {
      switch (type) {
        case "download":
          !!currentUser.id && downloadPdf("printSectionn", "save", "CV");
          break;
        case "print":
          !!currentUser.id && print("printSectionn");
          goBack();
          break;
        default:
          !!currentUser.id && print("printSectionn");
          goBack();
          break;
      }
    }
  }, [ready, type]);

  // print theme
  const A4Portrait = {
    width: "21cm",
    height: "29.7cm",
    size: "A4"
  };

  return (
    <ThemeProvider theme={A4Portrait}>
      <Styled.Global />
      <div id="pdf" style={{ height: "1mm" }} />
      <Styled.Page id="printSectionn">
        <Styled.Header>
          <Styled.Photo>
            <Image
              url={currentUser.picture ? currentUser.picture : UserIcon}
              type="roundedCircle"
              imgBackgroundSize="cover"
              imgSize="85"
              border
            />
          </Styled.Photo>

          {!!currentUser.name && (
            <Styled.HeaderItem>
              <Styled.DescriptionTitle>
                {currentUser.name}
                {!!birthday && `,  ${moment().diff(birthday, "years")} anos `}
              </Styled.DescriptionTitle>
            </Styled.HeaderItem>
          )}

          {!!currentUser.experiences && currentUser.experiences.length > 0 && (
            <Styled.HeaderItem>
              <Styled.DescriptionItem>
                {currentUser.experiences[0].occupation}
              </Styled.DescriptionItem>
              <Styled.DescriptionItem weight="bold">
                {currentUser.experiences[0].company}
              </Styled.DescriptionItem>
            </Styled.HeaderItem>
          )}
          <Styled.HeaderItem lineHeight="10">
            {!!currentUser.contacts && !!currentUser.contacts.secondaryEmail && (
              <Styled.DescriptionItem>
                <Styled.Link
                  href={`mailto:${currentUser.contacts.secondaryEmail}`}
                >
                  {currentUser.contacts.secondaryEmail}
                </Styled.Link>
              </Styled.DescriptionItem>
            )}
            {!!currentUser.contacts && !!currentUser.contacts.phone && (
              <Styled.DescriptionItem>
                {currentUser.contacts.phone}
              </Styled.DescriptionItem>
            )}
          </Styled.HeaderItem>
        </Styled.Header>
        <Styled.Body>
          <Styled.SectionTitle>INFORMAÇÕES</Styled.SectionTitle>
          <Styled.SectionBody>
            <Styled.Row>
              <Styled.Col flex="2">
                <Styled.SectionBodyTitle>Endereço</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {address || (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>Gênero</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.gender ? (
                    currentUser.gender === "male" ? (
                      "Masculino"
                    ) : currentUser.gender === "female" ? (
                      "Femenino"
                    ) : (
                      "Indefinido"
                    )
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>Nascimento</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.birthday ? (
                    moment(birthday).format("DD/MM/YYYY")
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
            </Styled.Row>
            <Styled.Row>
              <Styled.Col flex="2">
                <Styled.SectionBodyTitle>CPF</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.cpf ? (
                    currentUser.documents.cpf
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>RG</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.rg ? (
                    currentUser.documents.rg
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>CNH</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.driverLicense ? (
                    currentUser.documents.driverLicense
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
            </Styled.Row>
            <Styled.Row>
              <Styled.Col flex="2">
                <Styled.SectionBodyTitle>
                  Título de eleitor
                </Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.electoralNumber ? (
                    currentUser.documents.electoralNumber
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>
                  Zona eleitoral
                </Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.electoralZone ? (
                    currentUser.documents.electoralZone
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>
                  Seção eleitoral
                </Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.electoralSection ? (
                    currentUser.documents.electoralSection
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
            </Styled.Row>
            <Styled.Row>
              <Styled.Col flex="2">
                <Styled.SectionBodyTitle>Reservista</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.documents.reservist ? (
                    currentUser.documents.reservist
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col>
                <Styled.SectionBodyTitle>Nacionalidade</Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.address.country ? (
                    currentUser.address.country
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
              <Styled.Col />
            </Styled.Row>
            <Styled.Row>
              <Styled.Col>
                <Styled.SectionBodyTitle>
                  Portador de Necessidade Especial
                </Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.hasSpecialNeeds ? (
                    currentUser.hasSpecialNeeds === "1" ? (
                      currentUser.specialNeedsDescription
                    ) : (
                      "Não"
                    )
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
            </Styled.Row>
            <Styled.Row bottom="0">
              <Styled.Col>
                <Styled.SectionBodyTitle>
                  Portador de Deficiência
                </Styled.SectionBodyTitle>
                <Styled.SectionBodyText>
                  {currentUser.isDisabledPerson ? (
                    currentUser.isDisabledPerson === "1" ? (
                      currentUser.disabledPersonDescription
                    ) : (
                      "Não"
                    )
                  ) : (
                    <Styled.NotFound>Não especificado</Styled.NotFound>
                  )}
                </Styled.SectionBodyText>
              </Styled.Col>
            </Styled.Row>
          </Styled.SectionBody>

          <Styled.SectionTitle>RESUMO</Styled.SectionTitle>
          <Styled.SectionBody>
            {currentUser.about ? (
              <Styled.SectionBodyText>
                {currentUser.about}
              </Styled.SectionBodyText>
            ) : (
              <Styled.NotFound>Não especificado</Styled.NotFound>
            )}
          </Styled.SectionBody>

          <Styled.SectionTitle>FORMAÇÃO</Styled.SectionTitle>
          <Styled.SectionBody>
            {!!courses && !!courses.length > 0 ? (
              <Groups list={courses} />
            ) : (
              <Styled.NotFound>Não especificado</Styled.NotFound>
            )}
          </Styled.SectionBody>

          <Styled.SectionTitle>EXPERIÊNCIA</Styled.SectionTitle>
          <Styled.SectionBody>
            {!!experiences && !!experiences.length > 0 ? (
              <Groups list={experiences} />
            ) : (
              <Styled.NotFound>Não especificado</Styled.NotFound>
            )}
          </Styled.SectionBody>

          <Styled.SectionTitle>IDIOMAS</Styled.SectionTitle>
          <Styled.SectionBody>
            {!!languages && !!languages.length > 0 ? (
              <Groups list={languages} />
            ) : (
              <Styled.NotFound>Não especificado</Styled.NotFound>
            )}
          </Styled.SectionBody>

          <Styled.SectionTitle>HABILIDADES</Styled.SectionTitle>
          <Styled.SectionBody>
            {!!skills && !!skills.length > 0 ? (
              <Groups list={skills} />
            ) : (
              <Styled.NotFound>Não especificado</Styled.NotFound>
            )}
          </Styled.SectionBody>
        </Styled.Body>
      </Styled.Page>
    </ThemeProvider>
  );
};

export default Curriculum;
