const INITIAL_STATE = {
  userCurriculumFile: {},
  percentage: 0,
  error: {},
  languages: [],
  skills: [],
  userPicture: "",
  listCountries: [],
  listStates: [],
  blockedButtons: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@curriculum/USER_CURRICULUM_SUCCESS":
      return {
        ...state,
        userCurriculumFile: action.payload.userCurriculumFile
      };

    case "@curriculum/LANGUAGES_SUCCESS":
      return {
        ...state,
        languages: action.payload.languages
      };
    case "@curriculum/SKILLS_SUCCESS":
      return {
        ...state,
        skills: action.payload.skills
      };
    case "@curriculum/CURRICULUM_FAILURE":
      return {
        ...state,
        error: action.payload.error
      };
    case "@curriculum/PERCENTAGE":
      return {
        ...state,
        percentage: action.payload
      };
    case "@curriculum/NEW_SKILL_SUCCESS":
      return {
        ...state,
        newSkill: action.payload.newSkill
      };
    case "@curriculum/USER_PICTURE_SUCCESS":
      return {
        ...state,
        userPicture: action.payload.userPicture
      };
    case "@curriculum/LIST_COUNTRIES_SUCCESS":
      return {
        ...state,
        listCountries: action.payload.listCountries
      };
    case "@curriculum/LIST_STATES_SUCCESS":
      return {
        ...state,
        listStates: action.payload.listStates
      };
    case "@curriculum/CHANGE_SUBMIT_BUTTON_VISIBILITY":
      return {
        ...state,
        submitButtonVisible: action.payload.visibility
      };

    case "@curriculum/BLOCK_BUTTONS":
      return {
        ...state,
        blockedButtons: true
      };

    case "@curriculum/UNBLOCK_BUTTONS":
      return {
        ...state,
        blockedButtons: false
      };

    default:
      return state;
  }
}
