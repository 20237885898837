const INITIAL_STATE = {
  hasAppliedTalentBase: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@company/APPLY_TALENT_BASE_SUCESS":
      return {
        ...state,
        hasAppliedTalentBase: true
      };
    case "@company/APPLY_TALENT_BASE_FAILURE":
      return {
        ...state,
        hasAppliedTalentBase: false
      };
    case "@company/APPLICANT_CANDIDATE_TALENT_BASE_RESET":
      return {
        ...state,
        hasAppliedTalentBase: false
      };
    default:
      return state;
  }
}
