import React from "react";
// import { Pill, LinkPill, Link, Default } from "./components";
import { Default, Pill } from "./components";

import { ReactComponent as Close } from "../../assets/svg/close.svg";

const Badge = ({
  type,
  text,
  closeIcon = false,
  isTooltip = false,
  iconColor = false,
  description = "",
  fallbackClose
}) => {
  const renderBadge = () => {
    switch (type) {
      case "pill":
        return (
          <Pill
            text={text}
            isTooltip={isTooltip}
            description={description}
            closeIcon={closeIcon === true && <Close />}
            iconColor={iconColor}
            fallbackClick={fallbackClose}
          />
        );
      // case "linkPill":
      // return <LinkPill  />;
      // case "link":
      // return <Link onClick={onClick} text={text} leftIcon={leftIcon} rightIcon={rightIcon} {...rest} />;
      default:
        return (
          <Default
            text={text}
            isTooltip={isTooltip}
            description={description}
            closeIcon={closeIcon === true && <Close />}
            iconColor={iconColor}
            fallbackClick={fallbackClose}
          />
        );
    }
  };

  return <>{renderBadge()}</>;
};

export default Badge;
