import axios from "axios";
import * as cookie from "../utils/cookie";

const API = (hasLogged = true) => {
  const { token } = cookie.getJSON();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  api.defaults.timeout = 60000;
  api.interceptors.request.use(async (config) => {
    config.headers["Content-Type"] = "application/json";

    if (hasLogged) {
      config.headers.jToken = token || "";
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) =>
      // if (error.response.status === 401) {
      //     cookie.set("token", "");
      // }

      // if (!!error.response && error.response.status === 303) {
      //     window.location.href = error.response.data.link;
      // }
      // if (!isLogin) {
      //     if (!!error.response && error.response.status === 401) {
      //         // logout();
      //     }
      // }

      Promise.reject(error)
  );

  return api;
};

export default API;
