import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        overflow: ${({ modalOpen }) => (modalOpen ? "hidden" : "auto")};
    }
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1001;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
`;

export const ModalDialog = styled.div`
  position: relative;
  width: 100%;
  z-index: 1002;
  display: flex;

  ${({ scrollable }) =>
    scrollable &&
    `
		max-height: calc(100% - 1rem);
		${ModalBody} {
			overflow-y: auto;

			::-webkit-scrollbar {
				width: 6px;
			}

			::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				background-color: #333333;
			}
		}
    `}

  ${({ position }) =>
    position === "topLeft"
      ? "justify-content: flex-start;"
      : position === "topRight"
      ? "justify-content: flex-end;"
      : position === "topCenter"
      ? "justify-content: center;"
      : position === "middleLeft"
      ? "justify-content: flex-start; align-itens: center;"
      : position === "middleRight"
      ? "justify-content: flex-end; align-itens: center;"
      : position === "bottomLeft"
      ? "justify-content: flex-start; align-itens: flex-end;"
      : position === "bottomRight"
      ? "justify-content: flex-end; align-itens: flex-end;"
      : position === "bottomCenter"
      ? "justify-content: center; align-itens: flex-end;"
      : "justify-content: center; align-itens: center;"}
`;

export const ModalOverlay = styled.div`
  ${({ hasBgShadow, bgShadow }) =>
    hasBgShadow &&
    `
	  	position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		min-height: 100%;
		background-color: ${bgShadow || "#000"};
		z-index: 1000;
		opacity: 0.5;
	`}
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;
  z-index: 1003;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  /* less than 576px */
  width: 90%;
  margin: 110px 0;

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    ${({ size }) =>
      size === "fullsize"
        ? "width: calc(100% - 40px);"
        : size === "small"
        ? "width: 300px;"
        : size === "medium"
        ? "width: 600px;"
        : size === "large"
        ? "width: 900px;"
        : "width: auto;"}

    ${({ position }) =>
      position === "topLeft"
        ? "margin: 20px 0 20px 20px;"
        : position === "topRight"
        ? "margin: 20px 20px 20px 0;"
        : position === "topCenter"
        ? "margin: 20px 0;"
        : position === "middleLeft"
        ? "margin: 0 0 0 20px;"
        : position === "middleRight"
        ? "margin: 0 20px 0 0;"
        : position === "bottomLeft"
        ? "margin: 0 0 20px 20px;"
        : position === "bottomRight"
        ? "margin-bottom: 20px; margin-right: 20px; margin: 0 20px 20px 0;"
        : position === "bottomCenter"
        ? "margin-bottom: 20px;"
        : "margin: 1rem"}
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
`;

export const ButtonClose = styled.div`
  visibility: ${({ showClose }) => (showClose ? "visible" : "hidden")};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
    fill: #333333;
    cursor: pointer;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
`;

export const MainModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: absolute;
  z-index: 1001;
`;

export const MainModalBg = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  background: #5f4a8799;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`;

export const MainModal = styled.div`
  display: flex;
  flex: 1;
  position: fixed;
  background: #fff;
  z-index: 1001;
  border-radius: 5px;
  min-height: 300px;
  max-height: 90vh;
  overflow-y: auto;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #8872b2;
  }

  ${({ sizeMode }) =>
    sizeMode === "fullscreen"
      ? `width: calc(100% - 200px); min-width: 900px; height: 100%;`
      : `width: auto; min-width: 300px; height: auto;`}
`;

export const Header = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  padding-top: 20px;

  svg {
    width: ${({ sizeMode }) => (sizeMode === "auto" ? 20 : 25)}px;
    height: ${({ sizeMode }) => (sizeMode === "auto" ? 20 : 25)}px;
    fill: #8872b2;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  flex: 6;
  padding-left: 50px;
  padding-right: 50px;
  overflow: auto;

  :hover::-webkit-scrollbar {
    display: flex;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #8872b2;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
`;
export const Text = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  display: block;
  text-align: center;
  letter-spacing: -0.1px;
  color: #333333;
  margin-bottom: 27px;
`;
export const TextNormal = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: block;
  text-align: center;
  letter-spacing: -0.1px;
  color: #333333;
`;
export const IconContainer = styled.div`
  margin-bottom: 22px;

  i {
    color: ${({ color }) => (color ? `${color}` : `#28a745`)};
    font-size: 72px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 40px 60px 0;
  max-width: 400px;

  @media (max-width: 575px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    padding: 0px 0px 20px 0px;
  }
`;
export const ButtonCancel = styled.div`
  flex: ${({ flex }) => flex || "auto"};
  max-width: 160px;
`;
export const ButtonConfirm = styled.div`
  flex: ${({ flex }) => flex || "auto"};
  max-width: 190px;
`;

export const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
