import React from "react";

import { Button } from "@components/index";

import * as Styled from "./styles";

const modalsContent = {
  noApply: {
    title: "Deseja desistir?",
    legend:
      "ATENÇÃO: Essa ação não poderá ser desfeita e você perderá os dados não salvos."
  },
  applying: {
    title: "Deseja desistir de se candidatar?",
    legend:
      "ATENÇÃO: Essa ação não poderá ser desfeita e você perderá os dados não salvos."
  }
};

const Body = ({ stateOfEditing }) => (
  <Styled.Container>
    <Styled.Title>{modalsContent[stateOfEditing].title}</Styled.Title>
    <Styled.Description>
      {modalsContent[stateOfEditing].legend}
    </Styled.Description>
  </Styled.Container>
);

const Footer = ({ closing, quitAndRedirect }) => (
  <Styled.FooterButtons>
    <Button
      medium
      isUppercase={false}
      fontWeight="bold"
      type="link"
      style={{ color: "#333333" }}
      onClick={closing}
    >
      Cancelar
    </Button>
    <Button
      medium
      fontWeight="bold"
      type="dark"
      isUppercase={false}
      onClick={quitAndRedirect}
    >
      Sim, desistir
    </Button>
  </Styled.FooterButtons>
);

export { Body, Footer };
