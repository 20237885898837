import { useContext } from "react";
import { CurriculumModalContext } from "@contexts/index";

export function useCurriculumModal() {
  const curriculumModal = useContext(CurriculumModalContext);

  if (!curriculumModal) {
    throw new Error(
      "CurriculumModal should be used within a CurriculumProvider"
    );
  }

  return curriculumModal;
}
