import React from "react";

import useAlerts from "@utils/hooks/useAlerts";

import { Container } from "./styles";
import AlertBox from "./components/alertBox";

const Alerts = () => {
  const { alerts } = useAlerts();

  return <Container>{alerts}</Container>;
};

export const alert = {
  warning: (props) => <AlertBox type="warning" {...props} />,
  error: (props) => <AlertBox type="error" {...props} />,
  default: (props) => <AlertBox type="default" {...props} />
};

export default Alerts;
