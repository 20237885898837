import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.25rem 0;
  border-top: 0.063rem solid #d6d6d6;
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
`;

export const LeftLink = styled.a`
  font-family: "Roboto";
  font-weight: 700;
  font-size: 0.813rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.span`
  font-family: "Roboto";
  color: #8f8f8f;
  margin: 0 1.25rem;
  font-size: 0.813rem;
  font-weight: 700;
`;

export const RightLink = styled.a`
  font-family: "Roboto";
  color: #8f8f8f;
  font-weight: 700;
  font-size: 0.813rem;
  :hover {
    text-decoration: underline;
    color: #8f8f8f;
  }
  @media screen and (max-width: 540px) {
    margin-bottom: 54px;
  }
`;
