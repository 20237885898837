import { call, all, takeLatest, put, take } from "redux-saga/effects";
import { resolvePromiseAction } from "@adobe/redux-saga-promise";

import jwt from "jsonwebtoken";
import { notification } from "antd";

import * as cookie from "@utils/cookie";
import { getSlug } from "@utils/getHost";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_CANDIDATE,
  CURRENT_TERMS_OF_USE,
  DEFAULT_HOST_LINKEDIN_CALLBACK,
  DEFAULT_HOST_FACEBOOK_CALLBACK,
  DEFAULT_HIDDEN_VACANCY_ROUTES,
  DEFAULT_HOST_COMPANY,
  TYPES_SOCIAL_NETWORKS
} from "@services/constants";
import * as api from "./repository";

function encryptTokenExpires(tokenExpiration) {
  const expireSeconds = tokenExpiration * 1000;
  const expires = new Date(new Date().getTime() + expireSeconds);
  const data = jwt.sign({ expires }, process.env.REACT_APP_PRIVATE_KEY);

  cookie.set("token_expiration", data);
}

function resetUserData() {
  cookie.set("token", "");
  cookie.set("token_expiration", "");
}

function* authenticateUserByTokenSaga({ payload }) {
  const { token, token_expiration, user, profiler } = payload;
  const { destination, [window.name]: tab } = cookie.getJSON();
  let hasApplyedVacancy = false;

  try {
    if (tab?.applyingVacancy) {
      const { data: userApplications } = yield call(api.getMyApplications, {
        jToken: token
      });
      hasApplyedVacancy = userApplications?.data.find(
        (application) => application.id === tab?.vacancy?.id
      );
    }
  } catch (err) {}

  try {
    cookie.set("warningCookies", false);
    cookie.set("token", token);
    encryptTokenExpires(token_expiration);

    if (user.termsAccepted !== CURRENT_TERMS_OF_USE) {
      yield call(api.updateTermUse, { terms: CURRENT_TERMS_OF_USE });
    }

    if (tab?.applyingTalentBase && tab?.company?.slug) {
      if (profiler?.passaport) {
        yield put({
          type: "@company/APPLICANT_CANDIDATE_TALENT_BASE",
          payload: {
            company: tab.company,
            showMessage: false,
            user,
            profiler
          }
        });
        yield take("@company/APPLY_TALENT_BASE_FINISH");
      } else {
        cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
      }
    } else if (tab?.applyingVacancy) {
      if (!hasApplyedVacancy) {
        cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
      } else {
        cookie.set(
          window.name,
          {
            ...tab,
            vacancy: null,
            statusApply: "has_applied",
            applied: { vacancy: tab?.vacancy }
          },
          { expires: 1 }
        );

        if (tab?.company?.slug) {
          const COMPANY_HOST = DEFAULT_HOST_COMPANY(tab.company.slug);
          cookie.redirect(`${COMPANY_HOST}/applicationStatus`);
        } else {
          cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/applicationStatus`);
        }
      }
    } else {
      cookie.set(
        window.name,
        {
          ...tab,
          statusApply: "",
          applied: null
        },
        { expires: 1 }
      );
      cookie.set("destination", "");
      cookie.redirect(destination || DEFAULT_HOST_CANDIDATE); // perfil do candidato por padrão
    }
  } catch (err) {
    resetUserData();
    const auth_message = {
      type: "error",
      message: "Atenção",
      description:
        err.response && err.response.data
          ? err.response.data.message
          : "Não foi possível autenticar. Por favor, tente novamente mais tarde."
    };

    cookie.set("auth_message", auth_message);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } finally {
    yield put({
      type: "@utilities/MODAL",
      payload: { show: false }
    });
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* checkPoliciesUser(token, token_expiration) {
  try {
    // pega os dados do usuário
    const { data: responseUser } = yield call(api.currentUserData, false, {
      jToken: token
    });
    const { data: responseProfiler } = yield call(api.getProfiler, false, {
      jToken: token
    });
    const profiler = responseProfiler;
    const user = responseUser.data;
    const isShowingWarningCookies = ![false, "false"].includes(
      cookie.get("warningCookies")
    );
    // verifica se o termo de privacidade e cookies do usuário bate com o atual
    // e se o mesmo não aprovou o termo antes de realizar o login
    if (
      user?.termsAccepted !== CURRENT_TERMS_OF_USE &&
      !isShowingWarningCookies
    ) {
      yield put({
        type: "@utilities/MODAL",
        payload: {
          modal: {
            show: true,
            type: "updateTerms",
            options: {
              position: "center",
              size: "medium",
              hasBgShadow: true,
              showClose: false
            },
            dataParams: {
              token,
              token_expiration,
              user,
              isShowingWarningCookies
            }
          }
        }
      });
    } else {
      yield authenticateUserByTokenSaga({
        payload: {
          token,
          token_expiration,
          user,
          profiler,
          isShowingWarningCookies
        }
      });
    }
  } catch (err) {
    resetUserData();

    const auth_message = {
      type: "error",
      message: "Atenção",
      description:
        err.response && err.response.data
          ? err.response.data.message
          : "Não foi possível autenticar. Por favor, tente novamente mais tarde."
    };

    cookie.set("auth_message", auth_message);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* signInSaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: response } = yield call(api.signIn, {
      user: payload.email,
      password: payload.password
    });

    yield cookie.set("hasProfileExpired", response.hasProfileExpired);

    yield checkPoliciesUser(response.token, response.token_expiration);
  } catch (err) {
    resetUserData();

    if (err.response && err.response.data) {
      notification.error({
        message: "Atenção",
        description: err.response.data.message
      });
    } else {
      notification.error({
        message: "Atenção",
        description:
          "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
      });
    }
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* signInLinkedinSaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: response } = yield call(api.signInLinkedin, {
      code: payload.code,
      redirectUri: DEFAULT_HOST_LINKEDIN_CALLBACK
    });

    yield cookie.set("hasProfileExpired", response.hasProfileExpired);
    yield cookie.set("MethodSignIn", TYPES_SOCIAL_NETWORKS[0], { expires: 7 });
    yield checkPoliciesUser(response.token, response.token_expiration);
  } catch (err) {
    resetUserData();

    const auth_message = {
      type: "error",
      message: "Atenção",
      description:
        "Não foi possível autenticar com o Linkedin. Por favor, tente novamente mais tarde."
    };

    cookie.set("auth_message", auth_message);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* logoutSaga() {
  const redirectTo = (route) => {
    if (
      route.includes("applicationStatus") ||
      route.includes("talentBankStatus")
    ) {
      const slug = getSlug();
      const redirectRoute =
        slug && slug !== "login"
          ? DEFAULT_HOST_COMPANY(slug)
          : DEFAULT_HOST_LOGIN;

      cookie.redirect(redirectRoute);
    } else {
      window.location.reload();
    }
  };

  const clearCookiesData = () => {
    const exceptCookies = ["warningCookies", "origin", "MethodSignIn"];
    const cookies = cookie.getJSON();

    Object.keys(cookies).forEach((key) => {
      if (!exceptCookies.includes(key)) {
        cookie.remove(key);
      }
    });
  };

  const { href: HOST, pathname } = window.location;

  try {
    yield call(api.logout);
    clearCookiesData();

    if (
      HOST.includes(DEFAULT_HOST_LOGIN) ||
      (HOST.includes(DEFAULT_HOST_CANDIDATE) &&
        !HOST.includes(DEFAULT_HIDDEN_VACANCY_ROUTES))
    ) {
      cookie.redirect(DEFAULT_HOST_LOGIN);
    } else {
      cookie.set("auth_message", "");
      redirectTo(pathname);
    }
  } catch (err) {
    clearCookiesData();

    if (err.response && err.response.data) {
      notification.error({
        message: "Atenção",
        description: err.response.data.message
      });

      redirectTo(pathname);
    } else {
      notification.error({
        message: "Atenção",
        description:
          "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
      });
    }
  }
}

function* restoreTokenSaga() {
  try {
    const { data: response } = yield call(api.restoreToken);
    cookie.set("token", response.token);
    encryptTokenExpires(response.token_expiration);
  } catch (err) {}
}

function* forgotPasswordSaga(action) {
  try {
    const { data: response } = yield call(api.forgotPassword, action.payload);
    yield call(resolvePromiseAction, action, { displayForgotFormView: false });
  } catch (err) {
    yield call(resolvePromiseAction, action, { displayForgotFormView: true });
    notification.error({
      message: "Atenção",
      description:
        "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* updatePasswordSaga(action) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true, error: {} }
  });
  try {
    const { data: response } = yield call(api.updatePassword, action.payload);
    yield put({
      type: "@auth/SUCCESS_PASSWORD_RESET",
      payload: {
        resetPasswordSuccess: true
      }
    });
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } catch (err) {
    if (err.response?.data?.code_request_jobs === 401) {
      yield put({
        type: "@auth/ERROR_REQUEST",
        payload: {
          error: err.response.data
        }
      });
    } else {
      notification.error({
        message: "Atenção",
        description:
          "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
      });
    }
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* callbackSignUpByToken(token, token_expiration) {
  try {
    // dados da autenticação
    cookie.set("token", token);
    encryptTokenExpires(token_expiration);

    yield call(api.updateTermUse, { terms: CURRENT_TERMS_OF_USE });
    cookie.set("warningCookies", false);

    const { [window.name]: tab } = cookie.getJSON();

    // reseta os dados de aplicações passadas
    cookie.set(
      window.name,
      {
        ...tab,
        statusApply: "",
        applied: null
      },
      { expires: 1 }
    );

    // irá validar na aplicação a vaga após o cadastro dos dados no curriculo
    // da segmento no preenchimento do curriculo e validar profiler
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
  } catch (err) {
    resetUserData();

    if (err.response && err.response.data) {
      notification.error({
        message: "Atenção",
        description: err.response.data.message
      });
    } else {
      notification.error({
        message: "Atenção",
        description:
          "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
      });
    }
  }
}

function* signUpSaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true, error: {} }
  });

  try {
    const { data: response } = yield call(api.signUp, payload);

    yield callbackSignUpByToken(response.data.token, response.token_expiration);
  } catch (err) {
    resetUserData();

    if (err.response && err.response.data) {
      if (err.response?.data?.code_request_jobs === 401) {
        yield put({
          type: "@auth/ERROR_REQUEST",
          payload: {
            error: err.response.data
          }
        });
      }
    } else {
      notification.error({
        message: "Atenção",
        description:
          "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
      });
    }
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* signUpLinkedinSaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: response } = yield call(api.signUpLinkedin, {
      code: payload.code,
      redirectUri: DEFAULT_HOST_LINKEDIN_CALLBACK
    });

    // yield callbackSignUpByToken(response.data.token, response.token_expiration);
    yield checkPoliciesUser(response.data.token, response.token_expiration);
  } catch (err) {
    resetUserData();

    const auth_message = {
      type: "error",
      message: "Atenção",
      description:
        err.response && err.response.data
          ? err.response.data.message
          : "Não foi possível autenticar. Por favor, tente novamente mais tarde."
    };

    cookie.set("auth_message", auth_message);
    cookie.set("auth_view_type", "signUp");
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* getFacebookLinkSaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: response } = yield call(api.getFacebookLink, {
      redirectUri: DEFAULT_HOST_FACEBOOK_CALLBACK
    });

    if (response?.link) {
      cookie.redirect(response.link);
    }
  } catch (err) {
    resetUserData();

    const auth_message = {
      type: "error",
      message: "Atenção",
      description:
        "Não foi possível autenticar com o Facebook. Por favor, tente novamente mais tarde."
    };

    cookie.set("auth_message", auth_message);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* signUpOrSignInFacebookSaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: response } = yield call(api.signUpOrSignInFacebook, {
      ...payload,
      redirectUri: DEFAULT_HOST_FACEBOOK_CALLBACK
    });
    const token = response?.data?.token || response?.token;
    yield checkPoliciesUser(token, response.token_expiration);
  } catch (err) {
    resetUserData();

    const auth_message = {
      type: "error",
      message: "Atenção",
      description:
        "Não foi possível autenticar com o Facebook. Por favor, tente novamente mais tarde."
    };

    cookie.set("auth_message", auth_message);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest("@auth/SIGN_IN_REQUEST", signInSaga),
  takeLatest("@auth/SIGN_IN_LINKEDIN_REQUEST", signInLinkedinSaga),
  takeLatest("@auth/LOGOUT_REQUEST", logoutSaga),
  takeLatest("@auth/RESTORE_TOKEN_REQUEST", restoreTokenSaga),
  takeLatest("@auth/FORGOT_PASSWORD_REQUEST.TRIGGER", forgotPasswordSaga),
  takeLatest("@auth/UPDATE_PASSWORD", updatePasswordSaga),
  takeLatest("@auth/SIGN_UP_REQUEST", signUpSaga),
  takeLatest("@auth/SIGN_UP_LINKEDIN_REQUEST", signUpLinkedinSaga),
  takeLatest("@auth/AUTHENTICATION_USER_BY_TOKEN", authenticateUserByTokenSaga),
  takeLatest("@auth/GET_FACEBOOK_REQUEST", getFacebookLinkSaga),
  takeLatest(
    "@auth/SIGN_UP_OR_SIGN_IN_FACEBOOK_REQUEST",
    signUpOrSignInFacebookSaga
  )
]);
