import styled from "styled-components";

export const Header = styled.div`
  margin-bottom: 35px;

  @media screen and (max-width: 450px) {
    margin-bottom: 30px;
  }
`;

export const ListLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  img {
    width: 50px;
    height: 50px;
  }
`;

export const Close = styled.div`
  visibility: visible;
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
    fill: #333333;
    cursor: pointer;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const SubTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`;

export const SubTitleSpan = styled.span`
  font-weight: normal;
  text-transform: none;
`;

export const ShareContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const ShareText = styled.span`
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 5px;
`;

export const ShareButton = styled.div`
  button > svg {
    circle {
      stroke: #d0ccc8;
      stroke-width: 4px;
      fill: none;
      cx: 32;
      cy: 32;
      r: 30;
    }
    path {
      fill: ${({ color }) => color || "#333333"};
    }
  }
  button:hover > svg {
    circle {
      stroke: ${({ color }) => color || "#333333"};
      stroke-width: 4px;
      fill: ${({ color }) => color || "#333333"};
      cx: 32;
      cy: 32;
      r: 30;
    }
    path {
      fill: #ffffff;
    }
  }

  :nth-child(n) {
    margin-left: 5px;
  }
  :first-child {
    margin-left: 0px;
  }
`;

export const ShareButtonsContainer = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;

  /* less than 576px */
  flex-wrap: wrap;

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    flex-wrap: nowrap;
  }
`;

export const Body = styled.div`
  width: 100%;
`;

export const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    path {
      fill: #333333;
    }
  }
`;

export const DescriptionVideoContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DescriptionVideo = styled.div`
  max-width: 400px;
  width: 100%;
  height: 100%;
  border: none;

  iframe {
    border-radius: 1rem;

    /* less than 768px */
    min-height: 165px;

    /* Medium devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      min-height: 225px;
    }
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #333333;
  margin-top: 50px;

  ${({ isHidden }) => (isHidden ? "display: none;" : "display: grid;")}

  ${({ hasTwo }) =>
    hasTwo
      ? `
                grid-template-columns: 1fr 1fr;
                grid-gap: 30px;
            `
      : `
                grid-template-columns: 0.5fr;
                grid-gap: 0px;
            `}

    @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  @media screen and (max-width: 450px) {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const DetailsBox = styled.div`
  display: flex;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  flex: 1;
  flex-direction: column;
`;

export const DetailsBoxHeader = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 10px 0;
`;

export const DetailsBoxTitle = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  margin-left: 1rem;
`;

export const DetailsBoxBody = styled.div`
  width: 100%;
  padding-top: 15px;
`;

export const DetailsBoxItem = styled.div`
  padding-bottom: 40px;
  padding-left: 15px;
`;

export const DetailsBoxDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DetailsBoxDescriptionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-left: 30px;
  margin-bottom: 12px;
`;

export const DetailsBoxDescriptionLevel = styled.div`
  width: 100%;
  margin-left: auto;
  max-width: 120px;
  margin-bottom: 12px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 250px;
  align-items: center;
  text-align: center;
`;

export const Title = styled.span`
  ${(props) => props.hasRightSpace && "margin-right: 15px;"}
`;

export const AvailablePositions = styled.span`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color: ${({ color }) => color || "#333333"};
  white-space: nowrap;
  padding-top: 4px;

  i {
    margin-right: 2px;
    font-size: 18px;
  }
`;
