import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef
} from "react";

import { ReactComponent as Icon } from "../../assets/svg/iconSelect.svg";
import Loading from "../../assets/loading/load.gif";

import * as Styled from "./styles";

import { getInitials } from "../../utils/getInitials";

const Select = forwardRef(
  (
    {
      options = [],
      placeholder = "",
      onChange,
      loading = false,
      canClear = true,
      clear = false,
      disabled = false,
      showInitial = false,
      valueInitials = "",
      selected = {},
      error,
      ...rest
    },
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [_hasLoading, _setHasLoading] = useState(loading);
    const [_options, _setOptions] = useState(options);
    const [_selection, _setSelection] = useState({});

    useEffect(() => {
      _setHasLoading(loading);
    }, [loading]);

    useEffect(() => {
      // eslint-disable-next-line
            if (!!selected.value && !!options) {
        const newSelected = options.filter(
          (e) => e.value.toString() === selected.value.toString()
        );
        _setSelection(newSelected[0] ? newSelected[0] : selected);
      } else {
        _setSelection({});
      }
      // eslint-disable-next-line
        }, [selected]);

    useEffect(() => {
      const newOptions = options.map((item) => ({
        ...item,
        selected: item.value === _selection.value && !!item.isTitle === false
      }));

      _setOptions(newOptions);
    }, [options, _selection]);

    useImperativeHandle(ref, () => ({
      _handleClearSelect: () => {
        _handleChangeValue({ text: "", value: "" });
      }
    }));

    const _handleChangeValue = (e) => {
      const data = { ...e, name: e.text, selected: true };

      setShow(false);
      onChange(data);
      _setSelection(data);
    };

    const _handleClearSelect = () => {
      setShow(false);
      _setSelection({});
      onChange({});
    };

    const _renderInitialPhoto = (name, showInput) =>
      showInput ? (
        <Styled.InitialPhoto>
          <Styled.InitialPhotoText>{getInitials(name)}</Styled.InitialPhotoText>
        </Styled.InitialPhoto>
      ) : (
        <Styled.InitialPhoto>
          <Styled.InitialPhotoText>{getInitials(name)}</Styled.InitialPhotoText>
        </Styled.InitialPhoto>
      );

    const _renderPhotoList = (image, showInput) => (
      <Styled.Photo data={image} />
    );

    const showPhoto = (name, image, showInput = false) => {
      const hasPhoto = image !== "" && image !== undefined;
      return showInitial && hasPhoto === false
        ? _renderInitialPhoto(name, showInput)
        : showInitial && hasPhoto
        ? _renderPhotoList(image, showInput)
        : null;
    };

    return (
      <Styled.SelectContent
        onBlur={() => {
          setShow(false);
        }}
        tabIndex={0}
      >
        <Styled.Placeholder
          onClick={() => {
            if (disabled === false) {
              setShow(!show);
            }
          }}
          hasPhoto={showInitial}
          show={show}
          disabled={disabled}
          error={error}
          {...rest}
        >
          {!!_selection.text === false ? (
            <Styled.PlaceholderText hasPhoto={showInitial}>
              {placeholder}
            </Styled.PlaceholderText>
          ) : (
            <Styled.ValueText>
              {showPhoto(_selection.text, _selection.img)}
              {_selection.text}
            </Styled.ValueText>
          )}
          <Styled.Icon>
            <Icon />
          </Styled.Icon>
        </Styled.Placeholder>

        <Styled.ContainerList {...rest}>
          {loading || _hasLoading ? (
            <Styled.ListLoading show={show} {...rest}>
              <img src={Loading} alt="loading" />
            </Styled.ListLoading>
          ) : (
            <Styled.List show={show} hasPhoto={showInitial} {...rest}>
              {canClear && (
                <Styled.ListItem onClick={_handleClearSelect}>
                  <i>Limpar</i>
                </Styled.ListItem>
              )}

              {_options.map((e, i) => {
                if (e.isTitle) {
                  return (
                    <Styled.ListItemTitle key={i}>
                      {e.text}
                    </Styled.ListItemTitle>
                  );
                }
                return (
                  <Styled.ListItem
                    key={i}
                    onClick={(event) => {
                      event.preventDefault();
                      _handleChangeValue(e);
                    }}
                    hasImage={showInitial}
                    selected={e.selected}
                  >
                    {showPhoto(e.text, e.img)}
                    {e.text}
                  </Styled.ListItem>
                );
              })}
            </Styled.List>
          )}
        </Styled.ContainerList>

        {!!error && <Styled.InvalidFeedback>{error}</Styled.InvalidFeedback>}
      </Styled.SelectContent>
    );
  }
);

export default Select;
