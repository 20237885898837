import styled from "styled-components";

import { Input as InputComponent } from "@components/form";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-end;
  gap: 1rem;

  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.justifyContent && `align-items: ${props.justifyContent};`}

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  flex: ${({ flex }) => flex || 1};
  width: 100%;
  margin-bottom: 2.125rem;

  > p {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
    margin-bottom: 2rem;
    ${({ hideMobile }) => hideMobile && "display: none"};
  }
`;

export const Title = styled.h3`
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Roboto Condensed";

  margin-bottom: 1.5rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled(InputComponent)`
  &:not(:last-child) {
    margin-bottom: calc(3.2rem - 1.125rem);
  }
`;
