import useSwr from "swr";
import API from "../../services/api";

const api = API();

export default function useFetch(url, options = {}) {
  const { data, error, mutate } = useSwr(
    url,
    async (url) => {
      const response = await api.get(url);
      return response.data || {};
    },
    {
      ...options,
      onError: (error, key) => {
        if (error.status !== 403 && error.status !== 404) {
          // We can send the error to Sentry,
          // or show a notification UI.
        }
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error.response) {
          // Never retry on 404.
          if ([401, 404].includes(error.response.status)) return;
        }

        // Only retry up to 3 times.
        if (retryCount >= 3) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      }
    }
  );

  return { data, error, loading: !data && !error, mutate };
}
