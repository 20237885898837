import React, { useRef } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import resolve from "@utils/resolve";

import { ReactComponent as BlockSVG } from "@assets/svg/block.svg";
import { ReactComponent as CheckSVG } from "@assets/svg/check.svg";

import CurrencyFormat from "./components/currencyFormat";
import NumberFormat from "./components/numberFormat";
import PasswordFormat from "./components/passwordFormat";

import * as Styled from "./styles";
import { MessageError, Label } from "..";

const Input = ({
  label = undefined,
  name,
  style,
  className,
  required,
  type = "text",
  leftIcon,
  blocked,
  prefix,
  showValidateIcon = true,
  onChange = () => null,
  ...rest
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const value = useWatch({ control, name });

  const prefixRef = useRef(null);
  const { message: error } = resolve(name, errors);

  const containerProps = {
    style,
    className
  };

  const inputContainerProps = {
    $prefix: !!prefix,
    $prefixWidth: prefixRef.current && prefixRef.current.clientWidth,
    $hasLeftIcon: !!leftIcon,
    $hasError: !!error,
    $isBlocked: !!blocked
  };

  const inputProps = {
    ...rest,
    showValidateIcon,
    type: type === "currency" || type === "number" ? "text" : type
  };

  const InputToRender = {
    currency: CurrencyFormat,
    number: NumberFormat,
    password: PasswordFormat
  }[type];

  const getValidationIcon = showValidateIcon ? (
    blocked ? (
      <BlockSVG />
    ) : (
      !error && value && <CheckSVG />
    )
  ) : null;

  return (
    <Styled.Container {...containerProps}>
      {label !== undefined && <Label required={required}>{label}</Label>}
      <Styled.InputContainer {...inputContainerProps}>
        {leftIcon}
        {prefix && <Styled.Prefix ref={prefixRef}>{prefix}</Styled.Prefix>}

        <Controller
          name={name}
          control={control}
          render={({ field }) =>
            InputToRender ? (
              <InputToRender
                {...inputProps}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onChange(e);
                }}
              />
            ) : (
              <input
                {...inputProps}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onChange(e);
                }}
              />
            )
          }
        />

        {getValidationIcon}
      </Styled.InputContainer>
      {error && <MessageError message={error} />}
    </Styled.Container>
  );
};

export default Input;
