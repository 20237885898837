import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import * as authActions from "@store/modules/auth/actions";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_COMPANY
} from "@services/constants";

import { Header, Banner, Button } from "@components/index";

import { useAuth } from "@utils/hooks/useAuth";
import { useCompany } from "@utils/hooks/useCompany";

import * as cookie from "@utils/cookie";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import JobsBannerDesktopDefault from "@assets/svg/banner_default_jobs_desktop.svg";
import JobsBannerMobileDefault from "@assets/svg/banner_default_jobs_mobile.svg";

import * as StyledGlobal from "@styles/global";
import * as Styled from "../styles";
import StatusMessage from "../components/applicationMessageByStatus";
import Skeleton from "../components/skeleton";

function TalentBankStatus() {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const { company } = useCompany();
  const { token, user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    const data = cookie.getJSON();
    const tab = data[window.name];

    if (tab) {
      const { applied, statusApply } = tab;
      let newType = "not_found";

      if (applied?.company) {
        newType = statusApply || "error";
      }

      setType(newType);

      if (newType !== "error") {
        cookie.set(
          window.name,
          { ...tab, applyingTalentBase: false },
          { expires: 1 }
        );
      }
    } else {
      setType("not_found");
    }

    setTimeout(() => setLoading(false), 1000);
  }, [company]);

  const goToVacanciesCompanyHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    const host = DEFAULT_HOST_COMPANY(company.slug);
    cookie.redirect(`${host}/vacancies`);
  }, [company]);

  const goToCurriculumHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
  }, []);

  const perfilHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate`);
  }, []);

  const goToMyApplicationsHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate#company`);
  }, []);

  const renderSuccess = useMemo(
    () => (
      <StatusMessage
        title=" Sucesso!"
        iconClass="fas fa-check"
        text={[
          `Seu cadastro no banco de talentos da ${company.name} foi realizado com`,
          "sucesso! Entraremos em contato caso surja uma nova oportunidade compatível",
          "com as suas competências."
        ]}
        description={[
          "Agora você pode",
          <strong> voltar para a página da empresa</strong>,
          ", ou ",
          <strong>ver o status das suas aplicações.</strong>
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          <Styled.ContainerButton>
            <Button
              text="Ver minhas aplicações"
              type="custom"
              backgroundColor="#333333"
              textColor="#ffffff"
              fontWeight="bold"
              borderColor="#333333"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              onClick={goToMyApplicationsHandle}
              isUppercase={false}
              medium
            />
          </Styled.ContainerButton>
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [company, goToVacanciesCompanyHandle, goToMyApplicationsHandle]
  );

  const renderHasApplied = useMemo(
    () => (
      <StatusMessage
        title="Tudo certo!"
        iconClass="fas fa-check"
        text={[
          `Você já estava cadastrado no banco de talentos da ${company?.name}.`
        ]}
        description={[
          "Agora você pode",
          <strong> voltar para a página da empresa</strong>,
          ", ou ",
          <strong>ver o status das suas aplicações.</strong>
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          <Styled.ContainerButton>
            <Button
              text="Ver minhas aplicações"
              type="custom"
              backgroundColor="#333333"
              textColor="#ffffff"
              fontWeight="bold"
              borderColor="#333333"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              onClick={goToMyApplicationsHandle}
              isUppercase={false}
              medium
            />
          </Styled.ContainerButton>
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [company, goToVacanciesCompanyHandle, goToMyApplicationsHandle]
  );

  const renderError = useMemo(
    () => (
      <StatusMessage
        title={[
          "Ops! Tivemos um problema ",
          <span role="img" aria-label="sad">
            😕
          </span>
        ]}
        text={[
          `Algo estranho aconteceu e não foi possível se cadastrar no banco de talentos da`,
          <strong>{company?.name || ""}.</strong>,
          "Isso pode ser devido à alguma falha de conexão entre o sistema e a vaga."
        ]}
        secondaryText={[
          <strong>
            Clique no botão abaixo para editar seu currículo e tentar novamente.
          </strong>,
          "Se este problema persistir, entre em contato com o nosso time de suporte."
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          <Styled.ContainerButton>
            <Button
              text="Editar meu currículo"
              type="custom"
              backgroundColor="#333333"
              textColor="#ffffff"
              fontWeight="bold"
              borderColor="#333333"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              onClick={goToCurriculumHandle}
              isUppercase={false}
              medium
            />
          </Styled.ContainerButton>
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [company, goToVacanciesCompanyHandle, goToCurriculumHandle]
  );

  const renderNotFound = useMemo(
    () => (
      <StatusMessage
        title={[
          "Ops! Tivemos um problema ",
          <span role="img" aria-label="sad">
            😕
          </span>
        ]}
        text={[
          "Isso pode ser devido a não estar se aplicando ao banco de talentos da empresa no momento ou não ter efetuado o login."
        ]}
        secondaryText={[
          <strong>
            Clique no botão abaixo para{" "}
            {token ? "visualizar suas aplicações" : "voltar a tela inicial"}.
          </strong>,
          "Se este problema persistir, entre em contato com o nosso time de suporte."
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          {token && (
            <Styled.ContainerButton>
              <Button
                text="Ver minhas aplicações"
                type="custom"
                backgroundColor="#333333"
                textColor="#ffffff"
                fontWeight="bold"
                borderColor="#333333"
                borderWidth="1px"
                boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
                onClick={goToMyApplicationsHandle}
                isUppercase={false}
                medium
              />
            </Styled.ContainerButton>
          )}
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [goToVacanciesCompanyHandle, goToMyApplicationsHandle, token, company]
  );

  const renderStatus = useMemo(() => {
    const status = {
      success: renderSuccess,
      has_applied: renderHasApplied,
      error: renderError,
      not_found: renderNotFound
    };

    return token ? status[type] : status.not_found;
  }, [
    renderError,
    renderSuccess,
    renderHasApplied,
    renderNotFound,
    type,
    token
  ]);

  return (
    <StyledGlobal.Main>
      <Header
        logo={company?.logo || JobsLogo}
        urlLogo={DEFAULT_HOST_CANDIDATE}
        token={token}
        user={user}
        onPerfil={perfilHandle}
        onLogout={() => dispatch(authActions.logout())}
      />
      <Banner
        urlDesktop={company?.banner || JobsBannerDesktopDefault}
        urlMobile={company?.banner || JobsBannerMobileDefault}
        defaultBackground={company?.colorCompany}
      />
      <StyledGlobal.Container id="container_pdf">
        <StyledGlobal.BodyMain>
          <StyledGlobal.BodyContent>
            <Styled.ContainerBody>
              {loading ? <Skeleton /> : renderStatus}
            </Styled.ContainerBody>
          </StyledGlobal.BodyContent>
        </StyledGlobal.BodyMain>
      </StyledGlobal.Container>
    </StyledGlobal.Main>
  );
}

export default TalentBankStatus;
