import styled from "styled-components";

const theFont = '"Roboto", Arial, Helvetica, sans-serif';

const theFontCondensed = '"Roboto Condensed", Arial, Helvetica, sans-serif';

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem 1rem 2rem;
  color: #333333;
  /* Medium devices (landscape phones, 641px and down) */
  @media (max-width: 641px) {
    padding: 2.125rem 1.625rem 2.5rem;
  }
  @media screen and (max-width: 425px) {
    padding: 0 1rem 0.5rem 1rem;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 425px) {
    align-items: center;
    text-align: center;
  }
`;

export const SubTitle = styled.h3`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #ffba00;
  font-family: ${theFont};
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 3.125rem;
  color: white;
  text-transform: uppercase;
  font-family: ${theFontCondensed};
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 0.813rem;
  color: white;
  line-height: 1.125rem;
  font-family: ${theFont};
`;

export const Link = styled.a`
  text-transform: uppercase;
  background: #ffba00;
  color: #583f99;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  align-items: center;
  border-radius: 48px;
  padding: 0.5rem 3rem;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-family: ${theFontCondensed};
  &:hover {
    color: #583f99;
  }
`;

export const ContainerImage = styled.div`
  @media screen and (max-width: 425px) {
    display: none;
  }
`;
