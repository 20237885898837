import React, { createContext, useContext, useState, useEffect } from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import useFetch from "./useFetch";
import { useAuth } from "./useAuth";
import { useDefaultProvider } from "./useDefaultProvider";

import * as cookie from "../cookie";
import * as actionCompany from "../../store/modules/company/actions";

import { DEFAULT_HOST_COMPANY } from "../../services/constants";

const CompanyContext = createContext({});

export const CompanyProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [showTalentBank, setShowTalentBank] = useState(true);
  const { slug } = useDefaultProvider();
  const { token, user } = useAuth();
  const [company, setCompany] = useState({});

  const { data: companyData, error: companyError } = useFetch(
    () => (slug && slug !== "login" ? `/company/${slug}` : null),
    {
      revalidateOnFocus: false
    }
  );
  const { hasAppliedTalentBase } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(actionCompany.resetTalentBaseApplied());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pathname } = window.location;

    if (!pathname.includes("notFound") && companyError?.response?.data) {
      const { code_request_gestao, code_request_jobs } =
        companyError.response.data;

      if (code_request_gestao === 404 || code_request_jobs === 404) {
        const host = DEFAULT_HOST_COMPANY(slug);
        cookie.redirect(`${host}/notFound`);
      }
    }
  }, [slug, companyError]);

  useEffect(() => {
    setCompany(companyData?.data || {});

    if (!companyData?.data?.id && companyData?.data?.link) {
      cookie.redirect(companyData?.data?.link);
    }
  }, [companyData]);

  useEffect(() => {
    if (token && user?.myTalentBases?.length > 0) {
      const exists = user.myTalentBases.find((data) => data.slug === slug);
      if (exists) {
        setShowTalentBank(false);
      } else {
        setShowTalentBank(!hasAppliedTalentBase);
      }
    } else {
      setShowTalentBank(token ? !hasAppliedTalentBase : true);
    }
  }, [hasAppliedTalentBase, slug, token, user]);

  useEffect(() => {
    const { candidate_message } = cookie.getJSON();
    if (candidate_message) {
      const { type, message, description } = candidate_message;
      if (type && description) {
        notification[type]({
          message,
          description
        });
      }
      cookie.set("candidate_message", "", { expires: 1 });
    }
  }, [dispatch]);

  return (
    <CompanyContext.Provider
      value={{
        company,
        showTalentBank
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany() {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error("useCompany must be used within an CompanyProvider.");
  }

  return context;
}
