import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

import { Typography, Icon } from "@components";
import SliderMark from "@components/form/sliderMark";

import { Container, Header, RemoveSkill } from "./styles";

const SliderSkill = ({ marks, skill, name, onRemove = undefined }) => {
  const { control } = useFormContext();

  const defaultValue = useMemo(() => {
    const sliderValue = Object.keys(marks).find(
      (key) => marks[key].level.toString() === skill.level
    );

    return Number(sliderValue || 0);
  }, []);

  return (
    <Container id={"skill-".concat(skill.value)}>
      <Header>
        <Typography>
          Nível de experiência em <strong>{skill.name}</strong>
        </Typography>

        {onRemove && (
          <RemoveSkill onClick={onRemove}>
            <Icon className="fa fa-trash" />
            <p>Excluir</p>
          </RemoveSkill>
        )}
      </Header>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SliderMark
            step={null}
            marks={marks}
            defaultValue={defaultValue}
            onChange={(value) => field.onChange(marks[value]?.level || 0)}
          />
        )}
      />
    </Container>
  );
};

SliderSkill.propTypes = {
  skillName: PropTypes.string.isRequired,
  marks: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  )
};

export default React.memo(SliderSkill);
