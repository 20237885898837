export function getInitialVacancies(payload) {
  return {
    type: "@vacancies/INITIAL_VACANCIES",
    payload
  };
}

export function getSearchVacanciesByCompany(payload) {
  return {
    type: "@vacancies/SEARCH_VACANCIES_BY_COMPANY",
    payload
  };
}

export function getInitialVacancy() {
  return {
    type: "@vacancies/INITIAL_VACANCY"
  };
}

export function clearListVacancies() {
  return {
    type: "@vacancies/CLEAR_LIST_VACANCIES_SUCCESS"
  };
}
