import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useCurriculumModal } from "@hooks/useCurriculumModal";

import { Typography, Icon } from "@components/";
import scrollToElementById from "@utils/scrollToElement";
import { Cardboard } from "@pages/curriculumV2/components/cardboard";
import { Label, Input, Select, Checkbox, Button } from "@components/form";

import { LEVELS_HIDDEN } from "@utils/formValidations/curriculumV2/experiencesSchema";

import { Row, Col } from "../../../styles";
import ExperienceDateRange from "../experienceDateRange";
import { Container } from "./styles";

const LEVELS_COURSE = [
  {
    value: "curso",
    label: "Curso extra-curricular"
  },
  {
    value: "fundamental_incompleto",
    label: "Fundamental Incompleto"
  },
  {
    value: "fundamental_completo",
    label: "Fundamental Completo"
  },
  {
    value: "medio_incompleto",
    label: "Ensino médio incompleto"
  },
  {
    value: "medio_completo",
    label: "Ensino médio completo"
  },
  {
    value: "superior_incompleto",
    label: "Ensino superior incompleto"
  },
  {
    value: "superior_completo",
    label: "Ensino superior completo"
  },
  {
    value: "tecnologo",
    label: "Tecnólogo"
  },
  {
    value: "pos_graduacao",
    label: "Pós-graduação"
  },
  {
    value: "mestrado",
    label: "Mestrado"
  },
  {
    value: "doutorado",
    label: "Doutorado"
  }
];

const DEFAULT_FIELDS_COURSES = {
  course: "",
  college: "",
  level: "",
  startMonth: "",
  startYear: "",
  endMonth: "",
  endYear: "",
  current: false
};

export const CourseExperience = ({ required, defaultBox }) => {
  const { control, getValues, setValue } = useFormContext();
  const { handleOpenModal } = useCurriculumModal();
  const [disabledCourse, setDisabledCourse] = useState([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "courses"
  });
  const [hasRemoving, setHasRemoving] = useState(false);

  useEffect(() => {
    const isNewField = (required || defaultBox) && !hasRemoving;

    if (isNewField && fields.length === 0) {
      append(DEFAULT_FIELDS_COURSES, { shouldFocus: false });
      return;
    }
    const initialListDisabled = fields.reduce((accumulator, course) => {
      if (LEVELS_HIDDEN.includes(course.level)) accumulator.push(course.id);
      return accumulator;
    }, []);
    setDisabledCourse(initialListDisabled);
  }, [required, fields]);

  const handleNewCard = () => {
    append(DEFAULT_FIELDS_COURSES);
    const elementId = "course-experience-".concat(fields.length);
    setTimeout(() => scrollToElementById(elementId, -150), 200);
  };

  const handleRemoveCard = (id) => {
    if (id === 0 && required && fields.length === 1) return;

    setHasRemoving(true);
    const { courses } = getValues();
    const isDirty = Object.values(courses[id]).some(
      (value) => value && value !== "off"
    );

    if (isDirty) {
      handleOpenModal("delete-course", () => remove(id), "small");
    } else {
      remove(id);
    }
  };

  const handleHiddenCourse = (index, fieldId) => {
    setTimeout(() => {
      const levelSelected = getValues(`courses.${index}.level`);

      if (LEVELS_HIDDEN.includes(levelSelected)) {
        setValue(`courses.${index}.course`, "");
        setDisabledCourse((state) => [...state, fieldId]);
      } else {
        setDisabledCourse((state) => state.filter((item) => item !== fieldId));
      }
    }, 200);
  };

  useEffect(() => {
    setTimeout(() => {
      const courses = getValues("courses");
      courses.map((course, courseId) => handleHiddenCourse(courseId));
    }, 1000);
  }, []);

  return (
    <Container>
      <Typography type="subtitle">Escolaridade</Typography>

      {fields.map((field, index) => (
        <Cardboard
          key={field.id}
          disableClose={index === 0 && required && fields.length === 1}
          handleClose={() => handleRemoveCard(index)}
          elementId={"course-experience-".concat(index)}
        >
          <Row>
            <Col>
              <Label required>Grau de escolaridade</Label>
              <Select
                required
                options={LEVELS_COURSE}
                placeholder="Selecione"
                name={`courses.${index}.level`}
                onInputChange={() => handleHiddenCourse(index, field.id)}
              />
            </Col>
            {!disabledCourse.includes(field.id) && (
              <Col>
                <Input
                  required
                  label="Curso"
                  placeholder="Nome do curso"
                  name={`courses.${index}.course`}
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              <Input
                required
                label="Instituição"
                placeholder="Nome da escola ou universidade"
                name={`courses.${index}.college`}
              />
            </Col>
          </Row>

          <ExperienceDateRange groupName="courses" fieldId={index} />

          <Checkbox
            label="Estou cursando no momento"
            style={{ marginBottom: "1rem" }}
            name={`courses.${index}.current`}
            defaultValue={fields[index].current}
          />
        </Cardboard>
      ))}

      <Button onClick={handleNewCard}>
        <Icon className="fas fa-plus-circle" color="#333" size="1rem" />
        Adicionar escolaridade
      </Button>
    </Container>
  );
};

CourseExperience.propTypes = {
  required: PropTypes.bool,
  defaultBox: PropTypes.bool
};
