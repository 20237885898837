export const getSlug = () => {
  const host = window.location.hostname.replace("www.", "");
  const count = (host.match(/\./g) || []).length;
  let slug = "";

  if (
    (process.env.ENVIRONMENT === "development" && count > 2) ||
    (process.env.ENVIRONMENT === "production" && count >= 2)
  ) {
    slug = host.split(".")[0];
  }

  // if (
  //   (process.env.ENVIRONMENT === "development" && count > 2) ||
  //   (process.env.ENVIRONMENT === "production" && count > 2)
  // ) {
  //   slug = host.split(".")[0];
  // }

  return slug;
};

export const getHost = () => {
  let { host } = window.location;
  if (
    host.includes("localhost") ||
    host === "[::1]" ||
    host.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  ) {
    host = "http://localhost:3000";
  } else {
    const slug = getSlug();
    host = host.replace(`${slug}.`, "");
  }

  return host;
};
