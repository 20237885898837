import React, { useState } from "react";
import { InputWithLabel, RightIcon, InvalidFeedback } from "../../styles";

import { ReactComponent as PasswordIcon } from "../../../../assets/svg/password.svg";

export default function InputText({
  label,
  isValid,
  styleInput = {},
  rightIcon = true,
  error,
  ...rest
}) {
  const [_type, _setType] = useState("password");

  const showPassword = () => {
    const type = _type === "password" ? "text" : "password";
    _setType(type);
  };

  return (
    <InputWithLabel
      className="label-float"
      iconRight={rightIcon}
      error={error}
      {...rest}
    >
      <input style={styleInput} type={_type} placeholder="" />
      <label>{label}</label>
      {rightIcon && (
        <RightIcon
          {...rest}
          cursor="true"
          show={_type === "text"}
          onClick={() => showPassword()}
        >
          <PasswordIcon />
        </RightIcon>
      )}
      {!!error && <InvalidFeedback>{error}</InvalidFeedback>}
    </InputWithLabel>
  );
}
