import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import moment from "moment";

import Breadcrumb from "@components/breadcrumb";
import Button from "@components/button";
import Image from "@components/image";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import UserIcon from "@assets/svg/user-circle-solid.svg";
import JobsBannerDesktopCandidate from "@assets/svg/banner_candidate_jobs_desktop.svg";
import JobsBannerMobileCandidate from "@assets/svg/banner_candidate_jobs_mobile.svg";

import * as actionCandidate from "@store/modules/perfilCandidate/actions";
import * as actionAuth from "@store/modules/auth/actions";
import * as actionCurriculum from "@store/modules/curriculum/actions";
import * as actionsUtilities from "@store/modules/utilities/actions";
import * as actionVacancies from "@store/modules/vacancies/actions";
import { useAuth } from "@hooks/useAuth";
import { useCandidate } from "@hooks/useCandidate";
import { getUserHistoric } from "@utils/getUserHisotric";
import { DEFAULT_HOST_CANDIDATE } from "@services/constants";

import Header from "@components/header";
import Banner from "@components/banner";
import Footer from "@components/footer";

import * as StyledGlobal from "@styles/global";
import * as Styled from "./styles";

import TabHeader from "./components/tabHeader";
import Curriculum from "./body/curriculum";
import Vacancy from "./body/vacancy";
import Profiler from "./body/profiler";
import Company from "./body/company";
import AsideMenu from "./components/filter";

function PerfilCandidate({
  talentBases,
  vacancyDescription,
  history,
  percentage
}) {
  const dispatch = useDispatch();
  const [birthday, setBirthday] = useState("");
  const [social, setSocial] = useState([]);
  const [type, setType] = useState("curriculum");
  const [close, setClose] = useState(false);
  const [currentExperience, setCurrentExperience] = useState([]);

  const { profiler, loadingProfiler } = useCandidate();
  const {
    token,
    user: currentUser,
    loadingApplicant,
    loadingMyApplications,
    loadingMyTalentBank
  } = useAuth();
  const { state, hash } = useLocation();

  useEffect(() => {
    dispatch(actionCandidate.getInitialVacancy());
    dispatch(actionVacancies.clearListVacancies());
  }, [dispatch]);

  useEffect(() => {
    const validTabs = ["curriculum", "profiler", "vacancy", "company"];
    const newHash = hash?.replace("#", "") || "";

    if (state?.activeTab) {
      setType(state.activeTab);
    } else if (validTabs.includes(newHash)) {
      setType(newHash);
    } else {
      setType("curriculum");
    }
  }, [state, hash]);

  useEffect(() => {
    !!currentUser.birthday && setBirthday(moment(currentUser.birthday));

    const tempSocial = [];
    const defaultProps = {
      color: "#333333",
      borderColor: "rgba(0, 0, 0, 0.15)",
      borderWidth: "3px"
    };

    if (currentUser.contacts?.linkedin) {
      tempSocial.push({
        type: "linkedin",
        link: currentUser.contacts?.linkedin,
        ...defaultProps
      });
    }

    if (currentUser.contacts?.instagram) {
      tempSocial.push({
        type: "instagram",
        link: currentUser.contacts?.instagram,
        ...defaultProps
      });
    }
    if (currentUser.contacts?.facebook) {
      tempSocial.push({
        type: "facebook",
        link: currentUser.contacts?.facebook,
        ...defaultProps
      });
    }
    if (currentUser.contacts?.twitter) {
      tempSocial.push({
        type: "twitter",
        link: currentUser.contacts?.twitter,
        ...defaultProps
      });
    }
    if (currentUser.contacts?.whatsapp || currentUser.contacts?.phone) {
      const { contacts } = currentUser;
      const whatsappNum = contacts?.whatsapp || contacts?.phone;
      const defaultDDI = whatsappNum.length === 11 ? "55" : "";
      tempSocial.push({
        type: "whatsapp",
        link: `https://wa.me/${defaultDDI}${whatsappNum}`,
        ...defaultProps
      });
    }

    setSocial(tempSocial);

    dispatch(actionCurriculum.calculatePercentage(currentUser));

    const currentExp = currentUser?.experiences?.filter((exp) => !exp.endDate);
    setCurrentExperience(currentExp?.length ? currentExp : []);
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (close === true) {
      dispatch(actionCandidate.getInitialVacancy());
    }
    setClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close]);

  const logoutHandle = () => {
    dispatch(actionAuth.logout());
  };

  const breadcrumbList = getUserHistoric();

  const downloadPdfHandle = () => {
    history.push("pdf/curriculum", {
      currentUser,
      type: "download"
    });
  };

  const printHandle = () =>
    history.push("pdf/curriculum", {
      currentUser,
      type: "print"
    });

  const settingsHandle = () =>
    dispatch(
      actionsUtilities.showModal({
        show: true,
        type: "settings",
        options: {
          position: "topCenter",
          size: "large",
          hasBgShadow: true,
          showClose: true
        },
        dataParams: {
          currentUser,
          fallbackDelete: () => deleteUserHandle(),
          fallbackChangeEmail: (values) => submitSettingsEmailHandle(values),
          fallbackChangePassword: (values) =>
            submitSettingsPasswordHandle(values)
        }
      })
    );

  const deleteUserHandle = () => {
    dispatch(
      actionsUtilities.showAlert({
        show: true,
        type: "modalAlert",
        sizeMode: "auto",
        data: {
          type: "warning",
          title: "Excluir Conta",
          textContent: `Deseja que sua conta seja excluída? Essa ação NÃO poderá ser revertida.`,
          btn1: {
            text: "CANCELAR",
            type: "custom",
            bgColor: "#fff",
            color: "#333333",
            border: "#8872B2",
            flex: 1
          },
          btn2: {
            text: "SIM, DESEJO EXCLUIR",
            type: "custom",
            bgColor: "#333333",
            color: "#ffffff",
            border: "#333333",
            flex: 1
          },
          fallback: () => dispatch(actionCandidate.deleteUser())
        }
      })
    );
  };

  const submitSettingsEmailHandle = (values) => {
    if (values) {
      const { newEmail, confirmNewEmail, password } = values;

      dispatch(
        actionCandidate.setChangeEmail({
          newEmail,
          confirmNewEmail,
          password
        })
      );
    }
  };

  const submitSettingsPasswordHandle = (values) => {
    if (values) {
      const { actualPassword, newPassword, confirmNewPassword } = values;

      dispatch(
        actionCandidate.setChangePassword({
          actualPassword,
          newPassword,
          confirmNewPassword
        })
      );
    }
  };

  const renderStep = () => {
    const renders = {
      curriculum: (
        <Curriculum candidate={currentUser} loading={loadingApplicant} />
      ),
      profiler: <Profiler profiler={profiler} loading={loadingProfiler} />,
      vacancy: (
        <Vacancy
          applications={currentUser?.myApplications}
          vacancyDescription={vacancyDescription}
          loading={loadingMyApplications}
          fallbackVacancyDescription={(id) =>
            dispatch(
              actionCandidate.getDescriptionVacancy({
                vacancyId: id
              })
            )
          }
          fallBackCloseDescription={() => setClose(true)}
        />
      ),
      company: (
        <Company
          talentBases={currentUser?.myTalentBases}
          loading={loadingMyTalentBank}
        />
      )
    };

    return renders[type] || null;
  };

  return (
    <StyledGlobal.Main>
      <Header
        logo={JobsLogo}
        urlLogo={DEFAULT_HOST_CANDIDATE}
        token={token}
        user={currentUser}
        onPerfil={() => window.location.reload()}
        onLogout={() => dispatch(actionAuth.logout())}
      />

      <Banner
        urlDesktop={JobsBannerDesktopCandidate}
        urlMobile={JobsBannerMobileCandidate}
      />

      <StyledGlobal.Container
        id="container_pdf"
        style={{ paddingBottom: "120px" }}
      >
        <StyledGlobal.BodyMain>
          <StyledGlobal.BodyContent>
            <StyledGlobal.BreadcrumbContainer className="printNone">
              <Breadcrumb list={breadcrumbList} />
            </StyledGlobal.BreadcrumbContainer>
            <StyledGlobal.BoxHeader>
              <Styled.ContainerHeader>
                <Styled.HeaderItem center>
                  <Styled.Photo>
                    <Image
                      url={currentUser.picture ? currentUser.picture : UserIcon}
                      type="roundedCircle"
                      imgBackgroundSize="cover"
                      imgSize="148"
                      border
                    />
                  </Styled.Photo>
                </Styled.HeaderItem>

                <Styled.HeaderItem
                  marginBottom="10"
                  style={{ marginTop: "1rem" }}
                >
                  <Styled.HeaderTitle>
                    {currentUser.name}
                    {!!birthday &&
                      `,  ${moment().diff(birthday, "years")} anos `}
                  </Styled.HeaderTitle>
                </Styled.HeaderItem>

                {currentExperience.map((exp, index) => (
                  <Styled.HeaderItem key={`exp_${index}`} itensBar>
                    <Styled.HeaderDescription>
                      {exp.occupation}
                    </Styled.HeaderDescription>
                    <Styled.HeaderDescription bold>
                      {exp.company}
                    </Styled.HeaderDescription>
                  </Styled.HeaderItem>
                ))}

                <Styled.HeaderItem marginBottom="30" itensBar>
                  <Styled.HeaderDescription>
                    <Styled.Link href={`mailto:${currentUser.contacts?.email}`}>
                      {currentUser.contacts?.email || ""}
                    </Styled.Link>
                  </Styled.HeaderDescription>
                  {!!currentUser.contacts?.phone && (
                    <Styled.HeaderDescription>
                      {currentUser.contacts?.phone}
                    </Styled.HeaderDescription>
                  )}
                </Styled.HeaderItem>
                {!!social && (
                  <Styled.HeaderItem marginBottom="30">
                    <Button type="social" options={social} />
                  </Styled.HeaderItem>
                )}
                <TabHeader
                  changeType={(newType) => {
                    setType(newType);
                    window.location.hash = newType;
                  }}
                  type={type}
                />
              </Styled.ContainerHeader>
            </StyledGlobal.BoxHeader>
            <StyledGlobal.BoxContainer>
              <StyledGlobal.BoxBody>{renderStep()}</StyledGlobal.BoxBody>
            </StyledGlobal.BoxContainer>
          </StyledGlobal.BodyContent>

          <StyledGlobal.Aside>
            <AsideMenu
              percentage={percentage}
              type={type}
              history={history}
              downloadPdf={() => downloadPdfHandle()}
              print={() => printHandle()}
              logout={() => logoutHandle()}
              fallbackShowSettings={settingsHandle}
            />
          </StyledGlobal.Aside>
        </StyledGlobal.BodyMain>
      </StyledGlobal.Container>
      <Footer />
    </StyledGlobal.Main>
  );
}

const mapStateToProps = (state) => ({
  talentBases: state.perfilCandidate.talentBases,
  applications: state.perfilCandidate.applications,
  vacancyDescription: state.perfilCandidate.vacancy,
  vacancy: state.vacancies.vacancy,
  percentage: state.curriculum.percentage,
  company: state.company.data,
  applyedTalentBase: state.utilities.applyedTalentBase
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PerfilCandidate);
