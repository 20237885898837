import React from "react";

import Icon from "@components/icon";

import { Container, Image } from "./styles";

const Thumbnail = ({ link }) => (
  <Container>
    {link ? (
      <Image src={link} />
    ) : (
      <Icon className="fas fa-video" size="2rem" color="#666666" />
    )}
  </Container>
);

export default Thumbnail;
