import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import * as StyledGlobal from "@styles/global";

import * as cookie from "@utils/cookie";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";

import Header from "@components/header";
import Banner from "@components/banner";
import Button from "@components/button";
import Footer from "@components/footer";

import * as authActions from "@store/modules/auth/actions";
import * as companyActions from "@store/modules/company/actions";

import { useAuth } from "@utils/hooks/useAuth";
import { useDefaultProvider } from "@utils/hooks/useDefaultProvider";
import { useCompany } from "@utils/hooks/useCompany";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_COMPANY,
  DEFAULT_HOST_LOGIN
} from "@services/constants";
import StatusMessage from "../components/applicationMessageByStatus";
import Skeleton from "../components/skeleton";

import * as Styled from "../styles";

function PublicVacancyStatus() {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const { token, user } = useAuth();
  const { slug } = useDefaultProvider();
  const { company, showTalentBank } = useCompany();

  const dispatch = useDispatch();

  useEffect(() => {
    const data = cookie.getJSON();
    const tab = data[window.name];

    if (tab) {
      const { statusApply } = tab;

      if (statusApply !== "success") {
        goToVacanciesCompanyHandle();
      } else {
        setType("success");
        cookie.set(window.name, { ...tab, statusApply: null }, { expires: 1 });
      }
    } else {
      goToVacanciesCompanyHandle();
    }

    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (!token && company?.slug) {
      goToVacanciesCompanyHandle();
    }
  }, [token, company]);

  const goToVacanciesCompanyHandle = useCallback(() => {
    const host = DEFAULT_HOST_COMPANY(slug);
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${host}/vacancies`);
  }, [slug]);

  const authenticateHandle = useCallback((destination = "") => {
    cookie.set("destination", destination);
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(DEFAULT_HOST_LOGIN);
  }, []);

  const applyTalentBaseOfCompany = useCallback(() => {
    const data = {
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name
      },
      applyingTalentBase: true
    };

    cookie.set(window.name, data, { expires: 1 });

    if (token) {
      dispatch(companyActions.applyTalentBase(company, user));
    } else {
      authenticateHandle();
    }
  }, [authenticateHandle, dispatch, company, token]);

  const renderSuccess = useMemo(
    () => (
      <>
        <StyledGlobal.ContainerWizard>
          <StyledGlobal.ProgressBar progress={100} />
          <StyledGlobal.WizardList>
            <StyledGlobal.WizardListItem>
              <StyledGlobal.WizardListItemText bold>
                Cadastro de currículo e dados pessoais
              </StyledGlobal.WizardListItemText>
              <StyledGlobal.WizardListItemDot completed />
            </StyledGlobal.WizardListItem>
            <StyledGlobal.WizardListItem>
              <StyledGlobal.WizardListItemText bold>
                Questionário comportamental Profiler
              </StyledGlobal.WizardListItemText>
              <StyledGlobal.WizardListItemDot completed />
            </StyledGlobal.WizardListItem>
          </StyledGlobal.WizardList>
        </StyledGlobal.ContainerWizard>
        <StatusMessage
          title=" Sucesso!"
          iconClass="fas fa-check"
          text={[
            "Seu currículo e profiler foram criados com sucesso, mas você ainda não se candidatou à uma vaga. Acesse a página da empresa e se aplique no banco de talentos ou em alguma vaga que seja do seu interesse."
          ]}
        >
          <Styled.ContainerGroupButton>
            <Styled.ContainerButton>
              <Button
                text="Voltar para a tela inicial"
                type="custom"
                backgroundColor="#333333"
                textColor="#ffffff"
                fontWeight="bold"
                borderColor="#333333"
                borderWidth="1px"
                boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
                isUppercase={false}
                medium
                onClick={goToVacanciesCompanyHandle}
              />
            </Styled.ContainerButton>
          </Styled.ContainerGroupButton>
        </StatusMessage>
      </>
    ),
    [goToVacanciesCompanyHandle, company]
  );

  const renderStatus = useMemo(() => {
    const status = {
      success: renderSuccess
    };

    return token ? status[type] : status.success;
  }, [renderSuccess, token, type]);

  return (
    <StyledGlobal.Main>
      <Header
        logo={company?.logo || JobsLogo}
        urlLogo={DEFAULT_HOST_COMPANY(slug)}
        token={token}
        user={user}
        showTalentBank={showTalentBank}
        onApplyTalentBank={applyTalentBaseOfCompany}
        onAuthenticate={authenticateHandle}
        onPerfil={() => cookie.redirect(DEFAULT_HOST_CANDIDATE)}
        onLogout={() => dispatch(authActions.logout())}
      />

      <Banner
        urlDesktop={company?.banner}
        urlMobile={company?.banner}
        defaultBackground={company?.colorCompany}
      />

      <StyledGlobal.Container id="container_pdf">
        <StyledGlobal.BodyMain>
          <StyledGlobal.BodyContent>
            <Styled.ContainerBody>
              {loading ? <Skeleton /> : renderStatus}
            </Styled.ContainerBody>
          </StyledGlobal.BodyContent>
        </StyledGlobal.BodyMain>
      </StyledGlobal.Container>
      <Footer />
    </StyledGlobal.Main>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PublicVacancyStatus);
