import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "react-datepicker/dist/react-datepicker.css";
import "./assets/message.scss";
import "./assets/tooltip.scss";
import "./assets/notification.scss";
import "./assets/overrides.scss";
import "./assets/slider.scss";
import "./assets/scrollEffect.scss";
import "./assets/print.scss";
import "moment/locale/pt-br";

ReactDOM.render(<App />, document.getElementById("root"));
