import React, { createContext, useState, useMemo, useCallback } from "react";

const AlertModalContext = createContext(null);

function AlertModalProvider({ children }) {
  const [alertData, setAlertData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const showAlert = useCallback((data = null) => {
    if (!data) return;

    setAlertData(data);
    setIsVisible(true);

    document.body.style = "overflow: hidden;";
  }, []);

  const hideAlert = useCallback(() => {
    setIsVisible(false);

    document.body.style = "overflow: initial;";
  }, []);

  const value = useMemo(
    () => ({ alertData, isVisible, showAlert, hideAlert }),
    [alertData, isVisible, showAlert, hideAlert]
  );

  return (
    <AlertModalContext.Provider value={value}>
      {children}
    </AlertModalContext.Provider>
  );
}

export { AlertModalProvider, AlertModalContext };
