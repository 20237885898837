import styled, { css } from "styled-components";

import { ReactComponent as CloseSVG } from "@assets/svg/close.svg";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.5);

  transition: all 0.35s ease;
  pointer-events: none;
  opacity: 0;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      pointer-events: initial;
      opacity: 1;
    `}
`;

export const AlertContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  text-align: center;
  max-width: 620px;
  margin: 118px auto 0 auto;
  padding: 58px 28px 44px;

  transition: all 0.35s ease;
  transform: translateY(30%);

  -webkit-font-smoothing: antialiased;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: translateY(0%);
    `}
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: -0.1px;

  color: #333;

  margin-bottom: 1.5rem;
`;

export const Message = styled.p`
  font-size: 0.875rem;
  line-height: 1.5625rem;
  letter-spacing: -0.1px;

  color: #333;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  border: 0;
  background: transparent;
  padding: 0.25rem;
  cursor: pointer;
`;

export const CloseIcon = styled(CloseSVG)`
  width: 0.875rem;
  height: 0.875rem;

  path {
    fill: #333;
    fill-opacity: 1;

    &:last-child {
      fill: white;
      fill-opacity: 1;
    }
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: bold;
  padding: 0.75rem 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  & + & {
    margin-left: 0.5rem;
    @media (min-width: 375px) {
      margin-left: 1rem;
    }
  }

  ${({ $isMain }) =>
    $isMain &&
    css`
      color: #fff;
      background: #333333;
      padding: 0.75rem 2.4375rem;

      box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35),
        inset 0px -3px 0px rgba(0, 0, 0, 0.2);
      border-radius: 100px;
    `}
`;
