import React from "react";

import { ReactComponent as ChevronRight } from "@assets/svg/arrow-right.svg";

import getBreadcrumbItems from "@utils/getBreadcrumbItems";
import useAlertModal from "@utils/hooks/useAlertModal";
import * as cookie from "@utils/cookie";
import * as Styled from "./styles";

function Breadcrumb() {
  const items = getBreadcrumbItems();
  const { showAlert, hideAlert } = useAlertModal();
  const { [window.name]: tab } = cookie.getJSON();

  const isApplyingVacancy = !!tab?.applyingVacancy;

  function handleClick({ fallback }) {
    if (!fallback) return;

    showAlert({
      closeable: true,
      title: isApplyingVacancy
        ? "Deseja desistir de se candidatar?"
        : "Deseja desistir de editar o currículo?",
      message:
        "ATENÇÃO: Essa ação não poderá ser desfeita e você perderá os dados já preenchidos.",
      buttons: [
        {
          text: "Cancelar",
          onClick: hideAlert
        },
        {
          text: "Sim, desistir",
          main: true,
          onClick() {
            fallback();
            hideAlert();
          }
        }
      ]
    });
  }

  return (
    <Styled.Container>
      {items.map(({ text, isAnchor, isCompany, onClick }, index) => (
        <React.Fragment key={index}>
          {index > 0 && <ChevronRight />}

          <Styled.Item
            isCompany={isCompany}
            isAnchor={isAnchor}
            onClick={() => handleClick({ fallback: onClick })}
          >
            {text}
          </Styled.Item>
        </React.Fragment>
      ))}
    </Styled.Container>
  );
}

export default React.memo(Breadcrumb);
