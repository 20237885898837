import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";

import { LinkLogin } from "@components/linkAuth";
import { Typography, Button } from "@components/";
import { Input, Checkbox, InputPhone } from "@components/form";
import { ReactComponent as CheckSVG } from "@assets/svg/check.svg";
import { ReactComponent as CloseSVG } from "@assets/svg/closeModal.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import * as authActions from "@store/modules/auth/actions";
import * as utilitiesActions from "@store/modules/utilities/actions";
import { DEFAULT_HOST_LINKEDIN_CALLBACK } from "@services/constants";

import * as cookie from "@utils/cookie";
import {
  verifyMinimumCharacters,
  verifyContentNumber,
  verifyContentLetter,
  verifyContentSpecialCharacters
} from "@utils/rulesPassword";
import schema from "./schema";

import * as Styled from "./styles";

function SignUp({ callbackSignIn }) {
  const [termChecked, setTermChecked] = useState(false);
  const [isFocusPassword, setIsFocusPassword] = useState(false);
  const [rulesPassword, setRulesPassword] = useState({
    minimumCharacters: false,
    contentNumber: false,
    contentLetter: false,
    contentSpecialCharacters: false
  });

  const dispatch = useDispatch();

  const HOST_AUTH_LINKEDIN = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78pp350a2v5dvv&redirect_uri=${DEFAULT_HOST_LINKEDIN_CALLBACK}&state=signUp&scope=r_emailaddress`;

  const showTermsOfUseHandle = () =>
    dispatch(
      utilitiesActions.showModal({
        show: true,
        type: "termsOfUse",
        options: {
          position: "topCenter",
          size: "large",
          hasBgShadow: true,
          bgShadow: "",
          showClose: true
        }
      })
    );

  const formMethods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      confirmEmail: "",
      cpf: "",
      phone: "",
      phoneDDI: "55",
      password: "",
      confirmPassword: "",
      userTerm: false
    }
  });

  const [email, confirmEmail, password, confirmPassword] = formMethods?.watch([
    "email",
    "confirmEmail",
    "password",
    "confirmPassword"
  ]);

  const onSubmit = (values) => {
    const formattedValue = {
      ...values,
      phone: values.phoneDDI + values.phone
    };
    dispatch(authActions.signUp(formattedValue));
  };

  const errorRequestSignUp = useSelector((state) => state.auth.error);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [errorRequestSignUp]);

  useEffect(() => {
    const isError = confirmEmail && confirmEmail !== email;
    const messageError = {
      type: "manual",
      message: "Os e-mails não coincidem. Tente novamente."
    };
    formMethods.setError("confirmEmail", isError ? messageError : {});
  }, [email]);

  useEffect(() => {
    setRulesPassword({
      minimumCharacters: verifyMinimumCharacters(password),
      contentNumber: verifyContentNumber(password),
      contentLetter: verifyContentLetter(password),
      contentSpecialCharacters: verifyContentSpecialCharacters(password)
    });

    const isError = confirmPassword && confirmPassword !== password;
    const messageError = {
      type: "manual",
      message: "As senhas não coincidem. Tente novamente."
    };
    formMethods.setError("confirmPassword", isError ? messageError : {});
  }, [password]);

  const linkedinHandle = (event) => {
    event.preventDefault();

    if (termChecked) {
      cookie.redirect(HOST_AUTH_LINKEDIN);
    }
  };

  const handleIconRule = (isSuccess) =>
    isSuccess ? (
      <CheckSVG className="success" />
    ) : (
      <CloseSVG className="error" />
    );

  return (
    <FormProvider {...formMethods}>
      <Styled.Container>
        <Typography type="title">Crie sua conta</Typography>
        {errorRequestSignUp.code_request_jobs === 401 && (
          <Styled.SectionErrorSignUp>
            Encontramos um cadastro com o e-mail&nbsp;
            <b>{errorRequestSignUp.message}</b>. Reconhece esse e-mail?
            <Styled.Link href="#" onClick={callbackSignIn}>
              &nbsp;Faça o login aqui.
            </Styled.Link>
            <br />
            Ou, se não reconhece, entre em contato com o suporte pelo chat.
          </Styled.SectionErrorSignUp>
        )}
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Styled.Row>
            <Input
              type="email"
              label="E-mail"
              placeholder="nome@email.com"
              name="email"
              data-testid="email"
              required
            />
            <Input
              type="email"
              label="Confirme seu e-mail"
              placeholder="nome@email.com"
              name="confirmEmail"
              data-testid="confirmEmail"
              required
            />
            <Input
              type="number"
              label="CPF"
              placeholder="___.___.___-__"
              format="###.###.###-##"
              name="cpf"
              data-testid="cpf"
              required
            />
            <InputPhone
              label="Número de celular"
              name="phone"
              data-testid="phone"
              required
            />
            <Input
              label="Senha"
              type="password"
              name="password"
              data-testid="password"
              required
              onFocus={() => setIsFocusPassword(true)}
              showValidateIcon={false}
            />

            {Object.values(rulesPassword).includes(false) && isFocusPassword && (
              <Styled.SectionRullerPassword>
                <p>A senha deve ter:</p>
                <ul>
                  <li>
                    Mínimo de 6 caracteres
                    {handleIconRule(rulesPassword.minimumCharacters)}
                  </li>
                  <li>
                    Pelo menos uma letra
                    {handleIconRule(rulesPassword.contentLetter)}
                  </li>
                  <li>
                    Pelo menos um número
                    {handleIconRule(rulesPassword.contentNumber)}
                  </li>
                  <li>
                    Pelo menos um caractere especial
                    {handleIconRule(rulesPassword.contentSpecialCharacters)}
                  </li>
                </ul>
              </Styled.SectionRullerPassword>
            )}
            <Input
              label="Confirme sua senha"
              type="password"
              name="confirmPassword"
              data-testid="confirmPassword"
              required
              showValidateIcon={false}
            />
          </Styled.Row>

          <Styled.CheckboxContainer>
            <Checkbox
              name="userTerm"
              label={
                <>
                  Li e concordo com os{" "}
                  <Styled.TermsText onClick={showTermsOfUseHandle}>
                    termos de uso e política de privacidade.
                  </Styled.TermsText>
                </>
              }
            />
          </Styled.CheckboxContainer>

          <Button
            buttonType="submit"
            backgroundColor="#583F99"
            textColor="#fff"
            fontWeight="bold"
            boxShadowColor="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
            medium
          >
            CRIAR CONTA
          </Button>
          <Styled.SectionDescription>
            <Typography>OU</Typography>
          </Styled.SectionDescription>
          <Styled.ContainerSocial>
            <Styled.SocialButton
              onClick={() => dispatch(authActions.getFacebookLink())}
            >
              <Button
                type="facebook"
                boxShadow
                leftIcon
                medium
                isUppercase={false}
                disabled
              >
                Criar conta com&nbsp;<b>Facebook</b>
              </Button>
            </Styled.SocialButton>
            <Styled.SocialButton
              href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78pp350a2v5dvv&redirect_uri=${DEFAULT_HOST_LINKEDIN_CALLBACK}&state=foobar&scope=r_emailaddress`}
            >
              <Button type="linkedin" leftIcon medium isUppercase={false}>
                Criar conta com&nbsp;<b>Linkedin</b>
              </Button>
            </Styled.SocialButton>
          </Styled.ContainerSocial>
        </form>
        <LinkLogin />
      </Styled.Container>
    </FormProvider>
  );
}

export default SignUp;
