import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";

import formatHost from "../../../../utils/formatHost";

import * as Styled from "./styles";

const defaultProps = {
  company: "",
  applicationDate: ""
};

const propsTypes = {
  company: PropTypes.string,
  applicationDate: PropTypes.string,
  slug: PropTypes.string.isRequired
};

const Card = ({ company, applicationDate, slug }) => {
  const hiperlink = useMemo(() => {
    const host = formatHost(slug);
    const link = `${host}/vacancies`;
    return link;
  }, [slug]);

  return (
    <Styled.CardContainer>
      <Styled.CompanyContainer>
        <Styled.Anchor href={hiperlink}>
          <Styled.Title>{company}</Styled.Title>
        </Styled.Anchor>
      </Styled.CompanyContainer>

      <Styled.ApplicationDate>
        <Styled.Title>Data Aplicação</Styled.Title>

        <Styled.SubTitle>{applicationDate}</Styled.SubTitle>
      </Styled.ApplicationDate>
    </Styled.CardContainer>
  );
};

Card.defaultProps = defaultProps;
Card.propsTypes = propsTypes;

export default memo(Card);
