import styled from "styled-components";
import { mobileLarge } from "@styles/breakpoints";

export const Container = styled.div`
  position: relative;

  @media (max-width: ${mobileLarge}) {
    margin-bottom: ${({ isError }) => (isError ? "-1.125rem" : 0)};
  }
`;

export const Label = styled.div`
  padding-top: 0.25rem;
  margin-left: 1.625rem;
`;

// documentation for customizing the react select component https://react-select.com/home#custom-styles
export const style = (error, maxHeight) => ({
  control: (provided, state) => ({
    ...provided,
    background: state.isDisabled ? "transparent" : "#FFFFFF",
    boxShadow:
      state.isFocused && state.menuIsOpen
        ? "0px 8px 10px rgba(75, 85, 99, 0.14), 0px 3px 14px rgba(75, 85, 99, 0.12), 0px 4px 5px rgba(75, 85, 99, 0.12)"
        : "none",
    borderColor: error
      ? "#ef3d4e !important"
      : "rgba(0, 0, 0, 0.15) !important",
    borderTopRightRadius: state.isFocused && state.menuIsOpen ? "25px" : "50px",
    borderTopLeftRadius: state.isFocused && state.menuIsOpen ? "25px" : "50px",
    borderBottomLeftRadius:
      state.isFocused && state.menuIsOpen ? "0px" : "50px",
    borderBottomRightRadius:
      state.isFocused && state.menuIsOpen ? "0px" : "50px",
    padding: "0px 0.5rem",
    minHeight: "2.813rem"
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    marginTop: "0px"
  }),
  option: (provided, state) => ({
    ...provided,
    marginTop: "2px",
    backgroundColor: state.isSelected
      ? "#F1F1F1"
      : state.isFocused && "#F1F1F1",
    color: state.isFocused && "black"
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    boxShadow:
      "0px 8px 10px rgba(75, 85, 99, 0.14), 0px 3px 14px rgba(75, 85, 99, 0.12), 0px 4px 5px rgba(75, 85, 99, 0.12)",
    borderTop: "0",
    "::-webkit-scrollbar": {
      width: "8px"
    },
    "::-webkit-scrollbar-track": {
      background: "#fff"
    },
    "::-webkit-scrollbar-thumb": {
      width: "4px",
      background: "#333333",
      borderRadius: "50px"
    }
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#e3e3e3 !important",
    border: "none",
    borderRadius: "30px"
  }),

  multiValueRemove: (base) => ({
    ...base,
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px"
  })
});
