import React from "react";

import Button from "@components/button";
import Typography from "@components/typography";
import * as Styled from "./styles";

export const Body = () => (
  <Styled.ContainerBody>
    <Typography type="subtitle">Baixar currículo</Typography>
    <Styled.Description>
      ATENÇÃO: Caso você tenha editado o currículo recentemente o PDF será
      atualizado após 4 horas.
    </Styled.Description>
  </Styled.ContainerBody>
);
export const Footer = ({ onClose, onClick }) => (
  <Styled.ContainerFooter>
    <Button
      type="custom"
      backgroundColor="#FFFFFF"
      textColor="#333333"
      borderColor="#FFFFFF"
      borderWidth="0px"
      width="30%"
      boxShadow="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
      medium
      isUppercase={false}
      onClick={onClose}
    >
      <Styled.Text>cancelar</Styled.Text>
    </Button>
    <Button
      type="custom"
      backgroundColor="#333333"
      textColor="#FFFFFF"
      borderColor="#333333"
      borderWidth="0px"
      width="30%"
      boxShadow="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
      medium
      isUppercase={false}
      onClick={onClick}
    >
      <Styled.Text>continuar</Styled.Text>
    </Button>
  </Styled.ContainerFooter>
);
