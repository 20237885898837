export const mobileSmall = "320px";

export const mobileMedium = "375px";

export const mobileLarge = "425px";

export const tablet = "768px";

export const laptop = "1024px";

export const laptopLarge = "1440px";

export const wideLarge = "2560px";
