import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 700px) {
    span {
      max-width: 250px !important;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 45px;

  @media screen and (max-width: 700px) {
    .react-loading-skeleton {
      max-width: 250px !important;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin-bottom: 55px;

  @media screen and (max-width: 700px) {
    .react-loading-skeleton {
      max-width: 250px !important;
    }
  }
`;

export const Button = styled.div``;
