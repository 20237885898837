import { notification } from "antd";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import { Input } from "@components/form";
import { Typography, Button } from "@components/";
import { LinkRegister } from "@components/linkAuth";

import * as cookie from "@utils/cookie";
import * as authActions from "@store/modules/auth/actions";
import { TYPES_SOCIAL_NETWORKS } from "@services/constants";

import schema from "./schema";
import * as Styled from "./styles";
import SocialNetworks from "./SocialNetworks";

function SignIn({ callbackForgotPassword, callbackSignUp }) {
  const dispatch = useDispatch();
  const resetPasswordSuccess = useSelector(
    (state) => state.auth.resetPasswordSuccess
  );
  const { MethodSignIn } = cookie.getJSON();

  useEffect(() => {
    if (TYPES_SOCIAL_NETWORKS.find((item) => item === MethodSignIn)) {
      notification.open({
        message: (
          <Styled.MessageToast>
            Parece que você entrou com <span>{MethodSignIn}</span> da última vez
          </Styled.MessageToast>
        ),
        description: (
          <SocialNetworks
            socialType={MethodSignIn}
            TextButton={
              <>
                Continuar com &nbsp;<b>{MethodSignIn}</b>
              </>
            }
          />
        ),
        duration: 0
      });
    }
    if (resetPasswordSuccess) {
      notification.open({
        message: "Senha redefinida com sucesso!",
        description: "Agora você pode entrar com seu login e nova senha."
      });
      dispatch(authActions.clearSuccessPasswordReset());
    }
  }, []);

  const formMethods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      user: "",
      password: ""
    }
  });

  const onSubmit = (values) => {
    dispatch(authActions.signIn(values.user, values.password));
  };

  return (
    <FormProvider {...formMethods}>
      <Styled.Container>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Typography type="title">Entre com a sua conta</Typography>
          <Styled.SectionDescription>
            <Typography>Login social:</Typography>
          </Styled.SectionDescription>
          <Styled.ContainerSocial>
            <SocialNetworks socialType={TYPES_SOCIAL_NETWORKS[0]} />
            <SocialNetworks socialType={TYPES_SOCIAL_NETWORKS[1]} />
          </Styled.ContainerSocial>
          <Styled.Row>
            <Styled.SectionDescription>
              <Typography>Ou entre com os dados cadastrados:</Typography>
            </Styled.SectionDescription>
            <Input
              label="E-mail ou CPF"
              name="user"
              data-testid="user"
              required
            />
            <Input
              label="Senha"
              type="password"
              name="password"
              data-testid="password"
              required
              showValidateIcon={false}
            />
          </Styled.Row>
          <Styled.ForgotPassword>
            <Link to="/forgotPassword">Esqueci minha senha</Link>
          </Styled.ForgotPassword>
          <Styled.ButtonSubmit>
            <Button
              buttonType="submit"
              backgroundColor="#583F99"
              textColor="#fff"
              fontWeight="bold"
              boxShadowColor="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
              medium
            >
              Entrar
            </Button>
          </Styled.ButtonSubmit>
          <LinkRegister />
        </form>
      </Styled.Container>
    </FormProvider>
  );
}

export default SignIn;
