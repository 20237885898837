import styled from "styled-components";

export const ContainerDescription = styled.div`
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 40px;
  color: #333333;
`;

export const DescriptionPrimary = styled.span`
  font-size: 21px;
  line-height: 40px;
  color: #333333;

  ${({ background }) =>
    background &&
    ` background: rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      transform: matrix(1, -0.02, 0.07, 1, 0, 0);
    `}
`;

export const DescriptionSecondary = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 16px;
  line-height: 40px;
  color: #333333;
`;

export const DescriptionList = styled.ol`
  line-height: 30px;
  padding: 10px 10px 10px 40px;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  border-radius: 5px;
  border: 2px solid #dddddd;
`;

export const DescriptionListItem = styled.li`
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: #333333;
`;

export const FormQuiz = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 30px;
`;

export const TextBehavior = styled.label`
  cursor: pointer;
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  color: #333333;
`;

export const Behavior = styled.div`
  width: 165px;
  height: 35px;
  margin-bottom: 2px;
  margin-right: 15px;
  text-align: left;
  cursor: pointer;
  background-color: #ffffff;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;

  &.selected {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 24px;
  }

  &.selected ${TextBehavior} {
    font-weight: bold;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;

export const SelectBehavior = styled.input`
  opacity: 0;
  width: 1px;

  :checked {
    border: 1px solid rgba(66, 82, 110, 0.25);
    border-radius: 5px;
  }
`;
