import * as yup from "yup";

const schema = yup.object().shape({
  type: yup.string(),
  value: yup
    .string()
    .required("Campo de preenchimento obrigatório")
    .when("type", {
      is: (current) => current === "email",
      then: yup.string().isValidEmail()
    })
    .when("type", {
      is: (current) => current === "cpf",
      then: yup.string().isValidCPF()
    }),
  recaptcha: yup.boolean().oneOf([true], "Campo de preenchimento obrigatório")
});

export default schema;
