import styled from "styled-components";

export const ContainerTitle = styled.div`
  .react-loading-skeleton {
    max-width: 100%;
    border-radius: 0px;
  }

  @media screen and (max-width: 460px) {
    .react-loading-skeleton {
      max-height: 20px;
    }
  }
`;
export const Title = styled.div`
  margin-bottom: 5px;
`;
export const Description = styled.div`
  .react-loading-skeleton {
    max-width: 100%;
  }
`;
export const Button = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
