import { call, all, put, takeLatest } from "redux-saga/effects";

import * as api from "./repository";
import * as cookie from "../../../utils/cookie";
import { DEFAULT_HOST_COMPANY } from "../../../services/constants";

function* applyTalentBaseSaga({ payload }) {
  const { company, showMessage, user } = payload;
  const { [window.name]: tab } = cookie.getJSON();
  const COMPANY_HOST = DEFAULT_HOST_COMPANY(company?.slug);
  let hasApplyedCompany = false;

  try {
    const { data: userTalentBases } = yield call(api.getTalentBases);
    hasApplyedCompany = userTalentBases?.data.find(
      (item) => item.slug === company.slug
    );
  } catch (err) {}

  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    let statusApply = "";

    if (hasApplyedCompany) {
      statusApply = "has_applied";
    } else if (
      !user ||
      (user?.name && user?.contacts?.email && user?.documents?.cpf)
    ) {
      yield call(api.applyTalenteBase, {
        referenceId: company.referenceId,
        slug: company.slug
      });

      statusApply = "success";
    }

    cookie.set(
      window.name,
      {
        ...tab,
        applyingTalentBase: false,
        applied: { company },
        statusApply
      },
      { expires: 1 }
    );
  } catch (err) {
    cookie.set(
      window.name,
      {
        ...tab,
        statusApply: "error",
        applied: { company }
      },
      { expires: 1 }
    );

    if (showMessage) {
      yield put({
        type: "@company/APPLY_TALENT_BASE_FAILURE",
        payload: { error: err.response.data.message }
      });
    }
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
    yield put({
      type: "@company/APPLY_TALENT_BASE_FINISH"
    });

    cookie.redirect(`${COMPANY_HOST}/talentBankStatus`);
  }
}

export default all([
  takeLatest("@company/APPLICANT_CANDIDATE_TALENT_BASE", applyTalentBaseSaga)
]);
