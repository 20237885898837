import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useAuth } from "@hooks/useAuth";

import * as authActions from "@store/modules/auth/actions";

import * as cookie from "@utils/cookie";

import Header from "@components/header";
import Banner from "@components/banner";
import Button from "@components/button";
import Footer from "@components/footer";

import { DEFAULT_HOST_CANDIDATE } from "@services/constants";

import JobsBannerDesktopDefault from "@assets/svg/banner_default_jobs_desktop.svg";
import JobsBannerMobileDefault from "@assets/svg/banner_default_jobs_mobile.svg";
import JobsLogo from "@assets/svg/candidateJobsLogo.svg";

import * as StyledGlobal from "@styles/global";
import * as Styled from "../styles";

import Skeleton from "../components/skeleton";
import StatusMessage from "../components/applicationMessageByStatus";

function ApplicationStatus() {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [recruiterError, setRecruiterError] = useState("");
  const { token, user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    const data = cookie.getJSON();
    const tab = data[window.name];

    if (tab) {
      const { applied, statusApply, error406 } = tab;
      let newType = "not_found";

      if (error406) {
        setRecruiterError(error406);
      }

      setVacancy(applied?.vacancy || {});
      if (applied?.vacancy) {
        newType = statusApply || "error";
      }

      setType(newType);

      if (newType !== "error") {
        cookie.set(
          window.name,
          { ...tab, applyingVacancy: false },
          { expires: 1 }
        );
      }
    } else {
      setType("not_found");
    }

    setTimeout(() => setLoading(false), 1000);
  }, []);

  const goToCurriculumHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
  }, []);

  const goToCandidateVacanciesHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate#vacancy`);
  }, []);

  const perfilHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate`);
  }, []);

  const renderSuccess = useMemo(
    () => (
      <StatusMessage
        title=" Sucesso!"
        iconClass="fas fa-check"
        text={[
          `Aplicação realizada com sucesso na vaga ${vacancy?.title}.`,
          " Você também receberá um e-mail com mais detalhes."
        ]}
        description={[
          "Agora você pode ",
          <strong>ver o status da sua candidatura, </strong>,
          "para acompanhar o processo seletivo."
        ]}
      >
        <Styled.ContainerButton>
          <Button
            text="Ver minhas aplicações"
            type="custom"
            backgroundColor="#333333"
            textColor="#ffffff"
            fontWeight="bold"
            borderColor="#333333"
            borderWidth="1px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
            onClick={goToCandidateVacanciesHandle}
            isUppercase={false}
            medium
          />
        </Styled.ContainerButton>
      </StatusMessage>
    ),
    [goToCandidateVacanciesHandle, vacancy.title]
  );

  const renderError = useMemo(
    () => (
      <StatusMessage
        title={[
          "Ops! Tivemos um problema ",
          <span role="img" aria-label="sad">
            😕
          </span>
        ]}
        iconClass="fas fa-check"
        text={[
          `Algo estranho aconteceu e não foi possível se aplicar na vaga ${vacancy?.title}.`,
          "Isso pode ser devido à alguma falha de conexão entre o sistema e a vaga."
        ]}
        secondaryText={[
          <strong>
            Clique no botão abaixo para editar seu currículo e tentar se
            candidatar novamente.
          </strong>,
          "Se este problema persistir, entre em contato com o nosso time de suporte."
        ]}
      >
        <Styled.ContainerButton>
          <Button
            text="Editar meu currículo"
            type="custom"
            backgroundColor="#333333"
            textColor="#ffffff"
            fontWeight="bold"
            borderColor="#333333"
            borderWidth="1px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
            onClick={goToCurriculumHandle}
            isUppercase={false}
            medium
          />
        </Styled.ContainerButton>
      </StatusMessage>
    ),
    [goToCurriculumHandle, vacancy]
  );

  const renderFirstApplication = useMemo(
    () => (
      <>
        <StyledGlobal.ContainerWizard>
          <StyledGlobal.ProgressBar progress={100} />
          <StyledGlobal.WizardList>
            <StyledGlobal.WizardListItem>
              <StyledGlobal.WizardListItemText bold>
                Cadastro de currículo e dados pessoais
              </StyledGlobal.WizardListItemText>
              <StyledGlobal.WizardListItemDot completed />
            </StyledGlobal.WizardListItem>
            <StyledGlobal.WizardListItem>
              <StyledGlobal.WizardListItemText bold>
                Questionário comportamental Profiler
              </StyledGlobal.WizardListItemText>
              <StyledGlobal.WizardListItemDot completed />
            </StyledGlobal.WizardListItem>
          </StyledGlobal.WizardList>
        </StyledGlobal.ContainerWizard>
        {renderSuccess}
      </>
    ),
    [renderSuccess]
  );

  const renderHasApplied = useMemo(
    () => (
      <StatusMessage
        title="Tudo certo!"
        iconClass="fas fa-check"
        text={[`Você já estava aplicado na vaga ${vacancy?.title}.`]}
        description={[
          "Agora você pode ",
          <strong>ver o status da sua aplicação</strong>,
          " para acompanhar este processo seletivo."
        ]}
      >
        <Styled.ContainerButton>
          <Button
            text="Ver minhas aplicações"
            type="custom"
            backgroundColor="#333333"
            textColor="#ffffff"
            fontWeight="bold"
            borderColor="#333333"
            borderWidth="1px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
            onClick={() =>
              cookie.redirect(
                `${DEFAULT_HOST_CANDIDATE}/perfilCandidate#vacancy`
              )
            }
            isUppercase={false}
            medium
          />
        </Styled.ContainerButton>
      </StatusMessage>
    ),
    [vacancy]
  );

  const renderNotFound = useMemo(
    () => (
      <StatusMessage
        title={[
          "Ops! Tivemos um problema ",
          <span role="img" aria-label="sad">
            😕
          </span>
        ]}
        iconClass="fas fa-check"
        text={
          recruiterError
            ? [
                recruiterError,
                "Caso você seja um colaborador, entre em contato com o seu RH."
              ]
            : [
                "Não foi possível localizar a vaga.",
                " Isso pode ser devido a não estar se aplicando a uma vaga no momento."
              ]
        }
        secondaryText={[
          <strong>
            Clique no botão abaixo para visualizar suas aplicações.
          </strong>,
          "Se este problema persistir, entre em contato com o nosso time de suporte."
        ]}
      >
        <Styled.ContainerButton>
          <Button
            text="Ver minhas aplicações"
            type="custom"
            backgroundColor="#333333"
            textColor="#ffffff"
            fontWeight="bold"
            borderColor="#333333"
            borderWidth="1px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
            onClick={goToCandidateVacanciesHandle}
            isUppercase={false}
            medium
          />
        </Styled.ContainerButton>
      </StatusMessage>
    ),
    [goToCandidateVacanciesHandle, recruiterError]
  );

  const renderStatus = useMemo(() => {
    const status = {
      success: renderSuccess,
      has_applied: renderHasApplied,
      first_application: renderFirstApplication,
      error: renderError,
      not_found: renderNotFound
    };

    return status[type];
  }, [
    renderError,
    renderFirstApplication,
    renderHasApplied,
    renderNotFound,
    renderSuccess,
    type
  ]);

  return (
    <StyledGlobal.Main>
      <Header
        logo={JobsLogo}
        urlLogo={DEFAULT_HOST_CANDIDATE}
        token={token}
        user={user}
        onPerfil={perfilHandle}
        onLogout={() => dispatch(authActions.logout())}
      />

      <Banner
        urlDesktop={JobsBannerDesktopDefault}
        urlMobile={JobsBannerMobileDefault}
      />

      <StyledGlobal.Container id="container_pdf">
        <StyledGlobal.BodyMain>
          <StyledGlobal.BodyContent>
            <Styled.ContainerBody>
              {loading ? <Skeleton /> : renderStatus}
            </Styled.ContainerBody>
          </StyledGlobal.BodyContent>
        </StyledGlobal.BodyMain>
      </StyledGlobal.Container>
      <Footer />
    </StyledGlobal.Main>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ApplicationStatus);
