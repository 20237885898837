import React from "react";
import { useParams, useLocation } from "react-router-dom";

import { Curriculum } from "./components";

const Pdf = () => {
  const { type } = useParams();
  const { state } = useLocation();

  const render = () => {
    switch (type) {
      case "curriculum":
        return <Curriculum currentUser={state.currentUser} type={state.type} />;

      default:
        break;
    }
  };

  return render();
};
export default Pdf;
