import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3,
  span {
    color: #585858;
  }
`;

export const Title = styled.h3`
  margin-bottom: 4px;
  font-size: 16px;
`;

export const Content = styled.span`
  font-size: 0.875rem;

  a,
  a:hover {
    color: var(--purple);
    text-decoration: underline;
  }
`;

export const ContainerMessage = styled.div`
  position: relative;
`;

export const Close = styled.span`
  position: absolute;
  right: 0px;
  top: 5px;
  cursor: pointer;
  font-size: 7px;

  i {
    margin-right: 0px !important;
    color: rgba(0, 0, 0, 0.4);
  }
`;
