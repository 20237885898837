/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import { notification } from "antd";

// import * as StyledGlobal from "../../../../styles/global";
import * as Styled from "./styles";

import { signUp } from "../../../../utils/formValidations/auth/schema";
// import { getHost } from "../../../../utils/getHost";

import Input from "../../../../components/input";
import Button from "../../../../components/button";
import Checkbox from "../../../../components/checkbox";

const modalOptions = {
  termsOfUse: {
    position: "middle",
    size: "large",
    hasBgShadow: true,
    bgShadow: "",
    showClose: true
  },
  login: {
    position: "topRight",
    size: "small",
    hasBgShadow: true,
    showClose: true
  }
};

export const Body = ({
  data,
  changeModal,
  fallbackFormInputHandle,
  fallbackTermChecked,
  errors = {},
  color = "#333333"
}) => {
  const [formInputs, setFormInputs] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [termChecked, setTermChecked] = useState(false);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  useEffect(() => {
    fallbackTermChecked(termChecked);
  }, [termChecked]);

  useEffect(() => {
    setFormInputs({
      email: "",
      cpf: "",
      password: "",
      confirmPassword: ""
    });
  }, []);

  const inputChangeHandler = (type, value) => {
    const data = { ...formInputs };
    let error = { ...formErrors };
    const schema = signUp(formInputs);

    schema
      .validateAt(`${type}`, { [type]: value })
      .then((valid) => {
        delete error[type];
        setFormErrors(error);
      })
      .catch((err) => {
        error = { ...formErrors, [type]: err.message };
        setFormErrors(error);
      });
    // yup.reach(schema, type).validate(value);

    data[type] = value;
    setFormInputs(data);
    fallbackFormInputHandle(data);
  };

  const checkTermHandle = () => {
    setTermChecked(!termChecked);
    inputChangeHandler("userTerm", !termChecked);
  };

  return (
    <Styled.Container>
      <Styled.Title>Cadastrar como candidato</Styled.Title>
      <form autoComplete="off">
        <Styled.Field>
          <Input
            type="text"
            label="E-mail"
            onChange={(e) => inputChangeHandler("email", e.target.value)}
            error={formErrors.email ? formErrors.email : ""}
          />
        </Styled.Field>
        <Styled.Field>
          <Input
            type="text"
            label="CPF"
            onChange={(e) => inputChangeHandler("cpf", e.target.value)}
            error={formErrors.cpf ? formErrors.cpf : ""}
          />
        </Styled.Field>
        <Styled.Field>
          <Input
            type="password"
            label="Senha"
            name="password"
            autoComplete="new-password"
            onChange={(e) => inputChangeHandler("password", e.target.value)}
            error={formErrors.password ? formErrors.password : ""}
          />
        </Styled.Field>
        <Styled.Field>
          <Input
            type="password"
            label="Repetir senha"
            onChange={(e) =>
              inputChangeHandler("confirmPassword", e.target.value)
            }
            error={formErrors.confirmPassword ? formErrors.confirmPassword : ""}
          />
        </Styled.Field>
        <Styled.Field center noMargin>
          <Styled.TermContainer
            error={formErrors.userTerm ? formErrors.userTerm : ""}
          >
            <Checkbox
              type="checkbox"
              onClick={() => checkTermHandle()}
              checked={termChecked}
              defaultChecked={false}
              color="#c3c3c3"
            />
            <Styled.TextTerms>
              Li e concordo com os{" "}
              <Styled.Term
                textColor={color}
                onClick={() =>
                  changeModal("termsOfUse", modalOptions.termsOfUse)
                }
              >
                termos de uso e política de privacidade.
              </Styled.Term>
            </Styled.TextTerms>
          </Styled.TermContainer>
        </Styled.Field>
      </form>
    </Styled.Container>
  );
};

export const Footer = ({
  changeModal,
  fallbackFormSubmitHandle,
  fallbackSignUpLinkedin,
  fallbackUserTermLinkedin,
  color = "#333333",
  termChecked = false
}) => {
  // const [linkedinBaseUrl, setLinkedinBaseUrl] = useState("");

  useEffect(() => {
    getBaseUrl();
  }, []);

  // const handleSuccess = (data) => {
  //     fallbackSignUpLinkedin(data.code);
  // };

  // const handleFailure = (error) => {
  //     notification.warn({
  //         message: "Atenção",
  //         description: error.errorMessage,
  //     });
  // };

  const getBaseUrl = () => {
    // let host = getHost();
    // setLinkedinBaseUrl(host);
  };

  return (
    <Styled.Container>
      {/* <Styled.Field center="true">
                <Styled.ContainerSocialAuth>
                    <LinkedIn
                        clientId="78pp350a2v5dvv"
                        state="EGSQrt0T0kLEXVCt"
                        scope="r_liteprofile r_emailaddress"
                        onFailure={(error) => handleFailure(error)}
                        onSuccess={(data) => handleSuccess(data)}
                        redirectUri={`${linkedinBaseUrl}/auth/linkedin/callback`}
                        renderElement={({ onClick, disabled }) => (
                            <Button
                                small
                                type="linkedin"
                                isUppercase={false}
                                boxShadow={true}
                                leftIcon
                                onClick={() =>
                                    fallbackUserTermLinkedin(onClick)
                                }
                                disabled={disabled}
                            >
                                <StyledGlobal.TextButton>
                                    Cadastrar com
                                </StyledGlobal.TextButton>
                                <StyledGlobal.TextButton bold>
                                    &nbsp;Linkedin
                                </StyledGlobal.TextButton>
                            </Button>
                        )}
                    />
                </Styled.ContainerSocialAuth>
            </Styled.Field> */}
      <Styled.ContainerAuth>
        <Button
          medium
          type="link"
          isUppercase
          fontWeight="bold"
          textColor={color}
          text="Logar"
          onClick={() => changeModal("login", modalOptions.login)}
        />
        <Button
          medium
          isUppercase
          boxShadow
          fontWeight="bold"
          textColor="#FFFFFF"
          backgroundColor={color}
          text="Cadastrar"
          onClick={fallbackFormSubmitHandle}
        />
      </Styled.ContainerAuth>
    </Styled.Container>
  );
};
