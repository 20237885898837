const INITIAL_STATE = {
  loading: false,
  modal: {
    user: {},
    profiler: {},
    show: false,
    type: "",
    domain: "",
    options: {
      position: "middle", // topLeft, topCenter, topRight, middleLeft, middle, middleRight, bottomLeft, bottomCenter, bottomRight
      size: "small", // fullsize, small, medium, large
      hasBgShadow: true,
      bgShadow: "",
      showClose: true
    },
    dataParams: {}
  },
  alert: {
    show: false,
    type: "",
    data: {},
    sizeMode: "fullscreen"
  },
  candidateApply: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@utilities/LOADING":
      return {
        ...state,
        loading: action.payload.loading,
        content: action.payload.content
      };

    case "@utilities/MODAL":
      return {
        ...state,
        modal: action.payload.modal
      };
    case "@utilities/SHOW_ALERT":
      return {
        ...state,
        alert: action.payload.alert
      };
    case "@utilities/CANDIDATE_APPLY_SUCCESS":
      return {
        ...state,
        candidateApply: action.payload.options
      };
    default:
      return state;
  }
}
