import API from "@services/api";

export const getProfiler = async () =>
  new Promise((resolve, reject) => {
    const api = API();
    api.get(`/profiler`).then(resolve).catch(reject);
  });

export const sendAnswersProfiler = async (json) =>
  new Promise((resolve, reject) => {
    const api = API();
    api.post("/profiler", json).then(resolve).catch(reject);
  });
