import styled from "styled-components";

export const Container = styled.section`
  h1 {
    font-size: 1.5rem;
  }
`;

export const Row = styled.div`
  margin-top: 32px;

  label {
    font-size: 16px;
    min-height: auto;

    span {
      display: none;
    }
  }

  > div {
    margin: 28px 0;

    :not(:first-child) {
      margin-top: 16px;
    }
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 2rem;

  label {
    font-size: 0.813rem;
    margin-bottom: 0;

    @media (max-width: 600px) {
      text-align: center;
    }
  }
`;

export const TermsText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.purple};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Login = styled.div`
  padding: 18px 0px 0px 0px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;

export const Link = styled.a`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.purple};

  :hover {
    color: ${({ theme }) => theme.colors.purple};
    text-decoration: underline;
  }
`;

export const ContainerSocial = styled.div`
  margin: 0 0 32px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  button {
    width: 100%;
  }
`;

export const SocialButton = styled.a`
  width: 100%;
  button {
    font-size: 18px !important;
  }

  :nth-child(n + 2) {
    margin-top: 20px;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
`;

export const ButtonSubmit = styled.section`
  button div {
    font-size: 16px;
  }
`;

export const Register = styled.div`
  padding: 0 0 36px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;

  a {
    font-size: 16px;
    padding-left: 4px;
  }
`;

export const SectionDescription = styled.section`
  margin: 16px 0;

  p {
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    font-weight: 700;
    text-align: center;
  }
`;

export const SectionErrorSignUp = styled.section`
  margin-top: 32px;
  background: #ef3d4e26;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
`;

export const SectionRullerPassword = styled.section`
  animation: show 300ms linear;

  ul {
    margin: 0;
    padding-left: 24px;
  }

  svg {
    margin-left: 4px;

    &.success {
      width: 15px;
      height: 15px;
      fill: ${({ theme }) => theme.colors.green};
    }

    &.error {
      width: 12px;
      height: 12px;
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
