import React from "react";
import { Checkbox, Content, Text } from "./styles";

export default function CheckBoxComponent({
  onClick = () => {},
  type,
  text,
  style,
  checked,
  disabled,
  onChange,
  secondary,
  color,
  checkboxType,
  ...rest
}) {
  return (
    <Checkbox secondary={secondary} style={style} color={color} {...rest}>
      <Content
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}
      >
        <label className="pure-material-checkbox" style={style}>
          <input
            type="checkbox"
            onChange={onClick}
            value={checked}
            checked={checked}
            disabled={disabled}
          />
          <span />
        </label>
        <Text secondary={secondary}>{text}</Text>
      </Content>
    </Checkbox>
  );
}
