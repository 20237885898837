import styled from "styled-components";

export const CarouselMain = styled.div`
  width: 100%;
  background: transparent;
  position: relative;
`;

export const CarouselImageContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "340px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 2;
`;

export const CarouselCaptionContainer = styled.div`
  margin-top: 30px;
`;

export const CarouselCaptionContent = styled.div`
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin-bottom: 15px;
`;

export const CarouselCaptionAuthor = styled.div`
  font-family: "Roboto", arial, sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #333333;
`;

export const CarouselCaptionSource = styled.small`
  color: #888;
`;

export const CarouselIndicators = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
`;

export const CarouselIndicatorsItem = styled.div`
  display: block;
  width: 24px;
  height: 3px;
  background-color: #111;
  cursor: pointer;
  opacity: 0.15;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  :nth-of-type(n + 2) {
    margin-left: 9px;
  }
  :hover {
    opacity: 0.75;
  }

  ${(props) =>
    props.active &&
    `
    opacity: .75;
  `}
`;

export const CarouselIndicatorsSolides = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
`;

export const CarouselIndicatorsItemSolides = styled.div`
  display: block;
  width: 80px;
  height: 10px;
  background-color: #111;
  cursor: pointer;
  opacity: 0.15;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  border-radius: 90px;
  :nth-child(1) {
    background-color: #d74149;
    border: 1px solid #9a060c;
  }
  :nth-child(2) {
    background-color: #ffba00;
    border: 1px solid #a47603;
  }
  :nth-child(3) {
    background-color: #57dd6d;
    border: 1px solid #049017;
  }
  :nth-child(4) {
    background-color: #00c9db;
    border: 1px solid #09868e;
  }
  :nth-of-type(n + 2) {
    margin-left: 50px;
  }
  :hover {
    opacity: 0.75;
  }

  ${(props) =>
    props.active &&
    `
    opacity: .75;
  `}
`;

export const Arrows = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${(props) =>
    props.left &&
    `
    order: 1;
    margin-right: 1rem;
  `}

  ${(props) =>
    props.right &&
    `
    order: 3;
    margin-left: 1rem;
  `}
`;

export const ArrowsIcon = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    path {
      fill: ${({ color }) => color || "#333333"};
    }
  }

  :hover {
    opacity: 0.8;
    transition: opacity 0.15s ease;
  }
`;
