import * as yup from "yup";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Campo de preenchimento obrigatório")
    .matches(
      /(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~])(?=.*?[A-Za-z]).+/,
      {
        excludeEmptyString: true,
        message: "A senha deve seguir as regras abaixo."
      }
    )
    .min(6, "A senha deve seguir as regras abaixo."),
  confirmNewPassword: yup
    .string()
    .required("Campo de preenchimento obrigatório")
    .oneOf(
      [yup.ref("newPassword"), null],
      "As senhas não coincidem. Tente novamente."
    )
});

export default schema;
