import React, { useState, useEffect } from "react";

import * as Styled from "./styles";

import Behavior from "./behavior";
import optionsBehavior from "../../../utils/optionsBehavior";
import shuffle from "../../../utils/shuffle";

const QuizStep = ({ step, fallbackSelectItem }) => {
  const [selects, setSelects] = useState([]);
  const [adjectives, setAdjectives] = useState([]);
  const [lastStep, setLastStep] = useState(1);

  useEffect(() => {
    setAdjectives(shuffle(optionsBehavior.stepOne));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsBehavior, step]);

  useEffect(() => {
    if (step === "shouldBeChosenAdjectives") {
      setLastStep(2);
      window.scrollTo(0, 0);
      setSelects([]);

      const el = document.getElementsByTagName("div");
      let i;
      for (i = 0; i < el.length; i++) {
        if (el[i].classList.contains("selected")) {
          el[i].classList.remove("selected");
        }
      }
    }
  }, [step]);

  const _handleOnClick = (event, value) => {
    let data = selects;

    if (!event.currentTarget.classList.contains("selected")) {
      // eslint-disable-next-line array-callback-return
      data = selects.filter((name) => name !== value).map((e) => e);
    } else {
      data = [...selects, value];
    }

    setSelects(data);
    fallbackSelectItem(data);
  };

  const renderTextWichStep = () => {
    switch (step) {
      case "iAmChosenAdjectives":
        return (
          <Styled.ContainerDescription>
            <Styled.DescriptionPrimary>
              Marque abaixo os adjetivos
            </Styled.DescriptionPrimary>{" "}
            <Styled.DescriptionPrimary background>
              que melhor te representam.
            </Styled.DescriptionPrimary>
          </Styled.ContainerDescription>
        );

      case "shouldBeChosenAdjectives":
        return (
          <Styled.ContainerDescription>
            <Styled.DescriptionPrimary>
              Agora, marque, na sua
            </Styled.DescriptionPrimary>{" "}
            <Styled.DescriptionPrimary background>
              percepção como os outros pensam
            </Styled.DescriptionPrimary>{" "}
            <Styled.DescriptionPrimary>
              que você deveria ser (atributos que você tenha ou não).
            </Styled.DescriptionPrimary>
          </Styled.ContainerDescription>
        );
      default:
        break;
    }
  };

  const renderTextStepRules = () => {
    switch (step) {
      case "iAmChosenAdjectives":
        return (
          <Styled.ContainerDescription>
            <Styled.DescriptionSecondary bold>
              Regras:
            </Styled.DescriptionSecondary>
            <Styled.DescriptionList>
              <Styled.DescriptionListItem>
                Marque abaixo os adjetivos que você considera que fazem parte do
                seu perfil.
              </Styled.DescriptionListItem>
              <Styled.DescriptionListItem>
                Esta pesquisa é individual, não solicite apoio de outra pessoa
                para não prejudicar o resultado.
              </Styled.DescriptionListItem>
              <Styled.DescriptionListItem>
                Não interrompa o questionário com distrações ou conversas. Não
                discuta o questionário com pessoas à sua volta.
              </Styled.DescriptionListItem>
              <Styled.DescriptionListItem>
                Mais instruções na próxima página.
              </Styled.DescriptionListItem>
            </Styled.DescriptionList>
          </Styled.ContainerDescription>
        );

      case "shouldBeChosenAdjectives":
        return (
          <Styled.ContainerDescription>
            <Styled.DescriptionSecondary bold>
              Regras:
            </Styled.DescriptionSecondary>{" "}
            <Styled.DescriptionSecondary>
              Responda sozinho, sem ajuda de terceiros. Não interrompa o
              questionário com distrações ou conversas.
            </Styled.DescriptionSecondary>
          </Styled.ContainerDescription>
        );
      default:
        break;
    }
  };
  return (
    <>
      {renderTextWichStep()}
      {renderTextStepRules()}

      <Styled.FormQuiz>
        {adjectives.map((option, index) => (
          <Behavior
            key={`behavior_${index}`}
            name={option.english}
            behaviorId={lastStep}
            onClick={(event, children) => {
              _handleOnClick(event, children);
            }}
          >
            {option.value}
          </Behavior>
        ))}
      </Styled.FormQuiz>
    </>
  );
};

export default QuizStep;
