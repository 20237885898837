import styled from "styled-components";

export const ContainerSelect = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ContainerEntry = styled.div``;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Input = styled.input`
  width: 100%;
  height: 41px;
  background: #ffffff;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #333333;
  font-size: 16px;
  outline: none;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;

  ::placeholder {
    color: ${({ error }) => (error ? "#C6176E" : "#33333399")};
    font-size: 14px;
  }

  /* box-sizing: border-box;
    box-shadow: inset 0px 1px 3px #dddddd; */
  border-radius: 24px;

  :disabled {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.15);
    /* box-sizing: border-box;
        box-shadow: inset 0px 1px 3px #dddddd; */
    color: #c5b8de;
  }

  ${({ error }) =>
    error
      ? "border: 1px solid #C6176E;"
      : "border: 1px solid rgba(66, 82, 110, 0.25);"}
`;

export const ContainerOptions = styled.div`
  position: absolute;
  top: 41px;
  left: 0px;
  z-index: 10;
  width: 100%;

  display: ${({ isShow }) => (isShow ? "block" : "none")};
`;

export const EmptyOptionMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background: #ffffff;
  color: #333333;
`;

export const Options = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px #dddddd;
  border-radius: 5px;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${({ isCreatable }) =>
    isCreatable ? `padding: 8px 12px 48px 12px` : `padding: 8px 12px`};

  ${({ styleOptions }) =>
    !!styleOptions.containerSides &&
    `padding-left: ${styleOptions.containerSides}; padding-right: ${styleOptions.containerSides};`}

  ${({ minMenuHeight, isCreatable }) =>
    minMenuHeight && isCreatable
      ? `min-height: ${minMenuHeight + 41}px`
      : `min-height: ${minMenuHeight}px`};

  ${({ maxMenuHeight, isCreatable }) =>
    maxMenuHeight && isCreatable
      ? `max-height: ${maxMenuHeight + 41}px`
      : `max-height: ${maxMenuHeight}px`};

  :hover::-webkit-scrollbar {
    display: flex;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333333;
  }
`;

export const ContainerCreate = styled.div`
  position: absolute;
  bottom: 0px;
  background: #ffffff;
  border: 1px solid #c5b8de;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;

  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    color: #5f4a87;
  }
`;

export const CreateText = styled.div`
  color: #5f4a87;
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerOptionsSelected = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px;

  ${({ typeList }) => typeList && `flex-direction: ${typeList}`};
`;

export const ContainerStatus = styled.div`
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 12px;
`;
