import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

const Typography = ({ type, children, ...rest }) => (
  <Container type={type} {...rest}>
    {children}
  </Container>
);

Typography.defaultProps = {
  type: "normal"
};

Typography.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Typography;
