import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const DatePickerWrapper = styled.div``;

export const DatePicker = styled(TextField)`
  width: 100%;
  border: none;
  outline: none;
  border-bottom: none;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  line-height: 1.3125rem;
  width: 100%;
  outline: none;

  label {
    display: none;
  }

  fieldset {
    border: none;
  }

  div {
    padding-right: 0px !important;
  }

  input {
    padding: 0.6875rem 0 0.6875rem 0;
  }
`;

export const RightIcon = styled.span`
  position: absolute;
  right: 45px;
  top: 10px;

  svg {
    width: 0.875rem;
    height: 1.3125rem;

    path {
      fill: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const ContainerInput = styled.div.attrs((props) => ({
  border: props.border || "1px solid rgba(0, 0, 0, 0.15)",
  borderColor: props.hasError ? props.theme.colors.red : "rgba(0, 0, 0, 0.15)"
}))`
  position: relative;
  border: ${(props) => props.border};
  border-color: ${(props) => props.borderColor};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 51px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  max-height: 45px;

  & + svg {
    font-size: 1.2rem !important;

    path {
      fill: ${({ theme }) => theme.colors.default};
    }
  }
`;
