import PropTypes from "prop-types";
import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Label, Select } from "@components/form";
import { getMonths, getYears } from "@utils/monthsAndYears";

import StartDateError from "../startDateError";

import { ContainerDates } from "./styles";

const ExperienceDateRange = ({ groupName, fieldId }) => {
  const MONTHS = getMonths();
  const YEARS = getYears();

  const { control } = useFormContext();
  const watchGroup = useWatch({
    control,
    name: `${groupName}.${fieldId}`
  });
  const [startDateError, setStartDateError] = useState([]);

  return (
    <>
      <ContainerDates isError={startDateError.includes(fieldId)}>
        <Label required>Data de início</Label>
        <div>
          <Select
            options={MONTHS}
            placeholder="Mês"
            name={`${groupName}.${fieldId}.startMonth`}
          />
          <Select
            options={YEARS}
            placeholder="Ano"
            name={`${groupName}.${fieldId}.startYear`}
          />
        </div>

        <StartDateError
          groupName={groupName}
          fieldId={fieldId}
          onChangeError={setStartDateError}
        />
      </ContainerDates>

      <ContainerDates>
        <Label required={watchGroup?.current === "off"}>Data de término</Label>
        <div>
          <Select
            options={MONTHS}
            placeholder="Mês"
            name={`${groupName}.${fieldId}.endMonth`}
            isDisabled={watchGroup?.current === "on"}
          />
          <Select
            options={YEARS}
            placeholder="Ano"
            name={`${groupName}.${fieldId}.endYear`}
            isDisabled={watchGroup?.current === "on"}
          />
        </div>
      </ContainerDates>
    </>
  );
};

ExperienceDateRange.propTypes = {
  groupName: PropTypes.string.isRequired,
  fieldId: PropTypes.number.isRequired
};

export default ExperienceDateRange;
