import { all, put, takeLatest } from "redux-saga/effects";

function* candidateApplySaga({ payload }) {
  try {
    if (payload.vacancyId) {
      yield put({
        type: "@utilities/CANDIDATE_APPLY_SUCCESS",
        payload: { options: { vacancyId: payload.vacancyId } }
      });
    } else if (payload.referenceId) {
      yield put({
        type: "@utilities/CANDIDATE_APPLY_SUCCESS",
        payload: { options: { referenceId: payload.referenceId } }
      });
      yield put({
        type: "@utilities/IS_APPLYED_TALENT_BASE",
        payload: {}
      });
    } else {
      yield put({
        type: "@utilities/CANDIDATE_APPLY_SUCCESS",
        payload: { options: {} }
      });
    }

    yield put({
      type: "@curriculum/USER_CURRICULUM_SUCCESS",
      payload: { userCurriculumFile: {} }
    });
  } catch (error) {
    yield put({
      type: "@utilities/CANDIDATE_APPLY_SUCCESS",
      payload: { options: {} }
    });
  }
}

export default all([
  takeLatest("@utilities/CANDIDATE_APPLY", candidateApplySaga)
]);
