import React from "react";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import * as StyledGlobal from "@styles/global";

import {
  CurriculumProvider,
  AlertModalProvider,
  ApplyVacancyProvider,
  CurriculumModalProvider,
  AlertsProvider,
  CurriculumValidationProvider,
  CurriculumSectionProvider
} from "@contexts/index";

import { PageHeader } from "./components";
import CurriculumContent from "./curriculumContent";

export default function CurriculumV2() {
  return (
    <StyledGlobal.Main background="#f1f1f1">
      <PageHeader />
      <ApplyVacancyProvider>
        <CurriculumProvider>
          <CurriculumModalProvider>
            <CurriculumValidationProvider>
              <CurriculumSectionProvider>
                <AlertsProvider>
                  <AlertModalProvider>
                    <CurriculumContent />
                  </AlertModalProvider>
                </AlertsProvider>
              </CurriculumSectionProvider>
            </CurriculumValidationProvider>
          </CurriculumModalProvider>
        </CurriculumProvider>
      </ApplyVacancyProvider>
    </StyledGlobal.Main>
  );
}
