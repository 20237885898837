import React from "react";
import { InputWithLabel, InvalidFeedback } from "../../styles";

export default function InputNumber({
  label,
  style,
  onChange,
  onInput,
  error,
  ...rest
}) {
  return (
    <InputWithLabel {...rest}>
      <input
        className="number"
        type="number"
        placeholder=" "
        style={style}
        onChange={onChange}
        {...rest}
      />
      <label>{label}</label>
      {!!error && <InvalidFeedback>{error}</InvalidFeedback>}
    </InputWithLabel>
  );
}
