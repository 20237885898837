const print = (id) => {
  const elem = document.getElementById(id);
  const domClone = elem.cloneNode(true);
  const $printSection = document.createElement("div");

  $printSection.id = "printSection";
  document.body.appendChild($printSection);
  $printSection.innerHTML = "";
  $printSection.appendChild(domClone);
  window.print();

  $printSection.remove();
};

export default print;
