import React from "react";

import { ReactComponent as Subscribe } from "@assets/svg/inscrever.svg";
import { ReactComponent as Registered } from "@assets/svg/inscrito.svg";
import * as Styled from "./styles";
import TalentRow from "./talentRow";

const Table = ({
  list = [],
  isAppliedTalentBank,
  fallBackDescription,
  fallBackApplyTalentBank,
  anchorLink,
  showButton = true,
  color = "#333333",
  ...rest
}) => (
  <Styled.TableMain {...rest}>
    <TalentRow
      color={color}
      isAppliedTalentBank={isAppliedTalentBank}
      fallBackApplyTalentBank={fallBackApplyTalentBank}
    />
    {list.map((item, index) => (
      <Styled.TableContainer key={`item_${index}`}>
        <Styled.VacancyContainer>
          <Styled.AnchorTitle
            href={anchorLink ? `#${anchorLink}` : ""}
            onClick={() => fallBackDescription(item)}
          >
            <Styled.TableTitle fontWeight="bold">
              {item.title1}
            </Styled.TableTitle>
            <Styled.TableSubTitle>{item.subtitle1}</Styled.TableSubTitle>

            {item.title2 && item.subtitle2 && (
              <Styled.TableTitle
                marginTop={4}
              >{`${item.title2} / ${item.subtitle2}`}</Styled.TableTitle>
            )}
          </Styled.AnchorTitle>
        </Styled.VacancyContainer>

        <Styled.ContainerBadge>
          <Styled.Badge
            isLink
            color={color}
            onClick={() => fallBackDescription(item)}
            status={item.status === false ? "candidatar" : "inscrito"}
            marginRight="5"
          >
            <i className="fas fa-briefcase" /> {item.availablePositions}{" "}
            {item.availablePositions > 1 ? "vagas" : "vaga"}
          </Styled.Badge>

          <Styled.Badge
            isLink
            color={color}
            onClick={() => fallBackDescription(item)}
            status={item.status === false ? "candidatar" : "inscrito"}
          >
            <Styled.BadgeHover>
              {!item.status ? "VER VAGA" : "INSCRITO"}
            </Styled.BadgeHover>
            {!item.status ? (
              <i className="fas fa-arrow-circle-right" />
            ) : (
              <i className="fas fa-check-circle" />
            )}
          </Styled.Badge>
        </Styled.ContainerBadge>
      </Styled.TableContainer>
    ))}
  </Styled.TableMain>
);

export default Table;
