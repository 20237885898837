export const getInitials = function (string) {
  if (!!string === false) return;

  const names = string.split(" ");
  let initials;
  if (
    string.includes("C1") ||
    string.includes("C2") ||
    string.includes("E1") ||
    string.includes("E2") ||
    string.includes("G1") ||
    string.includes("G2")
  ) {
    initials = names[0].toUpperCase();
  } else {
    initials = names[0].substring(0, 1).toUpperCase();
  }

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
