import React from "react";

import * as Styled from "../styles";

import VacancyView from "../components/vacancy";
import VacancyListView from "../components/listVacancies";
import { SkeletonVacancies } from "../components/skeleton";

const Vacancy = ({
  applications,
  vacancyDescription,
  loading,
  fallbackVacancyDescription = () => {},
  fallBackCloseDescription = () => {}
}) => (
  <Styled.ContainerVacancy id="vacancyDescription">
    {!!vacancyDescription.id && (
      <VacancyView
        vacancy={vacancyDescription}
        fallBackCloseDescription={() => fallBackCloseDescription()}
      />
    )}

    <Styled.BoxHeader>
      <Styled.BoxHeaderCol>
        <Styled.Title>Vagas</Styled.Title>
      </Styled.BoxHeaderCol>
      <Styled.BoxHeaderCol>
        <Styled.Text fontWeight="bold">Status e etapa atual</Styled.Text>
      </Styled.BoxHeaderCol>
      <Styled.BoxHeaderCol>
        <Styled.Text fontWeight="bold">Data de aplicação</Styled.Text>
      </Styled.BoxHeaderCol>
    </Styled.BoxHeader>

    {loading ? (
      <SkeletonVacancies />
    ) : (
      <VacancyListView
        listVacancies={applications}
        fallbackVacancyDescription={(id) => fallbackVacancyDescription(id)}
      />
    )}
  </Styled.ContainerVacancy>
);

export default Vacancy;
