import React, { useCallback, useMemo } from "react";
import Scrollspy from "react-scrollspy";
import { useDispatch } from "react-redux";

import { ProgressBar, Button, FloatingFooter } from "@components/index";

import * as cookie from "@utils/cookie";

import * as utilitiesActions from "@store/modules/utilities/actions";

import { DEFAULT_HOST_CANDIDATE } from "@services/constants";

import * as StyledGlobal from "@styles/global";
import * as Styled from "../../styles";

const AsideMenu = ({ type, percentage }) => {
  const dispatch = useDispatch();

  const handlePrint = useCallback(async () => {
    dispatch(
      utilitiesActions.showModal({
        show: true,
        type: "curriculumDownload",
        options: {
          position: "middleCenter",
          size: "medium",
          hasBgShadow: true,
          bgShadow: "",
          showClose: true,
          closeOnOverlay: false
        }
      })
    );
  }, []);

  const handleEditCurriculum = useCallback(() => {
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
  }, []);

  const floatingFooterButtons = useMemo(
    () => [
      { text: "Baixar currículo", onClick: handlePrint },
      { text: "Editar", onClick: handleEditCurriculum, isPrimary: true }
    ],
    [handleEditCurriculum, handlePrint]
  );

  return (
    <>
      <StyledGlobal.Desktop>
        <Styled.ContainerAside>
          <ProgressBar
            minHeight="28px"
            percentage={percentage}
            hasShadown
            descriptionTitle="Quer encontrar sua vaga ideal?"
            description="Preencha todos os dados e aumente as chances de encontrar a vaga perfeita pra você."
            progressColor="#333333"
            progressBackground="#999999"
          />

          {!!type && type === "curriculum" && (
            <StyledGlobal.ScrollSpyContainer>
              <Scrollspy
                items={[
                  "tip01",
                  "tip02",
                  "tip03",
                  "tip04",
                  "tip05",
                  "tip06",
                  "tip07"
                ]}
                currentClassName="active"
                componentTag="div"
              >
                <StyledGlobal.ScrollSpyElement href="#tip01">
                  Sobre você
                </StyledGlobal.ScrollSpyElement>
                <StyledGlobal.ScrollSpyElement href="#tip02">
                  Experiência
                </StyledGlobal.ScrollSpyElement>
                <StyledGlobal.ScrollSpyElement href="#tip03">
                  Formação
                </StyledGlobal.ScrollSpyElement>
                <StyledGlobal.ScrollSpyElement href="#tip04">
                  Idiomas
                </StyledGlobal.ScrollSpyElement>
                <StyledGlobal.ScrollSpyElement href="#tip05">
                  Habilidades
                </StyledGlobal.ScrollSpyElement>
                <StyledGlobal.ScrollSpyElement href="#tip06">
                  Informações adicionais
                </StyledGlobal.ScrollSpyElement>
                <StyledGlobal.ScrollSpyElement href="#tip07">
                  Documentos
                </StyledGlobal.ScrollSpyElement>
              </Scrollspy>
            </StyledGlobal.ScrollSpyContainer>
          )}

          <Styled.ContainerAsideItem>
            <Styled.ContainerButton>
              <Button
                type="custom"
                text="Editar currículo"
                isUppercase={false}
                backgroundColor="#333333"
                medium
                onClick={handleEditCurriculum}
              />
            </Styled.ContainerButton>
            <Styled.ContainerButton>
              <Button
                type="custom"
                text="Baixar currículo"
                isUppercase={false}
                backgroundColor="#fff"
                textColor="#333333"
                borderColor="#8872B2"
                borderWidth="1px"
                boxShadow="rgba(47, 10, 119, 0.35)"
                medium
                onClick={handlePrint}
              />
            </Styled.ContainerButton>
          </Styled.ContainerAsideItem>
        </Styled.ContainerAside>
      </StyledGlobal.Desktop>

      <StyledGlobal.Mobile>
        <FloatingFooter buttons={floatingFooterButtons} />
      </StyledGlobal.Mobile>
    </>
  );
};

export default React.memo(AsideMenu);
