import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import { usePrevious } from "@hooks/usePrevious";
import useCurriculum from "@hooks/useCurriculum";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { useCurriculumValidation } from "@hooks/useCurriculumValidations";

const CurriculumSectionContext = createContext({});

function CurriculumSectionProvider({ children }) {
  const [selectedSection, setSelectedSection] = useState({
    key: "about-you"
  });
  const { validationsMenu } = useCurriculumValidation();
  const { formMethods, handleSubmit } = useCurriculum();

  const sectionOptions = useMemo(
    () => [
      {
        key: "about-you",
        text: "Sobre você",
        isValid: !!validationsMenu?.aboutYou?.isValid,
        status: validationsMenu?.aboutYou?.status
      },
      {
        key: "experiences",
        text: "Experiências",
        isValid: !!validationsMenu?.experiences?.isValid,
        status: validationsMenu?.experiences?.status
      },
      {
        key: "skills",
        text: "Habilidades",
        isValid: !!validationsMenu?.skills?.isValid,
        status: validationsMenu?.skills?.status
      },
      {
        key: "personal-data",
        text: "Dados pessoais",
        isValid: !!validationsMenu?.personal?.isValid,
        status: validationsMenu?.personal?.status
      },
      {
        key: "address",
        text: "Endereço",
        isValid: !!validationsMenu?.address?.isValid,
        status: validationsMenu?.address?.status
      },
      {
        key: "documents",
        text: "Documentos",
        isValid: !!validationsMenu?.documents?.isValid,
        status: validationsMenu?.documents?.status
      }
    ],
    [validationsMenu]
  );

  const changeSection = useCallback(
    async (newValue, isSubmit = true) => {
      window.scroll(0, 0);

      if (newValue && isSubmit) {
        await handleSubmit(newValue);
        if (Object.keys(formMethods?.formState?.errors).length === 0)
          setSelectedSection({ key: newValue });
      } else {
        setSelectedSection({ key: newValue });
      }
    },
    [formMethods?.formState, handleSubmit]
  );

  const getPreviousSection = useCallback(() => {
    if (selectedSection?.key === "about-you") return selectedSection;

    const currentSection = sectionOptions.findIndex(
      ({ key }) => key === selectedSection?.key
    );

    return sectionOptions[currentSection - 1].key;
  }, [selectedSection]);

  const handlePreviousSection = useCallback(() => {
    if (formMethods?.formState.isDirty) {
      return false;
    }
    changeSection(getPreviousSection(), false);
    return true;
  }, [formMethods?.formState.isDirty, selectedSection]);

  const value = useMemo(
    () => ({
      selectedSection,
      sectionOptions,
      getPreviousSection,
      handlePreviousSection,
      changeSection
    }),
    [
      selectedSection,
      sectionOptions,
      getPreviousSection,
      handlePreviousSection,
      changeSection
    ]
  );

  return (
    <CurriculumSectionContext.Provider value={value}>
      {children}
    </CurriculumSectionContext.Provider>
  );
}

export { CurriculumSectionContext, CurriculumSectionProvider };
