import React, { useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { Label } from "@components/form";
import {
  CurriculumSectionTemplate,
  Tooltip,
  Typography,
  Icon
} from "@components";
import { aboutYouSchema } from "@utils/formValidations/curriculumV2/index";

import { useAuth } from "@hooks/useAuth";
import useCurriculum from "@hooks/useCurriculum";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { useCurriculumSections } from "@hooks/useCurriculumSections";
import {
  changeUserPicture,
  deleteUserPicture
} from "@store/modules/curriculum/actions";

import Avatar from "../../avatar";
import TextLink from "../../link";
import { TooltipLinkedin } from "../../tooltips";
import VideoApresentation from "../../videoApresentation";

import * as Styled from "../styles";

function AboutYou({ onSave, children }) {
  const { user, setUserData } = useAuth();
  const { registerForm, handleSubmit } = useCurriculum();
  const { requiredFields, isRequiredField } = useApplyVacancy();
  const dispatch = useDispatch();
  const { sectionOptions } = useCurriculumSections();

  useEffect(() => {
    const schema = aboutYouSchema(requiredFields);
    const resolver = yupResolver(schema);
    registerForm({
      schema: { resolver },
      handleSubmit: { onSubmit }
    });
  }, [requiredFields, sectionOptions]);

  const onSubmit = useCallback(
    (data, nextPath) => {
      const formattedData = {
        name: data.name,
        salaryExpectation: data.salaryExpectation || "",
        videoUrl: data.videoUrl || "",
        portfolioUrl: data.portfolioUrl || "",
        contacts: {
          facebook: data.contacts.facebook
            ? `https://www.facebook.com/${data.contacts.facebook}`
            : "",
          instagram: data.contacts.instagram
            ? `https://www.instagram.com/${data.contacts.instagram}`
            : "",
          twitter: data.contacts.twitter
            ? `https://www.twitter.com/${data.contacts.twitter}`
            : "",
          linkedin: data.contacts.linkedin
        }
      };
      onSave(formattedData, nextPath);
    },
    [sectionOptions]
  );

  return (
    <CurriculumSectionTemplate title="Sobre você">
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.Row>
          <Styled.Col>
            <Avatar
              required={requiredFields.includes("photo")}
              link={user.picture || ""}
              onChange={async (tempFile) => {
                const data = await dispatch(
                  changeUserPicture({
                    type: user.picture ? "edit" : "new",
                    payload: tempFile
                  })
                );
                setUserData({ ...user, picture: data });
              }}
              onDelete={() => {
                dispatch(deleteUserPicture());
                setUserData({ ...user, picture: "" });
              }}
            />
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col>
            <Label required>Nome completo</Label>

            <Typography style={{ marginBottom: "0.5rem" }}>
              Nome de registro ou nome social.
            </Typography>

            <Styled.Input
              name="name"
              required
              maxLength="100"
              data-testid="name"
              placeholder="Nome e sobrenome"
            />
          </Styled.Col>

          <Styled.Col>
            <Styled.Input
              data-testid="salaryExpectation"
              name="salaryExpectation"
              label="Qual sua pretensão salarial?"
              type="currency"
              currency="BRL"
              required={isRequiredField("salary")}
              placeholder="R$ 0,00"
              maxLength={13}
            />
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col>
            <VideoApresentation
              name="videoUrl"
              required={isRequiredField("introductionVideoURL")}
            >
              <Styled.Input
                data-testid="videoUrl"
                name="videoUrl"
                placeholder="https://youtu.be/videoApresentação"
              />
            </VideoApresentation>
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col>
            <Label required={isRequiredField("portfolioUrl")}>
              Link do portfólio
            </Label>
            <Typography>
              Link do site onde estão publicados alguns dos seus
              <br />
              trabalhos e projetos já feitos, caso houver.
            </Typography>
            <Styled.Input
              name="portfolioUrl"
              data-testid="portfolioUrl"
              placeholder="https://www.exemplo.com.br/portfolio"
            />
          </Styled.Col>

          <Styled.Col>
            <Label required={isRequiredField("linkedin")}>Linkedin</Label>
            <Typography>
              Copie o link do seu perfil e cole abaixo.
              <br />
              <Tooltip close content={TooltipLinkedin}>
                <TextLink>Onde está o link?</TextLink>{" "}
                <Icon className="fas fa-question-circle" color="#1787e0" />
              </Tooltip>
            </Typography>
            <Styled.Input
              name="contacts.linkedin"
              data-testid="contacts[linkedin]"
              placeholder="https://www.linkedin.com/in/seu-usuario/"
              leftIcon={<Icon className="fab fa-linkedin" color="#333333" />}
            />
          </Styled.Col>
        </Styled.Row>

        <Typography type="subtitle" style={{ marginBottom: "1.5rem" }}>
          Redes sociais
        </Typography>

        <Styled.Row>
          <Styled.Col>
            <Label required={isRequiredField("facebook")}>Facebook</Label>
            <Typography>Complete com o seu nome de usuário</Typography>
            <Styled.Input
              name="contacts.facebook"
              data-testid="contacts[facebook]"
              placeholder="seu-usuário/"
              leftIcon={<Icon className="fab fa-facebook" color="#333333" />}
              prefix="https://www.facebook.com/"
            />
          </Styled.Col>

          <Styled.Col>
            <Label required={isRequiredField("instagram")}>Instagram</Label>
            <Typography>Complete com o seu nome de usuário</Typography>
            <Styled.Input
              name="contacts.instagram"
              data-testid="contacts[instagram]"
              placeholder="seu-usuário/"
              leftIcon={<Icon className="fab fa-instagram" color="#333333" />}
              prefix="https://www.instagram.com/"
            />
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col flex={0.5}>
            <Label required={isRequiredField("twitter")}>Twitter</Label>
            <Typography>Complete com o seu nome de usuário</Typography>
            <Styled.Input
              name="contacts.twitter"
              data-testid="contacts[twitter]"
              placeholder="seu-usuário/"
              leftIcon={<Icon className="fab fa-twitter" color="#333333" />}
              prefix="https://twitter.com/"
            />
          </Styled.Col>
        </Styled.Row>
        {children}
      </Styled.Form>
    </CurriculumSectionTemplate>
  );
}

export default React.memo(AboutYou);
