export default function getDiffDate(total) {
  if (total === undefined || total === null) return "";

  let years;
  let months;
  let duration;
  let yearText = "ano";
  let monthText = "mês";

  if (total > 12) {
    total = Math.round(total);

    years = Math.floor(total / 12);
    yearText = years === 1 ? yearText : "anos";

    const totalMonths = Math.round(total % 12);
    if (totalMonths !== 0) {
      months = totalMonths;
      monthText = months === 1 ? monthText : "meses";
      duration = `${years} ${yearText} e ${months} ${monthText}`;
    } else {
      duration = `${years} ${yearText}`;
    }
  } else {
    months = Math.round(total);
    monthText = months === 1 ? monthText : "meses";
    duration = `${months} ${monthText}`;
  }

  return duration;
}
