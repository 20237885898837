import React, { useState, useEffect } from "react";
import ReactNumberFormat from "react-number-format";

const NumberFormat = React.forwardRef(
  ({ onChange, value, defaultValue, format, ...rest }, ref) => {
    const [number, setNumber] = useState(value);

    useEffect(() => {
      setNumber(value);
    }, [value]);

    return (
      <ReactNumberFormat
        {...rest}
        ref={ref}
        value={number}
        format={format}
        mask="_"
        onValueChange={(target) => {
          setNumber(target.floatValue);
          onChange(target.value);
        }}
        isNumericString
        allowNegative={false}
        decimalScale={0}
      />
    );
  }
);

export default NumberFormat;
