import React from "react";
import { components } from "react-select";
import { Container, InputCheckbox } from "../../checkbox/styles";
import { Label } from "../styles";

const Option = ({ label, isMulti, isSelected, ...props }) =>
  isMulti ? (
    <div>
      <components.Option isSelected={isSelected} {...props}>
        <Container>
          <InputCheckbox type="checkbox" checked={isSelected} readOnly />
          <span />
          <Label>{label}</Label>
        </Container>
      </components.Option>
    </div>
  ) : (
    <components.Option isSelected={isSelected} {...props} />
  );

export default Option;
