import styled from "styled-components";

export const Container = styled.div`
  max-width: 808px;
  padding-bottom: 50px;
  margin: 0 auto;

  @media (min-width: 992px) {
    padding-top: 42px;
  }
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  min-height: 100px;
  padding: 75px 90px 55px 90px;

  @media screen and (max-width: 600px) {
    padding: 40px;
  }

  @media screen and (max-width: 450px) {
    padding: 40px 20px;
  }
`;

export const ContainerBodyText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
`;

export const ContainerTitle = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const ContainerText = styled.div`
  margin-bottom: 50px;
`;

export const Title = styled.h1`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #333333;
  margin-bottom: 40px;
`;

export const Text = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ title }) => (title ? "21px" : "16px")};
  line-height: ${({ title }) => (title ? "32px" : "40px")};
  text-align: center;
  color: #333333;
  margin: 0;
  @media screen and (max-width: 680px) {
    font-size: 15px;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
`;

export const ContainerGroupButton = styled.div`
  width: 75%;
  display: flex;

  @media screen and (max-width: 680px) {
    flex-direction: column;

    ${ContainerButton}:first-child {
      margin-bottom: 20px;
    }
  }
`;

export const BodyContent = styled.div`
  width: 100%;
  display: flex;
  flex: 2;
  align-items: normal;
  flex-direction: column;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 2;
  height: 100%;
  margin-bottom: 100px;

  @media (min-width: 992px) {
    margin-bottom: auto;
  }
`;

export const Icon = styled.i`
  font-size: 24px;
`;
