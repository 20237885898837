import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect
} from "react";
import { useDispatch } from "react-redux";

import * as Styled from "./styles";

import Button from "../button";
import Dropdown from "../dropdown";
import Image from "../image";
import useOutsideClick from "../useOutsideClick";

import { getShortName } from "../../utils/getShortName";
import { getAcronym } from "../../utils/getAcronym";
import * as cookie from "../../utils/cookie";
import { useCompany } from "../../utils/hooks/useCompany";

import * as utilitiesActions from "../../store/modules/utilities/actions";
import * as candidateActions from "../../store/modules/perfilCandidate/actions";

const Header = ({
  logo,
  urlLogo,
  token,
  user,
  showTalentBank = false,
  background,
  onAuthenticate,
  onGoToAbout,
  onGoToVacancies,
  onPerfil,
  onLogout
}) => {
  const dispatch = useDispatch();
  const { slug, company } = useCompany();
  const menuRef = useRef();
  const [menuOptionsVisible, setMenuOptionsVisible] = useState(false);
  const [menuMobile, setMenuMobile] = useState([]);

  useOutsideClick(menuRef, () => {
    setMenuOptionsVisible(false);
  });

  useEffect(() => {
    let data = [];

    if (token) {
      data = [
        ...data,
        {
          text: "Ver Perfil",
          onClick: onPerfil
        },
        {
          text: "Preferências",
          onClick: userSettingsHandle
        },
        {
          text: "Sair",
          onClick: onLogout
        }
      ];
    } else if (company?.slug && !token) {
      data.push(
        {
          text: "Sobre",
          onClick: onGoToAbout
        },
        {
          text: "Vagas",
          onClick: onGoToVacancies
        },
        {
          text: "Entrar",
          onClick: authenticateHandle
        }
      );
    } else {
      data.push({
        text: "Entrar",
        onClick: authenticateHandle
      });
    }

    setMenuMobile(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTalentBank, onLogout, onPerfil, token, slug]);

  const deleteUserHandle = useCallback(() => {
    dispatch(
      utilitiesActions.showAlert({
        show: true,
        type: "modalAlert",
        sizeMode: "auto",
        data: {
          type: "warning",
          title: "Excluir Conta",
          textContent: `Deseja que sua conta seja excluída? Essa ação NÃO poderá ser revertida.`,
          btn1: {
            text: "CANCELAR",
            type: "custom",
            bgColor: "#fff",
            color: "#333333",
            border: "#8872B2",
            flex: 1
          },
          btn2: {
            text: "SIM, DESEJO EXCLUIR",
            type: "custom",
            bgColor: "#333333",
            color: "#ffffff",
            border: "#333333",
            flex: 1
          },
          fallback: () => dispatch(candidateActions.deleteUser())
        }
      })
    );
  }, [dispatch]);

  const settingsEmailHandle = useCallback(
    (values) => {
      if (values) {
        const { newEmail, confirmNewEmail, password } = values;

        dispatch(
          candidateActions.setChangeEmail({
            newEmail,
            confirmNewEmail,
            password
          })
        );
      }
    },
    [dispatch]
  );

  const settingsPasswordHandle = useCallback(
    (values) => {
      if (values) {
        const { actualPassword, newPassword, confirmNewPassword } = values;

        dispatch(
          candidateActions.setChangePassword({
            actualPassword,
            newPassword,
            confirmNewPassword
          })
        );
      }
    },
    [dispatch]
  );

  const userSettingsHandle = useCallback(
    () =>
      dispatch(
        utilitiesActions.showModal({
          show: true,
          type: "settings",
          options: {
            position: "topCenter",
            size: "large",
            hasBgShadow: true,
            showClose: true
          },
          dataParams: {
            currentUser: user,
            fallbackDelete: () => deleteUserHandle(),
            fallbackChangeEmail: (values) => settingsEmailHandle(values),
            fallbackChangePassword: (values) => settingsPasswordHandle(values)
          }
        })
      ),
    [
      dispatch,
      deleteUserHandle,
      settingsEmailHandle,
      settingsPasswordHandle,
      user
    ]
  );

  const authenticateHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    onAuthenticate(window.location.href);
  }, [onAuthenticate]);

  const renderMenuMobileAuthenticated = useMemo(
    () => (
      <Styled.Mobile ref={menuRef}>
        <Styled.MenuIcon
          onClick={() => setMenuOptionsVisible(!menuOptionsVisible)}
        >
          <i className="fas fa-bars" />
        </Styled.MenuIcon>
        <Styled.MenuOptions visible={menuOptionsVisible}>
          <Styled.MenuIcon onClick={() => setMenuOptionsVisible(false)}>
            <i className="fas fa-times" />
          </Styled.MenuIcon>

          {menuMobile.map((item, index) => (
            <Styled.MenuItem
              key={`MenuMobile:${index}`}
              onClick={() => {
                setMenuOptionsVisible(false);
                item.onClick();
              }}
            >
              {item.text}
            </Styled.MenuItem>
          ))}
        </Styled.MenuOptions>
      </Styled.Mobile>
    ),
    [menuMobile, menuOptionsVisible]
  );

  const renderMenuMobileNotAuthenticated = useMemo(
    () => (
      <Styled.Mobile ref={menuRef}>
        {menuMobile.map((item, index) =>
          item?.text.includes("Entrar") ? (
            <Styled.MenuBtnLogin
              textColor={company?.colorCompany || "#333333"}
              key={`MenuMobile:${index}`}
              onClick={() => {
                setMenuOptionsVisible(false);
                item.onClick();
              }}
            >
              {item.text}
            </Styled.MenuBtnLogin>
          ) : (
            <Styled.MenuItemNotLogged
              textColor={company?.colorCompany || "#333333"}
              key={`MenuMobile:${index}`}
              onClick={() => {
                setMenuOptionsVisible(false);
                item.onClick();
              }}
            >
              {item.text}
            </Styled.MenuItemNotLogged>
          )
        )}
      </Styled.Mobile>
    ),
    [menuMobile, menuOptionsVisible, company]
  );

  return (
    <Styled.HeaderContainer background={background}>
      <Styled.HeaderBox background={background}>
        <Styled.LogoJobs>
          <Styled.Logo
            src={logo}
            alt="Logo do Jobs"
            onClick={() => cookie.redirect(urlLogo)}
          />
        </Styled.LogoJobs>
        <Styled.Authentication>
          <Styled.UserAuthenticated>
            {token ? (
              <Dropdown
                className="dropdownUser"
                medium
                isUppercase={false}
                type="outline"
                borderColor="#D0CCC8"
                textColor="#333333"
                options={[
                  {
                    text: "Perfil",
                    onClick: () => onPerfil()
                  },
                  {
                    text: "Preferências",
                    onClick: () => userSettingsHandle()
                  },
                  {
                    text: "Sair",
                    onClick: () => onLogout()
                  }
                ]}
                leftPhoto={
                  user?.picture ? (
                    <Image
                      imgType="roundedCircle"
                      imgSize="32"
                      url={user.picture}
                    />
                  ) : (
                    <Image
                      imgType="roundedCircle"
                      imgSize="32"
                      backgound={getAcronym(user.name || "Candidato(a)")}
                      backgoundColor="#333333"
                    />
                  )
                }
              >
                <Styled.TextButton>Olá!</Styled.TextButton>
                <Styled.TextButton bold>
                  &nbsp;
                  {user?.name ? getShortName(user.name, 2) : "Candidato(a)"}
                </Styled.TextButton>
              </Dropdown>
            ) : (
              <Button
                medium
                isUppercase={false}
                type="outline"
                borderColor={company?.colorCompany || "#333333"}
                textColor={company?.colorCompany || "#333333"}
                text="Entrar"
                onClick={authenticateHandle}
              />
            )}
          </Styled.UserAuthenticated>
        </Styled.Authentication>
        {token
          ? renderMenuMobileAuthenticated
          : renderMenuMobileNotAuthenticated}
      </Styled.HeaderBox>
    </Styled.HeaderContainer>
  );
};

export default Header;
