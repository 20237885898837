import React from "react";

import * as Styled from "./styles";

const ProgressBar = ({
  percentage = 50,
  textTop = "",
  textTopUppercase = false,
  descriptionTitle = "",
  description = "",
  titleColor = "",
  descriptionColor = "",
  progressColor = "",
  progressBackground = "",
  minHeight = "10px",
  borderColor = "",
  hasShadown = true,
  round = false
}) => (
  <Styled.ContainerProgress>
    {textTop && (
      <Styled.TitleProgressStep
        titleColor={titleColor}
        textTopUppercase={textTopUppercase}
      >
        {textTop}
      </Styled.TitleProgressStep>
    )}

    <Styled.ContainerProgressStep
      progressBackground={progressBackground}
      minHeight={minHeight}
      borderColor={borderColor}
      hasShadown={hasShadown}
    >
      <Styled.CurrentProgressStep
        minHeight={minHeight}
        percentage={percentage}
        progressColor={progressColor}
        progressBackground={progressBackground}
        hasShadown={hasShadown}
        round={round}
      />
      <Styled.ContainerRetina />
    </Styled.ContainerProgressStep>

    <Styled.ContainerDescription>
      {descriptionTitle && (
        <Styled.Description descriptionColor={descriptionColor} bold>
          {descriptionTitle}
        </Styled.Description>
      )}
      {description && (
        <Styled.Description descriptionColor={descriptionColor}>
          {description}
        </Styled.Description>
      )}
    </Styled.ContainerDescription>
  </Styled.ContainerProgress>
);

export default ProgressBar;
