import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "@components/form";

import useCurriculum from "@hooks/useCurriculum";
import { useCurriculumModal } from "@hooks/useCurriculumModal";
import { useCurriculumSections } from "@hooks/useCurriculumSections";

import { Container } from "./styles";

const FooterControl = ({ nextPath, lastSection }) => {
  const { handleOpenModal } = useCurriculumModal();
  const { formMethods } = useCurriculum();
  const { sectionOptions, changeSection, selectedSection } =
    useCurriculumSections();
  const {
    formState: { isDirty }
  } = useFormContext();

  const isValid = sectionOptions.find(
    (section) => section.key === selectedSection?.key
  ).isValid;

  const lastButton = useMemo(() => {
    const isSkip = !isDirty && isValid && formMethods?.formState?.isValid;

    if (isSkip) return { className: "skipBtn", label: "Pular" };
    return { className: "", label: "Salvar e enviar" };
  }, [isDirty, isValid, formMethods?.formState?.isValid]);

  return (
    <>
      {!isDirty && selectedSection && isValid && !lastSection ? (
        <Container position="fixed" bottom="0" left="0">
          <Button onClick={() => handleOpenModal("cancel")} theme="link">
            Cancelar
          </Button>
          <Button
            theme="dark"
            type="button"
            onClick={() => changeSection(nextPath)}
            className="skipBtn"
          >
            Pular
          </Button>
        </Container>
      ) : (
        <Container position="static">
          <Button onClick={() => handleOpenModal("cancel")} theme="link">
            Cancelar
          </Button>
          {lastSection ? (
            <Button theme="dark" type="submit" className={lastButton.className}>
              {lastButton.label}
            </Button>
          ) : (
            <Button theme="dark" type="submit">
              Salvar e continuar
            </Button>
          )}
        </Container>
      )}
    </>
  );
};

export default FooterControl;
