import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from "react";
import PropTypes from "prop-types";

import {
  Container,
  Close,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  ArrowDown
} from "./styles";

const Popover = ({
  parentEl,
  active,
  position,
  arrow,
  align,
  close,
  onMouseEnter,
  onMouseLeave,
  onClose,
  children
}) => {
  const rootRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(position);
  const [stylesheets, setStylesheets] = useState({});

  useEffect(() => {
    setCurrentPosition(position);
  }, [position]);

  useEffect(() => {
    if (active && parentEl.current && rootRef.current) loadStyles();
  }, [rootRef.current, active, parentEl.current, arrow]);

  const getArrowStyles = useCallback(
    (containerStyle = {}) => {
      const newPosition = containerStyle?.position || position;
      const style = {};

      if (["top", "bottom"].includes(newPosition)) {
        if (newPosition === "top") {
          style.top = "100%";
        } else {
          style.top = "-8px";
        }

        const directions = {
          center: "calc(50% - 9px)",
          left: "15px",
          right: "calc(100% - 35px)"
        };

        style.left = directions[arrow] || directions.center;
      }

      return style;
    },
    [parentEl.current, arrow, position, rootRef.current]
  );

  const getStyles = useCallback(() => {
    const parent = parentEl.current;
    const root = rootRef.current;
    const style = {};

    if (root && parent) {
      const rectRoot = root.getBoundingClientRect();
      const rectParent = parent.getBoundingClientRect();
      let newPosition = position;

      const getTop = (direction) => {
        const directions = {
          top: rectParent.top - rectRoot.height - 10 + window.scrollY,
          bottom: rectParent.top + rectParent.height + 10 + window.scrollY
        };

        style.top = directions[direction];
      };

      if (position === "top") getTop("top");

      if (style.top < 1) {
        getTop("bottom");
        newPosition = "bottom";
      }

      if (position === "bottom") getTop("bottom");

      if (
        style.top + rectRoot.height + rectParent.height >
        window.innerHeight
      ) {
        getTop("top");
        newPosition = "top";
      }

      if (["top", "bottom"].includes(newPosition)) {
        const leftParent = rectParent.x + rectParent.width / 2;
        const directions = {
          center: leftParent - root.offsetWidth / 2,
          left: rectParent.x,
          right: rectParent.x - root.offsetWidth + 50
        };

        style.left = directions[align] || directions.center;
      }

      return { style, position: newPosition };
    }

    return { style, position: 0 };
  }, [parentEl.current, position, align, rootRef.current]);

  const getArrow = (direction) => {
    const arrows = {
      up: <ArrowUp style={stylesheets.arrowStyle} />,
      down: <ArrowDown style={stylesheets.arrowStyle} />,
      left: <ArrowLeft style={stylesheets.arrowStyle} />,
      right: <ArrowRight style={stylesheets.arrowStyle} />
    };

    return arrows[direction] || null;
  };

  const renderArrow = () => {
    const positions = {
      top: getArrow("down"),
      bottom: getArrow("up"),
      left: getArrow("right"),
      right: getArrow("left")
    };

    return positions[currentPosition] || null;
  };

  const loadStyles = useCallback(() => {
    const container = getStyles();
    const arrowStyle = getArrowStyles(container);

    setCurrentPosition(container.position);
    setStylesheets({
      style: container.style,
      arrowStyle
    });
  }, [parentEl.current, position, arrow, align, rootRef.current]);

  useEffect(() => {
    if (stylesheets?.style?.left < 0) {
      setStylesheets({
        style: { ...stylesheets.style, left: 0 },
        arrowStyle: {
          ...stylesheets.arrowStyle,
          left: stylesheets.style.left * -1
        }
      });
    }
  }, [stylesheets?.style]);

  const renderView = useMemo(
    () =>
      active ? (
        <Container
          ref={rootRef}
          style={stylesheets.style}
          close={close}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {arrow ? renderArrow() : null}
          {close && (
            <Close onClick={onClose}>
              <i className="fas fa-times" />
            </Close>
          )}
          {children}
        </Container>
      ) : null,
    [active, onMouseEnter, onMouseLeave, rootRef, stylesheets.style]
  );

  return renderView;
};

Popover.defaultProps = {
  active: false,
  position: "top",
  arrow: null,
  align: null,
  close: false,
  onMouseEnter: () => false,
  onMouseLeave: () => false,
  onClose: () => false
};

Popover.propTypes = {
  active: PropTypes.bool,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]), // position fix of content
  arrow: PropTypes.oneOf([null, "center", "top", "right", "bottom", "left"]), // position arrows in content
  align: PropTypes.oneOf([null, "center", "right", "left"]) // position content
};

export default Popover;
