/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Loading from "../../assets/loading/load.gif";
import { ReactComponent as Close } from "../../assets/svg/close_select.svg";
import { ReactComponent as Add } from "../../assets/svg/add_select.svg";

import * as Styled from "./styles";

const Select2 = ({
  placeholder = "",
  data = [
    {
      id: 1,
      name: "Titulo 1",
      selected: false
    },
    {
      id: 2,
      name: "Titulo 2",
      selected: false
    },
    {
      id: 3,
      name: "Titulo 3",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcBmMxDu-Eo9TzLZxs1RTrPX4S6aLsxvIijXfAFT931Qz3Pw7B&s",
      selected: false
    }
  ],
  canCreate = true,
  showList = true,
  typeList = "row",
  onCreate = () => {},
  onChange = () => {},
  loading = false,
  ...rest
}) => {
  const [_hasFocus, _setHasFocus] = useState(false);
  const [_data, _setData] = useState(data);
  const [_show, _setShow] = useState(false);
  const [_valueInput, _setValueInput] = useState("");
  const [_selected, _setSelected] = useState([]);

  useEffect(() => {
    const newData = data.map((e) => {
      if (_selected.length !== 0) {
        const aux = _selected.filter(
          (ee) => e.id === ee.id && ee.selected === true
        );
        if (aux.length > 0) {
          e.selected = true;
        } else {
          e.selected = false;
        }
      }

      return e;
    });

    const aux = newData.filter((e) => !!e.selected === true);

    if (_selected.length === 0) {
      _setSelected(aux);
    }

    onChange(_valueInput, aux);
    _setData([...newData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (_hasFocus === false) {
      _setValueInput("");
    }
  }, [_hasFocus]);

  const _handleRemove = (item) => {
    const auxData = _data.map((e) => {
      if (item.id === e.id) {
        e.selected = false;
      }

      return e;
    });

    const auxSelected = _selected.map((e) => {
      if (item.id === e.id) {
        e.selected = false;
      }

      return e;
    });

    _setData(auxData);
    _setSelected(auxSelected);
    onChange(
      _valueInput,
      auxSelected.filter((e) => e.selected === true)
    );
  };

  const _handleChangeSelect = (item) => {
    const auxData = _data.map((e) => {
      if (item.id === e.id) {
        e.selected = !e.selected;
      }

      return e;
    });

    const newSelected = auxData.filter((e) => e.selected);

    if (_selected.length > 0) {
      const newSelect = _selected.filter((e) => e.id !== item.id);
      newSelect.push(item);
      _setSelected(newSelect);
    } else {
      _setSelected(newSelected);
    }

    _setData(auxData);
    onChange(_valueInput, newSelected);
  };

  const _handleChangeValue = (e) => {
    onChange(
      e.target.value,
      _data.filter((e) => e.selected === true)
    );
    _setValueInput(e.target.value);
  };

  return (
    <Styled.SelectContent {...rest}>
      <Styled.ContainerInput
        onBlur={() => {
          _setShow(!_hasFocus);
        }}
        tabIndex={0}
        onFocus={() => {
          _setShow(true);
          _setHasFocus(true);
        }}
      >
        <Styled.Autocomplete
          onChange={_handleChangeValue}
          value={_valueInput}
          onFocus={() => {
            _setShow(true);
          }}
          onBlur={() => {
            _setShow(false);
          }}
          show={_show}
          placeholder="Selecione"
          {...rest}
        />

        {loading ? (
          <Styled.ListLoading show={_show} {...rest}>
            <img src={Loading} alt="loading" />
          </Styled.ListLoading>
        ) : (
          <Styled.ListContainer {...rest}>
            <Styled.List show={_show} hasImage {...rest}>
              {_data.map((e, i) => (
                <Styled.ListItem
                  key={i}
                  onClick={() => _handleChangeSelect(e)}
                  hasImage={e.img !== undefined && e.img !== ""}
                  selected={e.selected}
                >
                  {e.img !== undefined && e.img !== "" && (
                    <Styled.PhotoContainer>
                      <Styled.Photo data={e.img} />
                    </Styled.PhotoContainer>
                  )}

                  <Styled.TextContainer>
                    <Styled.Text>{e.name}</Styled.Text>
                  </Styled.TextContainer>

                  {e.selected && (
                    <Styled.IconClose>
                      <Close />
                    </Styled.IconClose>
                  )}
                </Styled.ListItem>
              ))}

              {canCreate && _valueInput !== "" && (
                <Styled.Create
                  onClick={() => {
                    onCreate(_valueInput);
                  }}
                >
                  <Styled.CreateIconContainer>
                    <Add />
                  </Styled.CreateIconContainer>
                  <Styled.CreateTextCotainer>
                    <Styled.CreateText>{`Adicionar “${_valueInput}”`}</Styled.CreateText>
                  </Styled.CreateTextCotainer>
                </Styled.Create>
              )}
            </Styled.List>
          </Styled.ListContainer>
        )}
      </Styled.ContainerInput>

      {showList && (
        <Styled.ContainerList typeList={typeList}>
          {_selected
            .filter((e) => !!e.selected)
            .map((e, i) => (
              <Styled.ListItemOut
                key={i}
                onClick={() => {
                  _handleRemove(e);
                }}
              >
                {e.img !== "" && e.img !== undefined && (
                  <Styled.PhotoOutContainer>
                    <Styled.PhotoOut src={e.img} />
                  </Styled.PhotoOutContainer>
                )}

                <Styled.TextContainerOut>
                  <Styled.TextOut>{e.name}</Styled.TextOut>
                </Styled.TextContainerOut>
                <Styled.IconCloseOut>
                  <Close />
                </Styled.IconCloseOut>
              </Styled.ListItemOut>
            ))}
        </Styled.ContainerList>
      )}
    </Styled.SelectContent>
  );
};

export default Select2;
