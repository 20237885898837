import styled from "styled-components";

export const PaginateContainer = styled.div`
  margin-top: 30px;

  ${({ hide }) => hide && "display: none;"}
`;

export const FlexDiv = styled.div`
  display: flex;
  flex: 1 1 130%;
`;

export const Search = styled.div`
  width: 100%;

  div {
    padding-top: 0px;
    margin-top: 0px;
  }

  div div {
    top: 11px;
  }
`;

export const EmptyVacancies = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 35px;
`;

export const Button = styled.div`
  max-width: 230px;
  width: 100%;
`;
