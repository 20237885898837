import styled from "styled-components";
import * as breakpoints from "@styles/breakpoints";

export const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.background || "rgb(244, 240, 235)"};
`;

export const HeaderBox = styled.header`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 20px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100px;
  position: relative;
  background-color: ${(props) => props.background || "rgb(244, 240, 235)"};

  @media (max-width: ${breakpoints.laptop}) {
    padding: 0 30px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 150px 1fr;
  }
  @media screen and (max-width: 1023px) and (min-width: 641px) {
    height: 70px;
  }
  @media screen and (max-width: 1220px) and (min-width: 1025px) {
    max-width: 982px;
  }
  @media (min-width: 1320px) {
    max-width: 1300px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 70px 1fr;
    grid-gap: 10px;
    height: 68px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;
  }

  @media screen and (max-width: 1023px) and (min-width: 709px) {
    grid-template-columns: 70px 1fr;
    grid-gap: 10px;
    height: 68px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;
  }
`;

export const LogoJobs = styled.article``;

export const Logo = styled.img`
  cursor: pointer;
  max-width: 100%;
  max-height: 80px;

  @media (max-width: ${breakpoints.mobileLarge}) {
    max-width: 56px;
  }

  @media screen and (max-width: 1023px) and (min-width: 641px) {
    max-height: 50px;
  }
`;

export const Authentication = styled.article`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 1023px) and (min-width: 200px) {
    display: none;
  }
`;

export const UserAuthenticated = styled.div`
  width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px;`}

  @media screen and (max-width: 580px) {
    width: auto;
  }

  .dropdownUser {
    width: 100%;
  }
`;

export const TextButton = styled.span`
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "2px")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

export const Mobile = styled.article`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`;

export const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  margin-left: auto;
  cursor: pointer;
`;

export const MenuOptions = styled.div`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 195px;
  padding-top: 2.2rem;

  ${MenuIcon} {
    position: absolute;
    top: 0.875rem;
    right: 0.9375rem;
  }
`;

export const MenuItem = styled.div`
  width: 100%;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 2.75rem;
  color: #333333;
  cursor: pointer;
  padding-left: 2.25rem;

  &:hover {
    background: #dedede;
  }
`;

export const MenuItemNotLogged = styled.div`
  color: ${({ textColor }) => textColor} !important;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  color: #333333;
  padding-right: 15px;
  cursor: pointer;
`;

export const MenuBtnLogin = styled.button`
  color: ${({ textColor }) => textColor};
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  width: 77px;
  height: 40px;
  border-radius: 24px;
  border: 2px solid ${({ textColor }) => textColor};
  font-weight: 700;
  cursor: pointer;

  @media screen and (max-width: 640px) {
    width: 71px;
    height: 38px;
    border-radius: 23px;
  }
`;
