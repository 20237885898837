import React from "react";
import { Formik, Form, Field } from "formik";

import { Tooltip } from "antd";

import InputField from "../../../../components/inputField";
import Button from "../../../../components/button";

import { ReactComponent as QuestionIcon } from "../../../../assets/svg/question-circle.svg";

import {
  changeEmailSchema,
  changePasswordSchema
} from "../../../../utils/formValidations/settings/schema";

import * as Styled from "./styles";

export const Body = ({
  data = {},
  fallbackDelete,
  fallbackChangeEmail,
  fallbackChangePassword
}) => (
  <Styled.Container>
    <Styled.Title>Preferências</Styled.Title>

    <Styled.ContainerBody>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          newEmail: "",
          confirmNewEmail: "",
          password: ""
        }}
        validationSchema={changeEmailSchema}
        onSubmit={(values) => {
          fallbackChangeEmail(values);
          return values;
        }}
      >
        {({ errors, values, isValidating, setFieldValue }) => (
          <Form autocomplete="off">
            <Styled.Section>
              <Styled.SectionTitle>
                MUDANÇA DE E-MAIL DE ACESSO
              </Styled.SectionTitle>
              <Styled.SectionBody>
                <Styled.Description>
                  Altere as configurações gerais da sua conta
                </Styled.Description>
                <Styled.FormRow>
                  <Styled.FormGroup flex="0 0 50%">
                    <Styled.FormControl>
                      <Field
                        name="newEmail"
                        type="email"
                        label="Novo E-mail"
                        autocomplete="new-email"
                        component={InputField}
                      />
                    </Styled.FormControl>
                  </Styled.FormGroup>
                </Styled.FormRow>
                <Styled.FormRow>
                  <Styled.FormGroup flex="0 0 50%">
                    <Styled.FormControl>
                      <Field
                        name="confirmNewEmail"
                        type="email"
                        label="Confirme o novo E-mail"
                        component={InputField}
                      />
                    </Styled.FormControl>
                  </Styled.FormGroup>
                </Styled.FormRow>
                <Styled.FormRow>
                  <Styled.FormGroup flex="0 0 50%">
                    <Styled.FormControl>
                      <Field
                        name="password"
                        type="password"
                        label="Senha atual"
                        autocomplete="old-password"
                        onChangeField={(e) =>
                          setFieldValue("password", e.target.value)
                        }
                        component={InputField}
                      />
                    </Styled.FormControl>
                    <Styled.FormControlInfo>
                      <Tooltip
                        placement="top"
                        title="Por motivo de segurança, para modificar o seu e-mail é necessário inserir a senha."
                      >
                        <QuestionIcon />
                      </Tooltip>
                    </Styled.FormControlInfo>
                  </Styled.FormGroup>
                </Styled.FormRow>

                <Styled.ContainerButton>
                  <Styled.Button>
                    <Button
                      medium
                      isUppercase
                      boxShadow
                      fontWeight="bold"
                      textColor="#FFFFFF"
                      backgroundColor="#333333"
                      text="SALVAR E-MAIL"
                      buttonType="submit"
                    />
                  </Styled.Button>
                </Styled.ContainerButton>
              </Styled.SectionBody>
            </Styled.Section>
          </Form>
        )}
      </Formik>

      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          actualPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        }}
        validationSchema={changePasswordSchema}
        onSubmit={(values) => {
          fallbackChangePassword(values);
          return values;
        }}
      >
        {({ errors, values, isValidating, setFieldValue }) => (
          <Form autocomplete="off">
            <Styled.Section>
              <Styled.SectionTitle>SENHA</Styled.SectionTitle>
              <Styled.SectionBody>
                <Styled.Description>Altere a sua senha</Styled.Description>
                <Styled.FormRow>
                  <Styled.FormGroup flex="0 0 50%">
                    <Styled.FormControl>
                      <Field
                        name="actualPassword"
                        type="password"
                        label="Senha atual"
                        autocomplete="actual-password"
                        onChangeField={(e) =>
                          setFieldValue("actualPassword", e.target.value)
                        }
                        component={InputField}
                      />
                    </Styled.FormControl>
                  </Styled.FormGroup>
                </Styled.FormRow>
                <Styled.FormRow>
                  <Styled.FormGroup flex="0 0 50%">
                    <Styled.FormControl>
                      <Field
                        name="newPassword"
                        type="password"
                        label="Nova senha"
                        autocomplete="new-password"
                        onChangeField={(e) =>
                          setFieldValue("newPassword", e.target.value)
                        }
                        component={InputField}
                      />
                    </Styled.FormControl>
                  </Styled.FormGroup>
                </Styled.FormRow>
                <Styled.FormRow>
                  <Styled.FormGroup flex="0 0 50%">
                    <Styled.FormControl>
                      <Field
                        name="confirmNewPassword"
                        type="password"
                        label="Confirmação de senha"
                        onChangeField={(e) =>
                          setFieldValue("confirmNewPassword", e.target.value)
                        }
                        component={InputField}
                      />
                    </Styled.FormControl>
                  </Styled.FormGroup>
                </Styled.FormRow>

                <Styled.ContainerButton>
                  <Styled.Button>
                    <Button
                      medium
                      isUppercase
                      boxShadow
                      fontWeight="bold"
                      textColor="#FFFFFF"
                      backgroundColor="#333333"
                      text="SALVAR SENHA"
                      buttonType="submit"
                    />
                  </Styled.Button>
                </Styled.ContainerButton>
              </Styled.SectionBody>
            </Styled.Section>
          </Form>
        )}
      </Formik>

      <Styled.Section>
        <Styled.SectionTitle>EXCLUIR MINHA CONTA</Styled.SectionTitle>
        <Styled.SectionBody>
          <Styled.Description>
            Você tem certeza que deseja excluir sua conta?
          </Styled.Description>
          <Styled.Description>
            Uma vez excluída, seus dados serão apagados, você deixará de fazer
            parte do banco de talentos e não receberá mais e-mails.
          </Styled.Description>

          <Styled.ContainerButton>
            <Styled.Button>
              <Button
                medium
                isUppercase
                boxShadow
                fontWeight="bold"
                textColor="#FFFFFF"
                backgroundColor="#D74149"
                text="EXCLUIR CONTA"
                onClick={fallbackDelete}
              />
            </Styled.Button>
          </Styled.ContainerButton>
        </Styled.SectionBody>
      </Styled.Section>
    </Styled.ContainerBody>
  </Styled.Container>
);
