import React from "react";
import { useDispatch } from "react-redux";

import Button from "@components/button";

import * as utilitiesActions from "@store/modules/utilities/actions";

import * as Styled from "./styles";

export const Body = ({ callbackWarningCookies }) => {
  const dispatch = useDispatch();

  const showCookiesWarning = () => {
    dispatch(
      utilitiesActions.showModal({
        show: true,
        type: "cookiesWarning",
        options: {
          position: "middle",
          size: "medium",
          hasBgShadow: true,
          bgShadow: "",
          showClose: false,
          closeOnOverlay: false
        },
        dataParams: {
          callbackWarningCookies
        }
      })
    );
  };

  const showTermsOfUseHandle = () =>
    dispatch(
      utilitiesActions.showModal({
        show: true,
        type: "termsOfUse",
        options: {
          position: "topCenter",
          size: "large",
          hasBgShadow: true,
          bgShadow: "",
          showClose: true,
          closeOnOverlay: false
        },
        dataParams: {
          openCookiesWarning: showCookiesWarning
        }
      })
    );

  return (
    <Styled.ContainerBody>
      <Styled.Title>Este site usa cookies</Styled.Title>
      <Styled.Description>
        Utilizamos cookies para melhorar a sua experiência no site. Ao continuar
        navegando, você informa estar de acordo com essa utilização. Para
        visualizar os termos de uso e política de privacidade{" "}
        <Styled.Link onClick={() => showTermsOfUseHandle()}>
          clique aqui
        </Styled.Link>
        .
      </Styled.Description>
    </Styled.ContainerBody>
  );
};
export const Footer = ({ onClose }) => (
  <Styled.ContainerFooter>
    <Button
      type="custom"
      backgroundColor="#583F99"
      textColor="#FFFFFF"
      borderColor="#583F99"
      borderWidth="0px"
      width="45%"
      boxShadow="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
      medium
      isUppercase={false}
      onClick={onClose}
    >
      <Styled.Text>Entendi e aceito</Styled.Text>
    </Button>
  </Styled.ContainerFooter>
);
