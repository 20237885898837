import React from "react";
import * as Styled from "./styles";

const Image = ({
  url,
  backgound,
  backgoundColor,
  type,
  imgSize = 200,
  imgBackgroundSize = "cover",
  border = false,
  ...rest
}) =>
  url ? (
    <Styled.Image
      src={url}
      imgType={type}
      imgSize={imgSize}
      imgBackgroundSize={imgBackgroundSize}
      backgoundColor={backgoundColor}
      border={border}
      {...rest}
    />
  ) : (
    <Styled.Image
      imgType={type}
      imgSize={imgSize}
      imgBackgroundSize={imgBackgroundSize}
      backgoundColor={backgoundColor}
      border={border}
      {...rest}
    >
      {backgound}
    </Styled.Image>
  );

export default Image;
