import React from "react";
import PropTypes from "prop-types";

import { Button } from "@components/form";
import * as cookie from "@utils/cookie";
import useApplyVacancy from "@utils/hooks/useApplyVacancy";

import { DEFAULT_HOST_COMPANY } from "@services/constants";

import { Title, Description, ModalFooter } from "../styles";

export const CancelRegistration = ({ onClose }) => {
  const { isApplyVacancy, isApplyTalentBase } = useApplyVacancy();
  const { [window?.name]: tab } = cookie.getJSON();
  const company = tab?.company;

  const isApplying = isApplyVacancy || isApplyTalentBase;

  return (
    <>
      <Title>Deseja desistir{isApplying ? " de se candidatar" : ""}?</Title>
      <Description>
        ATENÇÃO: Essa ação não poderá ser desfeita e você perderá os dados já
        preenchidos.
      </Description>

      <ModalFooter>
        <Button onClick={onClose} theme="link">
          Cancelar
        </Button>
        <Button
          onClick={() =>
            cookie.redirect(
              isApplying ? DEFAULT_HOST_COMPANY(company.slug) : "/"
            )
          }
          theme="dark"
        >
          Sim, desistir
        </Button>
      </ModalFooter>
    </>
  );
};

CancelRegistration.propTypes = {
  onClose: PropTypes.func.isRequired
};
