import { useSelector, useDispatch } from "react-redux";
import React, { createContext, useMemo, useEffect } from "react";

import {
  getListStates,
  getListCountries
} from "@store/modules/curriculum/actions";

const AddressContext = createContext({});

function AddressProvider({ children }) {
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.curriculum.listCountries);
  const states = useSelector((state) => state.curriculum.listStates);

  useEffect(() => {
    dispatch(getListCountries());
    dispatch(getListStates());
  }, []);

  const countriesOptions = useMemo(() => {
    if (countries?.length) {
      const brazil = countries.find((country) => country.name === "Brasil");
      const sortedCountries = [
        brazil,
        ...countries.filter((item) => item.name !== "Brasil")
      ].map((country) => ({
        value: country.text,
        label: country.name
      }));

      return sortedCountries;
    }
    return [];
  }, [countries]);

  const value = useMemo(
    () => ({ countries: countriesOptions, states }),
    [countriesOptions, states]
  );

  return (
    <AddressContext.Provider value={value}>{children}</AddressContext.Provider>
  );
}

export { AddressContext, AddressProvider };
