import styled from "styled-components";

export const SelectContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 3px;
  cursor: pointer;
  outline: none;
`;

export const Placeholder = styled.div`
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 992px) {
    z-index: 1;
  }
  border: 2px solid rgba(0, 0, 0, 0.15);

  ${({ error }) => error && "border-color: #dc3545;"}
  background: #fff;
  font-family: "Roboto Condensed";

  ${(props) =>
    props.small
      ? `height: 35px;`
      : props.medium
      ? `height: 40px;`
      : props.large
      ? `height: 50px;`
      : `height: 40px;`}

  ${({ disabled }) =>
    disabled
      ? `background: #F1F1F1; color: #ccc; cursor: not-allowed;`
      : `background: #fff;color: #333333; cursor: default;`}

    ${({ hasPhoto }) =>
    hasPhoto ? `padding-left: 4px;` : `padding-left: 20px;`}

    ${(props) =>
    props.show === true
      ? "border-bottom-left-radius: 0; border-bottom-right-radius: 0; "
      : "border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;"}
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 80%;
  color: #dc3545;

  position: absolute;
  top: 39px;
`;

export const Icon = styled.div`
  padding-right: 10px;
  svg path {
    fill: #333333;
  }
`;

export const ContainerList = styled.div`
  width: 100%;
  position: absolute;
  z-index: 999;

  ${(props) =>
    props.small
      ? `top: 26px;`
      : props.medium
      ? `top: 39px;`
      : props.large
      ? `top: 41px;`
      : `top: 41px;`}
`;
export const List = styled.div`
  width: auto;
  height: auto;
  max-height: 200px;
  background: #fff;
  padding-top: 5px;
  padding-bottom: 20px;
  padding-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  overflow-y: auto;

  ${(props) => (props.show === true ? "display: block;" : "display: none;")}

  ${({ hasPhoto }) =>
    hasPhoto ? `padding-left: 10px;` : `padding-left: 10px;`}

    :hover::-webkit-scrollbar {
    display: flex;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333333;
  }
`;

export const ListLoading = styled.div`
  width: auto;
  height: auto;
  background: #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }

  ${(props) => (props.show === true ? "display: flex;" : "display: none;")}

  ${(props) =>
    props.small
      ? `top: 20px;`
      : props.medium
      ? `top: 30px;`
      : props.large
      ? `top: 35px;`
      : `top: 30px;`}
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #333333;
  display: flex;
  align-items: center;
  font-family: "Roboto Condensed";
  border-radius: 50px;
  margin-top: 3px;

  &:hover {
    color: #fff;
    background: #33333388;
  }

  ${({ selected }) => selected && `color: #fff; background: #333333;`}
  ${({ hasImage }) => (hasImage ? `padding-left: 1px;` : `padding-left: 10px;`)}
`;

export const ListItemTitle = styled.div`
  font-family: "Roboto Condensed";
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 50px;
  margin-top: 3px;
  font-weight: bold;
  cursor: default;
`;

export const PlaceholderText = styled.span`
  color: #33333399;
  font-size: 16px;

  ${({ hasPhoto }) => hasPhoto && "padding-left: 11px;"}
`;

export const ValueText = styled.div`
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;

  ${({ data }) => data && `background-image: url("${data}");`}
`;

export const InitialPhoto = styled.div`
  width: 32px;
  height: 32px;
  background: #462881;
  border-radius: 200px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #99999988;
`;

export const InitialPhotoText = styled.span`
  font-size: 13px;
  color: #f1f1f1;
  text-transform: uppercase;
`;

export const Autocomplete = styled.input`
  max-width: calc(100% - 10px);
  width: 100%;
  height: 38px;
  margin: 0px 5px 0px 5px;
  padding: 10px 16px;
  position: absolute;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #a79cbd;
  font-size: 16px;
  outline: none;

  &::placeholder {
    color: #a79cbd;
  }

  ${(props) => (props.show === true ? "display: flex;" : "display: none;")}
`;
