import styled from "styled-components";

import bastaoAzul from "../../assets/svg/authBastaoAzul.svg";
import bastaoAzulMobile from "../../assets/svg/authBastaoAzulMobile.svg";
import bastaoVerde from "../../assets/svg/authBastaoVerde.svg";
import bastaoVermelho from "../../assets/svg/authBastaoVermelho.svg";
import bastaoAmarelo from "../../assets/svg/authBastaoAmarelo.svg";

export const Container = styled.main`
  background: #f4f0eb;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 30px;
`;

export const Header = styled.header`
  width: 100%;
  height: 243px;
  background: #583f99;
  background-image: url(${bastaoVerde}), url(${bastaoAzul}),
    url(${bastaoVermelho}), url(${bastaoAmarelo});
  background-position: left 23px, left 163px, left bottom, right top;
  background-repeat: no-repeat;
  background-size: inherit;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 800px) {
    & {
      background-image: url(${bastaoVerde}), url(${bastaoAzulMobile}),
        url(${bastaoVermelho}), url(${bastaoAmarelo});
      background-size: 200px 108px, 650px 38px, 300px 182px, 80px 100px;
      background-position: left 143px, left calc(100% - 12px), left 142px,
        right top;
      height: 297px;
    }
  }

  @media screen and (max-width: 660px) {
    & {
      background-size: 200px 108px, 550px 38px, 300px 182px, 80px 100px;
      background-position: left 143px, left calc(100% - 10px), left 142px,
        right top;
    }
  }

  @media screen and (max-width: 560px) {
    & {
      background-image: url(${bastaoVerde}), url(${bastaoAzul}),
        url(${bastaoVermelho}), url(${bastaoAmarelo});
      background-size: 200px 108px, 410px 38px, 300px 182px, 80px 100px;
    }
  }

  @media screen and (max-width: 450px) {
    & {
      background-size: 200px 108px, 410px 38px, 300px 182px, 80px 100px;
    }
  }

  @media screen and (max-width: 400px) {
    & {
      background-size: 200px 108px, 350px 38px, 300px 182px, 80px 100px;
    }
  }

  @media screen and (max-width: 330px) {
    & {
      background-size: 200px 108px, 310px 38px, 270px 182px, 80px 100px;
      background-position: left 143px, left calc(100% - 10px), left 148px,
        right top;
    }
  }
`;

export const GridJobs = styled.article`
  padding: 12px 0px 0px 20px;
`;

export const BackLink = styled.a`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  text-decoration-line: none;
  color: #333333;
  visibility: var(--visible, "visible");

  :hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const ContainerJobs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerLogoJobs = styled.div`
  padding-top: 47px;
  margin-bottom: 140px;
`;

export const SocialLinks = styled.div`
  max-width: 230px;
`;

export const GridAuth = styled.article`
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 10;
  padding-right: 20px;
  margin-top: -90px;
`;

export const ContainerAuth = styled.div`
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 45px 30px 30px 30px;
  max-width: 400px;
  width: 100%;
`;

export const Desktop = styled.section``;
export const Mobile = styled.section`
  display: none;
`;

export const SectionGrid = styled.section`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 15px;

  @media print {
    display: none;
  }

  @media screen and (max-width: 800px) {
    ${Desktop} {
      display: none;
    }

    ${Mobile} {
      display: block;
    }

    ${Mobile} ${BackLink} {
      padding: 18px 0px;
      margin-left: 13px;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }

    & {
      grid-template-columns: 1fr;
    }

    ${GridAuth} {
      padding-right: 0px;
      margin-top: -325px;
    }

    ${ContainerAuth} {
      margin: 0 auto;
    }

    ${ContainerLogoJobs} {
      padding-top: 53px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 465px) {
    ${Mobile} ${BackLink} {
      margin-left: -17px;
    }

    ${GridAuth} {
      padding: 0 30px;
    }
  }
`;
