import React from "react";

import Icon from "@components/icon";

import { Container, Image } from "./styles";

const Picture = ({ alt = "", src, isError = false }) =>
  src ? (
    <Image src={src} alt={alt} />
  ) : (
    <Container isError={isError}>
      <Icon className="fas fa-camera" size="30px" color="#fff" />
    </Container>
  );

export default Picture;
