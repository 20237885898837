import React from "react";

import * as Styled from "../styles";

import { ReactComponent as Facebook } from "../../../assets/svg/facebook.svg";
import { ReactComponent as Twitter } from "../../../assets/svg/twitter.svg";
import { ReactComponent as Youtube } from "../../../assets/svg/youtube.svg";
import { ReactComponent as Whatsapp } from "../../../assets/svg/whatsapp.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/instagram.svg";
import { ReactComponent as Linkedin } from "../../../assets/svg/linkedin.svg";
import { ReactComponent as Skype } from "../../../assets/svg/skype.svg";

const Social = ({ onClick, options, ...rest }) => {
  const renderType = (option) => {
    switch (option.type) {
      case "facebook":
        return <Facebook />;
      case "twitter":
        return <Twitter />;
      case "youtube":
        return <Youtube />;
      case "whatsapp":
        return <Whatsapp />;
      case "instagram":
        return <Instagram />;
      case "linkedin":
        return <Linkedin />;
      case "skype":
        return <Skype />;
      default:
        return option.icon;
    }
  };

  const renderButton = options.map((option, index) => (
    <Styled.ButtonSocial
      key={`button_${index}`}
      borderRadius={option.borderRadius}
      borderColor={option.borderColor}
      borderWidth={option.borderWidth}
      color={option.color}
      textHover={option.textHover}
    >
      <Styled.Link
        href={option.link}
        target={option.target ? option.target : "_blank"}
      >
        {renderType(option)}
      </Styled.Link>
    </Styled.ButtonSocial>
  ));

  return <Styled.ContainerSocial>{renderButton}</Styled.ContainerSocial>;
};

export default Social;
