import styled from "styled-components";

export const SelectContent = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 3px;
  padding-bottom: 20px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CreateIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  svg {
    path {
      fill: #333333;
    }
  }
`;

export const CreateText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
`;

export const Create = styled.div`
  margin-top: 10px;
  margin-bottom: -20px;
  margin-left: -10px;
  margin-right: -10px;
  border-top: 1px solid #c5b8de;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.05);

    ${CreateIconContainer} svg path {
      fill: #000;
    }

    ${CreateText} {
      color: #000;
    }
  }
`;

export const CreateTextCotainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const List = styled.div`
  ${(props) => (props.show === true ? "display: block" : "display: none")}
  width: 100%;
  position: absolute;
  height: auto;
  ${(props) =>
    props.small
      ? `top: 35px`
      : props.medium
      ? `top: 40px`
      : props.large
      ? `top: 45px`
      : `top: 35px`};
  background: #fff;
  padding: 10px;
  border: 1px solid #c5b8de;
  box-shadow: 0px 1px 3px #dddddd;
  border-radius: 10px;
  z-index: 30;
  max-height: 180px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;

export const ListLoading = styled.div`
  ${(props) => (props.show === true ? "display: flex" : "display: none")}
  width: 100%;
  position: absolute;
  height: auto;
  ${(props) =>
    props.small
      ? `top: 22px`
      : props.medium
      ? `top: 29px`
      : props.large
      ? `top: 34px`
      : `top: 29px`};
  background: #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #efedf3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
  }
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin-top: 3px;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  &:hover {
    color: #000000;
    background: rgba(0, 0, 0, 0.05);
  }

  ${({ hasTitle }) =>
    hasTitle === true ? `padding-left: 25px;` : `padding-left: 10px`};

  ${({ hasImage }) => (hasImage ? `padding-left: 2px;` : `padding-left: 10px`)};

  ${({ selected }) =>
    selected === true && `color: #000000; background: rgba(0, 0, 0, 0.05);`};
`;

export const ListItemTitle = styled.div`
  font-family: "Roboto Condensed";
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #000000;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 50px;
  margin-top: 3px;
  font-weight: bold;
  align-items: center;
`;

export const ValueText = styled.div`
  color: #333333;
  display: flex;
  align-items: center;
`;

export const Autocomplete = styled.input`
  display: flex;
  /* max-width: calc(100% - 10px); */
  width: 100%;

  ${(props) =>
    props.small
      ? `height: 35px`
      : props.medium
      ? `height: 40px`
      : props.large
      ? `height: 50px`
      : `height: 40px`};

  padding: 10px 16px;
  position: absolute;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  outline: none;

  &::placeholder {
    color: #333333;
  }
`;

export const IconClose = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  flex: 20;
  display: flex;
  align-items: center;
`;

export const Text = styled.span``;

export const PhotoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  ${({ data }) => data && `background-image: url("${data}");`}
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;
`;

export const ContainerInput = styled.div``;

export const ContainerList = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  align-items: center;

  ${({ typeList }) =>
    typeList === "column" ? `flex-direction: column;` : `flex-direction: row;`}
`;

export const ListItemOut = styled.div`
  width: auto;
  height: 35px;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  min-width: 80px;
  border-radius: 50px;
  margin-right: 5px;
  cursor: pointer;
`;

export const IconCloseOut = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`;

export const TextContainerOut = styled.div`
  flex: 20;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const TextOut = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-size: 14px;
`;

export const PhotoOutContainer = styled.div``;

export const PhotoOut = styled.div``;
