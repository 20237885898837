import { call, all, put, takeLatest } from "redux-saga/effects";
import { notification } from "antd";

import * as api from "./repository";

function* getInitialVacanciesSaga({ payload }) {
  yield put({
    type: "@vacancies/SEARCH_VACANCIES_BY_COMPANY_SUCCESS",
    payload: {
      listVacancies: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
        totalAvailablePositions: 0,
        page: 1,
        pagination: 25
      },
      vacancy: {}
    }
  });

  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const params = {
      reference_id: payload?.referenceId,
      search: "",
      page: 1,
      pagination: 25,
      vacancyType: "jobs"
    };

    const { data: vacancies } = yield call(
      api.getVacanciesSearchByCompany,
      params
    );

    const listVacancies = vacancies.data.map((item) => ({
      ...item,
      title1: item.name,
      subtitle1: item.departament,
      title2: item.city ? item.city.name : "",
      subtitle2: item.state ? item.state.acronym : "",
      status: item.applied ? item.applied : false
    }));

    yield put({
      type: "@vacancies/SEARCH_VACANCIES_BY_COMPANY_SUCCESS",
      payload: {
        listVacancies: {
          list: listVacancies,
          totalPages: vacancies.totalPages,
          totalRecords: vacancies.totalRecords,
          totalVacancies: vacancies.totalVacancies,
          totalAvailablePositions: vacancies.totalAvailablePositions,
          page: vacancies.page,
          pagination: params.pagination
        },
        vacancy: {}
      }
    });

    yield put({
      type: "@vacancies/GET_CITIES",
      payload: { referenceId: payload?.referenceId }
    });
  } catch (err) {
    yield put({
      type: "@vacancies/SEARCH_VACANCIES_BY_COMPANY_SUCCESS",
      payload: {
        listVacancies: {
          list: [],
          totalPages: 0,
          totalRecords: 0,
          totalAvailablePositions: 0,
          page: 1,
          pagination: 25
        },
        vacancy: {}
      }
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* vacanciesSearchByCompanySaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const { company } = payload;

    const params = {
      reference_id: company.referenceId,
      search: payload.search,
      place: payload.place,
      page: payload.page,
      pagination: payload.pagination,
      vacancyType: "jobs"
    };

    const { data: vacancies } = yield call(
      api.getVacanciesSearchByCompany,
      params
    );

    const listVacancies = vacancies.data.map((item) => ({
      ...item,
      title1: item.name,
      subtitle1: item.departament,
      title2: item.city ? item.city.name : "",
      subtitle2: item.state ? item.state.acronym : "",
      status: item.applied ? item.applied : false
    }));

    yield put({
      type: "@vacancies/GET_CITIES",
      payload: { referenceId: company?.referenceId }
    });

    yield put({
      type: "@vacancies/SEARCH_VACANCIES_BY_COMPANY_SUCCESS",
      payload: {
        listVacancies: {
          list: listVacancies,
          totalPages: vacancies.totalPages,
          totalRecords: vacancies.totalRecords,
          totalVacancies: vacancies.totalVacancies,
          totalAvailablePositions: vacancies.totalAvailablePositions,
          page: vacancies.page,
          pagination: params.pagination
        }
      }
    });
  } catch (error) {
    notification.error({
      message: "Erro",
      description: error.response.data.message
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* getInitialVacancySaga() {
  yield put({
    type: "@vacancies/DESCRIPTION_VACANCY_SUCCESS",
    payload: { vacancy: {} }
  });
}

export default all([
  takeLatest("@vacancies/INITIAL_VACANCIES", getInitialVacanciesSaga),
  takeLatest("@vacancies/INITIAL_VACANCY", getInitialVacancySaga),
  takeLatest(
    "@vacancies/SEARCH_VACANCIES_BY_COMPANY",
    vacanciesSearchByCompanySaga
  )
]);
