import React, { useState, useEffect, useRef } from "react";
import { notification } from "antd";
import { useFormContext, Controller } from "react-hook-form";
import Resizer from "react-image-file-resizer";

import { Icon } from "@components";
import { Label, Button } from "@components/form";

import Description from "../description";

import Picture from "./components/picture";

import {
  Container,
  ContainerPicture,
  RequiredField,
  ContainerUpload,
  InputFile,
  ContainerButtons,
  DeleteText,
  ButtonsRow
} from "./styles";

const Avatar = ({
  link = "",
  onChange = () => {},
  onDelete = () => {},
  required = false
}) => {
  const fileRef = useRef(null);
  const [picture, setPicture] = useState("");
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setPicture(link);
    setValue("picture", link);
  }, [link]);

  const handlePictureChange = async (event, field) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedExtensions = ["jpg", "jpeg", "png"];

    if (
      allowedTypes.includes(file?.type) &&
      allowedExtensions.includes(
        file.name.substr(file.name.lastIndexOf(".") + 1)
      )
    ) {
      const tempFile = await resizeFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const output = fileRef.current;
        output.src = reader.result;
        setPicture(reader.result);
        onChange(tempFile, reader.result);
        field.onChange(reader.result);
      };

      reader.readAsDataURL(tempFile);
    } else {
      notification.warn({
        message: "Formato não suportado",
        description:
          "O formato da imagem inserida está incorreto. Tente novamente com uma imagem JPEG ou PNG."
      });
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "jpg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const renderButtons = (field) => {
    if (picture) {
      return (
        <ContainerButtons>
          <Button
            data-testid="updatePhotoCandidate"
            maxWidth="148px"
            onClick={() => fileRef.current.click()}
          >
            Editar foto
          </Button>

          <Button
            data-testid="deletePhotoCandidate"
            theme="link"
            maxWidth="94px"
            onClick={() => {
              setPicture("");
              field.onChange("");
              fileRef.current.value = "";
              onDelete();
            }}
          >
            <Icon className="fas fa-trash-alt" color="#EF3D4E" size="14px" />
            <DeleteText>Excluir</DeleteText>
          </Button>
        </ContainerButtons>
      );
    }

    return (
      <Button
        data-testid="uploadPhotoCandidate"
        theme="light"
        maxWidth="192px"
        onClick={() => fileRef.current.click()}
      >
        Fazer upload da foto
      </Button>
    );
  };

  return (
    <Container>
      <ContainerPicture>
        <Picture
          src={picture}
          alt="Avatar do Usuário"
          isError={errors?.picture}
        />
      </ContainerPicture>

      <Controller
        name="picture"
        control={control}
        render={({ field }) => (
          <>
            <ContainerUpload>
              <Label required={required} style={{ margin: 0 }}>
                Escolha uma foto
              </Label>
              {errors?.picture && !picture && (
                <RequiredField>{errors.picture?.message}</RequiredField>
              )}

              <Description mb="0px">
                Dê preferência por uma foto quadrada, do seu rosto.
              </Description>

              <ButtonsRow>{renderButtons(field)}</ButtonsRow>
            </ContainerUpload>

            <InputFile
              ref={fileRef}
              type="file"
              accept="image/png,image/jpeg,image/jpg"
              onChange={(event) => handlePictureChange(event, field)}
            />
          </>
        )}
      />
    </Container>
  );
};

export default Avatar;
