import styled from "styled-components";

export const Image = styled.div`
  width: ${({ imgType, imgSize }) =>
    imgSize && imgType === "roundedCircle" ? `${imgSize}px` : "100%"};
  max-width: ${({ maxWidth }) => maxWidth || "340px"};
  ${({ imgSize }) =>
    imgSize && `height: ${imgSize}px; line-height:${imgSize}px;`}
  background: ${({ backgoundColor }) => backgoundColor || "transparent"};
  color: #ffffff;
  cursor: ${({ cursor }) => cursor || "default"};

  ${({ src, imgBackgroundSize }) =>
    src &&
    `
    background-image: url('${src}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: ${imgBackgroundSize};
  `}

  ${({ imgType, imgSize, border }) =>
    imgType === "roundedCircle" &&
    `
    height: '${imgSize}px';
    border-radius: 50%;
    max-height: 128px;
    max-width: 128px;
  `};

  ${({ imgType, border }) =>
    imgType === "roundedCircle" &&
    border === true &&
    `border: 16px solid #EFEDF3;`};

  ${(props) =>
    props.imgType === "rounded" &&
    `
    border-radius: 1rem;
  `}
`;

export const ImageSvg = styled.div`
  cursor: pointer;
  svg {
    height: ${(props) => props.imgSize}px;
  }

  ${(props) =>
    props.imgType === "roundedCircle" &&
    `
    svg{
      max-height: 128px;
      max-width: 128px;
      border-radius: 50%;
      border: 16px solid #EFEDF3;
    }
  `}

  ${(props) =>
    props.imgType === "rounded" &&
    `
    svg{
      border-radius: 1rem;
    }
  `}
`;
