import React from "react";
import { components } from "react-select";
import Icon from "@components/icon";

const MultiValueRemove = ({ isFocused, ...props }) => (
  <components.MultiValueRemove {...props}>
    <Icon
      size="0.9rem"
      className="fa fa-times-circle "
      color={!isFocused ? "#333333" : "red"}
    />
  </components.MultiValueRemove>
);

export default MultiValueRemove;
