import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, image, url }) => {
  const getMeta = (type, content) => (
    <meta key={type} property={type} content={content} />
  );

  const getMetas = (types, content) =>
    types.map((type) => getMeta(type, content));

  const titles =
    title && getMetas(["title", "og:title", "twitter:title"], title);

  const descriptions =
    description &&
    getMetas(
      ["description", "og:description", "twitter:description"],
      description
    );

  const images =
    image && getMetas(["image", "og:image", "twitter:image"], image);

  const urls = url && getMetas(["og:url", "twitter:url"], url);

  return (
    <Helmet>
      <title>{title}</title>

      {titles}
      {descriptions}
      {images}
      {urls}

      {getMeta("og:site_name", title)}
      {getMeta("og:type", "website")}
      {getMeta("twitter:card", "summary_large_image")}
    </Helmet>
  );
};

export default SEO;
