import { DOMAIN } from "@services/constants";

const formatHost = (slug) => {
  let host = window.location.hostname;
  if (
    host.includes("localhost") ||
    host === "[::1]" ||
    host.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  ) {
    host = "http://localhost:4000";
  } else if (slug) {
    host = `https://${slug}.${DOMAIN}`;
  }

  return host;
};

export default formatHost;
