import React from "react";
import { message } from "antd";
import parse from "html-react-parser";

import * as Styled from "./styles";

message.config({
  duration: 15,
  maxCount: 1
});

const alert = (type, { title, content }) => {
  message[type](
    <Styled.ContainerMessage>
      <Styled.Close>
        <i className="fas fa-times" onClick={() => message.destroy()} />
      </Styled.Close>
      <Styled.Container>
        {title && <Styled.Title>{title}</Styled.Title>}
        <Styled.Content>
          {typeof content === "string" ? parse(content) : content}
        </Styled.Content>
      </Styled.Container>
    </Styled.ContainerMessage>
  );
};

const messages = {
  config: (props) =>
    message.config({
      duration: props.duration || 15,
      maxCount: props.maxCount || 1,
      ...props
    }),
  open: (props) => alert("open", props),
  success: (props) => alert("success", props),
  error: (props) => alert("error", props),
  info: (props) => alert("info", props),
  warning: (props) => alert("warning", props),
  warn: (props) => alert("warn", props),
  loading: (props) => alert("loading", props)
};

export default messages;
