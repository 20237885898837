import React from "react";

import * as Styled from "./styles";

const Tip = ({
  title,
  description,
  titleColor = "",
  descriptionColor = "",
  borderColor = ""
}) => (
  <Styled.Container borderColor={borderColor}>
    <Styled.Title titleColor={titleColor}>{title}</Styled.Title>
    <Styled.Description descriptionColor={descriptionColor}>
      {description}
    </Styled.Description>
  </Styled.Container>
);

export default Tip;
