import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import * as curriculumActions from "@store/modules/curriculum/actions";
import { AddressProvider } from "@contexts/index";

import useCurriculum from "@hooks/useCurriculum";
import { useCurriculumSections } from "@hooks/useCurriculumSections";
import { useCandidate } from "@hooks/useCandidate";

import {
  CurriculumSectionSwitcher as SectionSwitcher,
  Alert
} from "@components/index";
import FooterControl from "./components/footerControl";
import { MenuDesktop, MenuMobile, Breadcrumb } from "./components";
import {
  AboutYou,
  Experiences,
  Skills,
  PersonalData,
  Address,
  Documents
} from "./components/sections";
import Alerts from "./components/alerts";

import * as Styled from "./styles";

export default function CurriculumContent() {
  const dispatch = useDispatch();
  const { formMethods } = useCurriculum();
  const { sectionOptions } = useCurriculumSections();
  const { profiler } = useCandidate();

  const handleNextStep = useCallback(
    async (value) => {
      try {
        const { isDirty } = formMethods?.formState || {};
        if (isDirty) {
          return await dispatch(
            curriculumActions.saveCurriculum({
              curriculum: value,
              hasProfiler: !!profiler?.perfil
            })
          );
        }

        return true;
      } catch (ex) {
        return false;
      }
    },
    [dispatch, formMethods, sectionOptions, profiler]
  );

  return (
    <Styled.Container>
      <MenuMobile />
      <Styled.Content>
        <Styled.BoxContent>
          <Breadcrumb />
          <Alerts />
          <SectionSwitcher
            component={AboutYou}
            path="about-you"
            nextPath="experiences"
            onSave={handleNextStep}
          >
            <FooterControl nextPath="experiences" />
          </SectionSwitcher>
          <SectionSwitcher
            component={Experiences}
            path="experiences"
            nextPath="skills"
            onSave={handleNextStep}
          >
            <FooterControl nextPath="skills" />
          </SectionSwitcher>
          <SectionSwitcher
            component={Skills}
            path="skills"
            nextPath="personal-data"
            onSave={handleNextStep}
          >
            <FooterControl nextPath="personal-data" />
          </SectionSwitcher>
          <SectionSwitcher
            component={PersonalData}
            path="personal-data"
            nextPath="address"
            onSave={handleNextStep}
          >
            <FooterControl nextPath="address" />
          </SectionSwitcher>
          <AddressProvider>
            <SectionSwitcher
              component={Address}
              path="address"
              nextPath="documents"
              onSave={handleNextStep}
            >
              <FooterControl nextPath="documents" />
            </SectionSwitcher>
          </AddressProvider>
          <SectionSwitcher
            component={Documents}
            path="documents"
            onSave={handleNextStep}
            lastSection
          >
            <FooterControl lastSection />
          </SectionSwitcher>
        </Styled.BoxContent>
      </Styled.Content>
      <Alert />
      <MenuDesktop />
    </Styled.Container>
  );
}
