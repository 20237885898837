import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  padding: ${(props) => (props.close ? "25px 15px" : "15px 13px")};
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  z-index: 9999;
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 1;
  color: #a9a9a9cc;
  cursor: pointer;
`;

const Arrow = styled.div`
  position: absolute;
  z-index: 10000;
  width: 0;
  height: 0;
`;

export const ArrowUp = styled(Arrow)`
  top: -8px;
  left: calc(50% - 9px);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #ffffff;
`;

export const ArrowDown = styled(Arrow)`
  top: 100%;
  left: calc(50% - 9px);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ffffff;
`;

export const ArrowLeft = styled(Arrow)`
  left: -8px;
  top: calc(50% - 9px);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #ffffff;
`;

export const ArrowRight = styled(Arrow)`
  left: 100%;
  top: calc(50% - 9px);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid #ffffff;
`;
