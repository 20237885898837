import styled from "styled-components";

export const TableMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 10px;

  border-bottom: 1px solid #e4e4e4;
  min-height: 4rem;
  padding: 1rem 0.5rem;
  background-color: ${({ background }) => background || "transparent"};

  cursor: ${({ cursor }) => (cursor === true ? "pointer" : "default")};
  /* less than 576px */
  position: relative;

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 0 0.8rem 1px 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const VacancyContainer = styled.div`
  @media (min-width: 576px) {
    display: flex;
    flex: 4;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const TableTitle = styled.div`
  font-size: 14px;
  line-height: 135%;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
`;

export const TableSubTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: flex-start;
  justify-content: center;
  margin-left: 0.2rem;

  /* less than 576px */
  margin: 0;
  flex-direction: row;
  div:first-child {
    margin: 0 4px 0 0;
  }
  div:not(:first-child)::before {
    margin: 0 4px 0 0;
    content: "/";
  }

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    margin-left: 0.2rem;
    flex-direction: column;

    div:first-child {
      margin: 0;
    }
    div:not(:first-child)::before {
      margin: 0 4px 0 0;
      content: none;
    }
  }
`;

export const ButtonText = styled.span`
  display: none;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  padding-right: 0.5rem;
`;

export const ButtonIconContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
    path {
      fill: ${({ color }) => color || "#333333"};
    }
  }
`;

export const AnchorTitle = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  :hover {
    color: #333333;
  }
`;

export const Anchor = styled.a`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  font-size: 12px;
  line-height: 32px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  cursor: ${({ blocked }) => (blocked ? "not-allowed" : "pointer")};

  &:hover ${ButtonText} {
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
      props.status === "candidatar" &&
      `
      color: #FFFFFF;
      svg {
        path {
          fill: #FFFFFF;
        }
      }
    `}
  }

  &:hover ${ButtonIconContainer} {
    ${(props) =>
      props.status === "candidatar" &&
      `
      svg {
        path {
          fill: #ffffff;
        }
      }
    `}
  }

  :hover {
    ${({ status, color }) =>
      status === "candidatar" &&
      `
      background-color: ${color || "#333333"};
      color: #FFFFFF;
      border: 2px solid ${color || "#333333"};
    `}

    ${(props) =>
      props.status === "inscrito" &&
      `

      background-color: #FFFFFF;
      color: #333333;
      border: 2px solid rgba(0, 0, 0, 0.15);;
    `}

    width: 120px;
    border-radius: 24px;

    transition: 0.3s;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 575px) {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    display: flex;

    ${Anchor} {
      position: absolute;
    }
  }
`;

export const TalentBankRow = styled.a`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  font-size: 12px;
  line-height: 32px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  cursor: ${({ blocked }) => (blocked ? "not-allowed" : "pointer")};
  width: 120px;
  border-radius: 24px;

  ${({ status, color }) =>
    status === "candidatar" &&
    `
  background-color: ${color || "#333333"};
  color: #FFFFFF;
  border: 2px solid ${color || "#333333"};
`}

  ${ButtonIconContainer} {
    ${(props) =>
      props.status === "candidatar" &&
      `
    svg {
      path {
        fill: #ffffff;
      }
    }
  `}
  }

  ${ButtonText} {
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
      props.status === "candidatar" &&
      `
      color: #FFFFFF;
      svg {
        path {
          fill: #FFFFFF;
        }
      }
    `}
  }
`;

export const ContainerBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BadgeHover = styled.span`
  overflow: hidden;
  width: 0;
  height: 0;
  white-space: nowrap;
  transition: 0.5s;
`;

export const Badge = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0px 12px;
  color: ${({ status, color }) =>
    status === "candidatar" ? color || "#333333" : "#333333"};
  font-weight: bold;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 11px;
  line-height: 28px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  height: 32px;

  ${({ isLink }) => isLink && "cursor: pointer;"}

  i {
    font-size: 12px;

    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px;`}
  }

  :first-child {
    margin-left: 0px;
  }

  @media screen and (min-width: 576px) {
    :hover ${BadgeHover} {
      width: 60px;
      height: auto;
      transition: 0.5s;
    }
  }

  :hover {
    ${({ status, color }) =>
      status === "candidatar" &&
      `
      background-color: ${color || "#333333"};
      color: #FFFFFF;
      border: 2px solid ${color || "#333333"};
    `}

    ${(props) =>
      props.status === "inscrito" &&
      `
      background-color: #FFFFFF;
      color: #333333;
      border: 2px solid rgba(0, 0, 0, 0.15);;
    `}
  }
`;
