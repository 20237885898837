import React from "react";

import useAlertModal from "@utils/hooks/useAlertModal";
import * as Styled from "./styles";

function Alert() {
  const { isVisible, alertData, hideAlert } = useAlertModal();

  const { closeable, title, message, buttons } = alertData || {};

  return (
    <Styled.Overlay $isVisible={isVisible}>
      <Styled.AlertContainer $isVisible={isVisible}>
        {closeable && (
          <Styled.CloseButton onClick={hideAlert}>
            <Styled.CloseIcon />
          </Styled.CloseButton>
        )}
        {title && <Styled.Title>{title}</Styled.Title>}
        {message && <Styled.Message>{message}</Styled.Message>}

        {buttons && (
          <Styled.ButtonsContainer>
            {buttons.map((button, index) => (
              <Styled.Button
                $isMain={button.main}
                onClick={button.onClick}
                key={String(index)}
              >
                {button.text}
              </Styled.Button>
            ))}
          </Styled.ButtonsContainer>
        )}
      </Styled.AlertContainer>
    </Styled.Overlay>
  );
}

export default React.memo(Alert);
