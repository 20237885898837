import React, { createContext, useState } from "react";

import { ModalCurriculum } from "@pages/curriculumV2/components/modal/";

const DEFAULT_MODAL_PROPS = {
  open: false,
  template: "",
  size: "",
  action: ""
};

export const CurriculumModalContext = createContext({});

export function CurriculumModalProvider({ children }) {
  const [modalProps, setModalProps] = useState(DEFAULT_MODAL_PROPS);

  const handleOpenModal = (template, action = () => {}, size = "normal") => {
    setModalProps({ open: true, template, action, size });
  };

  const handleCloseModal = () => setModalProps(DEFAULT_MODAL_PROPS);

  return (
    <CurriculumModalContext.Provider
      value={{ handleCloseModal, handleOpenModal }}
    >
      {children}
      {modalProps.open && (
        <ModalCurriculum onClose={handleCloseModal} {...modalProps} />
      )}
    </CurriculumModalContext.Provider>
  );
}
