import styled from "styled-components";

export const Row = styled.div`
  margin-top: 10px;
  :first-child {
    margin-top: 0px;
  }
  label {
    font-size: 16px;
    min-height: auto;
    span {
      display: none;
    }
  }
  > div {
    :not(:first-child) {
      margin-top: 28px;
    }
  }
`;

export const Register = styled.div`
  padding: 18px 0 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;

  a {
    font-size: 16px;
    padding-left: 4px;
  }
`;

export const Link = styled.a`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.purple};

  :hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const ForgotPassword = styled(Link)`
  display: block;
  margin: 28px 0 32px;
  font-size: 13px;
  a {
    color: ${({ theme }) => theme.colors.purple};
    :hover {
      color: inherit;
    }
  }
`;

export const ContainerSocial = styled.div`
  padding: 0 0 30px;
  margin: 0 0 24px;
  border-bottom: 1px solid #cac8c8;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  button {
    width: 100%;
  }
`;

export const Text = styled.p`
  color: ${(theme) => theme.colors.primary};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
`;

export const Container = styled.section`
  h1 {
    font-size: 1.5rem;
  }
`;

export const SectionDescription = styled.section`
  margin: 32px 0 22px;
  p {
    color: #000;
  }
`;

export const ButtonSubmit = styled.section`
  margin: 0 0 32px;
  button div {
    font-size: 16px;
  }
`;

export const MessageToast = styled.p`
  span {
    text-transform: capitalize;
  }
`;
