import React, { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import useFetch from "@hooks/useFetch";
import scrollToElementById from "@utils/scrollToElement";

import { OtherSkills } from "./components/otherSkills";
import { OptionalSkills } from "./components/optionalSkills";
import { RequiredSkills } from "./components/requiredSkills";

const START_POSITION_DYNAMIC_OPTIONS = 0;
const END_POSITION_DYNAMIC_OPTIONS = 6;
const TIME_DISPLAY_SCROLL = 200;
const OFFSET_ELEMENT = -150;

export const Skills = () => {
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: "skills" });

  const { data: dynamicOptions } = useFetch("/dynamic-options/skills");

  const dynamicSkills = useMemo(() => {
    if (!dynamicOptions || !fields) return [];

    return dynamicOptions.data.reduce((acc, option) => {
      const skill = fields.find(({ name }) => name === option.name);

      if (!skill?.required && !skill?.optional) {
        acc.push({ label: option.name, value: option.id });
      }
      return acc;
    }, []);
  }, [dynamicOptions, fields]);

  const appendSkill = (skill) => {
    append(skill);
    const elementId = "skill-".concat(skill.value);

    setTimeout(
      () => scrollToElementById(elementId, OFFSET_ELEMENT),
      TIME_DISPLAY_SCROLL
    );
  };

  const mountSkill = (skillName, moreProps = {}) => {
    const isNewSkill = fields.findIndex(({ name }) => name === skillName);

    if (isNewSkill === -1) {
      const skill = dynamicSkills.find(({ label }) => skillName === label);
      appendSkill({
        ...moreProps,
        level: 1,
        name: skill.label,
        value: skill.value
      });
    }
  };

  const removeSkill = (skill) => {
    const index = fields.findIndex((field) => skill.value === field.value);

    if (index > -1) {
      setValue("select-skill-value", undefined);
      remove(index);
    }
  };

  return (
    <>
      <RequiredSkills />

      <OtherSkills
        addSkill={mountSkill}
        removeSkill={removeSkill}
        dynamicSkills={dynamicSkills.slice(
          START_POSITION_DYNAMIC_OPTIONS,
          END_POSITION_DYNAMIC_OPTIONS
        )}
      />

      <OptionalSkills
        removeSkill={removeSkill}
        addSkillToInput={appendSkill}
        addSkillToSelect={mountSkill}
        dynamicSkills={dynamicSkills}
        skipSkills={END_POSITION_DYNAMIC_OPTIONS}
      />
    </>
  );
};
