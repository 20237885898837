import styled from "styled-components";

export const Container = styled.label`
  color: #333;
  display: block;
  font-size: 1.25rem;
  min-height: 1.875rem;
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-weight: bold;

  margin-bottom: 0.5rem;

  .label-required {
    font-weight: 400;
    font-size: 0.75rem;
  }
`;
