export function showLoading(loading, content) {
  return {
    type: "@utilities/LOADING",
    payload: { loading, content }
  };
}

export function showModal({
  user,
  profiler,
  domain,
  show,
  type,
  dataParams = {},
  options = {}
}) {
  return {
    type: "@utilities/MODAL",
    payload: {
      modal: {
        user,
        profiler,
        domain,
        show,
        type,
        dataParams,
        options
      }
    }
  };
}

export const showAlert = ({ show, type = "", data = [], sizeMode = "" }) => ({
  type: "@utilities/SHOW_ALERT",
  payload: {
    alert: {
      show,
      type,
      data,
      sizeMode
    }
  }
});

export const candidateApply = (data = {}) => ({
  type: "@utilities/CANDIDATE_APPLY",
  payload: data
});
