import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import SignIn from "@pages/auth/signIn";
import * as cookie from "@utils/cookie";
import { DEFAULT_HOST_LOGIN } from "@services/constants";
import { parseURLEncodeToObject } from "@utils/urlParse";
import * as authActions from "@store/modules/auth/actions";
import * as utilitiesActions from "@store/modules/utilities/actions";

function LinkedinCallback() {
  const dispatch = useDispatch();

  cookie.set("MethodSignIn", "");

  useEffect(() => {
    dispatch(utilitiesActions.showLoading(true));

    return () => dispatch(utilitiesActions.showLoading(false));
  }, []);

  useEffect(() => {
    const response = parseURLEncodeToObject(window.location.search);

    if (response && response.code) {
      response.state && response.state === "signUp"
        ? dispatch(authActions.signUpLinkedin(response.code))
        : dispatch(authActions.signInLinkedin(response.code));
    } else {
      dispatch(utilitiesActions.showLoading(false));

      const auth_message = {
        type: "error",
        message: "Atenção",
        description:
          "Não foi possível autenticar com o Linkedin. Verifique se as permissões foram cedidas conforme solicitada por nossa página."
      };

      cookie.set("auth_message", auth_message);
      // retorna para a tela de login - supondo que estejam no mesmo domínio
      cookie.redirect(DEFAULT_HOST_LOGIN);
    }
  }, [window.location.search]);

  return <SignIn />;
}

export default LinkedinCallback;
