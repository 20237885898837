import { useFormContext } from "react-hook-form";
import React, { useMemo, useCallback, useEffect } from "react";

import { useAuth } from "@hooks/useAuth";
import useCurriculum from "@hooks/useCurriculum";
import { useCurriculumSections } from "@hooks/useCurriculumSections";
import { useCurriculumValidation } from "@hooks/useCurriculumValidations";
import message from "@components/message";

import { formatArrayToText } from "@utils/formatArrayToText";
import * as cookie from "@utils/cookie";
import * as curriculumActions from "@store/modules/curriculum/actions";
import * as utilitiesActions from "@store/modules/utilities/actions";
import { useCandidate } from "@hooks/useCandidate";
import { useDispatch } from "react-redux";
import useApplyVacancy from "@hooks/useApplyVacancy";

import { DEFAULT_HOST_CANDIDATE } from "@services/constants";
import { useCurriculumModal } from "@hooks/useCurriculumModal";

export default function CurriculumSectionSwitcher({
  component,
  path,
  nextPath,
  onSave,
  onSwitch,
  lastSection,
  ...rest
}) {
  const { setUserData } = useAuth();
  const { setUserIsReady } = useCurriculum();
  const { changeSection, sectionOptions, selectedSection } =
    useCurriculumSections();
  const { profiler } = useCandidate();
  const { handleOpenModal } = useCurriculumModal();
  const { isApplyVacancy, isApplyTalentBase } = useApplyVacancy();
  const { setValidationsMenu } = useCurriculumValidation();
  const {
    formState: { isValid },
    getValues,
    setValue
  } = useFormContext();
  const { hasProfileExpired } = cookie.getJSON();
  const defaultRequireds = getValues("defaultRequireds") || {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isApplyVacancy &&
      selectedSection?.key === "skills" &&
      (defaultRequireds?.skills || defaultRequireds?.languages)
    ) {
      setValue("defaultRequireds.skills", false);
      setValue("defaultRequireds.languages", false);

      setTimeout(
        () =>
          setValidationsMenu((state) => ({
            ...state,
            skills: { isValid: true }
          })),
        1500
      );
    }
  }, [isApplyVacancy, selectedSection, defaultRequireds]);

  const ComponentToRender = useMemo(
    () => (selectedSection?.key === path ? component : () => <></>),
    [component, path, selectedSection]
  );

  const validateAllSections = useCallback(() => {
    const invalidSections = sectionOptions.filter(
      (section) =>
        section.isValid === false && section.key !== selectedSection.key
    );

    const moreInvalidSections = invalidSections.length > 1;

    if (invalidSections.length > 0) {
      const textInvalidSection = formatArrayToText(
        invalidSections.map((section) => section.text)
      );
      message.error({
        title: "Atenção",
        content: moreInvalidSections ? (
          <>
            As etapas <b>{textInvalidSection}</b> estão incompletas.
          </>
        ) : (
          <>
            A etapa <b>{invalidSections[0]?.text}</b> está incompleta.
          </>
        )
      });

      if (!moreInvalidSections) changeSection(invalidSections[0]?.key);
    }

    return invalidSections.length === 0;
  }, [sectionOptions, selectedSection]);

  const handleFinishedForm = () => {
    if (!validateAllSections() || !isValid) return;

    if (profiler?.perfil && hasProfileExpired === true) {
      dispatch(
        utilitiesActions.showModal({
          show: true,
          type: "profileExpired",
          options: {
            position: "middleCenter",
            size: "medium",
            hasBgShadow: true,
            bgShadow: "",
            showClose: false,
            closeOnOverlay: false
          }
        })
      );
    } else if (!profiler?.perfil) {
      handleOpenModal("finished", () => {
        dispatch(curriculumActions.answerProfiler());
      });
    } else if (isApplyVacancy) {
      dispatch(curriculumActions.applyCandidate());
    } else if (isApplyTalentBase) {
      dispatch(curriculumActions.applyTalentBase());
    } else {
      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate`);
    }
  };

  const handleSubmit = (values, menuPath) => {
    onSave(values).then((result) => {
      if (result) {
        if (typeof result === "object") {
          setUserData({ ...result, defaultRequireds });
          setUserIsReady(false);
        }

        const isChangeSection = !lastSection || !isValid;

        if (isChangeSection) {
          typeof menuPath === "string"
            ? changeSection(menuPath, false)
            : changeSection(nextPath, false);
        }
      }

      if (typeof menuPath !== "string" && lastSection) handleFinishedForm();
    });
  };

  return <ComponentToRender {...rest} onSave={handleSubmit} />;
}
