import React from "react";

import * as Styled from "./styles";

import Button from "../../../../components/button";

const Body = () => (
  <Styled.Container>
    <Styled.Title>
      Gostaria de atualizar o seu currículo antes de se cadastrar no banco de
      talentos?
    </Styled.Title>
    <Styled.Description>
      Ao enviar seu currículo, seu cadastro será realizado imediatamente.
    </Styled.Description>
  </Styled.Container>
);

const Footer = ({ onApplyTalentBank, onRedirectToCurriculum }) => (
  <Styled.FooterButtons>
    <Button medium type="outline" onClick={onRedirectToCurriculum}>
      Atualizar meu currículo
    </Button>
    <Button medium isUppercase={false} onClick={onApplyTalentBank}>
      Enviar meu currículo
    </Button>
  </Styled.FooterButtons>
);

export { Body, Footer };
