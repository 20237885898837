import styled from "styled-components";

export const Container = styled.div`
  display: block;
  position: relative;
  cursor: pointer;

  min-width: 18px;
  min-height: 30px;

  .container-checkbox {
    position: absolute;
    top: 6px;
    left: 0;
    height: 17px;
    width: 17px;
    border: 2px solid #333;
    border-radius: 2px;
    background: transparent;

    &::after {
      content: "";
      position: absolute;
      display: none;

      top: 1px;
      left: 4px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }

  input:checked ~ .container-checkbox {
    background: #333;
  }

  input:checked ~ .container-checkbox::after {
    display: block;
  }

  label {
    margin-left: 1.625rem;
  }
`;

export const InputCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
