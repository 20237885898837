import React from "react";

import Skeleton from "react-loading-skeleton";

import * as Styled from "./styles";

const SkeletonPage = () => (
  <Styled.Container>
    <Styled.Title>
      <Skeleton width={500} height={30} />
    </Styled.Title>
    <Styled.Description>
      <Skeleton width={600} height={20} style={{ marginBottom: 20 }} />
      <Skeleton width={400} height={20} style={{ marginBottom: 55 }} />
      <Skeleton width={500} height={20} style={{ marginBottom: 25 }} />
      <Skeleton width={400} height={20} />
    </Styled.Description>

    <Styled.Button>
      <Skeleton width={250} height={42} style={{ borderRadius: 30 }} />
    </Styled.Button>
  </Styled.Container>
);

export default SkeletonPage;
