import React from "react";
import { ReactComponent as ModalWomanPic } from "@assets/svg/modal_woman_pic.svg";

import * as Styled from "./styles";

export const Body = ({ onClose }) => (
  <Styled.ContainerBody>
    <Styled.ContainerContent>
      <Styled.SubTitle>CURSO 100% GRATUITO</Styled.SubTitle>
      <Styled.Title>EMPREGABILIDADE</Styled.Title>
      <Styled.Text>
        Uma vaga de trabalho para chamar de sua <br /> em 2022! Estude com a
        Sólides.
      </Styled.Text>
      <Styled.Text>
        Faça o curso Empregabilidade. <br /> 100% Online. 100% Gratuito
      </Styled.Text>
      <Styled.Link
        href="https://conteudo.solides.com.br/curso-gratuito-empregabilidade/"
        target="_blank"
        onClick={onClose}
      >
        INSCREVA-SE AGORA
      </Styled.Link>
    </Styled.ContainerContent>
    <Styled.ContainerImage>
      <ModalWomanPic />
    </Styled.ContainerImage>
  </Styled.ContainerBody>
);
