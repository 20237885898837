import styled from "styled-components";

export const Body = styled.section`
  width: 100%;
  background: #583f99;
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const ContentMessage = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  max-width: 405px;
  margin: 0 auto;
  padding: 30px;

  @media screen and (max-width: 950px) {
    padding: 0px 15px;
    align-items: center;
    padding-bottom: 20px;
    max-width: 385px;
  }

  @media screen and (min-width: 451) and (max-width: 950px) {
    max-width: 450px;
  }

  @media screen and (max-width: 450px) {
    max-width: 350px;
  }
`;

export const Title = styled.h1`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 220px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;

  @media screen and (max-width: 450px) {
    font-size: 120px;
  }
`;

export const SubTitle = styled.h2`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;

  @media screen and (max-width: 450px) {
    font-size: 26px;
  }
`;

export const Description = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 188%;
  color: #ffffff;
  margin-bottom: 40px;

  @media screen and (max-width: 950px) {
    text-align: center;
  }
`;

export const Button = styled.div`
  max-width: 160px;
  width: 100%;
`;

export const Banner = styled.article`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 950px) {
    padding-left: 30px;
    padding-bottom: 0px;
  }

  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const Desktop = styled.div`
  display: none;

  @media screen and (min-width: 951px) {
    display: flex;
  }
`;

export const Mobile = styled.div`
  display: flex;

  @media screen and (min-width: 950px) {
    display: none;
  }
`;
