import styled from "styled-components";

export const ListVacanciesContainer = styled.div`
  margin-top: 20px;
`;

export const BoxListItem = styled.div`
  width: 100%;
  display: flex;
  border-left: 2px solid
    ${({ active }) => (active ? `#57DD6D` : `rgba(0, 0, 0, 0.05)`)};
  box-sizing: border-box;
  margin-bottom: 0.4375rem;
  cursor: ${({ cursor }) => cursor || `normal`};

  /* less than 576px */
  padding: 8px 0px 8px 10px;
  flex-direction: column;

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    margin-bottom: 0.1875rem;
    padding: 8px 0px 8px 18px;
    flex-direction: row;
  }

  @media (max-width: 640px) {
    position: relative;
  }
`;

export const BoxListItemCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 20px;

  :first-child {
    /* less than 992px */
    min-width: 230px;
    max-width: 300px;

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      min-width: 230px;
      max-width: 230px;
    }

    /* Small devices (devices, 640px and down) */
    @media (max-width: 640px) {
      margin-bottom: 11px;
      max-width: calc(100% - 128px);
      min-width: unset;
      padding: 0;
    }
  }
  :nth-child(2) {
    min-width: 140px;
    max-width: 140px;

    /* Small devices (devices, 640px and down) */
    @media (max-width: 640px) {
      margin-bottom: 11px;
    }
  }
  :nth-child(3) {
    max-width: 100px;
  }
  :last-child {
    padding-right: 0;
    max-width: 125px;
    align-items: flex-end;

    /* Medium devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      display: flex;
    }
  }
`;

export const Text = styled.p`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  line-height: 17px;
  color: #333333;
  margin: 0;
  padding: 0;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoxPagination = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 0.2rem;
  position: relative;

  @media (max-width: 640px) {
    position: absolute;
    top: 5px;
    right: 0;
  }

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    display: flex;
  }
`;

export const ButtonText = styled.span`
  display: none;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  padding-right: 0.5rem;
`;

export const ButtonIconContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
    path {
      fill: ${({ color }) => color || "#333333"};
    }
  }
`;

export const AnchorTitle = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  :hover {
    color: #333333;
  }
`;

export const Anchor = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  font-size: 12px;
  line-height: 32px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  outline: none;

  &:hover ${ButtonText} {
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
      props.status === "candidatar" &&
      `
      color: #FFFFFF;
      svg {
        path {
          fill: #FFFFFF;
        }
      }
    `}
  }

  &:hover ${ButtonIconContainer} {
    ${(props) =>
      props.status === "candidatar" &&
      `
      svg {
        path {
          fill: #ffffff;
        }
      }
    `}
  }

  :hover {
    ${({ status, color }) =>
      status === "candidatar" &&
      `
      background-color: ${color || "#333333"};
      color: #FFFFFF;
      border: 2px solid ${color || "#333333"};
    `}

    ${(props) =>
      props.status === "inscrito" &&
      `

      background-color: #FFFFFF;
      color: #333333;
      border: 2px solid rgba(0, 0, 0, 0.15);;
    `}

    width: 120px;
    border-radius: 24px;

    transition: 0.3s;
  }

  @media (max-width: 640px) {
    position: initial;
  }
`;
