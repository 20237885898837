import styled from "styled-components";

export const ContainerBody = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #333333;
  /* Medium devices (landscape phones, 641px and down) */
  @media (max-width: 641px) {
    padding: 34px 26px 40px 26px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  line-height: 22px;
  text-align: center;
  width: 70%;

  margin: 22px 52px 34px;
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 41px;
`;

export const Text = styled.p`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  margin: 0;
`;
