import React from "react";

import * as Styled from "./styles";

import Button from "../../../../components/button";

const Body = () => (
  <Styled.Container>
    <Styled.Title>
      Gostaria de atualizar o seu currículo antes de se candidatar para esta
      vaga?
    </Styled.Title>
    <Styled.Description>
      Ao enviar seu currículo, sua candidatura será realizada imediatamente.
    </Styled.Description>
  </Styled.Container>
);

const Footer = ({ onApplyVacancy, onRedirectToCurriculum }) => (
  <Styled.FooterButtons>
    <Button medium type="outline" onClick={onRedirectToCurriculum}>
      Atualizar meu currículo
    </Button>
    <Button medium isUppercase={false} onClick={onApplyVacancy}>
      Enviar meu currículo
    </Button>
  </Styled.FooterButtons>
);

export { Body, Footer };
