import styled from "styled-components";

export const SelectContent = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  ${({ show }) => (show ? "display: flex;" : "display: none;")}
  flex-direction: column;
  padding: 5px 10px 8px 10px;
  width: 100%;
  max-height: 188px;
  overflow-y: auto;
  overflow-x: hidden;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  :hover::-webkit-scrollbar {
    display: flex;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #333333;
  }
`;

export const CreateIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  svg {
    path {
      fill: #333333;
    }
  }
`;

export const CreateTextCotainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateText = styled.span`
  font-size: 16px;
  color: #333333;
`;

export const Create = styled.div`
  margin: ${({ lenght }) =>
    lenght ? "8px -11px -8px -11px" : "-6px -11px -8px -11px"};
  border-top: 1px solid #efedf3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;

  :hover {
    /* background: #2f0a77; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    /*${CreateIconContainer} svg path {
            fill: #fff;
        }

        ${CreateText} {
            color: #fff;
        }*/
  }
`;

export const List = styled.div`
  width: 100%;
  height: 100%;

  ${(props) => (props.show === true ? "display: block;" : "display: none;")}
`;

export const ContainerEntryData = styled.div`
  position: relative;
  width: 100%;
`;

export const ContainerSelectList = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 2;

  ${({ show }) => (show === true ? "display: flex;" : "display: none;")}

  ${({ small, medium, large }) =>
    small
      ? `top: 37px;`
      : medium
      ? `top: 40px;`
      : large
      ? `top: 57px;`
      : `top: 40px;`};
`;

export const ListLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  img {
    width: 50px;
    height: 50px;
  }
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #333333;
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin-top: 3px;
  cursor: pointer;
  position: relative;

  &:hover {
    color: #fff;
    background: #33333388;
  }

  ${({ selected }) => selected === true && `color: #fff; background: #333333;`}
`;

export const ListItemTitle = styled.div`
  font-family: "Roboto Condensed";
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 50px;
  margin-top: 3px;
  font-weight: bold;
  align-items: center;
`;

export const ValueText = styled.div`
  color: #333333;
  display: flex;
  align-items: center;
`;

export const Autocomplete = styled.input`
  display: flex;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #333333;
  font-size: 16px;
  outline: none;
  font-family: "Roboto Condensed";

  &::placeholder {
    color: #33333399;
    font-size: 14px;
  }

  ${({ show, large }) =>
    show && !large
      ? ` border-radius: 20px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; `
      : !show && !large
      ? ` border-radius: 20px;`
      : show && large
      ? ` border-radius: 30px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; `
      : ` border-radius: 30px;`}

  ${({ small, medium, large }) =>
    small
      ? `height: 16px;`
      : medium
      ? `height: 41px;`
      : large
      ? `height: 36px;`
      : `height: 41px;`}



    ${({ photo }) => photo && `padding-left: 42px;`}
    ${({ error }) => error && "border-color: #dc3545;"}
`;

export const IconClose = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const Icon = styled.div`
  padding-right: 10px;
  position: absolute;
  right: 0;
  svg path {
    fill: #333333;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);

  ${({ hasImage }) => hasImage === false && "padding-left: 13px;"}
`;

export const Text = styled.span`
  font-family: "Roboto Condensed";
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
`;

export const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;

  ${({ data }) => data && `background-image: url("${data}");`}
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  outline: none;
`;

export const ContainerList = styled.div`
  margin-top: 5px;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  align-items: center;

  display: ${({ show }) => (show ? "grid" : "none")};

  ${({ typeList }) =>
    typeList === "column"
      ? ` grid-template-columns: 1fr; 
          flex-direction: column;
          
          ${ListItemOut} {
            margin-right: 0px;
          }`
      : ` grid-template-columns: 1fr 1fr 1fr;
          flex-direction: row;`}
`;

export const ListItemOut = styled.div`
  width: auto;
  height: 35px;
  padding: 0px 2px;
  background: #333333;
  display: flex;
  min-width: 80px;
  border-radius: 50px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  position: relative;

  :hover {
    color: #fff;
    background: #33333388;
  }
`;

export const IconCloseOut = styled.div`
  width: auto;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  right: 15px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: #fff;
    }
  }
`;

export const TextContainerOut = styled.div`
  display: flex;
  align-items: center;

  padding-left: ${({ hasPhoto }) => (hasPhoto ? "1" : "15")}px;
`;

export const TextOut = styled.span`
  color: white;
  font-size: 14px;
  font-family: "Roboto Condensed";
`;

export const PhotoOutContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PhotoOut = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;

  ${({ data }) => data && `background-image: url("${data}");`}
`;

export const ContainerAutocompleteImage = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const PhotoAutocomplete = styled.div`
  position: absolute;
  left: 4px;

  width: 32px;
  height: 32px;
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ data }) => data && `background-image: url("${data}");`}
`;

export const InitialPhoto = styled.div`
  width: 32px;
  height: 32px;
  background: #462881;
  border-radius: 200px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #99999988;

  ${({ showInput }) =>
    showInput &&
    `
    position: absolute;
    left: 4px;
  `}
`;

export const InitialPhotoText = styled.div`
  font-size: 13px;
  color: #f1f1f1;
  text-transform: uppercase;
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 80%;
  color: #dc3545;

  position: absolute;
  top: 39px;
`;
