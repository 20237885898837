import React from "react";
import { Tooltip } from "antd";
import * as Styled from "../styles";

import "antd/dist/antd.css";

const Pill = ({
  text,
  children = false,
  isTooltip = false,
  description = false,
  closeIcon,
  iconColor,
  fallbackClick
}) => (
  <Styled.ContentBadge>
    <Styled.Badge pill>
      {text}
      {children !== false && <Styled.Caption>{children}</Styled.Caption>}
      {closeIcon !== false &&
        (isTooltip ? (
          <Tooltip placement="top" title={description}>
            <Styled.CloseIcon iconColor={iconColor} onClick={fallbackClick}>
              {closeIcon}
            </Styled.CloseIcon>
          </Tooltip>
        ) : (
          <Styled.CloseIcon iconColor={iconColor} onClick={fallbackClick}>
            {closeIcon}
          </Styled.CloseIcon>
        ))}
    </Styled.Badge>
  </Styled.ContentBadge>
);

export default Pill;
