import React from "react";
import { useFormContext } from "react-hook-form";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { Typography } from "@components";
import { Label } from "@components/form";

import SliderSkill from "../sliderSkill";
import { MARK_LEVEL_OTHER_SKILL } from "../../utils";

import { Content } from "../../styles";
import { TagsSkill, TagSkill } from "./styles";

export const OtherSkills = ({ addSkill, removeSkill, dynamicSkills }) => {
  const { getValues } = useFormContext();
  const skills = getValues("skills") || [];
  const { isApplyVacancy, vacancy } = useApplyVacancy();
  return (
    <div>
      {isApplyVacancy && vacancy?.skills?.length > 0 && (
        <Label style={{ marginTop: "2rem" }}>Outras habilidades</Label>
      )}

      <Typography>Marque as habilidades que você mais se destaca:</Typography>

      <TagsSkill>
        {dynamicSkills.map(({ label }) => (
          <TagSkill
            key={label}
            onClick={() => addSkill(label)}
            selected={skills.find(({ name }) => name === label)}
          >
            <p>{label}</p>
          </TagSkill>
        ))}
      </TagsSkill>

      <Content>
        {skills.map(
          (skill, index) =>
            !skill.required &&
            !skill.optional && (
              <SliderSkill
                key={skill.value}
                skill={skill}
                name={`skills.${index}.level`}
                marks={MARK_LEVEL_OTHER_SKILL}
                onRemove={() => removeSkill(skill)}
              />
            )
        )}
      </Content>
    </div>
  );
};
