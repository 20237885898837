export const getDisabilities = () => [
  {
    value: "physical",
    label: "Física"
  },
  {
    value: "hearing",
    label: "Auditiva"
  },
  {
    value: "visual",
    label: "Visual"
  },
  {
    value: "intellectual",
    label: "Intelectual"
  },
  {
    value: "multiple",
    label: "Múltipla"
  }
];
