import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import React, { useEffect, useMemo, memo } from "react";

import { MessageError } from "@components/form";

const StartDateError = ({ groupName, fieldId, onChangeError }) => {
  const {
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();
  const [startMonth, startYear, endMonth, endYear, current] = watch([
    `${groupName}.${fieldId}.startMonth`,
    `${groupName}.${fieldId}.startYear`,
    `${groupName}.${fieldId}.endMonth`,
    `${groupName}.${fieldId}.endYear`,
    `${groupName}.${fieldId}.current`
  ]);

  const clearFields = () => {
    setValue(`${groupName}.${fieldId}.endYear`, "");
    setValue(`${groupName}.${fieldId}.endMonth`, "");
  };

  useEffect(() => {
    setTimeout(() => current === "on" && clearFields(), 2000);
  }, []);

  useEffect(() => {
    if (current === "on") {
      clearFields();

      if (errors && errors[groupName]) {
        const endYearError = errors[groupName][fieldId]?.endYear;
        const endMonthError = errors[groupName][fieldId]?.endMonth;
        if (endYearError?.type || endMonthError?.type) {
          setError(`${groupName}.${fieldId}.endMonth`, { type: "manual" });
          setError(`${groupName}.${fieldId}.endYear`, { type: "manual" });
        }
      }
    }
  }, [current]);

  const isError = useMemo(() => {
    if (current === "on") return false;

    if (!!startMonth && !!startYear && !!endMonth && !!endYear) {
      const startDate = new Date(startYear, startMonth);
      const endDate = new Date(endYear, endMonth);

      return startDate > endDate;
    }

    return false;
  }, [startMonth, startYear, endMonth, endYear, current]);

  useEffect(() => {
    if (isError) {
      onChangeError((state) => [...state, fieldId]);
    } else {
      onChangeError((state) => state.filter((item) => item !== fieldId));
    }
  }, [isError]);

  return (
    isError && (
      <MessageError message="A data de início deve ser anterior ou igual à data de término." />
    )
  );
};

StartDateError.propTypes = {
  groupName: PropTypes.string.isRequired,
  fieldId: PropTypes.number.isRequired,
  onChangeError: PropTypes.func.isRequired
};

export default memo(StartDateError);
