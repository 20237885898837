import React, { useEffect, useState } from "react";
// import { LinkedIn } from "react-linkedin-login-oauth2";

import {
  signIn,
  recoverPass
} from "../../../../utils/formValidations/auth/schema";
// import { getHost } from "../../../../utils/getHost";

// import * as StyledGlobal from "../../../../styles/global";
import * as Styled from "./styles";

import Input from "../../../../components/input";
import Button from "../../../../components/button";

const modalOptions = {
  registerUser: {
    position: "topRight",
    size: "small",
    hasBgShadow: true,
    showClose: true
  },
  login: {
    position: "topRight",
    size: "small",
    hasBgShadow: true,
    showClose: true
  },
  forgotPassword: {
    position: "topRight",
    size: "small",
    hasBgShadow: true,
    showClose: true
  }
};

export const Body = ({
  data,
  type = "login",
  changeModal,
  fallbackFormInputHandle,
  fallbackFormSubmitHandle,
  errors = {},
  color = "#333333"
}) => {
  const [formInputs, setFormInputs] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  useEffect(() => {
    setFormInputs({ type: "" });
  }, []);

  // useEffect(() => {
  //     const listener = (event) => {
  //         if (event.code === "Enter" || event.code === "NumpadEnter") {
  //             fallbackFormSubmitHandle();
  //         }
  //     };
  //     document.addEventListener("keydown", listener);
  //     return () => {
  //         document.removeEventListener("keydown", listener);
  //     };
  // }, []);

  const inputChangeHandler = (type, value) => {
    const data = { ...formInputs };
    let error = { ...formErrors };
    let schema = signIn(formInputs);

    if (type === "email") {
      schema = recoverPass(formInputs);
    }

    schema
      .validateAt(`${type}`, { [type]: value })
      .then((valid) => {
        delete error[type];
        setFormErrors(error);
      })
      .catch((err) => {
        error = { ...formErrors, [type]: err.message };
        setFormErrors(error);
      });

    data[type] = value;
    setFormInputs(data);
    fallbackFormInputHandle(data);
  };

  return (
    <>
      {type === "login" ? (
        <Styled.Container>
          <Styled.Title>Logar como candidato</Styled.Title>

          <form autoComplete="off">
            <Styled.Field>
              <Input
                type="text"
                label="email ou CPF"
                onChange={(e) => inputChangeHandler("user", e.target.value)}
                error={formErrors.user ? formErrors.user : ""}
              />
            </Styled.Field>
            <Styled.Field>
              <Input
                type="password"
                label="senha"
                name="password"
                autoComplete="new-password"
                onChange={(e) => inputChangeHandler("password", e.target.value)}
                error={formErrors.password ? formErrors.password : ""}
              />
            </Styled.Field>
            <Styled.Field center="true">
              <Styled.ForgotPassword
                onClick={() =>
                  changeModal("forgotPassword", modalOptions.forgotPassword)
                }
                textColor={color}
              >
                Esqueci a minha senha
              </Styled.ForgotPassword>
            </Styled.Field>
          </form>
        </Styled.Container>
      ) : (
        <Styled.Container>
          <Styled.Title>Esqueci minha senha</Styled.Title>

          <form autoComplete="off">
            <Styled.Field>
              <Input
                type="text"
                label="Digite seu email de cadastro"
                name="recover"
                autoComplete="off"
                onChange={(e) => inputChangeHandler("email", e.target.value)}
                error={formErrors.email ? formErrors.email : ""}
              />
            </Styled.Field>
          </form>
        </Styled.Container>
      )}
    </>
  );
};

export const Footer = ({
  type = "login",
  changeModal,
  fallbackFormSubmitHandle,
  fallbackSignInLinkedin,
  fallbackRecoverPassHandle,
  color = "#333333"
}) => {
  // eslint-disable-next-line no-unused-vars
  // const [code, setCode] = useState("");
  // eslint-disable-next-line no-unused-vars
  // const [errorMessage, setErrorMessage] = useState("");
  // const [linkedinBaseUrl, setLinkedinBaseUrl] = useState("");

  useEffect(() => {
    getBaseUrl();
  }, []);

  // const handleSuccess = (data) => {
  //     setCode(data.code);
  //     setErrorMessage("");
  //     fallbackSignInLinkedin(data.code);
  // };

  // const handleFailure = (error) => {
  //     setCode("");
  //     setErrorMessage(error.errorMessage);
  // };

  const getBaseUrl = () => {
    // const host = getHost();
    // setLinkedinBaseUrl(host);
  };

  return (
    <>
      {type === "login" ? (
        <Styled.Container>
          {/* <Styled.Field center="true">
                        <Styled.ContainerSocialAuth>
                            <LinkedIn
                                clientId="78pp350a2v5dvv"
                                scope="r_liteprofile r_emailaddress"
                                onFailure={(error) => handleFailure(error)}
                                onSuccess={(data) => handleSuccess(data)}
                                redirectUri={`${linkedinBaseUrl}/auth/linkedin/callback`}
                                renderElement={({ onClick, disabled }) => (
                                    <Button
                                        small
                                        type="linkedin"
                                        isUppercase={false}
                                        boxShadow={true}
                                        leftIcon
                                        onClick={onClick}
                                        disabled={disabled}
                                    >
                                        <StyledGlobal.TextButton>
                                            Logar com
                                        </StyledGlobal.TextButton>
                                        <StyledGlobal.TextButton bold>
                                            &nbsp;Linkedin
                                        </StyledGlobal.TextButton>
                                    </Button>
                                )}
                            />
                        </Styled.ContainerSocialAuth>
                    </Styled.Field> */}
          <Styled.ContainerAuth>
            <Button
              type="link"
              isUppercase
              fontWeight="bold"
              textColor={color}
              text="Cadastrar"
              onClick={() =>
                changeModal("registerUser", modalOptions.registerUser)
              }
            />
            <Button
              medium
              isUppercase
              boxShadow
              fontWeight="bold"
              textColor="#FFFFFF"
              backgroundColor={color}
              text="Logar"
              onClick={fallbackFormSubmitHandle}
            />
          </Styled.ContainerAuth>
        </Styled.Container>
      ) : (
        <Styled.Container>
          <Styled.ContainerAuth>
            <Button
              type="link"
              isUppercase
              fontWeight="bold"
              textColor={color}
              text="Voltar"
              onClick={() => changeModal("login", modalOptions.login)}
            />
            <Button
              medium
              isUppercase
              boxShadow
              fontWeight="bold"
              textColor="#FFFFFF"
              backgroundColor={color}
              text="Recuperar"
              onClick={fallbackRecoverPassHandle}
            />
          </Styled.ContainerAuth>
        </Styled.Container>
      )}
    </>
  );
};
