import { call, all, put, takeLatest } from "redux-saga/effects";
import { notification } from "antd";
import { resolvePromiseAction } from "@adobe/redux-saga-promise";

import * as cookie from "@utils/cookie";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_COMPANY
} from "@services/constants";

import * as api from "./repository";

function* applyVacancySaga(action) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  let hasApplyed = false;
  let statusApply = "";

  try {
    const { data: userApplications } = yield call(api.getMyApplications);
    hasApplyed = userApplications?.data?.find(
      (application) => application.id === action.payload.vagaId
    );

    statusApply =
      userApplications?.data?.length > 0 ? "success" : "first_application";
  } catch (err) {}

  const { [window.name]: tab } = cookie.getJSON();

  try {
    if (!hasApplyed) {
      yield call(api.applyVacancy, action.payload);
      yield put({
        type: "@utilities/MODAL",
        payload: { show: false }
      });
    } else {
      statusApply = "has_applied";
    }

    // adiciona o status atual após se aplicar a vaga
    // salva a vaga aplicada na aba e remove o dado da vaga atual
    cookie.set(
      window.name,
      {
        ...tab,
        vacancy: null,
        statusApply,
        applied: { vacancy: tab?.vacancy }
      },
      { expires: 1 }
    );
  } catch (err) {
    // adiciona o status atual de erro após a tentativa de se aplicar a vaga
    cookie.set(
      window.name,
      {
        ...tab,
        statusApply: "error",
        applied: { vacancy: tab?.vacancy }
      },
      { expires: 1 }
    );

    const errorApi = err.response.data || {};
    if (errorApi?.code_request_gestao !== 406) {
      const description =
        err.response && err.response.data
          ? err.response.data.message
          : "Não foi possível se aplicar a vaga no momento. Por favor, tente novamente mais tarde.";

      notification.error({
        message: "Atenção",
        description
      });
    } else if (errorApi) {
      cookie.set(
        window.name,
        { ...tab, error406: err.response.data.message },
        { expires: 1 }
      );
    }
  } finally {
    // verificar aqui quando for uma vaga oculta o redirecionamento
    if (tab?.company?.slug) {
      const COMPANY_HOST = DEFAULT_HOST_COMPANY(tab.company.slug);
      cookie.redirect(`${COMPANY_HOST}/applicationStatus`);
    } else {
      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/applicationStatus`);
    }

    yield put({
      type: "@curriculum/APPLY_VACANCY_FINISH"
    });

    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* getRequiredFieldsSaga(action) {
  try {
    const { data: response } = yield call(
      api.getRequiredFields,
      action.payload
    );

    yield call(resolvePromiseAction, action, response?.data || []);
  } catch (err) {
    const description =
      err.response && err.response.data
        ? err.response.data.message
        : "Não foi possível obter os campos obrigatórios da vaga no momento. Por favor, tente novamente mais tarde.";

    notification.error({
      message: "Atenção",
      description
    });

    yield call(resolvePromiseAction, action, []);
  }
}

function* getVacancySaga(action) {
  try {
    const { data: response } = yield call(api.getVacancy, action.payload);

    yield call(resolvePromiseAction, action, response?.data || []);
  } catch (err) {
    const description =
      err.response && err.response.data
        ? err.response.data.message
        : "Não foi possível obter os dados da vaga. Por favor, tente novamente mais tarde.";

    notification.error({
      message: "Atenção",
      description
    });

    yield call(resolvePromiseAction, action, []);
  }
}

export default all([
  takeLatest("@vacancy/APPLY_VACANCY_REQUEST", applyVacancySaga),
  takeLatest(
    "@vacancy/GET_REQUIRED_FIELDS_REQUEST.TRIGGER",
    getRequiredFieldsSaga
  ),
  takeLatest("@vacancy/GET_VACANCY_REQUEST.TRIGGER", getVacancySaga)
]);
