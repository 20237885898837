import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import SanitizeHTML from "../../components/sanitize";
import Button from "../../components/button";
import * as Styled from "./styles";

import { ReactComponent as Close } from "../../assets/svg/closeModal.svg";

function Alert({ onClose, alert }) {
  const [modalOpen, setModalOpen] = useState(true);

  const options = {
    position: "middle", // topLeft, topCenter, topRight, middleLeft, middle, middleRight, bottomLeft, bottomCenter, bottomRight
    size: "medium", // fullsize, small, medium, large
    hasBgShadow: true,
    bgShadow: "",
    showClose: true
  };

  useEffect(() => {
    if (modalOpen === false) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const handleOnSaveEvalueted = (fallback) => {
    fallback();
    onClose();
  };

  const renderIcon = () => {
    switch (alert.data.type) {
      case "error":
        return (
          <Styled.IconContainer color="#dc3545">
            <i className="fas fa-times-circle" />
          </Styled.IconContainer>
        );
      case "warning":
        return (
          <Styled.IconContainer color="#EE8F00">
            <i className="fas fa-exclamation-triangle" />
          </Styled.IconContainer>
        );
      case "info":
        return (
          <Styled.IconContainer color="#17a2b8">
            <i className="fas fa-exclamation-circle" />
          </Styled.IconContainer>
        );
      default:
        return (
          <Styled.IconContainer color="#28a745">
            <i className="fas fa-check-circle" />
          </Styled.IconContainer>
        );
    }
  };

  const renderHeader = () => (
    <Styled.ButtonClose showClose={options.showClose}>
      <Close onClick={() => setModalOpen(false)} />
    </Styled.ButtonClose>
  );

  const renderBody = () => (
    <Styled.BodyContainer>
      {renderIcon()}
      <Styled.Text>
        <SanitizeHTML html={alert.data.title} />
      </Styled.Text>
      <Styled.TextNormal>
        <SanitizeHTML html={alert.data.textContent} />
      </Styled.TextNormal>
    </Styled.BodyContainer>
  );

  const renderFooter = () => (
    <Styled.ButtonsContainer>
      <Styled.ButtonCancel flex={alert.data.btn1.flex}>
        <Button
          text={alert.data.btn1.text}
          type={alert.data.btn1.type}
          backgroundColor={alert.data.btn1.bgColor}
          textColor={alert.data.btn1.color}
          borderColor={alert.data.btn1.border}
          borderWidth="1px"
          boxShadow="rgba(47, 10, 119, 0.35)"
          onClick={onClose}
          isUppercase={false}
          medium
        />
      </Styled.ButtonCancel>
      <Styled.ButtonConfirm flex={alert.data.btn2.flex}>
        <Button
          text={alert.data.btn2.text}
          type={alert.data.btn2.type}
          backgroundColor={alert.data.btn2.bgColor}
          textColor={alert.data.btn2.color}
          borderColor={alert.data.btn2.border}
          borderWidth="1px"
          boxShadow="rgba(47, 10, 119, 0.35)"
          onClick={() => handleOnSaveEvalueted(alert.data.fallback)}
          isUppercase={false}
          medium
        />
      </Styled.ButtonConfirm>
    </Styled.ButtonsContainer>
  );

  return (
    <>
      <Styled.GlobalStyle modalOpen={modalOpen} />
      <Styled.ModalContainer>
        <Styled.ModalDialog
          position={options.position}
          scrollable={options.scrollable}
        >
          <Styled.ModalContent position={options.position} size={options.size}>
            <Styled.ModalHeader>{renderHeader()}</Styled.ModalHeader>
            <Styled.ModalBody>{renderBody()}</Styled.ModalBody>
            <Styled.ModalFooter>{renderFooter()}</Styled.ModalFooter>
          </Styled.ModalContent>
          <Styled.ModalOverlay
            hasBgShadow={options.hasBgShadow}
            bgShadow={options.bgShadow}
            onClick={() => setModalOpen(false)}
          />
        </Styled.ModalDialog>
      </Styled.ModalContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  alert: state.utilities.alert
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
