import styled from "styled-components";
import { mobileLarge } from "@styles/breakpoints";

export const Container = styled.div.attrs((props) => ({
  position: props.position || "static",
  left: props.left || "15px",
  bottom: props.bottom || "unset"
}))`
  display: flex;
  justify-content: flex-end;
  margin-top: 3.5rem;

  @media screen and (min-width: 450px) and (max-width: 600px) {
    margin-top: 1.5rem;
  }

  @media (max-width: ${mobileLarge}) {
    position: ${(props) => props.position};
    width: 100%;
    background-color: #f1f1f1;
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    padding: 0.75rem 1rem;
  }

  button {
    max-width: 200px;

    @media (max-width: ${mobileLarge}) {
      max-width: 100%;
    }
  }
  .skipBtn {
    background: #fff;
    color: #333;
  }
`;
