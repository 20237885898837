import styled, { css } from "styled-components";

export const typographys = {
  title: {
    tag: "h1",
    style: css`
      font-size: ${({ theme }) => theme.typography.title.size};
      font-weight: ${({ theme }) => theme.typography.title.weight};
      font-family: ${({ theme }) => theme.typography.title.family};
    `
  },
  subtitle: {
    tag: "h2",
    style: css`
      font-size: ${({ theme }) => theme.typography.subtitle.size};
      font-weight: ${({ theme }) => theme.typography.subtitle.weight};
      font-family: ${({ theme }) => theme.typography.subtitle.family};
    `
  },
  label: {
    tag: "label",
    style: css`
      font-size: ${({ theme }) => theme.typography.label.size};
      font-weight: ${({ theme }) => theme.typography.label.weight};
      font-family: ${({ theme }) => theme.typography.label.family};
    `
  },
  normal: {
    tag: "p",
    style: css`
      font-size: ${({ theme }) => theme.typography.normal.size};
      font-weight: ${({ theme }) => theme.typography.normal.weight};
      font-family: ${({ theme }) => theme.typography.normal.family};
    `
  }
};

export const Container = styled.div.attrs(({ type }) => ({
  type,
  as: typographys[type].tag,
  styles: typographys[type].style
}))`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};
  ${(props) => props.styles};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ type, theme }) => theme.typography[type].sizeMobile};
  }
`;
