import styled from "styled-components";

export const PaginateMain = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Indicators = styled.button`
  font-family: "Roboto", arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  width: ${({ size }) => (size ? `${size}px` : "35px")};
  height: ${({ size }) => (size ? `${size}px` : "35px")};

  border: 2px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 24px;
  margin: 0 1px;

  background-color: transparent;
  cursor: pointer;
  color: ${({ color }) => color || "#333333"};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active, color }) =>
    active &&
    `
      background-color: ${color || "#333333"};
      color: #FFFFFF;
      border: 2px solid ${color || "#333333"};
    `}

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}

  :hover {
    color: #ffffff;
    border: 2px solid ${({ color }) => color || "#333333"};
    background-color: ${({ color }) => color || "#333333"};

    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  svg {
    height: 10px;
    path {
      fill: ${({ color }) => color || "#333333"};
    }
  }
`;
