import React from "react";

import * as Styled from "./styles";

import Button from "../button";

const Advice = ({ showAdvice = false, showButton = false, data }) => {
  const percentage = (data.level * 100) / data.totalLevel;
  return (
    <Styled.Container>
      {showAdvice === true && (
        <Styled.ContainerCircle>
          <Styled.Circle disabled={data.totalAdvice.replace("%", "") === "0"}>
            {data.totalAdvice}
          </Styled.Circle>
        </Styled.ContainerCircle>
      )}
      <Styled.ContainerColumn>
        <Styled.Title>{data.text}</Styled.Title>
        <Styled.Description>{data.description}</Styled.Description>
      </Styled.ContainerColumn>
      <Styled.ContainerColumn>
        <Styled.ContainerProgress>
          <Styled.TitleProgressStep>{data.levelName}</Styled.TitleProgressStep>
          <Styled.ContainerProgressStep>
            <Styled.CurrentProgressStep percentage={percentage} />
          </Styled.ContainerProgressStep>
        </Styled.ContainerProgress>
      </Styled.ContainerColumn>
      {showButton === true && (
        <Styled.ContainerColumn>
          <Button
            type="custom"
            text="SOLICITAR RECOMENDAÇÃO"
            backgroundColor="#F4F0EB"
            textColor="#333333"
            borderColor="#C5B8DE"
            borderWidth="1px"
            medium
            onClick={() => data.onClick()}
          />
        </Styled.ContainerColumn>
      )}
    </Styled.Container>
  );
};

export default Advice;
