import React from "react";
import * as Styled from "./styles";

const Behavior = ({ children, onClick, name, behaviorId }) => {
  const _selected = (event) => {
    event.currentTarget.classList.toggle("selected");
    onClick(event, name);
  };

  return (
    <Styled.Behavior
      onClick={(event) => {
        _selected(event);
      }}
      id={`${children}_${behaviorId}`}
    >
      <Styled.SelectBehavior type="radio" name="option" id="option" />
      <Styled.TextBehavior>{children}</Styled.TextBehavior>
    </Styled.Behavior>
  );
};

export default Behavior;
