import styled from "styled-components";
import * as breakpoints from "@styles/breakpoints";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 35px;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.mobileLarge}) {
    grid-gap: 15px;
  }
`;

export const ContainerPicture = styled.div``;

export const RequiredField = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 0.5rem;
`;

export const ContainerUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const InputFile = styled.input`
  display: none;
`;

export const ButtonsRow = styled.div`
  width: 100%;
  margin-top: 15px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    margin-top: 7px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  grid-gap: 0.25rem;
  width: 100%;
  max-width: 350px;
`;

export const DeleteText = styled.span`
  color: ${({ theme }) => theme.colors.red};
  text-decoration: underline;
`;
