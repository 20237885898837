import React from "react";
import Skeleton from "react-loading-skeleton";
import { Skeleton as SkeletonAntd } from "antd";

import * as StyledPage from "../../styles";
import * as Styled from "./styles";

const SkeletonVacancy = () => (
  <>
    <StyledPage.Header>
      <Styled.ContainerTitle>
        <Styled.Title>
          <Skeleton width={500} height={30} />
        </Styled.Title>
        <Styled.Description style={{ marginBottom: 10 }}>
          <Skeleton width={200} height={15} />
        </Styled.Description>
        <Skeleton width={300} height={30} />
      </Styled.ContainerTitle>
    </StyledPage.Header>
    <StyledPage.Body>
      <Styled.Description>
        <SkeletonAntd active paragraph={{ rows: 8 }} />
      </Styled.Description>
    </StyledPage.Body>
    <StyledPage.Footer>
      <Styled.Button>
        <Skeleton width={250} height={42} style={{ borderRadius: 30 }} />
      </Styled.Button>
    </StyledPage.Footer>
  </>
);

export default SkeletonVacancy;
