import * as yup from "yup";
import { validateCPF } from "../../validations";

const signIn = () =>
  yup.object().shape({
    type: yup.string(),
    user: yup
      .string()
      .required("Campo obrigatório")
      .when("type", {
        is: "email",
        then: yup.string().email("Email em formato inválido")
      }),
    password: yup.string().required("Campo obrigatório")
  });

const signUp = (formInputs = null) => {
  let passwordField;

  if (formInputs) {
    passwordField = formInputs.password
      ? formInputs.password
      : yup.ref("password");
  } else {
    passwordField = yup.ref("password");
  }

  yup.addMethod(yup.string, "validateCPF", validateCPF);

  return yup.object().shape({
    email: yup
      .string()
      .required("Campo obrigatório")
      .email("Email em formato inválido"),
    cpf: yup.string().required("Campo obrigatório").validateCPF("CPF inválido"),
    password: yup
      .string()
      .matches(/(?=.*?[0-9])(?=.*?[A-Za-z]).+/, {
        excludeEmptyString: true,
        message: "A senha deve conter ao menos uma letra e um número"
      })
      .min(6, "A senha deve conter mais que 6 dígitos")
      .max(20, "A senha deve conter menos que 20 dígitos")
      .required("Campo obrigatório"), // (?=.*?[0-9])(?=.*?[A-Za-z])(?=.*[^0-9A-Za-z]).+,
    confirmPassword: yup
      .string()
      .matches(/(?=.*?[0-9])(?=.*?[A-Za-z]).+/, {
        excludeEmptyString: true,
        message: "A senha deve conter ao menos uma letra e um número"
      })
      .min(6, "A senha deve conter mais que 6 dígitos")
      .max(20, "A senha deve conter menos que 20 dígitos")
      .oneOf([passwordField, null], "Senhas não coincidem")
      .required("Campo obrigatório"),
    userTerm: yup
      .boolean()
      .required("Campo obrigatório")
      .oneOf([true], "O termo deve ser aceito")
  });
};

const recoverPass = () =>
  yup.object().shape({
    email: yup
      .string()
      .required("Campo obrigatório")
      .email("Email em formato inválido")
  });

export { signIn, signUp, recoverPass };
