import React from "react";
import { Route } from "react-router-dom";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_COMPANY_ROUTES
} from "../../services/constants";

import Vacancies from "../../pages/vacancies";
import PublicVacancyStatus from "../../pages/applicationStatus/publicVacancy";
import TalentBankStatus from "../../pages/applicationStatus/talentBank";
import CompanyProfilerStatus from "../../pages/applicationStatus/companyProfiler";

const CompanyRoutes = ({ history, slug, token, ...rest }) => {
  const slugVerify = () => {
    const HOST = window.location.href.replace("//www.", "//");
    const { pathname } = window.location;
    let hasValidRoute = false;

    // teste com ngrok
    if (HOST.includes("ngrok.io")) {
      return (
        <Route
          {...rest}
          exact
          path={["/", "/vacancies", "/:id", "/vacancies/:id"]}
          render={() => <Vacancies history={history} />}
          history={history}
        />
      );
    }

    DEFAULT_COMPANY_ROUTES.forEach((route) => {
      const vacancyId = parseInt(pathname.replace(route, ""));

      let currentRoute = route;
      if (!isNaN(vacancyId)) {
        currentRoute = `${route}${vacancyId}`;
      }

      const matchRoute = pathname.replace(currentRoute, "");
      if (matchRoute === "") {
        hasValidRoute = true;
      }
    });

    if (
      slug &&
      !HOST.includes(DEFAULT_HOST_LOGIN) &&
      !HOST.includes(DEFAULT_HOST_CANDIDATE) &&
      hasValidRoute
    ) {
      // verifica se possui slug, e se o usuário não está no host de autenticação e candidato
      // e se o caminho atual no host está incluso nas rotas disponíveis para a empresa
      // registra as rotas disponíveis para a empresa.
      return (
        <>
          <Route path="/applicationStatus" component={PublicVacancyStatus} />
          <Route path="/talentBankStatus" component={TalentBankStatus} />
          <Route path="/profilerStatus" component={CompanyProfilerStatus} />
          {!pathname.includes("applicationStatus") &&
            !pathname.includes("talentBankStatus") &&
            !pathname.includes("profilerStatus") && (
              <Route
                {...rest}
                exact
                path={["/", "/vacancies", "/:id", "/vacancies/:id"]}
                render={() => <Vacancies history={history} />}
                history={history}
              />
            )}
        </>
      );
    }
    // caso não atenda a nenhuma das regras aqui irá carregar as próximas validações
    return null;
  };

  return slugVerify();
};

export default CompanyRoutes;
