import * as yup from "yup";
import { parse, isDate, addHours } from "date-fns";

const requiredMessage = "Campo de preenchimento obrigatório";

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

const schema = (requiredFields = []) =>
  yup.object().shape({
    documents: yup.object().shape({
      driverLicense: yup
        .number()
        .typeError("Somente números")
        .isRequired("drivers_license", requiredFields, requiredMessage)
        .max(11, "Você atingiu o limite de caracteres")
        .isNotZero()
        .nullable(),
      driversLicenseCategory: yup.lazy((val) =>
        Array.isArray(val)
          ? yup
              .array()
              .of(
                yup.object().shape({ label: yup.string(), value: yup.string() })
              )
              .typeError("Selecione pelo menos uma opção")
              .isRequired(
                "drivers_license_category",
                requiredFields,
                "Campo de preenchimento obrigatório"
              )
              .nullable()
          : yup
              .string()
              .typeError("Selecione pelo menos uma opção")
              .isRequired(
                "drivers_license_category",
                requiredFields,
                requiredMessage
              )
              .nullable()
      ),
      driversLicenseEmissionDate: yup
        .date()
        .transform(parseDateString)
        .isRequired(
          "drivers_license_issuance_date",
          requiredFields,
          requiredMessage
        )
        .typeError("Selecione a data de emissão da CNH")
        .min(new Date("1930-01-01"), "Data inválida")
        .max(
          addHours(new Date(), 1),
          "A data não pode ser maior do que a data de hoje"
        )
        .nullable(),
      driversLicenseFirstDate: yup
        .date()
        .transform(parseDateString)
        .isRequired(
          "drivers_license_first_date",
          requiredFields,
          requiredMessage
        )
        .typeError("Selecione a data da primeira habilitação")
        .min(new Date("1930-01-01"), "Data inválida")
        .max(
          addHours(new Date(), 1),
          "A data não pode ser maior do que a data de hoje"
        )
        .nullable(),
      driversLicenseUf: yup
        .string()
        .typeError("Escreva o estado de emissão da CNH")
        .isRequired(
          "drivers_license_state",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .hasNumber("Números não são permitidos")
        .nullable(),
      electoralNumber: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "electoral_number",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable(),
      electoralZone: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "electoral_zone",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable(),
      electoralSection: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "pulling_station",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable(),
      pis: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "social_integration_program",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable(),
      ctpsNum: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "work_permit_num",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable(),
      ctpsSerie: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "work_permit_serie",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable(),
      reservist: yup
        .number()
        .typeError("O campo deve conter apenas números")
        .integer("O campo deve conter apenas números")
        .isRequired(
          "reservist",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable()
    })
  });

export default schema;
