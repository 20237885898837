import React, { useCallback, useEffect } from "react";

import useCurriculum from "@hooks/useCurriculum";
import { useCurriculumSections } from "@utils/hooks/useCurriculumSections";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { yupResolver } from "@hookform/resolvers/yup";
import { skillsSchema } from "@utils/formValidations/curriculumV2/index";
import { CurriculumSectionTemplate } from "@components/index";

import { Skills } from "./components/skills";
import Languages from "./components/languages";

import { Form } from "../styles";

function SkillsSection({ onSave, children }) {
  const { requiredFields, vacancy } = useApplyVacancy();
  const { registerForm, handleSubmit } = useCurriculum();
  const { sectionOptions } = useCurriculumSections();

  useEffect(() => {
    const resolver = yupResolver(skillsSchema(requiredFields, vacancy));

    registerForm({
      schema: { resolver },
      handleSubmit: { onSubmit }
    });
  }, [requiredFields, sectionOptions, vacancy]);

  const onSubmit = useCallback(
    (data, nextPath) => {
      const { languages, skills } = data;
      onSave({ languages, skills }, nextPath);
    },
    [sectionOptions]
  );

  return (
    <CurriculumSectionTemplate title="Habilidades">
      <Form onSubmit={handleSubmit}>
        <Skills />
        <Languages />
        {children}
      </Form>
    </CurriculumSectionTemplate>
  );
}

export default React.memo(SkillsSection);
