import { useCallback, useRef } from "react";

export default function useDebounceCallback(
  callback,
  observers = [],
  delay = 750
) {
  // const [timeout, setTime] = useState(null);
  const timeout = useRef(null);
  const handleDebounce = useCallback(
    (...params) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => callback(...params), delay);
    },
    [timeout, callback, delay]
  );

  return (...params) => handleDebounce(...params);
}
