export const schemaTranslate = {
  name: "Nome",
  salaryExpectation: "Pretensão salarial",
  videoUrl: "Vídeo de apresentação",
  portfolioUrl: "Portfólio",
  linkedin: "Linkedin",
  facebook: "Facebook",
  instagram: "Instagram",
  twitter: "Twitter",
  about: "Sobre",
  occupation: "Cargo",
  company: "Empresa",
  startMonth: "Data de início (mês)",
  startYear: "Data de início (ano)",
  endMonth: "Data de término (mês)",
  endYear: "Data de término (ano)",
  description: "Descrição",
  level: "Grau de escolaridade",
  course: "Curso",
  college: "Instituição",
  skills: "Habilidades",
  languages: "Idiomas",
  email: "E-mail login",
  secondaryEmail: "E-mail secundário",
  cpf: "CPF",
  rg: "RG",
  phone: "Número de celular",
  whatsapp: "Whatsapp",
  birthday: "Data de nascimento",
  gender: "Gênero",
  maritalStatus: "Estado civil",
  country: "País",
  zipCode: "CEP",
  street: "Logradouro",
  number: "Número",
  complement: "Complemento",
  district: "Bairro",
  province: "Estado",
  city: "Cidade",
  driverLicense: "CNH",
  driversLicenseCategory: "Categoria da CNH",
  driversLicenseEmissionDate: "Data de emissão da CNH",
  driversLicenseFirstDate: "Data da primeira habilitação",
  driversLicenseUf: "Estado de emissão da CNH",
  electoralNumber: "Título de eleitor",
  electoralZone: "Zona eleitoral",
  electoralSection: "Seção eleitoral",
  pis: "PIS",
  ctpsNum: "CTPS número",
  ctpsSerie: "CTPS série",
  reservist: "Reservista",
  disabledPersonDescription: "Pessoa com deficiência",
  picture: "Foto de perfil"
};
