import React, { useMemo, useState, useEffect } from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";

import { ReactComponent as AuthLogoJobs } from "@assets/svg/authLogoJobs.svg";

import Button from "@components/button";
import * as authActions from "@store/modules/auth/actions";
import * as cookie from "@utils/cookie";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_HIDDEN_VACANCY
} from "@services/constants";

import * as Styled from "./styles";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState("signIn");

  useEffect(() => {
    // exibe uma notificação para o usuário caso seja necessário
    // na tela de autenticação quando iniciada
    const { auth_message, auth_view_type } = cookie.getJSON();
    if (auth_message) {
      const { type, message, description } = auth_message;
      if (type && description) {
        notification[type]({
          message,
          description
        });
      }
      cookie.set("auth_message", "");
    }

    if (auth_view_type) {
      setViewType(auth_view_type);
      cookie.set("auth_view_type", "");
    }
  }, []);

  useEffect(() => {
    dispatch(authActions.clearErrorRequestAuth());
  }, [dispatch]);

  const renderJobs = useMemo(
    () => (
      <Styled.ContainerJobs>
        <Styled.ContainerLogoJobs>
          <AuthLogoJobs />
        </Styled.ContainerLogoJobs>
        <Styled.SocialLinks>
          <Button
            type="social"
            options={[
              {
                type: "linkedin",
                color: "#583F99",
                borderColor: "rgba(0, 0, 0, 0.15)",
                link: "https://www.linkedin.com/company/solidestecnologia"
              },
              {
                type: "youtube",
                color: "#583F99",
                borderColor: "rgba(0, 0, 0, 0.15)",
                link: "https://www.youtube.com/channel/UCqG25smPnLbI-q2I1V760DQ"
              },
              {
                type: "instagram",
                color: "#583F99",
                borderColor: "rgba(0, 0, 0, 0.15)",
                link: "https://www.instagram.com/solidestecnologia"
              },
              {
                type: "facebook",
                color: "#583F99",
                borderColor: "rgba(0, 0, 0, 0.15)",
                link: "https://pt-br.facebook.com/solidestecnologia/"
              },
              {
                type: "twitter",
                color: "#583F99",
                borderColor: "rgba(0, 0, 0, 0.15)",
                link: "https://twitter.com/solides"
              }
            ]}
          />
        </Styled.SocialLinks>
      </Styled.ContainerJobs>
    ),
    []
  );

  const renderBack = useMemo(() => {
    const { pathname } = window.location;
    const { origin } = cookie.getJSON();

    const isVisible =
      origin &&
      (!origin.includes(DEFAULT_HOST_CANDIDATE) ||
        origin.includes(DEFAULT_HOST_HIDDEN_VACANCY)) &&
      !pathname.includes("notFound");

    return (
      <Styled.BackLink
        href={`${origin}`}
        style={{ "--visible": isVisible ? "visible" : "hidden" }}
      >
        <i className="fas fa-arrow-left" /> &nbsp;Voltar
      </Styled.BackLink>
    );
  }, []);

  return (
    <Styled.Container>
      <Styled.Header />
      <Styled.SectionGrid>
        <Styled.GridJobs>
          <Styled.Desktop>
            {renderBack}
            {renderJobs}
          </Styled.Desktop>
        </Styled.GridJobs>
        <Styled.GridAuth>
          <Styled.Mobile>{renderBack}</Styled.Mobile>
          <Styled.ContainerAuth>{children}</Styled.ContainerAuth>
          <Styled.Mobile>{renderJobs}</Styled.Mobile>
        </Styled.GridAuth>
      </Styled.SectionGrid>
    </Styled.Container>
  );
}

export default Auth;
