import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useFormContext } from "react-hook-form";

import { useCurriculumSections } from "@hooks/useCurriculumSections";
import { useCurriculumValidation } from "@hooks/useCurriculumValidations";
import { formatArrayToText } from "@utils/formatArrayToText";

import { alert } from "@pages/curriculumV2/components/alerts";
import { schemaTranslate } from "@utils/formValidations/curriculumV2/translate";

const AlertsContext = createContext(null);

function AlertsProvider({ children }) {
  const [alerts, setAlerts] = useState([]);
  const { getValues } = useFormContext();
  const { isDisabledPerson, disabledPersonDescription } = getValues();
  const { sectionOptions } = useCurriculumSections();
  const { fieldsNeedingToBeFilledAgain } = useCurriculumValidation();

  useEffect(() => {
    if (sectionOptions) {
      createAlertsFromSections();
    }
  }, [sectionOptions, disabledPersonDescription, isDisabledPerson]);

  const createAlertsFromSections = useCallback(() => {
    const alerts = [];

    for (const section in sectionOptions) {
      if (sectionOptions[section].status) {
        const status = sectionOptions[section].status;

        if (!checkAlertExceptions(status)) {
          if (!alerts.some((alert) => alert.key === status.error)) {
            alerts.push(createAlert(status));
          }
        }
      }
    }

    setAlerts([...alerts]);
  }, [sectionOptions, disabledPersonDescription, isDisabledPerson]);

  const createAlert = useCallback(
    (status) =>
      alert[status.type]({
        key: status.error,
        content: getAlertContent[status.error]
      }),
    [sectionOptions]
  );

  const getAlertContent = useMemo(
    () => ({
      "needs-to-fill-again": (
        <>
          O currículo foi atualizado! Por isso o(s) campo(s){" "}
          <b>
            {formatArrayToText(
              fieldsNeedingToBeFilledAgain.map(
                (field) => schemaTranslate[field]
              )
            )}
          </b>{" "}
          deve(m) ser preenchido(s) novamente.
        </>
      ),
      "is-required": (
        <>
          Para se candidatar à vaga preencha os campos obrigatórios na(s)
          etapa(s){" "}
          <b>
            {formatArrayToText(
              sectionOptions
                .filter((section) => section?.status?.type === "error")
                .map((section) => section.text)
            )}
          </b>
          .
        </>
      )
    }),
    [sectionOptions, fieldsNeedingToBeFilledAgain]
  );

  const checkAlertExceptions = useCallback(
    (status) =>
      status.error === "needs-to-fill-again" &&
      isDisabledPerson === true &&
      disabledPersonDescription?.length > 0,
    [disabledPersonDescription, isDisabledPerson]
  );

  const value = useMemo(() => ({ alerts }), [alerts]);

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
}

export { AlertsProvider, AlertsContext };
