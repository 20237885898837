import React from "react";

import { ReactComponent as Subscribe } from "@assets/svg/inscrever.svg";
import { ReactComponent as Registered } from "@assets/svg/inscrito.svg";
import * as Styled from "./styles";

const TalentRow = ({
  isAppliedTalentBank,
  fallBackApplyTalentBank,
  color = "#333333"
}) => (
  <Styled.TableContainer background="#F6F6F6">
    <Styled.VacancyContainer>
      <Styled.TableTitle fontWeight="bold">Banco de talentos</Styled.TableTitle>
    </Styled.VacancyContainer>
    <Styled.ButtonContainer>
      <Styled.TalentBankRow
        status={isAppliedTalentBank ? "inscrito" : "candidatar"}
        onClick={() => !isAppliedTalentBank && fallBackApplyTalentBank()}
        color={color}
        blocked={isAppliedTalentBank}
      >
        <Styled.ButtonText>
          {!isAppliedTalentBank ? "CADASTRAR" : "INSCRITO"}
        </Styled.ButtonText>
        <Styled.ButtonIconContainer color={color}>
          {!isAppliedTalentBank ? <Subscribe /> : <Registered />}
        </Styled.ButtonIconContainer>
      </Styled.TalentBankRow>
    </Styled.ButtonContainer>
  </Styled.TableContainer>
);

export default TalentRow;
