import React from "react";
import * as Styled from "../../styles";

const StatusMessage = ({
  title,
  text = [],
  secondaryText = [],
  description,
  iconClass,
  children
}) => (
  <Styled.ContainerBodyText>
    <Styled.Title>
      {title} {iconClass && <Styled.Icon className={iconClass} />}
    </Styled.Title>
    <Styled.ContainerText>
      {text.map((item, index) => (
        <Styled.Text key={`primaryText-${index}`}>{item}</Styled.Text>
      ))}
    </Styled.ContainerText>
    {secondaryText.length > 0 && (
      <Styled.ContainerText>
        {secondaryText.map((item, index) => (
          <Styled.Text key={`secondayText-${index}`}>{item}</Styled.Text>
        ))}
      </Styled.ContainerText>
    )}
    <Styled.ContainerText>
      <Styled.Text>{description}</Styled.Text>
    </Styled.ContainerText>
    {children && children}
  </Styled.ContainerBodyText>
);

export default StatusMessage;
