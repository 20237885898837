import React, { useEffect, useRef, useState } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import resolve from "@utils/resolve";
import { ReactComponent as BlockSVG } from "@assets/svg/block.svg";
import { ReactComponent as CheckSVG } from "@assets/svg/check.svg";

import NumberFormat from "../input/components/numberFormat";

import ddi from "./ddi-countries.json";
import { MessageError, Label, Select } from "..";
import { Container, SelectContainer, InputContainer } from "./styles";

const InputPhone = ({
  label,
  name,
  style,
  className,
  required,
  blocked,
  showValidateIcon = true,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
    setError
  } = useFormContext();
  const value = useWatch({ control, name });
  const prefixRef = useRef(null);
  const { message: error } = resolve(name, errors);

  const containerProps = { style, className };

  const inputContainerProps = {
    $prefixWidth: prefixRef.current && prefixRef.current.clientWidth,
    $hasError: !!error,
    $isBlocked: !!blocked
  };

  const getValidationIcon = showValidateIcon ? (
    blocked ? (
      <BlockSVG />
    ) : (
      !error && value && <CheckSVG />
    )
  ) : null;

  useEffect(() => {
    setError(`${name}DDI`, error ? { type: "manual", message: true } : {});
  }, [error]);

  return (
    <Container {...containerProps}>
      {label && <Label required={required}>{label}</Label>}
      <SelectContainer>
        <Select required options={ddi} name={`${name}DDI`} />
      </SelectContainer>
      <InputContainer {...inputContainerProps}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <NumberFormat
              showValidateIcon={showValidateIcon}
              {...rest}
              {...field}
              type="text"
              format="(##)#####-####"
              placeholder="(__)_____-____"
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
        {getValidationIcon}
      </InputContainer>
      {error && <MessageError message={error} />}
    </Container>
  );
};

export default InputPhone;
