import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .isValidEmail(),
    confirmEmail: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .isValidEmail()
      .oneOf(
        [yup.ref("email"), null],
        "Os e-mails não coincidem. Tente novamente."
      ),
    cpf: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .isValidCPF(),
    phone: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .length(11, "O número de celular deve ter 11 dígitos."),
    password: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .matches(
        /(?=.*?[0-9])(?=.*?[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~])(?=.*?[A-Za-z]).+/,
        {
          excludeEmptyString: true,
          message: "A senha deve seguir as regras abaixo."
        }
      )
      .min(6, "A senha deve seguir as regras abaixo."),
    confirmPassword: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .oneOf(
        [yup.ref("password"), null],
        "As senhas não coincidem. Tente novamente."
      ),
    userTerm: yup.string().oneOf(["on"], "Campo de preenchimento obrigatório")
  })
  .required();

export default schema;
