import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const HeaderDescription = styled.span`
  font-size: 12px;
  ${({ bold }) => bold && "font-weight: bold"}
  margin: 0 2px;
`;

export const HeaderItem = styled.div`
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}

  font-family: "Roboto";
  font-style: normal;
  line-height: 120%;
  text-align: center;
  color: #333333;
  display: flex;
  flex-direction: row;

  ${({ center }) =>
    center &&
    `
      width: 100%;
      align-items: center;
      justify-content: center;
  `}

  ${({ itensBar }) =>
    itensBar &&
    `
      ${HeaderDescription}:not(:first-child)::before {
        margin: 0 4px 0 0;
        color: #333333;
        content: "/";
      }
  `}
`;

export const Photo = styled.div`
  margin: 0 15px;
  display: flex;
`;

export const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const Link = styled.a`
  font-size: 12px;
  text-decoration-line: underline;
  color: #333333;

  :hover {
    color: #000;
    text-decoration-line: underline;
  }
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  min-height: 100px;

  /* less than 992px */
  padding: 0 18px;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 0 50px 50px 50px;
  }
`;

export const ContainerFilter = styled.div`
  padding: 30px;
`;

export const ContainerCurriculum = styled.div``;

export const ContainerCompany = styled.div`
  margin-bottom: 50px;
`;

export const ContainerProfiler = styled.div``;

export const ContainerVacancy = styled.div`
  margin-bottom: 50px;
`;

export const ContainerButton = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
`;

export const ContainerField = styled.div`
  font-family: "Roboto";
  font-style: normal;
  line-height: 130%;
  text-align: center;
  color: #333333;
  margin-bottom: 50px;
`;

export const FieldTitle = styled.div`
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 10px;
`;

export const FieldDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
`;

export const CurriculumTitle = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding: 0 20px;
  margin-bottom: 30px;
`;

export const ContainerMedia = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ContainerVideo = styled.div`
  max-width: 550px;
  width: 100%;
  height: 100%;

  iframe {
    border: 4px solid #f4f0eb;
    border-radius: 5px;

    /* less than 992px */
    min-height: 180px;

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      min-height: 306px;
    }
  }
`;

export const CurriculumDescription = styled.div`
  font-weight: normal;
  font-size: 14px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  line-height: 20px;
`;

export const ContainerAside = styled.div`
  padding: 40px 60px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  @media (max-width: 736px) {
    padding: 30px 15px;
  }

  @media (min-width: 736px) and (max-width: 1000px) {
    padding: 30px 15px;
  }
`;

export const ContainerAsideItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;

  ${ContainerButton} {
    margin-top: 10px;
  }
`;

export const TextButton = styled.span`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  text-transform: none;
  text-shadow: none;
`;

export const ButtonRow = styled.div`
  position: relative;
  width: 100%;
`;

export const Logo = styled.div`
  position: absolute;
  left: 10px;
  top: 0px;

  svg {
    height: 14px;
  }
`;

export const Groups = styled.div`
  padding: 0 20px;
`;

export const NotFound = styled.div`
  font-style: italic;
`;

export const Mobile = styled.div``;

export const FooterButton = styled.div`
  position: fixed;
  bottom: ${({ two }) => (two ? "60px" : "18px")};
  left: 0px;
  width: 100%;
  z-index: 1000;
  /*
  * {
    border-radius: 0px !important;
  }

  button div {
    padding: 30px 30px;
  } */

  @media screen and (max-width: 992px) {
    .curriculum {
      ${({ two }) =>
        two && `bottom: 60px !important; border-bottom: 2px white solid;`}
    }

    .white {
      border: none;
      box-shadow: none;
    }
  }
`;

export const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

export const BoxHeaderCol = styled.div`
  width: 100%;
  display: flex;
  padding-right: 20px;

  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 20px;

  :first-child {
    /* less than 576px */
    min-width: 242px;
    max-width: 312px;

    /* Medium devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      min-width: 250px;
      max-width: 320px;
    }

    /* Medium devices (landscape phones, 768px and up) */
    @media (min-width: 768px) {
      min-width: 320px;
      max-width: 320px;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      min-width: 250px;
      max-width: 250px;
    }
  }
  :nth-child(2) {
    min-width: 140px;
    max-width: 140px;

    /* less than 576px */
    display: none;

    /* Medium devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      display: flex;
    }
  }
  :nth-child(3) {
    width: 100%;
    padding-right: 0;

    /* less than 576px */
    display: none;

    /* Medium devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      display: flex;
    }
  }
`;

export const Text = styled.p`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  line-height: 17px;
  color: #333333;
  margin: 0;
  padding: 0;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h3`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.1px;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin: 0;
  padding: 0;
  max-width: 100%;
`;
