import styled from "styled-components";
import * as breakpoints from "@styles/breakpoints";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0px 15px;
  justify-content: flex-start;
  margin: 5rem auto 4.25rem;

  @media screen and (max-width: 1023px) and (min-width: 641px) {
    width: 100%;
    max-width: 685px;
    margin-top: 150px;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: 70%;
    margin-top: 20px;
  }

  @media screen and (min-width: ${breakpoints.laptopLarge}) {
    max-width: 1100px !important;
  }

  @media screen and (min-width: ${breakpoints.wideLarge}) {
    max-width: 80%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
`;

export const BoxContent = styled.div`
  width: 100%;
  justify-content: center;
`;
