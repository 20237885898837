import React from "react";
import { useSelector } from "react-redux";

import { ExpiredTokenPasswordReset } from "@components/expiredTokenPasswordReset";
import { PasswordResetForm } from "@components/passwordResetForm";

import * as Styled from "./styles";

function RecoveryPassword({ callbackSignIn }) {
  const errorRequest = useSelector((state) => state.auth.error);

  return (
    <Styled.Container>
      {!errorRequest.code_request_jobs ? (
        <PasswordResetForm />
      ) : (
        <ExpiredTokenPasswordReset />
      )}
    </Styled.Container>
  );
}

export default RecoveryPassword;
