export function applyTalentBase(company, user = null, showMessage = true) {
  return {
    type: "@company/APPLICANT_CANDIDATE_TALENT_BASE",
    payload: { company, user, showMessage }
  };
}

export function resetTalentBaseApplied() {
  return {
    type: "@company/APPLICANT_CANDIDATE_TALENT_BASE_RESET"
  };
}
