import styled from "styled-components";

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.span`
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #dc3545;
  margin-top: 5px;
`;
