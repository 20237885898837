import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { ptBR } from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DesktopDatePicker from "@material-ui/lab/DesktopDatePicker";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import resolve from "@utils/resolve";
import { Label, MessageError } from "@components/form";
import { ReactComponent as CheckSVG } from "@assets/svg/check.svg";

import * as Styled from "./styles";

const DatePicker = ({
  id,
  label,
  dataTestid,
  required,
  name,
  maxDate = null
}) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext();

  const value = useWatch({ control, name });
  const { message: error } = resolve(name, errors);

  useEffect(() => {
    // Quando faltar o valor da hora nas datas vindas da API
    if (typeof value === "string" && !value.includes(":")) {
      setValue(name, new Date(value.concat("T12:00:00")));
    }
  }, [value]);

  const handleCheckError = () => required && trigger(name);

  return (
    <Styled.Container>
      {label !== undefined && <Label required={required}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        data-testid={dataTestid}
        render={({ field }) => (
          <Styled.DatePickerWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                {...field}
                toolbarPlaceholder="dd/mm/aaaa"
                value={
                  field?.value
                    ? new Date(
                        typeof field.value === "string"
                          ? field.value.replace(" ", "T")
                          : field.value
                      )
                    : ""
                }
                id={id}
                name={name}
                data-testid={dataTestid}
                disableHighlightToday
                placeholder="dd/mm/aaaa"
                inputFormat="dd/MM/yyyy"
                maxDate={maxDate}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                onClose={handleCheckError}
                inputProps={{
                  placeholder: "dd/mm/aaaa"
                }}
                renderInput={(params) => (
                  <Styled.ContainerInput hasError={!!error}>
                    <Styled.DatePicker
                      {...params}
                      placeholder="dd/mm/aaaa"
                      onBlur={handleCheckError}
                    />
                    {!error && value && (
                      <Styled.RightIcon>
                        <CheckSVG />
                      </Styled.RightIcon>
                    )}
                  </Styled.ContainerInput>
                )}
              />
            </LocalizationProvider>
          </Styled.DatePickerWrapper>
        )}
      />
      {error && <MessageError message={error} />}
    </Styled.Container>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  noBorder: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  numberOfMonths: PropTypes.number,
  isOutsideRange: PropTypes.func,
  dataTestid: PropTypes.string,
  required: PropTypes.bool,
  openDirection: PropTypes.string
};

export default DatePicker;
