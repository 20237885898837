import React from "react";

import { ReactComponent as ChevronRight } from "@assets/svg/arrow-right.svg";
import * as Styled from "./style";

const Breadcrumb = ({ list }) => (
  <Styled.Breadcrumb>
    {list.map((item, index, key = String(index)) => (
      <React.Fragment key={`${key}_${index}`}>
        {index > 0 && <ChevronRight />}
        <Styled.BreadcrumbItem isAnchor={item.isAnchor} onClick={item.onClick}>
          {item.text}
        </Styled.BreadcrumbItem>
      </React.Fragment>
    ))}
  </Styled.Breadcrumb>
);

export default React.memo(Breadcrumb);
