export const themes = {
  link: {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    borderRadius: "0px"
  },
  dark: {
    color: "#fff",
    background: "#333",
    border: "1px solid #333333",
    boxShadow: "none"
  }
};
