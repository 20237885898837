import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import * as StyledGlobal from "@styles/global";

import * as cookie from "@utils/cookie";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";

import Header from "@components/header";
import Banner from "@components/banner";
import Button from "@components/button";
import Footer from "@components/footer";

import * as authActions from "@store/modules/auth/actions";
import * as companyActions from "@store/modules/company/actions";

import { useAuth } from "@utils/hooks/useAuth";
import { useDefaultProvider } from "@utils/hooks/useDefaultProvider";
import { useCompany } from "@utils/hooks/useCompany";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_COMPANY,
  DEFAULT_HOST_LOGIN
} from "@services/constants";
import StatusMessage from "../components/applicationMessageByStatus";
import Skeleton from "../components/skeleton";

import * as Styled from "../styles";

function PublicVacancyStatus() {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [recruiterError, setRecruiterError] = useState([]);
  const { token, user } = useAuth();
  const { slug } = useDefaultProvider();
  const { company, showTalentBank } = useCompany();

  const dispatch = useDispatch();

  useEffect(() => {
    const data = cookie.getJSON();
    const tab = data[window.name];

    if (tab) {
      const { applied, statusApply, error406 } = tab;
      let newType = "not_found";

      if (error406) {
        const splitMessage = error406.split(".");
        const message = splitMessage
          .filter((message) => message)
          .map((message) => `${message}.`);
        setRecruiterError(message);
      }

      setVacancy(applied?.vacancy || {});
      if (applied?.vacancy) {
        newType = statusApply || "error";
      }

      setType(newType);

      if (newType !== "error") {
        cookie.set(
          window.name,
          { ...tab, applyingVacancy: false },
          { expires: 1 }
        );
      }
    } else {
      setType("not_found");
    }

    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (!token && company?.slug) {
      goToVacanciesCompanyHandle();
    }
  }, [token, company]);

  const goToVacanciesCompanyHandle = useCallback(() => {
    const host = DEFAULT_HOST_COMPANY(slug);
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${host}/vacancies`);
  }, [slug]);

  const goToCurriculumHandle = useCallback(() => {
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
  }, []);

  const goToCandidateVacanciesHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate#vacancy`);
  }, []);

  const goToPerfilCandidateHandle = useCallback(() => {
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate`);
  }, []);

  const authenticateHandle = useCallback((destination = "") => {
    cookie.set("destination", destination);
    cookie.set(window.name, {}, { expires: 1 });
    cookie.redirect(DEFAULT_HOST_LOGIN);
  }, []);

  const applyTalentBaseOfCompany = useCallback(() => {
    const data = {
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name
      },
      applyingTalentBase: true
    };

    cookie.set(window.name, data, { expires: 1 });

    if (token) {
      dispatch(companyActions.applyTalentBase(company, user));
    } else {
      authenticateHandle();
    }
  }, [authenticateHandle, dispatch, company, token]);

  const renderSuccess = useMemo(
    () => (
      <StatusMessage
        title=" Sucesso!"
        iconClass="fas fa-check"
        text={[
          `Candidatura realizada com sucesso na vaga ${vacancy?.title} da ${company?.name}.`,
          "Você também receberá um e-mail com mais detalhes."
        ]}
        description={[
          "Agora você pode ",
          <strong>voltar para a página da empresa onde viu a vaga, </strong>,
          <br />,
          "ou ",
          <strong>ver o status da sua aplicação, </strong>,
          "para acompanhar este processo seletivo."
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          <Styled.ContainerButton>
            <Button
              text="Ver minhas aplicações"
              type="custom"
              backgroundColor="#333333"
              textColor="#ffffff"
              fontWeight="bold"
              borderColor="#333333"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              onClick={goToCandidateVacanciesHandle}
              isUppercase={false}
              medium
            />
          </Styled.ContainerButton>
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [goToCandidateVacanciesHandle, goToVacanciesCompanyHandle, vacancy, company]
  );

  const renderError = useMemo(
    () => (
      <StatusMessage
        title={[
          "Ops! Tivemos um problema ",
          <span role="img" aria-label="sad">
            😕
          </span>
        ]}
        text={[
          `Algo estranho aconteceu e não foi possível se aplicar na vaga ${vacancy?.title} da ${company?.name}.`,
          "Isso pode ser devido à alguma falha de conexão entre o sistema e a vaga."
        ]}
        description={[
          <strong>
            Clique no botão abaixo para editar seu currículo e tentar se
            candidatar novamente.
          </strong>,
          <br />,
          "Se este problema persistir, entre em contato com o nosso time de suporte."
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          <Styled.ContainerButton>
            <Button
              text="Editar meu currículo"
              type="custom"
              backgroundColor="#333333"
              textColor="#ffffff"
              fontWeight="bold"
              borderColor="#333333"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              onClick={goToCurriculumHandle}
              isUppercase={false}
              medium
            />
          </Styled.ContainerButton>
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [goToCurriculumHandle, goToVacanciesCompanyHandle, vacancy, company]
  );

  const renderFirstApplication = useMemo(
    () => (
      <>
        <StyledGlobal.ContainerWizard>
          <StyledGlobal.ProgressBar progress={100} />
          <StyledGlobal.WizardList>
            <StyledGlobal.WizardListItem>
              <StyledGlobal.WizardListItemText bold>
                Cadastro de currículo e dados pessoais
              </StyledGlobal.WizardListItemText>
              <StyledGlobal.WizardListItemDot completed />
            </StyledGlobal.WizardListItem>
            <StyledGlobal.WizardListItem>
              <StyledGlobal.WizardListItemText bold>
                Questionário comportamental Profiler
              </StyledGlobal.WizardListItemText>
              <StyledGlobal.WizardListItemDot completed />
            </StyledGlobal.WizardListItem>
          </StyledGlobal.WizardList>
        </StyledGlobal.ContainerWizard>
        {renderSuccess}
      </>
    ),
    [renderSuccess]
  );

  const renderHasApplied = useMemo(
    () => (
      <StatusMessage
        title="Tudo certo!"
        iconClass="fas fa-check"
        text={[
          `Você já estava aplicado na vaga ${vacancy?.title} da ${company?.name}.`
        ]}
        description={[
          "Agora você pode ",
          <strong>voltar para a tela inicial </strong>,
          "ou ",
          <strong>ver o status da sua aplicação, </strong>,
          <br />,
          "para acompanhar este processo seletivo."
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          <Styled.ContainerButton>
            <Button
              text="Ver minhas aplicações"
              type="custom"
              backgroundColor="#333333"
              textColor="#ffffff"
              fontWeight="bold"
              borderColor="#333333"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              onClick={goToCandidateVacanciesHandle}
              isUppercase={false}
              medium
            />
          </Styled.ContainerButton>
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [goToCandidateVacanciesHandle, goToVacanciesCompanyHandle, vacancy, company]
  );

  const renderNotFound = useMemo(
    () => (
      <StatusMessage
        title={[
          "Ops! Tivemos um problema ",
          <span role="img" aria-label="sad">
            😕
          </span>
        ]}
        text={
          recruiterError || [
            `Não foi possível localizar a vaga ${
              company?.name && `da ${company.name}`
            }.`,
            "Isso pode ser devido a não estar se aplicando a uma vaga no momento ou não ter efetuado o login."
          ]
        }
        secondaryText={[
          "Você pode voltar para a tela inicial para ver todas as vagas disponíveis ou acessar o perfil para acompanhar as suas candidaturas."
        ]}
      >
        <Styled.ContainerGroupButton>
          <Styled.ContainerButton>
            <Button
              text="Voltar para a tela inicial"
              type="custom"
              backgroundColor="#FFFFFF"
              textColor="#333333"
              fontWeight="bold"
              borderColor="#757373"
              borderWidth="1px"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
              isUppercase={false}
              medium
              onClick={goToVacanciesCompanyHandle}
            />
          </Styled.ContainerButton>
          {token && (
            <Styled.ContainerButton>
              <Button
                text="Ir para o perfil"
                type="custom"
                backgroundColor="#333333"
                textColor="#ffffff"
                fontWeight="bold"
                borderColor="#333333"
                borderWidth="1px"
                boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
                onClick={goToPerfilCandidateHandle}
                isUppercase={false}
                medium
              />
            </Styled.ContainerButton>
          )}
        </Styled.ContainerGroupButton>
      </StatusMessage>
    ),
    [
      goToPerfilCandidateHandle,
      goToVacanciesCompanyHandle,
      token,
      company,
      recruiterError
    ]
  );

  const renderStatus = useMemo(() => {
    const status = {
      success: renderSuccess,
      has_applied: renderHasApplied,
      first_application: renderFirstApplication,
      error: renderError,
      not_found: renderNotFound
    };

    return token ? status[type] : status.not_found;
  }, [
    renderError,
    renderFirstApplication,
    renderHasApplied,
    renderNotFound,
    renderSuccess,
    token,
    type
  ]);

  return (
    <StyledGlobal.Main>
      <Header
        logo={company?.logo || JobsLogo}
        urlLogo={DEFAULT_HOST_COMPANY(slug)}
        token={token}
        user={user}
        showTalentBank={showTalentBank}
        onApplyTalentBank={applyTalentBaseOfCompany}
        onAuthenticate={authenticateHandle}
        onPerfil={() => cookie.redirect(DEFAULT_HOST_CANDIDATE)}
        onLogout={() => dispatch(authActions.logout())}
      />

      <Banner
        urlDesktop={company?.banner}
        urlMobile={company?.banner}
        defaultBackground={company?.colorCompany}
      />

      <StyledGlobal.Container id="container_pdf">
        <StyledGlobal.BodyMain>
          <StyledGlobal.BodyContent>
            <Styled.ContainerBody>
              {loading ? <Skeleton /> : renderStatus}
            </Styled.ContainerBody>
          </StyledGlobal.BodyContent>
        </StyledGlobal.BodyMain>
      </StyledGlobal.Container>
      <Footer />
    </StyledGlobal.Main>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PublicVacancyStatus);
