import styled, { css } from "styled-components";
import { tablet } from "@styles/breakpoints";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 1.125rem 1.25rem;

  @media screen and (max-width: ${tablet}) {
    grid-template-columns: repeat(
      ${({ columns, responsive }) => (responsive ? 1 : columns)},
      1fr
    );
  }
`;

export const Option = styled.label`
  display: flex;
  align-items: flex-start;
  color: #000;
  font-size: 1rem;

  span {
    margin-left: 0.5rem;
  }

  input[type="radio"] {
    -moz-appearance: initial;
  }

  ${(props) =>
    props.disabled &&
    css`
      visibility: hidden;

      input:after {
        visibility: hidden;
      }

      @media screen and (max-width: ${tablet}) {
        display: none;
      }
    `}
`;

export const InputRadio = styled.input`
  visibility: hidden;

  &:after,
  &:checked:after {
    position: relative;
    top: 3px;
    left: 0;

    width: 1rem;
    height: 1rem;
    border-radius: 1rem;

    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #000;
  }

  &:after {
    background: ${({ theme }) => theme.colors.white};
  }

  &:checked:after {
    background: radial-gradient(
      black 0%,
      black 40%,
      transparent 50%,
      transparent
    );
  }
`;
