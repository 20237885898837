const colors = {
  background: "#CDCDCD",
  border: "#D2D2D2",

  primary: "#333333",
  secondary: "#CCCCCC",

  default: "rgba(0, 0, 0, 0.54)",

  white: "#FFFFFF",
  blue: "#1787E0",
  red: "#EF3D4E",
  green: "#177727",
  purple: "#583F99",
  yellow: "#FFBA00",

  shadow:
    "0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
};

const typography = {
  title: {
    weight: "bold",
    size: "2.25rem",
    sizeMobile: "1.5rem",
    family: "Roboto Condensed, arial, sans-serif"
  },
  subtitle: {
    weight: "bold",
    size: "1.5rem",
    sizeMobile: "1.25rem",
    family: "Roboto Condensed, arial, sans-serif"
  },
  label: {
    weight: "bold",
    size: "1.25rem",
    sizeMobile: "1.125rem",
    family: "Roboto Condensed, arial, sans-serif"
  },
  normal: {
    weight: "normal",
    size: "0.9375rem",
    sizeMobile: "0.875rem",
    family: "Roboto, arial, sans-serif"
  }
};

const breakpoints = {
  mobileSmall: "320px",
  mobileMedium: "375px",
  mobile: "450px",
  tablet: "768px",
  laptop: "1024px",
  laptopLarge: "1440px",
  wideLarge: "2560px"
};

export default { colors, typography, breakpoints };
