import React from "react";
import { Route } from "react-router-dom";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_CANDIDATE_ROUTES
} from "../../services/constants";

import PerfilCandidate from "../../pages/perfilCandidate";
import CurriculumCandidate from "../../pages/curriculumV2";
import ProfilerCandidate from "../../pages/profiler";
import ApplicationStatus from "../../pages/applicationStatus/hiddenVacancy";
import Pdf from "../../pages/pdf";

const CandidateRoutes = ({ history, slug, token, ...rest }) => {
  const slugVerify = () => {
    const HOST = window.location.href.replace("//www.", "//");
    const { pathname } = window.location;

    if (
      !slug &&
      token &&
      HOST.includes(DEFAULT_HOST_CANDIDATE) &&
      DEFAULT_CANDIDATE_ROUTES.includes(pathname)
    ) {
      // caso não possua slug da empresa, exista um token ativo e o usuário esteja acessando
      // o host do candidato e se o caminho atual no host está incluso nas rotas disponíveis do candidato
      // então registra as rotas disponíveis para o candidato.
      return (
        <>
          <Route
            {...rest}
            exact
            path="/"
            render={() => <PerfilCandidate history={history} />}
          />
          <Route
            {...rest}
            path="/curriculum"
            render={() => <CurriculumCandidate />}
          />
          <Route
            {...rest}
            path="/profiler"
            render={() => <ProfilerCandidate history={history} />}
          />

          <Route
            {...rest}
            path="/perfilCandidate"
            render={() => <PerfilCandidate history={history} />}
          />

          <Route path="/applicationStatus" component={ApplicationStatus} />

          <Route path="/pdf/:type" component={Pdf} />
        </>
      );
    }
    // caso não atenda a nenhuma das regras aqui irá carregar as próximas validações
    return null;
  };

  return slugVerify();
};

export default CandidateRoutes;
