import { ContainerSocial } from "@components/button/styles";
import * as yup from "yup";

const regexOnlyNumber = new RegExp(/^\d+$/);

const isCpfInvalid = (value) => {
  const searchCpfMask = new RegExp(/\d+\./);
  const SearchLetters = new RegExp(/([A-z])/);

  if (!SearchLetters.test(value) && searchCpfMask.test(value)) {
    return true;
  }

  return false;
};

const schema = yup
  .object()
  .shape({
    user: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .when({
        is: (val) => isCpfInvalid(val),
        then: yup
          .string()
          .test("teste", "O CPF deve conter somente números.", () => false)
      })
      .when({
        is: (val) => !regexOnlyNumber.test(val),
        then: yup.string().isValidEmail()
      })
      .when({
        is: (val) => regexOnlyNumber.test(val),
        then: yup.string().isValidCPF()
      }),
    password: yup.string().required("Campo de preenchimento obrigatório")
  })
  .required();

export default schema;
