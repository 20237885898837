import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 40px;
  padding-left: 15px;
`;

export const Title = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  margin-left: 1rem;
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-left: 30px;
`;

export const Level = styled.div`
  width: 100%;
  margin-left: auto;
  max-width: 120px;
`;

// progress bar
export const ContainerProgress = styled.div`
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  min-width: 100px;
`;

export const TitleProgressStep = styled.span`
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.1px;
  font-size: 10px;
  color: #333333;
  line-height: 12px;
  text-transform: uppercase;
`;

export const ContainerProgressStep = styled.div`
  background: transparent;
  width: 100%;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  height: 8px;
`;

export const CurrentProgressStep = styled.div`
  width: ${({ percentage }) => percentage && percentage}%;
  height: 100%;
  border-radius: 100px;
  background: #333333;
  box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0.25);
`;
