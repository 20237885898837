import API from "@services/api";

export const getVacanciesSearchByCompany = async (params) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/vacancy/search`, {
        params
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
