import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import Header from "@components/header";
import Button from "@components/button";
import Footer from "@components/footer";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_COMPANY,
  DEFAULT_HOST_CANDIDATE
} from "@services/constants";

import * as cookie from "@utils/cookie";

import { useAuth } from "@hooks/useAuth";
import { useDefaultProvider } from "@hooks/useDefaultProvider";
import { useCompany } from "@hooks/useCompany";

import * as authActions from "@store/modules/auth/actions";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import { ReactComponent as NotFoundBannerDesktop } from "@assets/svg/notFoundBannerDesktop.svg";
import { ReactComponent as NotFoundBannerMobile } from "@assets/svg/notFoundBannerMobile.svg";

import * as StyledGlobal from "@styles/global";
import * as Styled from "./styles";

function NotFound() {
  const dispatch = useDispatch();
  const { token, user } = useAuth();
  const { slug } = useDefaultProvider();
  const { company } = useCompany();

  const authenticateHandle = useCallback(
    (destination = "") => {
      const data = {
        company: {
          referenceId: company.referenceId,
          slug: company.slug,
          name: company.name
        },
        applyingTalentBase: false
      };

      cookie.set(window.name, data, { expires: 1 });
      cookie.set("destination", destination);
      cookie.redirect(DEFAULT_HOST_LOGIN);
    },
    [company]
  );

  const goBackHandle = useCallback(() => {
    const { origin } = cookie.getJSON();

    if (origin && origin !== window.location.href) {
      cookie.redirect(origin);
    } else if (slug) {
      cookie.redirect(DEFAULT_HOST_COMPANY(slug));
    } else {
      cookie.redirect(DEFAULT_HOST_CANDIDATE);
    }
  }, [slug]);

  const goToPerfilCandidateHandle = useCallback(() => {
    const data = slug
      ? {
          company: {
            referenceId: company.referenceId,
            slug: company.slug,
            name: company.name
          }
        }
      : {};

    cookie.set(window.name, data, { expires: 1 });
    cookie.redirect(DEFAULT_HOST_CANDIDATE);
  }, [slug, company]);

  return (
    <StyledGlobal.Main>
      <Header
        logo={company?.logo || JobsLogo}
        urlLogo={slug ? DEFAULT_HOST_COMPANY(slug) : DEFAULT_HOST_CANDIDATE}
        token={token}
        user={user}
        showTalentBank={false}
        onAuthenticate={authenticateHandle}
        onPerfil={goToPerfilCandidateHandle}
        onLogout={() => dispatch(authActions.logout())}
      />

      <Styled.Body>
        <Styled.Mobile>
          <Styled.Banner>
            <NotFoundBannerMobile />
          </Styled.Banner>
        </Styled.Mobile>

        <Styled.ContentMessage>
          <Styled.Title>404</Styled.Title>
          <Styled.SubTitle>Página não encontrada</Styled.SubTitle>
          <Styled.Description>
            A página que você está procurando não foi encontrada. O link pode
            estar errado ou a página não existe.
          </Styled.Description>
          {((slug && company?.slug) || !slug) && (
            <Styled.Button>
              <Button
                text={<b>Voltar</b>}
                type="custom"
                isUppercase={false}
                backgroundColor="#fff"
                textColor="#333333"
                borderColor="#8872B2"
                borderWidth="1px"
                boxShadow="rgba(47, 10, 119, 0.35)"
                medium
                onClick={goBackHandle}
              />
            </Styled.Button>
          )}
        </Styled.ContentMessage>

        <Styled.Desktop>
          <Styled.Banner>
            <NotFoundBannerDesktop />
          </Styled.Banner>
        </Styled.Desktop>
      </Styled.Body>
      <Footer />
    </StyledGlobal.Main>
  );
}

export default NotFound;
