import React, { useState, useMemo } from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";

import { Typography } from "@components";
import { Select, Input, Button } from "@components/form";
import { setDynamicOption } from "@store/modules/curriculum/repository";
import * as utilitiesActions from "@store/modules/utilities/actions";

import { MAX_LENGTH_INPUT_SKILL } from "@utils/formValidations/curriculumV2";
import SliderSkill from "../sliderSkill";
import { MARK_LEVEL_OTHER_SKILL } from "../../utils";

import { Content } from "../../styles";
import {
  AddNewSkill,
  NewDinamicSkill,
  Count,
  FieldSkill,
  Error,
  DetailFieldSkill
} from "./styles";

export const OptionalSkills = ({
  removeSkill,
  addSkillToInput,
  addSkillToSelect,
  dynamicSkills,
  skipSkills
}) => {
  const inputName = "optionalSkill";

  const dispatch = useDispatch();
  const {
    getValues,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();
  const skills = getValues("skills") || [];

  const defaultInputValue = { value: undefined, count: 0 };
  const [inputValue, setInputValue] = useState({
    value: getValues(inputName),
    count: getValues(inputName)?.length || 0
  });

  const hasOnlyWhitesSpaces = !/\S/.test(inputValue?.value);
  const isMaxLength = inputValue?.count > MAX_LENGTH_INPUT_SKILL - 1;

  const isTouchScreen = () => "ontouchstart" in document.documentElement;

  const handleAddSkillToSelect = () => {
    const skillValue = getValues("select-skill-value");
    const skill = dynamicSkills.find(({ value }) => skillValue === value);
    if (skill) addSkillToSelect(skill.label, { optional: true });
  };

  const addingSkillToSelect = () => {
    if (isTouchScreen()) {
      handleAddSkillToSelect();
      return;
    }
    setTimeout(() => {
      handleAddSkillToSelect();
    }, 500);
  };

  const checkAddedSkill = () => {
    const skillName = getValues(inputName)
      .replace(/\s+/g, " ")
      .trim()
      .toLocaleLowerCase();

    const inUserSkills = skills.find(
      ({ name }) => name.toLocaleLowerCase() === skillName
    );

    if (inUserSkills?.name) {
      return "Esta habilidade já foi inserida.";
    }

    const inDynamicSkills = dynamicSkills.find(
      ({ label }) => label.toLocaleLowerCase() === skillName
    );

    if (inDynamicSkills?.label) {
      return "Essa habilidade já está cadastrada.";
    }

    return "";
  };

  const addingSkillToInput = async () => {
    try {
      const hasAddedSkill = checkAddedSkill();
      if (hasAddedSkill) {
        setError(inputName, { type: "manual", message: hasAddedSkill });
        return;
      }

      dispatch(utilitiesActions.showLoading(true));

      const { data: newSkill } = await setDynamicOption({
        option: "skill",
        name: getValues(inputName)
      });

      if (!newSkill?.data?.id) throw new Error("");

      addSkillToInput({
        value: newSkill.data.id,
        name: newSkill.data.name,
        level: 1,
        optional: true
      });
    } catch (err) {
      notification.warn({
        message: "Atenção",
        description: "Não foi possível adicionar a habilidade! Tente novamente."
      });
    }

    dispatch(utilitiesActions.showLoading(false));
    setValue(inputName, "");
    setInputValue(defaultInputValue);
  };

  const handleInputValue = (value) => {
    setInputValue({ value, count: value.length });
    if (value.trim().length === 0) {
      setValue(inputName, undefined);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addingSkillToInput();
    }
  };

  const optionsSkills = useMemo(() => {
    if (dynamicSkills?.length && skills.length >= 0) {
      return dynamicSkills.slice(skipSkills).filter((dynamicSkill) => {
        const isSkill = skills.find(
          (skill) => Number(skill.value) === dynamicSkill.value
        );
        return !isSkill;
      });
    }

    return dynamicSkills || [];
  }, [skills, dynamicSkills, skipSkills]);

  return (
    <>
      <AddNewSkill>
        <div>
          <Typography>
            Selecione abaixo habilidades que deseja adicionar:
          </Typography>

          <Select
            name="select-skill-value"
            options={optionsSkills}
            placeholder="Selecione"
            onMenuClose={addingSkillToSelect}
          />
        </div>

        <NewDinamicSkill>
          <Typography>Ou escreva alguma habilidade não listada:</Typography>
          <FieldSkill
            disabled={
              (hasOnlyWhitesSpaces && inputValue?.value.length > 0) ||
              isMaxLength
            }
          >
            <Input
              name={inputName}
              placeholder="Digite a habilidade e clique em adicionar"
              onChange={(e) => handleInputValue(e.target.value)}
              maxLength={MAX_LENGTH_INPUT_SKILL}
              onKeyPress={handleEnterPress}
            />
          </FieldSkill>

          <DetailFieldSkill>
            <Error>
              {isMaxLength && "Você atingiu o limite de caracteres"}
            </Error>
            <Count>{`${inputValue?.count}/${MAX_LENGTH_INPUT_SKILL}`}</Count>
          </DetailFieldSkill>

          <Button
            disabled={
              hasOnlyWhitesSpaces || !inputValue?.value || errors?.optionalSkill
            }
            style={{ marginTop: errors?.optionalSkill ? "0.375rem" : "0" }}
            onClick={addingSkillToInput}
          >
            Adicionar
          </Button>
        </NewDinamicSkill>
      </AddNewSkill>

      <Content>
        {skills.map(
          (skill, index) =>
            skill.optional && (
              <SliderSkill
                key={skill.value}
                skill={skill}
                name={`skills.${index}.level`}
                marks={MARK_LEVEL_OTHER_SKILL}
                onRemove={() => removeSkill(skill)}
              />
            )
        )}
      </Content>
    </>
  );
};
