import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  border-radius: 0.5rem;
  background-color: #e3e3e3;
  padding: 1rem 1.5rem;

  min-height: 120px;
  animation: fadeIn 1.2s linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RemoveSkill = styled.div`
  display: flex;
  align-items: center;

  i,
  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.red};
  }

  p {
    margin: 2px 0 0 4px;
    font-weight: bold;
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
