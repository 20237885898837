/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const hasEmptySpaces = (value) => /^.+\s.+$/g.test(value);

/* eslint-disable eqeqeq */
const maskCpf = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

const maskTel = (v) => {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
};

const maskCel = (v) => {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
};

const validCPF = (cpf) => {
  let numeros;
  let digitos;
  let soma;
  let i;
  let resultado;
  let digitos_iguais;
  digitos_iguais = 1;
  if (cpf.length < 11) return false;
  for (i = 0; i < cpf.length - 1; i++)
    if (cpf.charAt(i) != cpf.charAt(i + 1)) {
      digitos_iguais = 0;
      break;
    }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9);
    digitos = cpf.substring(9);
    soma = 0;
    for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    numeros = cpf.substring(0, 10);
    soma = 0;
    for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  }
  return false;
};

const equalTo = (ref, msg) =>
  yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path
    },
    test(value) {
      return value === this.resolve(ref);
    }
  });

const checkValidadeCPF = (number) => {
  if (!number) return false;
  number = number.replace(/[\(\)\.\s-]+/g, "");
  let sum;
  let rest;
  let i;
  sum = 0;
  if (number == "00000000000") return false;
  for (i = 1; i <= 9; i++)
    sum += parseInt(number.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(number.substring(9, 10))) return false;
  sum = 0;
  for (i = 1; i <= 10; i++)
    sum += parseInt(number.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(number.substring(10, 11))) return false;
  return true;
};

const validateCPF = (msg) =>
  yup
    .mixed()
    .test(
      "count-characteres",
      "O CPF deve ter 11 dígitos.",
      (value) => !value || value.toString().length === 11
    )
    .test({
      name: "validateCPF",
      exclusive: false,
      message: msg || "Invalid CPF",
      test: checkValidadeCPF
    });

function isValidCPF(message = "CPF inválido") {
  return this.test(
    "count-characteres",
    "O CPF deve ter 11 dígitos.",
    (value) => !value || value.toString().length >= 11
  ).test("is-required", message, checkValidadeCPF);
}

const validatePhone = (type, msg = "", isRequired = false) =>
  yup.mixed().test("isValidPhone", msg, (value) => {
    if (value === undefined || value === null) return !isRequired;
    const phone = value.replace(/[^0-9]/g, "");
    if (type) {
      return (
        (type.toLowerCase() === "phone" && phone.length === 10) ||
        (type.toLowerCase() === "cellphone" && phone.length === 11)
      );
    }
    return phone.length === 10 || phone.length === 11;
  });

const isValidLink = (value, validateData = []) => {
  const validations = validateData.map((item) => value.includes(item));
  return !!validations.filter((item) => item).length;
};

function isValidVideo(value = "", types = ["youtube", "vimeo"]) {
  if (!!value === false) return true;
  if (hasEmptySpaces(value)) return false;
  const validations = types.map((type) => {
    switch (type) {
      case "youtube":
        return isValidLink(value, ["youtube.com/", "youtu.be/"]);
      case "vimeo":
        return isValidLink(value, ["vimeo.com/"]);
      default:
        return false;
    }
  });

  return !!validations.filter((item) => item).length;
}

// use after isRequired
function isValidEmail(message = "E-mail inválido. Ex: nome@email.com") {
  return this.email(message).test("is-required", message, function (value) {
    if (!value) return this;

    const { path, createError } = this;
    const maskEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return !maskEmail.test(value) ? createError({ path, message }) : this;
  });
}

export function isValidUrl(message = "") {
  return yup.mixed().test("isValidUrl", message, (value) => {
    if (value === undefined) return false;

    return value.matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      message
    );
  });
}

const hasSpace = (message = "Formato inválido") =>
  yup.string().test("is-has-space", message, (value) => {
    if (!value) return true;
    if (!value.trimEnd().includes(" ")) return true;

    return false;
  });

// before another validations
function isRequired(field, requiredFields = [], message = "Campo obrigatório") {
  return yup.string().test("is-required", message, function (value) {
    const { path, createError } = this;

    return requiredFields.includes(field) && !value
      ? createError({ path, message })
      : this;
  });
}

const isRequiredDate = (
  field,
  requiredFields = [],
  message = "Campo obrigatório"
) =>
  yup.date().test("is-required", message, (value) => {
    if (requiredFields.includes(field) && !!value === false) {
      return false;
    }

    return true;
  });

const isRequiredArray = (
  field,
  requiredFields = [],
  message = "Campo obrigatório"
) =>
  yup.array().test("is-required", message, (value) => {
    if (requiredFields.includes(field) && !!value?.length === false) {
      return false;
    }

    return true;
  });

const hasItemsArray = (isRequired = false, items = [], message = "") =>
  yup.array().test("is-required", message, (values) => {
    if (!isRequired) return true;

    const existsItem = (item) =>
      items.find(
        (element) =>
          element.id.toString() === item.value.toString() ||
          element.name.toLowerCase() === item.name.toLowerCase()
      );

    const foundIds = values.filter(existsItem);
    return foundIds.length === items.length;
  });

function hasNumber(message = "Formato inválido") {
  return this.test("is-required", message, function (value) {
    const { path, createError } = this;

    return /\d/g.test(value) ? createError({ path, message }) : this;
  });
}

function isNotZero(message = "O número deve ser diferente de zero.") {
  return this.test("is-required", message, function (value) {
    if (!value) return this;

    const { path, createError } = this;

    return Number(value) === 0 ? createError({ path, message }) : this;
  });
}

yup.addMethod(yup.string, "equalTo", equalTo);
yup.addMethod(yup.string, "validateCPF", validateCPF);
yup.addMethod(yup.string, "validatePhone", validatePhone);
yup.addMethod(yup.string, "isValidVideo", isValidVideo);
yup.addMethod(yup.string, "isValidUrl", isValidUrl);
yup.addMethod(yup.string, "isValidEmail", isValidEmail);
yup.addMethod(yup.string, "isValidCPF", isValidCPF);
yup.addMethod(yup.mixed, "hasSpace", hasSpace);
yup.addMethod(yup.mixed, "isRequired", isRequired);
yup.addMethod(yup.date, "isRequired", isRequiredDate);
yup.addMethod(yup.array, "isRequired", isRequiredArray);
yup.addMethod(yup.array, "hasItems", hasItemsArray);
yup.addMethod(yup.mixed, "hasNumber", hasNumber);
yup.addMethod(yup.string, "isNotZero", isNotZero);

export {
  maskCpf,
  maskTel,
  maskCel,
  validCPF,
  equalTo,
  validateCPF,
  validatePhone,
  isValidVideo
};
