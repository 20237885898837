import React from "react";

import Icon from "@components/icon";

import {
  TooltipContainer,
  TooltipText,
  TooltipList,
  TooltipItem
} from "./styles";

const TooltipLinkedin = (
  <TooltipContainer>
    <TooltipText marginBottom="15px">
      Para encontrar a URL do seu perfil público:
    </TooltipText>

    <TooltipList>
      <TooltipItem>
        <TooltipText>
          Toque na sua foto de perfil <b>&gt; Visualizar perfil.</b>
        </TooltipText>
      </TooltipItem>
      <TooltipItem>
        <TooltipText>
          Navegue para baixo até a seção <b>Contato.</b>
        </TooltipText>
      </TooltipItem>
      <TooltipItem>
        <TooltipText>
          Em <b>Seu perfil,</b> localize a URL do seu perfil público.
        </TooltipText>
      </TooltipItem>
      <TooltipItem>
        <TooltipText>
          Trata-se de um endereço que começa com <b>www.linkedin.com/in.</b>
        </TooltipText>
      </TooltipItem>
      <TooltipItem>
        <TooltipText>Copie e cole no campo abaixo.</TooltipText>
      </TooltipItem>
    </TooltipList>
  </TooltipContainer>
);

const TooltipVideoApresentation = (
  <TooltipContainer>
    <TooltipList>
      <TooltipItem>
        <TooltipText>Comece a assistir o vídeo no youtube.com.</TooltipText>
      </TooltipItem>
      <TooltipItem>
        <TooltipText>
          Abaixo do conteúdo, clique em{" "}
          <b>
            Compartilhar{" "}
            <Icon className="fas fa-share" size="0.8rem" color="#333333" />
          </b>
          .
        </TooltipText>
      </TooltipItem>
      <TooltipItem>
        <TooltipText>
          Clique no botão <b>Copiar</b> para copiar o link do vídeo e cole no
          campo abaixo.
        </TooltipText>
      </TooltipItem>
    </TooltipList>
  </TooltipContainer>
);

const TooltipEmail = (
  <TooltipContainer>
    <TooltipText>
      Caso você queira trocar o seu e-mail de login, acesse o menu Preferências.
    </TooltipText>
  </TooltipContainer>
);

const TooltipCpf = (
  <TooltipContainer>
    <TooltipText>
      O CPF não pode ser alterado. Dúvidas, entre em contato com o suporte.
    </TooltipText>
  </TooltipContainer>
);

export { TooltipLinkedin, TooltipVideoApresentation, TooltipEmail, TooltipCpf };
