import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";

import useFetch from "@hooks/useFetch";
import useApplyVacancy from "@hooks/useApplyVacancy";

import { Typography } from "@components";
import scrollToElementById from "@utils/scrollToElement";
import { Label, Select, RadioGroup } from "@components/form";
import { Cardboard } from "@pages/curriculumV2/components/cardboard";

import { LanguageOptions, LanguageSelect } from "./styles";
import { RADIOS_OPTIONS, RADIOS_OPTIONS_MOBILE } from "./utils";

function Languages() {
  const [radioOptions, setRadioOptions] = useState(
    window?.innerWidth < 750 ? RADIOS_OPTIONS_MOBILE : RADIOS_OPTIONS
  );
  const { control, getValues, setValue } = useFormContext();
  const { isApplyVacancy } = useApplyVacancy();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "languages"
  });

  const requiredLanguages = useMemo(
    () => fields.filter((language) => language.required),
    [fields]
  );

  const otherLanguages = useMemo(
    () => fields.filter((language) => !language.required),
    [fields]
  );

  // Lista dos nomes dos idiomas que devem ser ocultos do select
  const hiddenLanguages = useMemo(
    () =>
      fields.reduce((acc, item) => {
        acc.push(item.name);
        return acc;
      }, []),
    [fields]
  );

  const { data: dynamicOptions } = useFetch(() => "dynamic-options/languages");

  const selectLanguages = useMemo(() => {
    if (!dynamicOptions) return [];

    return dynamicOptions.data.reduce((acc, option) => {
      if (!hiddenLanguages.includes(option.name)) {
        acc.push({ label: option.name, value: option.id });
      }
      return acc;
    }, []);
  }, [dynamicOptions, hiddenLanguages]);

  const handleAddLanguage = () => {
    setTimeout(() => {
      const language = getValues("language");
      const option = selectLanguages.find((op) => language === op.value);
      if (option && !hiddenLanguages.includes(option.label)) {
        append({ name: option.label, value: option.value, level: "1" });

        const elementId = "language-".concat(fields.length);
        setTimeout(() => {
          scrollToElementById(elementId, -150);
          setValue(`languages.${fields.length}.level`, "1");
        }, 200);
        setValue("language", "");
      }
    }, 200);
  };

  const handleRemoveLanguage = (languageIndex) => {
    remove(languageIndex + requiredLanguages.length);
  };

  useEffect(() => {
    const handleEventResize = () => {
      if (window?.innerWidth <= 768) {
        setRadioOptions(RADIOS_OPTIONS_MOBILE);
      } else {
        setRadioOptions(RADIOS_OPTIONS);
      }
    };

    window.addEventListener("resize", handleEventResize);

    return () => window.removeEventListener("resize", handleEventResize);
  }, []);

  return (
    <>
      <Typography
        type="title"
        style={{ marginBottom: "1rem", marginTop: "2.5rem" }}
      >
        Idiomas
      </Typography>

      {isApplyVacancy && requiredLanguages.length !== 0 && (
        <>
          <Label required>Idiomas exigidos</Label>
          <Typography style={{ marginBottom: "1rem" }}>
            Marque o seu nível de fluência nos idiomas exigidos para a vaga:
          </Typography>

          {requiredLanguages.map((language, index) => (
            <LanguageOptions key={language.id} style={{ marginBottom: "2rem" }}>
              <Typography type="label">
                Qual a sua fluência em {language.name.toLocaleLowerCase()}?
              </Typography>
              <RadioGroup
                options={radioOptions}
                name={`languages.${index}.level`}
              />
            </LanguageOptions>
          ))}

          <Typography type="label" style={{ margin: "0.5rem 0 1.5rem" }}>
            Outros idiomas
          </Typography>
        </>
      )}

      {otherLanguages.map((language, index) => (
        <Cardboard
          key={language.id}
          handleClose={() => handleRemoveLanguage(index)}
          elementId={"language-".concat(index + requiredLanguages.length)}
        >
          <LanguageOptions>
            <Typography type="label">
              Qual a sua fluência em {language?.name}?
            </Typography>
            <RadioGroup
              options={radioOptions.slice(2, 8)}
              name={`languages.${index + requiredLanguages.length}.level`}
            />
          </LanguageOptions>
        </Cardboard>
      ))}

      <LanguageSelect>
        <Typography>Selecione abaixo os idiomas que você fala:</Typography>

        <Select
          name="language"
          maxHeight="200px"
          placeholder="Selecione"
          options={selectLanguages}
          onMenuClose={handleAddLanguage}
        />
      </LanguageSelect>
    </>
  );
}

export default React.memo(Languages);
