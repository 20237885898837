import React, { useState, useEffect } from "react";

import { ReactComponent as LeftArrow } from "@assets/svg/left-arrow.svg";
import { ReactComponent as RightArrow } from "@assets/svg/right-arrow.svg";

import SanitizeHTML from "../sanitize";
import * as Styled from "./styles";
import Image from "../image";

const Carousel = ({
  list = [],
  initialPosition = 0,
  arrows = true,
  caption = false,
  dots = false,
  dotsType = "default",
  autoPlay = false,
  autoplaySpeed = 3000,
  imgType = "",
  imgSize = "",
  color = "#333333",
  onClick = () => {},
  maxWidth = "",
  cursor = "default"
}) => {
  const [position, setPosition] = useState(initialPosition);

  const timer = null;
  let newPosition;

  useEffect(() => {
    let timer = null;
    if (autoPlay) {
      const next = (position + 1) % list.length;
      timer = setTimeout(() => setPosition(next), autoplaySpeed);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const goToPrevSlide = (e) => {
    newPosition = position;
    newPosition = newPosition - 1 < 0 ? list.length - 1 : newPosition - 1;

    setPosition(newPosition);
    clearTimeout(timer);
  };

  const goToNextSlide = () => {
    newPosition = position;
    newPosition = newPosition + 1 > list.length - 1 ? 0 : newPosition + 1;

    setPosition(newPosition);
    clearTimeout(timer);
  };

  const goToSlide = (index) => {
    setPosition(index);
    clearTimeout(timer);
  };

  const indicators = (type) => {
    if (type === "solides") {
      return (
        <Styled.CarouselIndicatorsSolides>
          {list.map((item, index) => (
            <Styled.CarouselIndicatorsItemSolides
              key={index}
              active={position === index}
              onClick={() => goToSlide(index)}
            />
          ))}
        </Styled.CarouselIndicatorsSolides>
      );
    }
    return (
      <Styled.CarouselIndicators>
        {list.map((item, index) => (
          <Styled.CarouselIndicatorsItem
            key={index}
            active={position === index}
            onClick={() => goToSlide(index)}
          />
        ))}
      </Styled.CarouselIndicators>
    );
  };

  const arrowsContainer = () => (
    <>
      <Styled.Arrows left>
        <Styled.ArrowsIcon left color={color} onClick={() => goToPrevSlide()}>
          <LeftArrow />
        </Styled.ArrowsIcon>
      </Styled.Arrows>
      <Styled.Arrows right>
        <Styled.ArrowsIcon right color={color} onClick={() => goToNextSlide()}>
          <RightArrow />
        </Styled.ArrowsIcon>
      </Styled.Arrows>
    </>
  );

  const captionContainer = () => {
    const data = list[position];
    if (!data) return;

    return (
      <Styled.CarouselCaptionContainer>
        {!!data.text && (
          <Styled.CarouselCaptionContent>
            <SanitizeHTML html={data.text} />
          </Styled.CarouselCaptionContent>
        )}
        {!!data.author && (
          <Styled.CarouselCaptionAuthor>
            - {data.author}
            {!!data.source && (
              <>
                ,{" "}
                <Styled.CarouselCaptionSource>
                  {data.source}
                </Styled.CarouselCaptionSource>
              </>
            )}
          </Styled.CarouselCaptionAuthor>
        )}
      </Styled.CarouselCaptionContainer>
    );
  };

  const slides = () => {
    const data = list[position];
    if (!data) return;

    return (
      <>
        <Styled.CarouselImageContainer>
          <Styled.ImageContainer maxWidth={maxWidth}>
            <Image
              url={data.photo}
              type={imgType}
              border
              imgSize={imgSize}
              maxWidth={maxWidth}
              cursor={cursor}
              onClick={() => onClick(data.id)}
            />
            {dots && list.length > 1 && indicators(dotsType)}
          </Styled.ImageContainer>
          {arrows && list.length > 1 && arrowsContainer()}
        </Styled.CarouselImageContainer>
        {caption && captionContainer()}
      </>
    );
  };

  return <Styled.CarouselMain>{slides()}</Styled.CarouselMain>;
};

export default Carousel;
