import React, {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect
} from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useAuth } from "@hooks/useAuth";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { schemaTranslate } from "@utils/formValidations/curriculumV2/translate";
import { formatArrayToText } from "@utils/formatArrayToText";
import { getDisabilities } from "@utils/getDisabilities";

import message from "@components/message";

const CurriculumContext = createContext({});

function CurriculumProvider({ children }) {
  const [form, registerForm] = useState({});
  const [isDocumentsOptional, setIsDocumentsOptional] = useState(false);
  const [userIsReady, setUserIsReady] = useState(false);
  const { user, setUserData } = useAuth();
  const { vacancy, isApplyVacancy } = useApplyVacancy();

  const formMethods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    ...form?.schema
  });

  const getSocialFields = ({ contacts }) => {
    const socialMedias = ["facebook", "instagram", "twitter"].reduce(
      (accumulator, socialMedia) => {
        if (!contacts) {
          return { ...accumulator };
        }
        let username = contacts[socialMedia] || "";
        if (username) {
          const fields = username.split("/");

          const lastField = fields[fields.length - 1];
          const lastSecondField = fields[fields.length - 2];
          const socialNickname =
            lastField || (!lastField && lastSecondField?.includes(".com"))
              ? lastField
              : fields[fields.length - 2];

          if (!socialNickname.includes(".com")) {
            username = socialNickname;
          }
        }

        return { ...accumulator, [socialMedia]: username };
      },
      {}
    );

    return { ...socialMedias, linkedin: contacts?.linkedin };
  };

  const filterDataVacancyUser = (initialData) =>
    initialData.reduce((accumulator, item) => {
      // Irá exister a prop id quando os dados vem da vaga
      const isDataOptional = !!item?.value;
      const value = isDataOptional ? item.value : item.id.toString();
      const itemIndex = accumulator.findIndex(
        (accumulatorItem) =>
          accumulatorItem.value === value ||
          accumulatorItem.name.toLowerCase() === item.name.toLowerCase()
      );

      if (itemIndex === -1) {
        accumulator.push({
          value,
          name: item.name,
          level: isDataOptional && item.level ? item.level : "0",
          required: !isDataOptional
        });
      } else if (isDataOptional) {
        // Quando é um idioma do usuário e atualiza o level
        accumulator[itemIndex].level = item.level || "0";
      }

      return accumulator;
    }, []);

  const getSkills = useCallback(() => {
    if (!vacancy.skills || vacancy.skills.length === 0) return user.skills;

    if (vacancy?.skills?.length) {
      const initialSkills = vacancy.skills.concat(user.skills);
      return filterDataVacancyUser(initialSkills);
    }
    return [];
  }, [user, vacancy]);

  const getPhoneNumbers = ({ contacts }) => {
    const phone = contacts?.phone || "";
    const whatsapp = contacts?.whatsapp || "";
    return {
      phone: phone.slice(-11),
      phoneDDI: phone.slice(0, -11) || "55",
      whatsapp: whatsapp.slice(-11),
      whatsappDDI: whatsapp.slice(0, -11) || "55",
      isWhatsapp:
        whatsapp?.replace(/\D/g, "") === phone?.replace(/\D/g, "")
          ? "yes"
          : "no"
    };
  };

  const getLanguages = useCallback(() => {
    if (!vacancy.languages || vacancy.languages.length === 0) {
      return user.languages;
    }
    const initialLanguages = vacancy.languages.concat(user.languages);
    return filterDataVacancyUser(initialLanguages);
  }, [user, vacancy]);

  const getDisabledPersonDescription = useCallback(
    ({ isDisabledPerson, disabledPersonDescription }) => {
      if (!isDisabledPerson) return [];

      let disabledPersonDescriptionValue = [];
      const options = getDisabilities();

      if (
        !!disabledPersonDescription &&
        Array.isArray(disabledPersonDescription)
      ) {
        for (const disability of disabledPersonDescription) {
          const value = options.find((option) => option.value === disability);

          if (!value) {
            disabledPersonDescriptionValue = null;
            break;
          } else {
            disabledPersonDescriptionValue.push(value);
          }
        }
      }

      return disabledPersonDescriptionValue;
    },
    [user]
  );

  const formattedValueCurrent = (itens) => {
    if (!itens) return [];
    return itens.map((item) => ({
      ...item,
      current: item.current ? "on" : "off"
    }));
  };

  const userData = useMemo(() => {
    const checkVacancy = !isApplyVacancy || (isApplyVacancy && vacancy?.id);

    if (checkVacancy && user?.id) {
      const data = {
        ...user,
        skills: getSkills(),
        contacts: {
          ...user.contacts,
          ...getPhoneNumbers(user),
          ...getSocialFields(user)
        },
        experiences: formattedValueCurrent(user?.experiences),
        courses: formattedValueCurrent(user?.courses),
        languages: getLanguages(),
        disabledPersonDescription: getDisabledPersonDescription(user),
        address: {
          ...user.address,
          country: user.address?.country || "Brasil"
        },
        documents: {
          ...user.documents,
          rg: user.documents.rg?.replace(/\D/g, "")
        }
      };

      if (
        isApplyVacancy &&
        (vacancy?.skills?.length || vacancy?.languages?.length)
      ) {
        const existsItem = (type, item) =>
          Array.isArray(vacancy[type]) &&
          vacancy[type].find(
            (element) =>
              element.id.toString() === item.value.toString() ||
              element.name.toLowerCase() === item.name.toLowerCase()
          );

        const isRequiredField = (type) =>
          Array.isArray(user[type]) &&
          user[type].filter((element) => existsItem(type, element)).length !==
            vacancy[type]?.length;

        if (!data.defaultRequireds) {
          data.defaultRequireds = {
            skills: isRequiredField("skills"),
            languages: isRequiredField("languages")
          };
        }
      } else {
        data.defaultRequireds = {};
      }

      return data;
    }
    return {};
  }, [user, isApplyVacancy, vacancy]);

  useEffect(() => {
    if (userData.id && !userIsReady) {
      setUserIsReady(true);
      handleResetForm();
    }
  }, [userData, userIsReady]);

  useEffect(() => {
    if (formMethods?.formState?.isSubmitted) {
      formMethods.reset(formMethods.control._formValues);
      setUserData(formMethods.control._formValues);
    }
  }, [formMethods]);

  const translateErrorFields = (errors = []) => {
    const fieldsWithError = [];

    if (errors) {
      const fields = [
        "contacts",
        "documents",
        "courses",
        "experiences",
        "languages",
        "skills",
        "address"
      ];

      Object.entries(errors).forEach(([key]) => {
        if (fields.includes(key)) {
          Object.keys(errors[key]).forEach((error) => {
            if (Array.isArray(errors[key])) {
              errors[key].forEach((groupErrors) => {
                Object.keys(groupErrors).forEach((field) => {
                  if (groupErrors[field]?.type !== "max")
                    fieldsWithError.push(field);
                });
              });
            } else {
              fieldsWithError.push(error);
            }
          });
        } else if (errors[key]?.type !== "max") {
          fieldsWithError.push(key);
        }
      });
    }

    const translate = fieldsWithError.map((field) => schemaTranslate[field]);
    return [...new Set(translate.filter(Boolean))];
  };

  const buildErrorDescription = (fields) => {
    let description = "";
    if (fields.length > 5)
      description =
        "Existem campos obrigatórios que não foram preenchidos. Confira os campos marcados em vermelho.";
    else if (fields.length === 0)
      description = "Confira o(s) campo(s) marcado(s) em vermelho.";
    else if (fields.length === 1)
      description = (
        <>
          O campo obrigatório <b>{fields[0]}</b> não foi preenchido. Confira o{" "}
          campo marcado em vermelho.
        </>
      );
    else
      description = (
        <>
          Os campos obrigatórios <b>{formatArrayToText(fields)}</b> não foram{" "}
          preenchidos. Confira os campos marcados em vermelho.
        </>
      );

    return description;
  };

  const onErrorSubmit = useCallback((errors) => {
    const fieldsWithError = translateErrorFields(errors) || [];

    window.scroll(0, 0);
    message.error({
      title: "Atenção",
      content: buildErrorDescription(fieldsWithError)
    });
  }, []);

  const handleSubmit = useMemo(
    () =>
      formMethods.handleSubmit(
        form?.handleSubmit?.onSubmit,
        form?.handleSubmit?.onError || onErrorSubmit
      ),
    [formMethods, form?.handleSubmit, onErrorSubmit]
  );

  const handleResetForm = () => formMethods.reset(userData);

  const value = useMemo(
    () => ({
      registerForm,
      handleSubmit,
      formMethods,
      handleResetForm,
      isDocumentsOptional,
      setIsDocumentsOptional,
      userData,
      setUserIsReady
    }),
    [
      formMethods,
      handleSubmit,
      handleResetForm,
      isDocumentsOptional,
      setIsDocumentsOptional,
      userData,
      setUserIsReady
    ]
  );

  return (
    <CurriculumContext.Provider value={value}>
      <FormProvider {...formMethods}>{children}</FormProvider>
    </CurriculumContext.Provider>
  );
}

export { CurriculumContext, CurriculumProvider };
