import styled, { css } from "styled-components";
import * as breakpoints from "@styles/breakpoints";
import { themes } from "./themes";

const getTheme = ({ theme, disabled }) => css`
  background: ${themes[theme]?.background || "#fff"};
  color: ${!disabled ? themes[theme]?.color || "#333" : "#33333340"};
  border: ${!disabled
    ? themes[theme]?.border || "none"
    : "1px solid #33333340"};
  box-shadow: ${!disabled
    ? themes[theme]?.boxShadow ||
      "0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
    : "none"};
  border-radius: ${themes[theme]?.borderRadius || "100px"};
`;
export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "auto")};
  box-sizing: border-box;

  font-weight: bold;
  font-size: 1rem;
  line-height: 1.188rem;
  font-family: "Roboto Condensed", arial, sans-serif;

  width: 100%;
  max-width: ${(props) => props.maxWidth || "100%"};
  height: ${(props) => props.height || "2.5rem"};

  ${getTheme}

  i {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: ${breakpoints.mobileLarge}) {
    font-size: 0.875rem;
    line-height: 1rem;
    max-width: 100%;
  }
`;
