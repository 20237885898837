import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 50px;

  @media screen and (max-width: 625px) {
    padding: 0px 10px;
  }
`;

export const Title = styled.h2`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #333333;
  margin-bottom: 42px;

  @media screen and (max-width: 625px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const Description = styled.div`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #333333;

  @media screen and (max-width: 625px) {
    font-size: 14px;
  }
`;

export const FooterButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
  padding: 30px 50px;

  @media screen and (max-width: 625px) {
    padding: 0px 15px 20px 15px;
    grid-gap: 3px;
  }
`;
