import React from "react";

import Button from "@components/button";

import * as Styled from "./styles";

export const Body = () => (
  <Styled.ContainerBody>
    <Styled.Title>Seu profiler está desatualizado</Styled.Title>
    <Styled.Description>
      Para que o profiler esteja sempre coerente com o seu momento profissional
      atual, nós recomendamos que ele seja{" "}
      <strong>refeito a cada 6 meses.</strong>
    </Styled.Description>
  </Styled.ContainerBody>
);
export const Footer = ({ onClose }) => (
  <Styled.ContainerFooter>
    <Button
      type="custom"
      backgroundColor="#583F99"
      textColor="#FFFFFF"
      borderColor="#583F99"
      borderWidth="0px"
      width="45%"
      boxShadow="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
      medium
      isUppercase={false}
      onClick={onClose}
    >
      <Styled.Text>Atualizar o profiler</Styled.Text>
    </Button>
  </Styled.ContainerFooter>
);
