import utilities from "./utilities/reducers";
import company from "./company/reducers";
import vacancies from "./vacancies/reducers";
import auth from "./auth/reducers";
import curriculum from "./curriculum/reducers";
import profiler from "./profiler/reducers";
import perfilCandidate from "./perfilCandidate/reducers";
import vacancy from "./vacancy/reducers";

export default {
  auth,
  company,
  vacancies,
  utilities,
  curriculum,
  profiler,
  perfilCandidate,
  vacancy
};
