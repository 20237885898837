import React, { useState, useRef, useMemo } from "react";

import Portal from "../portal";
import Popover from "../popover";

const Tooltip = ({ close = false, position = "top", content, children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const parent = useRef(null);

  let timeout = null;

  const onMouseEnter = (e) => {
    if (timeout) clearTimeout(timeout);
    setTooltipVisible(true);
  };

  const onMouseLeave = () => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      setTooltipVisible(false);
    }, 500);
  };

  const onClose = () => {
    setTooltipVisible(false);
    if (timeout) clearTimeout(timeout);
  };

  const renderTooltip = useMemo(() => {
    if (!tooltipVisible) return null;

    return (
      <Portal>
        <Popover
          active
          arrow="center"
          align="center"
          parentEl={parent}
          position={position}
          close={close}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClose={onClose}
        >
          {content}
        </Popover>
      </Portal>
    );
  }, [tooltipVisible, timeout, parent.current]);

  return (
    <span
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={parent}
      key="parent"
    >
      <span key="tooltip">{children}</span>

      {renderTooltip}
    </span>
  );
};

export default Tooltip;
