import React from "react";
// import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { PersistGate } from "redux-persist/lib/integration/react";

import theme from "@styles/theme";
import Loading from "@pages/loading";
import history from "@services/history";

import { AuthProvider } from "@hooks/useAuth";
import { CompanyProvider } from "@hooks/useCompany";
import { WindowTabProvider } from "@hooks/useWindowTab";
import { CandidateProvider } from "@hooks/useCandidate";
import { DefaultProvider } from "@hooks/useDefaultProvider";

import Routes from "./routes";
import { store, persistor } from "./store";

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <BrowserRouter>
          <WindowTabProvider>
            <AuthProvider>
              <CandidateProvider>
                <DefaultProvider>
                  <CompanyProvider>
                    <Routes history={history} />
                  </CompanyProvider>
                </DefaultProvider>
              </CandidateProvider>
            </AuthProvider>
          </WindowTabProvider>
        </BrowserRouter>
      </PersistGate>
    </ThemeProvider>
  </Provider>
);

export default App;
