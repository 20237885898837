import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 5px;

  input {
    ${({ type }) =>
      type === "time" ? `padding-right: 0px;` : `padding-right: 20px;`}
  }

  svg {
    /* position: absolute; */
    right: 15px;
    width: 14px;
    height: 14px;

    ${({ type }) =>
      type === "icon"
        ? `left: 0px; top: 26.5px;`
        : type === "textEditor"
        ? `right: 17px !important;`
        : `top: 26.5px;`}
  }

  .containerDate {
    left: 50px;
    top: 0px;
  }
`;

export const ErrorText = styled.span`
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #dc3545;
`;

export const Counter = styled.span`
  font-family: "Roboto Condensed";
  font-style: italic;
  font-size: 12px;
  text-align: right;
  float: right;
`;

export const ContainerMessage = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 5px;
`;
