import styled from "styled-components";
import { mobileLarge } from "@styles/breakpoints";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 2rem;

  h2 {
    margin-bottom: 1.5rem;
  }

  > button {
    max-width: 230px;

    @media screen and (max-width: ${mobileLarge}) {
      max-width: 200px;
    }
  }
`;
