import React from "react";
import * as Styled from "./styles";

const Footer = () => (
  <Styled.FooterContainer>
    <Styled.LeftLink
      href="https://solides.zendesk.com/hc/pt-br"
      target="_blank"
      rel="noreferrer"
    >
      Central de ajuda | Suporte ao candidato
    </Styled.LeftLink>
    <Styled.Separator>•</Styled.Separator>
    <Styled.RightLink
      href="https://solides.com.br"
      target="_blank"
      rel="noreferrer"
    >
      Sólides, tudo que o RH precisa em um só lugar
    </Styled.RightLink>
  </Styled.FooterContainer>
);

export default Footer;
