import React, { useCallback } from "react";

import { useAuth } from "@hooks/useAuth";
import { useCurriculumSections } from "@utils/hooks/useCurriculumSections";

import Icon from "@components/icon";
import * as Styled from "./styles";

const Desktop = () => {
  const { loadingApplicant } = useAuth();
  const { sectionOptions, changeSection, selectedSection } =
    useCurriculumSections();

  const handleMenuItemClick = useCallback(
    (newSection) => {
      if (!loadingApplicant) {
        changeSection(newSection);
      }
    },
    [changeSection, loadingApplicant]
  );

  const getSectionStatusIcon = (status) => {
    switch (status.type) {
      case "warning":
        return <Icon className="fas fa-exclamation-circle" color="#FEBA01" />;
      case "error":
        return <Icon className="fas fa-exclamation-circle" color="#EF3D4E" />;
      default:
        return null;
    }
  };

  return (
    <Styled.MenuDesktopContainer>
      <Styled.MenuDesktopBox>
        <Styled.ContainerSticky>
          <Styled.MenuDesktopTitleStep>
            <span>Seções do Currículo</span>
          </Styled.MenuDesktopTitleStep>
          {sectionOptions.map(({ key, text, isValid, status }) => (
            <Styled.MenuDesktopStep key={key}>
              <Styled.MenuDesktopStepRounder
                actived={selectedSection?.key === key}
              >
                <div className="connect" />
                <Styled.MenuDesktopItem
                  key={key}
                  disabled={loadingApplicant}
                  onClick={() => handleMenuItemClick(key)}
                  $isSelected={selectedSection?.key === key}
                >
                  {text}
                  {isValid ? (
                    <Styled.ContainerIcon>
                      <Icon className="fas fa-check" color="#20A135" />
                    </Styled.ContainerIcon>
                  ) : (
                    status && (
                      <Styled.ContainerIcon>
                        {getSectionStatusIcon(status)}
                      </Styled.ContainerIcon>
                    )
                  )}
                </Styled.MenuDesktopItem>
              </Styled.MenuDesktopStepRounder>
            </Styled.MenuDesktopStep>
          ))}
        </Styled.ContainerSticky>
      </Styled.MenuDesktopBox>
    </Styled.MenuDesktopContainer>
  );
};

export default React.memo(Desktop);
