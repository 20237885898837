import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "recompose";
import { notification } from "antd";
import HtmlParser from "html-react-parser";

import SEO from "@components/seo";

import * as StyledGlobal from "@styles/global";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import { ReactComponent as SearchIcon } from "@assets/svg/search.svg";

import {
  Header,
  Banner,
  Select,
  Table,
  Paginate,
  Badge,
  Input,
  Button,
  Footer
} from "@components/index";

import * as cookie from "@utils/cookie";

import * as actionCompany from "@store/modules/company/actions";
import * as actionVacancies from "@store/modules/vacancies/actions";
import * as authActions from "@store/modules/auth/actions";

import scrollToElementById from "@utils/scrollToElement";
import * as utilitiesActions from "@store/modules/utilities/actions";

import { useAuth } from "@utils/hooks/useAuth";
import { useCandidate } from "@utils/hooks/useCandidate";

import { useDefaultProvider } from "@utils/hooks/useDefaultProvider";
import { useCompany } from "@utils/hooks/useCompany";
import { useCompanyCities } from "@hooks/useCompanyCities";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_COMPANY,
  DEFAULT_HOST_CANDIDATE
} from "@services/constants";
import Vacancy from "./components/vacancy";
import Company from "./components/company";
import * as Styled from "./styles";

function Vacancies({ history, listVacancies }) {
  const [search, setSearch] = useState("");
  const [badge, setBadge] = useState("");
  const [close, setClose] = useState(false);
  const [hasVacancies, setHasVacancies] = useState(false);
  const [selected, setSelected] = useState({});

  const dispatch = useDispatch();
  const { id } = useParams();
  const { token, user, curriculumIsFilled } = useAuth();
  const { profiler } = useCandidate();
  const { slug } = useDefaultProvider();
  const { company, showTalentBank } = useCompany();
  const { cities: listCities } = useCompanyCities();

  const { hasProfileExpired } = cookie.getJSON();

  const clearSearchHandler = useCallback(
    (company) => {
      setSearch("");
      setBadge("");
      setSelected({ value: "" });
      dispatch(actionVacancies.getInitialVacancy());
      if (company?.referenceId) {
        dispatch(actionVacancies.getInitialVacancies(company));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    clearSearchHandler();
  }, [clearSearchHandler]);

  useEffect(() => {
    const { vacancy_message } = cookie.getJSON();
    if (vacancy_message) {
      const { type, message, description } = vacancy_message;
      if (type && description) {
        notification[type]({
          message,
          description: HtmlParser(description),
          duration: 10
        });
      }
      cookie.set("vacancy_message", "");
    }
  }, []);

  useEffect(() => {
    company?.referenceId &&
      dispatch(actionVacancies.getInitialVacancies(company));
  }, [company]);

  useEffect(() => {
    setHasVacancies(listVacancies?.totalRecords > 0);
  }, [listVacancies]);

  useEffect(() => {
    if (!id) {
      dispatch(actionVacancies.getInitialVacancy());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (close) {
      dispatch(actionVacancies.getInitialVacancy());
      history.push("/vacancies");
    }
    setClose(false);
  }, [dispatch, close, history]);

  const changePageHandle = (page) => {
    dispatch(
      actionVacancies.getSearchVacanciesByCompany({
        company: { referenceId: company?.referenceId },
        search,
        page,
        pagination: 25
      })
    );
  };

  const searchSelectHandler = (event) => {
    if (!!company && !!event && event?.text !== selected?.cityName) {
      dispatch(
        actionVacancies.getSearchVacanciesByCompany({
          company: { referenceId: company?.referenceId },
          place: event.text,
          page: 1,
          pagination: 25
        })
      );
      setBadge(event.text);
      setSelected(listCities.filter((city) => city.text === event.text)[0]);
    } else {
      dispatch(
        actionVacancies.getSearchVacanciesByCompany({
          company: { referenceId: company?.referenceId },
          search,
          page: 1,
          pagination: 25
        })
      );
      setBadge("");
      setSelected({ value: "", cityName: "" });
    }
  };

  const authenticateHandle = useCallback(
    (destination = "", applyingTalentBase = false) => {
      const data = {
        company: {
          referenceId: company.referenceId,
          slug: company.slug,
          name: company.name
        },
        applyingTalentBase
      };
      cookie.set(window.name, data, { expires: 1 });
      cookie.set("destination", destination);
      cookie.redirect(DEFAULT_HOST_LOGIN);
    },
    [company]
  );

  const applyTalentBaseOfCompany = useCallback(() => {
    const data = {
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name
      },
      applyingTalentBase: true
    };
    cookie.set(window.name, data, { expires: 1 });
    if (token && showTalentBank) {
      if (!curriculumIsFilled) {
        cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
      } else if (Object.keys(profiler).length <= 0) {
        cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/profiler`);
      } else if (hasProfileExpired === true) {
        dispatch(
          utilitiesActions.showModal({
            show: true,
            type: "profileExpired",
            options: {
              position: "middleCenter",
              size: "medium",
              hasBgShadow: true,
              bgShadow: "",
              showClose: false,
              closeOnOverlay: false
            },
            dataParams: {
              redirect: true
            }
          })
        );
      } else {
        dispatch(
          utilitiesActions.showModal({
            show: true,
            type: "applyTalentBank",
            options: {
              position: "center",
              size: "small",
              hasBgShadow: true,
              showClose: true,
              styles: {
                minWidth: "590px",
                marginTop: "10%"
              }
            },
            dataParams: {
              onApplyTalentBank: () =>
                dispatch(actionCompany.applyTalentBase(company, user)),
              onRedirectToCurriculum: () =>
                cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`)
            }
          })
        );
      }
    } else {
      authenticateHandle("/login", true);
    }
  }, [authenticateHandle, dispatch, company, user, profiler, token]);

  const searchHandle = useCallback(
    (event) => {
      setSearch(event.target.value);
      if (event.key === "Enter") {
        dispatch(actionVacancies.getInitialVacancy());
        dispatch(
          actionVacancies.getSearchVacanciesByCompany({
            company: { referenceId: company?.referenceId },
            search: event.target.value,
            place: selected?.cityName,
            page: 1,
            pagination: 25
          })
        );
        setBadge(search);
      }
    },
    [dispatch, search, selected]
  );

  const goToVacanciesCompanyHandle = useCallback(() => {
    const host = DEFAULT_HOST_COMPANY(slug);
    cookie.redirect(`${host}/vacancies`);
  }, [slug]);

  const goToVacanciesAnchor = useCallback(() => {
    const host = DEFAULT_HOST_COMPANY(slug);
    if (id) {
      return scrollToElementById("vacanciesList", -100);
    }
    cookie.redirect(`${host}/vacancies#vacancyDescription`);
  }, [slug, id]);

  const goToAboutAnchor = useCallback(() => {
    const host = DEFAULT_HOST_COMPANY(slug);
    cookie.redirect(`${host}/vacancies#aboutCompany`);
  }, [slug]);

  const goToPerfilCandidateHandle = useCallback(() => {
    const data = {
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name
      }
    };
    cookie.set(window.name, data, { expires: 1 });
    cookie.redirect(DEFAULT_HOST_CANDIDATE);
  }, [company]);

  const openModal = () => {
    if (slug === "solides") {
      dispatch(
        utilitiesActions.showModal({
          show: true,
          type: "solidesFreeCourse",
          options: {
            position: "middle",
            size: "medium",
            hasBgShadow: true,
            showClose: true,
            styles: {
              backgroundColor: "#583F99"
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    const { marketing_modal } = cookie.getJSON();
    if (!marketing_modal) {
      openModal();
    }

    // if (!service_unavailable) {
    //   dispatch(
    //     utilitiesActions.showModal({
    //       show: true,
    //       type: "solidesServiceUnavailable",
    //       options: {
    //         position: "middle",
    //         size: "medium",
    //         hasBgShadow: true,
    //         showClose: true,
    //         styles: {
    //           backgroundColor: "#583F99"
    //         }
    //       }
    //     })
    //   );
    // }
  }, [dispatch, slug, company]);

  return (
    <>
      <SEO
        title={`Trabalhe Conosco | ${company?.name}`}
        description={`Faça parte do nosso time, confira as vagas disponíveis na empresa ${company?.name}. Trabalhe conosco, inscreva-se on-line.`}
        image={company?.logo}
        url={`http://${company?.slug}.solides.jobs/`}
      />
      <StyledGlobal.Main>
        <Header
          logo={company?.logo || JobsLogo}
          urlLogo={DEFAULT_HOST_COMPANY(slug)}
          token={token}
          user={user}
          onGoToVacancies={goToVacanciesAnchor}
          onGoToAbout={goToAboutAnchor}
          onAuthenticate={authenticateHandle}
          onPerfil={goToPerfilCandidateHandle}
          onLogout={() => dispatch(authActions.logout())}
        />
        <Banner
          urlDesktop={company?.banner}
          urlMobile={company?.banner}
          defaultBackground={company?.colorCompany}
        />
        <StyledGlobal.Container id="container_pdf">
          <StyledGlobal.BodyMain>
            <StyledGlobal.BodyMarginContainer>
              <StyledGlobal.BodyContainer>
                <StyledGlobal.BodyContent id="vacancyDescription">
                  <StyledGlobal.BoxContainer>
                    {id && (
                      <Vacancy
                        company={company}
                        callbackDefaultHost={goToVacanciesCompanyHandle}
                        callbackCloseDescription={() => setClose(true)}
                      />
                    )}

                    <StyledGlobal.BoxHeader>
                      <StyledGlobal.BoxHeaderFilter>
                        <StyledGlobal.TextContainer>
                          {listVacancies?.totalAvailablePositions}{" "}
                          {listVacancies?.totalAvailablePositions > 1
                            ? "Vagas abertas"
                            : "Vaga aberta"}
                        </StyledGlobal.TextContainer>
                        <StyledGlobal.FilterContainer>
                          <Styled.Search>
                            <Input
                              type="search"
                              placeholder="Buscar vaga"
                              rightIcon={<SearchIcon />}
                              iconColor="#000000"
                              value={search}
                              onKeyPress={searchHandle}
                              onChange={searchHandle}
                            />
                          </Styled.Search>

                          <StyledGlobal.SpaceBoxContainer maxWidth="200">
                            <Select
                              options={listCities || []}
                              selected={selected}
                              onChange={(e) => searchSelectHandler(e)}
                              canClear={false}
                              placeholder="Cidade"
                              medium
                            />
                          </StyledGlobal.SpaceBoxContainer>
                        </StyledGlobal.FilterContainer>
                      </StyledGlobal.BoxHeaderFilter>

                      {!!badge && (
                        <Badge
                          text={badge}
                          type="pill"
                          closeIcon
                          iconColor="#666666"
                          isTooltip
                          description="Limpar busca"
                          fallbackClose={() => clearSearchHandler(company)}
                        />
                      )}
                    </StyledGlobal.BoxHeader>
                    <StyledGlobal.BoxBody>
                      <Table
                        id="vacanciesList"
                        isAppliedTalentBank={!showTalentBank}
                        list={hasVacancies ? listVacancies.list : []}
                        fallBackDescription={(item) => {
                          scroll(0, 0);
                          history.push(`/vacancies/${item.id}`);
                        }}
                        fallBackApplyTalentBank={applyTalentBaseOfCompany}
                        anchorLink="vacancyDescription"
                        color={
                          !!company && !!company.colorCompany
                            ? company.colorCompany.toString()
                            : "#333333"
                        }
                      />

                      {hasVacancies && (
                        <Styled.PaginateContainer
                          hide={listVacancies.totalPages <= 1}
                        >
                          <Paginate
                            totalPages={listVacancies.totalPages}
                            currentPage={listVacancies.page}
                            fallbackPageHandle={(e) => changePageHandle(e)}
                            color={
                              !!company && !!company.colorCompany
                                ? company.colorCompany.toString()
                                : "#333333"
                            }
                          />
                        </Styled.PaginateContainer>
                      )}

                      {!hasVacancies && (
                        <>
                          <Styled.EmptyVacancies>
                            No momento não existe vaga aberta.
                          </Styled.EmptyVacancies>

                          {!!badge && (
                            <Styled.Button>
                              <Button
                                text="Voltar para a listagem de vagas"
                                type="custom"
                                backgroundColor="#333333"
                                textColor="#ffffff"
                                fontWeight="bold"
                                borderColor="#333333"
                                borderWidth="1px"
                                boxShadow="0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2)"
                                onClick={goToVacanciesCompanyHandle}
                                isUppercase={false}
                                medium
                              />
                            </Styled.Button>
                          )}
                        </>
                      )}
                    </StyledGlobal.BoxBody>
                  </StyledGlobal.BoxContainer>
                </StyledGlobal.BodyContent>

                <StyledGlobal.Aside
                  position="relative"
                  hideMobile={!!id}
                  id="aboutCompany"
                >
                  <Company company={company} />
                </StyledGlobal.Aside>
              </StyledGlobal.BodyContainer>
            </StyledGlobal.BodyMarginContainer>
          </StyledGlobal.BodyMain>
        </StyledGlobal.Container>
      </StyledGlobal.Main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  listVacancies: state.vacancies.listVacancies
});

export default compose(withRouter, connect(mapStateToProps, null))(Vacancies);
