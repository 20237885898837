import { useContext } from "react";
import { CurriculumContext } from "@contexts/index";

export default function useCurriculum() {
  const curriculum = useContext(CurriculumContext);

  if (!curriculum) {
    throw new Error("useCurriculum should be used within a CurriculumProvider");
  }

  return curriculum;
}
