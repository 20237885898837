import * as yup from "yup";

import { isValidVideo, hasEmptySpaces } from "@utils/validations";

const schema = (requiredFields = []) =>
  yup.object().shape({
    picture: yup
      .string()
      .isRequired("photo", requiredFields, "Campo de preenchimento obrigatório")
      .nullable(),
    name: yup
      .string()
      .typeError("Nome em formato inválido")
      .transform((value) => (value || "").trim())
      .required("Campo de preenchimento obrigatório")
      .max(100, "Você atingiu o limite de caracteres")
      .test("count-words", "Deve conter pelo menos um sobrenome", (value) => {
        if (!value) return true;
        return value?.split(" ").filter(Boolean).length > 1;
      })
      .test(
        "count-characteres",
        "Você atingiu o limite de caracteres",
        (value) => {
          if (value && value.length === 100) return false;
          return true;
        }
      )
      .nullable(),
    salaryExpectation: yup
      .number()
      .typeError("Somente números")
      .isRequired(
        "salary",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .test(
        "too-small",
        "O valor deve ser maior ou igual a R$ 1,00",
        (value) => {
          if (!value) return true;
          return value >= 1;
        }
      )
      .test("too-big", "Você atingiu o valor máximo", (value) => {
        if (value === undefined || value === null) {
          return true;
        }

        return value <= 999999.98;
      })
      .max(14, "Você atingiu o valor máximo")
      .nullable(),
    videoUrl: yup
      .string()
      .typeError("Link em formato inválido")
      .isRequired(
        "introductionVideoURL",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .test(
        "is-valid-link",
        "URL inválida. Ex: https://youtu.be/videoApresentação",
        (value) => {
          if (!value) return true;
          if (!(value.includes("http://") || value.includes("https://")))
            return false;
          return isValidVideo(value);
        }
      )
      .nullable(),
    portfolioUrl: yup
      .string()
      .typeError("Link em formato inválido")
      .isRequired(
        "portfolioUrl",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .max(250, "Você atingiu o limite de caracteres")
      .test("has-link", "Link inválido", (value) => {
        if (!value) return true;
        if (
          !value.trim().includes(" ") &&
          (value.includes("http://") || value.includes("https://"))
        )
          return true;

        return false;
      })
      .nullable(),
    contacts: yup.object().shape({
      linkedin: yup
        .string()
        .typeError("Link em formato inválido")
        .isRequired(
          "linkedin",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .test(
          "is-link-linkedin",
          "URL inválida. Ex: https://www.linkedin.com/in/seu-usuário/",
          (value) => {
            if (!value) return true;
            if (hasEmptySpaces(value)) return false;
            if (value.includes("https://www.linkedin.com/in/")) return true;

            return false;
          }
        )
        .nullable(),
      facebook: yup
        .string()
        .typeError("Facebook em formato inválido")
        .isRequired(
          "facebook",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .test("is-has-space", "Facebook em formato inválido", (value) => {
          if (!value) return true;
          if (!value.trimEnd().includes(" ")) return true;

          return false;
        })
        .nullable(),
      instagram: yup
        .string()
        .typeError("Instagram em formato inválido")
        .isRequired(
          "instagram",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .test("is-has-space", "Instagram em formato inválido", (value) => {
          if (!value) return true;
          if (!value.trimEnd().includes(" ")) return true;

          return false;
        })
        .nullable(),
      twitter: yup
        .string()
        .typeError("Twitter em formato inválido")
        .isRequired(
          "twitter",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .test("is-has-space", "Twitter em formato inválido", (value) => {
          if (!value) return true;
          if (!value.trimEnd().includes(" ")) return true;

          return false;
        })
        .nullable()
    })
  });

export default schema;
