import React from "react";

import print from "@utils/print";

import * as StyledGlobal from "@styles/global";

import Button from "@components/button";
import { ReactComponent as LogoJobs } from "@assets/svg/authLogoJobs.svg";

import * as Styled from "./styles";

export const Body = () => (
  <Styled.Container id="print">
    <Styled.Logo>
      <LogoJobs alt="logo" height="50" />
    </Styled.Logo>
    <Styled.Title>POLÍTICA DE PRIVACIDADE</Styled.Title>
    <Styled.Description>
      Para a Sólides é fundamental a transparência quanto aos dados cadastrados
      no nosso sistema e nos preocupamos com sua privacidade e proteção.
    </Styled.Description>
    <Styled.Description>
      Você precisa conhecer e estar de acordo com a nossa Política de
      Privacidade, nela você vai encontrar informações claras sobre coleta, uso,
      tratamento, armazenamento e proteção dos seus dados.
    </Styled.Description>
    <Styled.Description>
      Esta política se aplica a todos os nossos produtos e serviços.
    </Styled.Description>
    <Styled.SubTitle>COLETA DE DADOS PESSOAIS E INFORMAÇÕES</Styled.SubTitle>
    <Styled.Description>
      Coletamos informações que você nos fornece diretamente.
    </Styled.Description>
    <Styled.Description>
      Por exemplo, coletamos informações quando você cria uma conta nas nossas
      plataformas, atualiza seu perfil, participa de certos recursos
      interativos, preenche formulários, pesquisas, se candidata a alguma vaga
      dos nossos clientes, solicita atendimento do suporte do cliente ou se
      comunica conosco pelos nossos diversos canais de comunicação como redes
      sociais, e-mail e telefone.
    </Styled.Description>
    <Styled.Description>
      As informações que coletamos podem incluir nome, endereço de e-mail,
      telefone, interesses, empresa, cargo, localidade, CPF e outras informações
      necessárias para a interação com nosso software.
    </Styled.Description>
    <Styled.Description>
      Se você nos fornecer informações pessoais de outra pessoa, você deve
      assegurar que possui o direito de divulgá-las para a Sólides. Inclusive,
      tendo obtido o consentimento necessário para tal compartilhamento.
    </Styled.Description>
    <Styled.Description>
      Vale ressaltar que é de extrema importância para a Sólides a coleta,
      armazenamento e tratamento dos dados pessoais dos usuários, a fim de
      possibilitar a prestação dos nossos serviços ofertados através das nossas
      plataformas.
    </Styled.Description>
    <Styled.SubTitle>USO DOS DADOS</Styled.SubTitle>
    <Styled.Description>
      Os dados pessoais coletados, bem como demais informações, poderão ser
      armazenados, tratados e utilizados pela Sólides com as seguintes
      finalidades:
    </Styled.Description>
    <Styled.List>
      <Styled.Item>
        Possibilitar a prestação dos serviços ofertados pela Sólides, permitindo
        assim, a operacionalização das nossas plataformas.
      </Styled.Item>
      <Styled.Item>Desenvolver melhorias nos nossos serviços.</Styled.Item>
      <Styled.Item>
        Fornecer recomendações, conteúdos e recursos que correspondam com os
        interesses do usuário.
      </Styled.Item>
      <Styled.Item>
        Fornecer um atendimento cada vez melhor e eficaz.
      </Styled.Item>
      <Styled.Item>
        A Sólides pode entrar em contato com o intuito de apresentar novas
        oportunidades como vagas ou novos produtos que melhorem a experiência do
        usuário.
      </Styled.Item>
    </Styled.List>
    <Styled.SubTitle>DIREITO DOS USUÁRIOS</Styled.SubTitle>
    <Styled.Description>
      Os usuários possuem o direito de requerer exclusão, retificação e
      atualização dos seus dados. Além disso possuímos o canal de atendimento
      para possibilitar a comunicação sobre dúvidas relacionadas ao tratamento e
      armazenamento de dados.
    </Styled.Description>
    <Styled.SubTitle>COMPARTILHAMENTO COM TERCEIROS</Styled.SubTitle>
    <Styled.Description>
      A Sólides poderá realizar o compartilhamento dos seus dados com terceiros
      nas seguintes hipóteses:
    </Styled.Description>
    <Styled.List>
      <Styled.Item>
        Quando os dados forem coletados pela plataforma Jobs.Solides para
        cadastro em vagas oferecidas pelos nossos clientes. Diante disso, os
        dados serão compartilhados com a empresa que originou a vaga em questão.
      </Styled.Item>
      <Styled.Item>
        Quando os dados forem coletados através de uma ação de co-marketing.
        Nesse caso no próprio conteúdo será descrito e informado que é uma
        parceria da Sólides com a empresa específica.
      </Styled.Item>
    </Styled.List>
    <Styled.SubTitle>POLÍTICA DE COOKIES</Styled.SubTitle>
    <Styled.Description>
      Utilizamos cookies e web beacons para coletar informações de navegação.
    </Styled.Description>
    <Styled.Description>
      Os dados de navegação como IP, ISP, Browser, e outras informações são
      coletadas apenas com a finalidade estatística de melhorar a usabilidade e
      relevância de nosso site para você. A coleta desses dados é feita pelo
      Analytics.
    </Styled.Description>
    <Styled.Description>
      Porém, você pode navegar anonimamente usando este recurso em seu browser.
    </Styled.Description>
    <Styled.SubTitle>ARMAZENAMENTO DE DADOS</Styled.SubTitle>
    <Styled.Description>
      Nosso intuito é acompanhar toda a trajetoria profissional dos nossos
      usuários, para podermos oferecer novas oportunidades e inovação. Por isso,
      os dados serão armazenados pelo tempo necessário para cumprir os serviços
      ofertados e adquiridos. Vale ressaltar que caso o usuário não realize
      nenhuma ação dentro das nossas plataformas por muito tempo, nós entraremos
      em contato para saber se ainda possui interesse em manter seus dados
      conosco. Além disso, conforme já mencionado nessa Política, garantimos os
      direitos dos titulares de exclusão dos seus dados a qualquer momento.
    </Styled.Description>
    <Styled.SubTitle>SEGURANÇA</Styled.SubTitle>
    <Styled.Description>
      Desde o momento da inserção dos dados no sistema, até a sua finalidade,
      estes são armazenados em bancos de dados seguros, com acesso restrito
      apenas a funcionários com autorização para viabilizar as atividades com os
      referidos dados.
    </Styled.Description>
    <Styled.SubTitle>ATUALIZAÇÃO DA POLÍTICA</Styled.SubTitle>
    <Styled.Description>
      A equipe da Solides reserva-se no direito de alterar esta Política. Desse
      modo, recomendamos que consulte a nossa política de privacidade com
      regularidade de forma a estar sempre atualizado.
    </Styled.Description>
    <Styled.Description>
      Qualquer dúvida, o usuário pode falar diretamente com a Solides Tecnologia
      S.A. (CNPJ 10.461.302/0001-10) através do telefone +55 (31) 3262-3464,
      pelo e-mail:{" "}
      <a href="mailto:suporte@solides.com.br">suporte@solides.com.br</a> para
      suporte técnico ou{" "}
      <a href="mailto:privacidade@solides.com.br">privacidade@solides.com.br</a>{" "}
      para proteção e privacidade de dados.
    </Styled.Description>
    <Styled.ContainerLegend>
      <Styled.Legend>Belo Horizonte, 02 de agosto de 2021.</Styled.Legend>
    </Styled.ContainerLegend>
  </Styled.Container>
);

export const Footer = ({ onClose }) => (
  <Styled.Container footer>
    <Styled.ContainerButton>
      <StyledGlobal.SpaceBoxContainer maxWidth="112">
        <Button
          large
          type="outline"
          text="Imprimir"
          isUppercase
          borderColor="#333333"
          textColor="#333333"
          onClick={() => print("print")}
        />
      </StyledGlobal.SpaceBoxContainer>
      <StyledGlobal.SpaceBoxContainer maxWidth="112">
        <Button
          large
          type="primary"
          text="Fechar"
          isUppercase
          onClick={onClose}
        />
      </StyledGlobal.SpaceBoxContainer>
    </Styled.ContainerButton>
  </Styled.Container>
);
