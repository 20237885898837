import styled from "styled-components";

export const Container = styled.section``;

export const Title = styled.h1`
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #333333;
  margin-bottom: 35px;
`;

export const Description = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #333333;
  max-width: 520px;
  margin: 0 auto;
  margin-bottom: 50px;
`;

export const Button = styled.div`
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
`;
