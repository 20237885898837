import React, { useState } from "react";
import { InputWithLabel, InvalidFeedback } from "../../styles";

export default function Email({ label, styleInput, error, ...rest }) {
  const [valid, setValid] = useState(true);
  const validEmail = (value) => {
    const valida = () => {
      const re =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return re.test(value);
    };

    if (valida(value)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };
  return (
    <InputWithLabel>
      <input
        onBlur={(e) => {
          e.target.value !== "" ? validEmail(e.target.value) : setValid(true);
        }}
        style={
          valid === true
            ? { borderRadius: 50, ...styleInput }
            : {
                borderRadius: 50,
                border: "1px solid red",
                ...styleInput
              }
        }
        placeholder=" "
        {...rest}
      />
      <label>{label}</label>
      {!!error && <InvalidFeedback>{error}</InvalidFeedback>}
    </InputWithLabel>
  );
}
