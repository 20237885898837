import styled, { css } from "styled-components";

const buttonStyles = ({ isPrimary = false }) =>
  isPrimary
    ? css`
        background: #333;
        color: #fff;
        min-width: 50%;

        @media (min-width: 360px) {
          min-width: 55%;
        }

        @media (min-width: 400px) {
          min-width: 60%;
        }

        @media (min-width: 460px) {
          min-width: 65%;
        }
      `
    : css`
        background: #fff;
        color: #333;
      `;

export const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 0.375rem 1rem;
`;

export const Button = styled.button`
  padding: 0.75rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  font-family: "Roboto Condensed", "Roboto", sans-serif;
  border: none;
  border-radius: 61px;
  flex: 1;
  cursor: pointer;

  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"}

  ${buttonStyles};
`;
