import styled from "styled-components";

const handleType = (type, colors) => {
  let color;

  switch (type) {
    case "error":
      color = "#999999";
      break;
    case "warning":
      color = colors.yellow;
      break;
    default:
      color = "#666666";
  }

  return `
    border-color: ${color};
    background-color: ${color}26;
  `;
};

export const Box = styled.div`
  width: 100%;
  padding: 10px 27px;
  border: 1px solid;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  font-family: "Roboto";
  font-size: 14px;
  line-height: 150%;

  ${({ $type, theme }) => handleType($type, theme.colors)}

  & > span {
    display: inline-block;
  }
`;
