import styled from "styled-components";

export const trackStyle = {
  backgroundColor: "#333333",
  height: "7px"
};

export const railStyle = {
  backgroundColor: "#ffffff",
  border: "1px solid #D9D9D9",
  height: "7px"
};

export const dotStyle = {
  border: "1px solid #D9D9D9"
};

export const handleStyle = {
  background: "#333333",
  border: "none",
  boxShadow: "none !important",
  marginTop: "-3.5px"
};

export const Container = styled.div`
  .rc-slider-dot {
    background: ${({ theme }) => theme.colors.white} !important;
    border: 2px solid ${({ theme }) => theme.colors.secondary} !important;
    width: 10px;
    height: 10px;
    bottom: -4px;
  }

  .rc-slider-mark > span {
    font-weight: lighter;
  }

  .rc-slider-dot-active {
    border: 2px solid ${({ theme }) => theme.colors.primary} !important;
  }
  .rc-slider-mark > span:first-child {
    left: 5% !important;
  }
  .rc-slider-mark > span:last-child {
    left: 93% !important;
  }
`;
