export function sendAnswersProfiler(data) {
  return {
    type: "@profiler/SEND_ANSWERS_PROFILER",
    payload: data
  };
}

export function unblockButtons() {
  return {
    type: "@profiler/UNBLOCK_BUTTONS"
  };
}
