import styled from "styled-components";

export const ContainerTabHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-size: 16px;
  line-height: 48px;
  color: #333333;

  border-bottom: 7px solid rgba(0, 0, 0, 0.05);

  /* less than 992px */
  padding: 0px 18px;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 0px 70px;
  }
`;

export const TabItem = styled.div`
  ${({ active }) =>
    active &&
    "font-weight: bold;border-bottom: 7px solid #000000;margin-bottom: -7px;"}

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  cursor: pointer;

  :hover {
    font-weight: bold;
  }
`;

export const ContainerGroups = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerGroup = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerLogo = styled.div`
  max-width: 70px;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  height: 100%;
  font-family: "Roboto";
  font-style: normal;
  color: #333333;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SubTitle = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

export const Topic = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const SubTopic = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

export const Description = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  word-break: break-word;
`;

export const ContainerAdditionalInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AdditionalInformationRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  /* less than 992px */
  flex-direction: column;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const ContainerBox = styled.div`
  flex: ${({ flex }) => flex || 1};
  padding: 0px 20px 20px 20px;
  text-align: left;
`;

export const BoxTitle = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #333333;
`;

export const BoxDescription = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #333333;
  font-style: ${({ fontStyle }) => fontStyle || "normal"};
`;
