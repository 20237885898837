/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Loading from "../../assets/loading/load.gif";
import { ReactComponent as Close } from "../../assets/svg/close2.svg";
import { ReactComponent as ErrorIcon } from "../../assets/svg/validationError.svg";
import { ReactComponent as SuccessIcon } from "../../assets/svg/validationSuccess.svg";

import * as Styled from "./styles";

const Select2 = ({
  placeholder = "",
  data = [
    {
      id: 1,
      name: "Titulo 1",
      selected: false
    },
    {
      id: 2,
      name: "Titulo 2",
      selected: false
    },
    {
      id: 3,
      name: "Titulo 3",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcBmMxDu-Eo9TzLZxs1RTrPX4S6aLsxvIijXfAFT931Qz3Pw7B&s",
      selected: false
    }
  ],
  multiselect = true,
  onCreate = () => {},
  onChange = () => {},
  loading = false,
  error = false,
  showValidateIcon = false,
  ...rest
}) => {
  const [_hasFocus, _setHasFocus] = useState(false);
  const [_data, _setData] = useState(data);
  const [_show, _setShow] = useState(false);
  const [_valueInput, _setValueInput] = useState("");
  const [_selected, _setSelected] = useState([]);

  useEffect(() => {
    const newData = data.map((e) => {
      if (_selected.length !== 0) {
        const aux = _selected.filter(
          (ee) => e.id === ee.id && ee.selected === true
        );
        if (aux.length > 0) {
          e.selected = true;
        } else {
          e.selected = false;
        }
      }

      return e;
    });

    const aux = newData.filter((e) => !!e.selected === true);
    // _setSelected(newData.filter(e => !!e.selected === true));
    if (_selected.length === 0 && multiselect) {
      _setSelected(aux);
    }

    // onChange(_valueInput, _selected);
    _setData([...newData]);

    multiselect === false &&
      _setValueInput(
        !!aux.length && _valueInput === "" ? aux[0].name : _valueInput
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (_hasFocus === false) {
      _setValueInput("");
    }
  }, [_hasFocus]);

  useEffect(() => {
    if (multiselect) {
    } else {
      _setValueInput(_selected.length ? _selected[0].name : "");
    }
  }, [_selected, multiselect]);

  const _handleRemove = (item) => {
    const auxData = _data.map((e) => {
      if (item.id === e.id) {
        e.selected = false;
      }
      return e;
    });
    const auxSelected = _selected.map((e) => {
      if (item.id === e.id) {
        e.selected = false;
      }
      return e;
    });
    _setData(auxData);
    _setSelected(auxSelected);
    onChange(
      _valueInput,
      auxSelected.filter((e) => e.selected === true)
    );
  };

  const _handleChangeSelect = (item) => {
    const auxData = _data.map((e) => {
      if (item.id === e.id) {
        return { ...e, selected: true };
      }
      return { ...e, selected: false };
    });

    let valueInput = "";

    if (auxData.filter((e) => e.selected).length > 0) {
      valueInput = auxData.filter((e) => e.selected)[0].name;
    }

    // const newSelected = auxData.filter(e => e.selected);
    // if (_selected.length > 0 && multiselect) {
    //     let newSelect = _selected.filter(e => e.id !== item.id);
    //     newSelect.push(item);
    //     _setSelected(newSelect);
    // } else {
    //     _setSelected(newSelected);
    // }

    // const aux = [..._selected, item].filter(e => e.selected === true);
    // const newAUX = aux.filter((e, i) => {
    //     return aux.indexOf(e) === i;
    // });

    _setValueInput(valueInput);
    onChange(
      valueInput,
      auxData.filter((e) => e.selected)
    );

    _setShow(false);
    _setData(auxData);
  };
  const _handleChangeValue = (e) => {
    onChange(e.target.value, []);
    _setValueInput(e.target.value);
  };
  return (
    <Styled.SelectContent {...rest}>
      <Styled.ContainerInput
        onBlur={() => {
          _setShow(!_hasFocus);
        }}
        tabIndex={0}
        onFocus={() => {
          _setShow(true);
          _setHasFocus(true);
        }}
      >
        <Styled.Autocomplete
          onChange={_handleChangeValue}
          value={_valueInput}
          onFocus={() => {
            _setShow(true);
          }}
          onBlur={() => {
            _setShow(false);
          }}
          show={_show}
          placeholder="Selecione"
          error={showValidateIcon && error}
        />

        {loading ? (
          <Styled.ListLoading show={_show} {...rest}>
            <img src={Loading} alt="loading" />
          </Styled.ListLoading>
        ) : (
          <Styled.ListContainer {...rest}>
            <Styled.List show={_show} hasImage {...rest}>
              {_data.map((e, i) => (
                <Styled.ListItem
                  key={i}
                  onClick={() => _handleChangeSelect(e)}
                  hasImage={e.img !== undefined && e.img !== ""}
                  selected={e.selected}
                >
                  {e.img !== undefined && e.img !== "" && (
                    <Styled.PhotoContainer>
                      <Styled.Photo data={e.img} />
                    </Styled.PhotoContainer>
                  )}
                  <Styled.TextContainer>
                    <Styled.Text>{e.name}</Styled.Text>
                  </Styled.TextContainer>
                  {e.selected && multiselect && (
                    <Styled.IconClose>
                      <Close />
                    </Styled.IconClose>
                  )}
                </Styled.ListItem>
              ))}
            </Styled.List>
          </Styled.ListContainer>
        )}
      </Styled.ContainerInput>

      <Styled.ContainerStatus>
        {showValidateIcon && error ? (
          <ErrorIcon />
        ) : showValidateIcon && (!!_valueInput || !!_selected.length) ? (
          <SuccessIcon />
        ) : null}
      </Styled.ContainerStatus>
    </Styled.SelectContent>
  );
};
export default Select2;
