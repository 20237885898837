import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import * as utilitiesActions from "../../store/modules/utilities/actions";

import Loading from "../../pages/loading";
import Modal from "../../pages/modal";
import Alert from "../../pages/alert";

const Main = ({
  children,
  company,
  loading,
  modal,
  content,
  alert,
  history,
  ...rest
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      utilitiesActions.showModal({
        show: false
      })
    );
    dispatch(utilitiesActions.showLoading(false));

    return () => {
      dispatch(
        utilitiesActions.showModal({
          show: false
        })
      );
      dispatch(utilitiesActions.showLoading(false));
    };
  }, [dispatch]);

  const renderLoading = () => loading && <Loading content={content} />;

  const renderModal = () =>
    modal &&
    modal.show && (
      <Modal
        {...modal}
        type={modal.type}
        options={modal.options}
        dataParams={modal.dataParams}
        onClose={() =>
          dispatch(
            utilitiesActions.showModal({
              show: false
            })
          )
        }
      />
    );

  const renderAlert = () =>
    !!alert &&
    !!alert.show && (
      <Alert
        type={alert.type}
        onClose={() =>
          dispatch(
            utilitiesActions.showAlert({
              show: false,
              type: ""
            })
          )
        }
        sizeMode={alert.sizeMode}
        history={history}
      />
    );

  return (
    <>
      {renderLoading()}
      {renderModal()}
      {renderAlert()}
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  company: state.company,
  loading: state.utilities.loading,
  modal: state.utilities.modal,
  content: state.utilities.content,
  alert: state.utilities.alert
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
