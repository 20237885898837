import React from "react";
import { components } from "react-select";
import Icon from "@components/icon";

const DropdownIndicator = ({ isDisabled, ...props }) => (
  <components.DropdownIndicator {...props}>
    {isDisabled ? (
      <Icon className="fa fa-ban" color="#333333" />
    ) : (
      <Icon className="fas fa-caret-down " color="#333333" />
    )}
  </components.DropdownIndicator>
);

export default DropdownIndicator;
