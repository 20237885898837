import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 0.125rem;

  input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: ${({ $isBlocked }) => ($isBlocked ? "transparent" : "#fff")};
    color: #333333;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 100%;
    border-radius: 51px;
    outline: none;
    padding: 0.6875rem 1rem;

    &::placeholder {
      color: #a0a0a0;
    }

    ${({ $hasLeftIcon, $prefix }) =>
      $hasLeftIcon && !$prefix && "padding-left: calc(1rem + 1.5rem);"}

    ${({ $hasLeftIcon, $prefix, $prefixWidth }) =>
      $hasLeftIcon &&
      $prefix &&
      `padding-left: calc(1rem + 1.5rem + ${$prefixWidth}px);`}

    ${({ $prefix, $prefixWidth }) =>
      $prefix && `padding-left: calc(1rem + 1.5rem + ${$prefixWidth}px);`}

    ${({ $hasError }) =>
      $hasError
        ? `border-color: #EF3D4E`
        : `padding-right: calc(1rem + 1.25rem)`}
  }

  i {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 18px;
    width: 1rem;
    height: 1.3rem;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);

    width: ${({ $isBlocked }) => ($isBlocked ? "1.125rem" : "0.875rem")};
    height: ${({ $isBlocked }) => ($isBlocked ? "1.6875rem" : "1.3125rem")};

    *,
    svg {
      fill: ${({ $isBlocked }) => ($isBlocked ? "#333333" : "#177727")};
    }
  }
`;

export const Prefix = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  position: absolute;
  top: 49%;
  left: 2.6rem;
  transform: translateY(-50%);
  font-size: 0.875rem;
`;

export const PasswordContainer = styled.div`
  position: relative;

  input {
    padding-right: ${({ showValidateIcon, hasValue }) =>
      showValidateIcon && hasValue ? "62px" : "40px"};
  }

  i {
    position: absolute;
    top: 58%;
    left: ${({ showValidateIcon, hasValue }) =>
      showValidateIcon && hasValue ? "calc(100% - 55px)" : "calc(100% - 32px)"};
    z-index: 2;
    font-size: 14px;
    cursor: pointer;
    color: #333333;
  }
`;
