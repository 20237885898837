import styled from "styled-components";

export const TooltipContainer = styled.div`
  max-width: 270px;
  width: 100%;
`;

export const TooltipText = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #333333;
  margin-bottom: ${(props) => props.marginBottom || "0px"} !important;
`;

export const TooltipList = styled.ol`
  padding: 0px 0px 0px 20px;
  margin: 0px;
  line-height: 163%;
`;

export const TooltipItem = styled.li``;
