import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 2.25rem 1rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  animation: fadeIn 1.2s linear;

  @keyframes fadeIn {
    0% {
      background: rgba(220, 220, 220, 0.6);
    }
    100% {
      background: rgba(220, 220, 220, 0, 0);
    }
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 0;
  margin-left: auto;
  background: none;

  &:hover {
    cursor: pointer;
  }
`;
