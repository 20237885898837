/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { InputWithLabel, InvalidFeedback } from "../../styles";

export default function InputText({
  label,
  isValid,
  onChange = () => {},
  value,
  styleInput = {},
  placeholder = "",
  error,
  ...rest
}) {
  // const [currentValue, setCurrentValue] = useState("");
  // const ref = useRef(null);
  // let time = null;

  // useEffect(() => {
  //     setCurrentValue(value);
  // }, [value]);

  // useEffect(() => {
  //     ref.current.addEventListener("keyup", handleKeyUp);
  //     return () => ref.current.removeEventListener("keyup", handleKeyUp);
  // }, []);

  // const handleKeyUp = event => {
  //     clearTimeout(time);
  //     time = setTimeout(() => onChange(event), 1000);
  // };

  return (
    <InputWithLabel className="label-float" error={error} {...rest}>
      <input
        style={styleInput}
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        {...rest}
      />
      <label>{label}</label>
      {!!error && <InvalidFeedback>{error}</InvalidFeedback>}
    </InputWithLabel>
  );
}
