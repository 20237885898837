import styled from "styled-components";

export const RangeSlider = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 12px;
`;

export const Text = styled.span`
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  font-size: 12px;
  line-height: 20px;
`;

export const ContainerSlider = styled.div`
  width: 100%;
  height: 8px;

  .ant-slider {
    margin: 2px 6px 10px;
  }

  .ant-slider-rail {
    height: 8px;
    background-color: #ffffff;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #f5f5f5;
  }
  .ant-slider-track {
    height: 8px;
    background-color: #333333;
    border-radius: 24px;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #000000;
  }
  .ant-slider-step {
    height: 8px;
  }
  .ant-slider-dot-active {
    border-color: #333333;
  }
  .ant-slider-dot {
    top: -1.1px; /* -13% */
    width: 10px;
    height: 10px;
  }
  .ant-slider-handle {
    margin-top: -3.5px;
    background-color: #333333;
    border: solid 2px #333333;
  }
  .ant-slider-handle:focus {
    border-color: #555555;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #000000;
  }
`;
