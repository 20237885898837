import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContainerBody = styled.div`
  margin: 0px;

  @media screen and (min-width: 480px) {
    margin: 30px;
  }
`;

export const Section = styled.div`
  width: 100%;
  ${({ position }) =>
    position === "center" &&
    `
        display: flex;
        flex-direction: column;
        align-items: center;
    `}
`;

// text title
export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.1px;
  color: #333333;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 0 0 0 20px;
  min-height: 40px;
  height: 100%;
`;

export const Title = styled.h3`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.1px;
  color: #333333;
`;
export const Description = styled.p`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: -0.1px;
  color: #333333;
  margin-bottom: 0;
`;

export const SectionBody = styled.div`
  margin: 20px 25px 40px 25px;

  @media screen and (max-width: 640px) {
    margin: 20px 25px 40px 5px;
  }
`;

// form
export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  align-items: ${({ align }) => align || "normal"};

  /* less than 992px */
  flex-direction: column;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;
export const FormGroup = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  ${({ noMarging }) => !noMarging && "margin-bottom: 1rem;"}

  ${(props) =>
    props.padding &&
    `
    padding-right: 5px;
    padding-left: 5px;
  `}

  flex: ${({ flex }) => flex || "none"};
`;

export const FormControl = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  ${({ paddingTop }) => paddingTop && "padding-top: 19px"}
`;

export const FormControlInfo = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 16px;
  position: absolute;
  right: -25px;
  bottom: 12px;
  width: 100%;
  height: 16px;
  cursor: help;

  svg {
    width: 100%;
    height: 16px;
    path {
      fill: #333333;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: 640px) {
    justify-content: center;
    margin-top: 15px;
  }
`;
export const Button = styled.div`
  width: 100%;
  max-width: 170px;
`;
