import React from "react";

import * as Styled from "./styles";

import Button from "../../../button";

const NotFound = ({ colorCompany, onGoBack }) => (
  <Styled.Container>
    <Styled.Title>
      Vaga não encontrada{" "}
      <span role="img" aria-label="sad">
        😕
      </span>
    </Styled.Title>
    <Styled.Description>
      Infelizmente, não encontramos essa vaga. O link pode estar quebrado ou ela
      foi encerrada pela empresa. <br />
      <br />
      Que tal retornar para <b>procurar outra vaga</b> que combina com você?
    </Styled.Description>
    <Styled.Button>
      <Button
        medium
        type="custom"
        text={<b>Voltar para a tela inicial</b>}
        backgroundColor={colorCompany}
        textColor="#FFFFFF"
        isUppercase={false}
        onClick={onGoBack}
      />
    </Styled.Button>
  </Styled.Container>
);

export default NotFound;
