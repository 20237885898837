import React from "react";
import { Tooltip } from "antd";
import {
  ButtonCustom,
  ButtonOutline,
  ButtonSocial,
  ButtonLink,
  Linkedin,
  Facebook,
  Google
} from "./components";

import "antd/dist/antd.css";

const Button = ({
  text,
  type,
  isTooltip = false,
  description = "",
  leftIcon = false,
  rightIcon = false,
  uppercase,
  onClick,
  options,
  buttonId,
  ...rest
}) => {
  const renderButton = () => {
    switch (type) {
      case "custom":
        return (
          <ButtonCustom onClick={onClick} text={text} id={buttonId} {...rest} />
        );
      case "outline":
        return (
          <ButtonOutline
            onClick={onClick}
            text={text}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            id={buttonId}
            {...rest}
          />
        );
      case "social":
        return <ButtonSocial onClick={onClick} options={options} />;
      case "link":
        return <ButtonLink onClick={onClick} text={text} {...rest} />;
      case "linkedin":
        return (
          <Linkedin
            type="button"
            onClick={onClick}
            text={text}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            {...rest}
          />
        );
      case "facebook":
        return (
          <Facebook
            type="button"
            onClick={onClick}
            text={text}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            {...rest}
          />
        );
      case "google":
        return (
          <Google
            onClick={onClick}
            text={text}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            {...rest}
          />
        );
      default:
        return <ButtonCustom onClick={onClick} text={text} {...rest} />;
    }
  };

  return isTooltip ? (
    <Tooltip placement="top" title={description}>
      {renderButton()}
    </Tooltip>
  ) : (
    <>{renderButton()}</>
  );
};

export default Button;
