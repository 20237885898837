import React from "react";
import { Link } from "react-router-dom";

import { Button, Typography } from "@components/";
import { LinkRegister, LinkLogin } from "@components/linkAuth";

function ExpiredTokenPasswordReset() {
  const propsButton = {
    buttonType: "submit",
    backgroundColor: ({ theme }) => theme.colors.purple,
    fontWeight: "bold",
    boxShadowColor: ({ theme }) => theme.colors.shadow
  };

  return (
    <>
      <Typography type="title">
        Link de redefinição de senha expirado
      </Typography>
      <Typography>
        O link de redefinição de senha está expirado ou já foi utilizado.
      </Typography>
      <Link to="/forgotPassword">
        <Button text="REDEFINIR NOVAMENTE" medium {...propsButton} />
      </Link>
      <LinkLogin />
      <LinkRegister />
    </>
  );
}

export { ExpiredTokenPasswordReset };
