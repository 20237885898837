import { createStore, compose, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";

export default (reducers, middlewares) => {
  const enhancer =
    process.env.ENVIRONMENT === "development"
      ? compose(
          composeWithDevTools(applyMiddleware(...middlewares, ReduxThunk))
        )
      : applyMiddleware(...middlewares, ReduxThunk);

  return createStore(reducers, enhancer);
};
