/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";

import * as Styled from "./styles";

export const Body = ({ data = {} }) => {
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = data.match(regExp);

    if (match && match[2].length === 11) {
      setVideoId(match[2]);
    }
  }, [data]);

  return (
    <Styled.ContainerMedia>
      <iframe
        title="ytVideo"
        width="860"
        height="484"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Styled.ContainerMedia>
  );
};
