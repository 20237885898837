import React, { createContext, useEffect, useState, useMemo } from "react";

import { useDispatch } from "react-redux";

import * as cookie from "@utils/cookie";
import * as vacancyActions from "@store/modules/vacancy/actions";
import { useWindowTab } from "@hooks/useWindowTab";

const ApplyVacancyContext = createContext({});

const ApplyVacancyProvider = ({ children }) => {
  const [requiredFields, setRequiredFields] = useState([]);
  const [isApplyVacancy, setIsApplyVacancy] = useState(false);
  const [isApplyTalentBase, setIsApplyTalentBase] = useState(false);
  const [vacancy, setVacancy] = useState({});
  const { name } = useWindowTab();

  const dispatch = useDispatch();

  async function getRequiredFields(vacancy) {
    const data = await dispatch(vacancyActions.getRequiredFields(vacancy.id));
    setRequiredFields(data);
  }

  async function getVacancy(vacancy) {
    const data = await dispatch(vacancyActions.getVacancy(vacancy.id));
    setVacancy(data);
  }

  useEffect(() => {
    const { [name]: tab } = cookie.getJSON();

    setIsApplyVacancy(!!tab?.applyingVacancy);
    setIsApplyTalentBase(!!tab?.applyingTalentBase);

    if (tab?.vacancy?.id) {
      getRequiredFields(tab.vacancy);
      getVacancy(tab.vacancy);
    }
  }, [name]);

  const isRequiredField = (field) => requiredFields.includes(field);

  const value = useMemo(
    () => ({
      requiredFields,
      isApplyVacancy,
      isApplyTalentBase,
      vacancy,
      isRequiredField
    }),
    [
      requiredFields,
      isApplyVacancy,
      isApplyTalentBase,
      vacancy,
      isRequiredField
    ]
  );

  return (
    <ApplyVacancyContext.Provider value={value}>
      {children}
    </ApplyVacancyContext.Provider>
  );
};

export { ApplyVacancyContext, ApplyVacancyProvider };
