import styled from "styled-components";

export const SelectContent = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 3px;
  padding-bottom: 20px;

  min-height: 40px;
`;

export const ContainerStatus = styled.div`
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 12px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Create = styled.div`
  margin-bottom: -20px;
  margin-left: -10px;
  margin-right: -10px;
  border-top: 1px solid #efedf3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
`;

export const CreateIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  svg {
    path {
      fill: #2f0a77;
    }
  }
`;

export const CreateTextCotainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateText = styled.span`
  font-size: 16px;
  color: #5f4a87;
`;

export const List = styled.div`
  ${(props) => (props.show === true ? "display: block" : "display: none")}
  width: 100%;
  position: absolute;
  height: auto;
  ${(props) =>
    props.small
      ? `top: 22px`
      : props.medium
      ? `top: 31px`
      : props.large
      ? `top: 34px`
      : `top: 31px`};
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #efedf3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  z-index: 30;
  max-height: 180px;
  overflow-y: auto;
`;

export const ListLoading = styled.div`
  ${(props) => (props.show === true ? "display: flex" : "display: none")}
  width: 100%;
  position: absolute;
  height: auto;
  ${(props) =>
    props.small
      ? `top: 22px`
      : props.medium
      ? `top: 31px`
      : props.large
      ? `top: 34px`
      : `top: 31px`};
  background: #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #efedf3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }
`;

export const ListItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #5f4a87;
  display: flex;
  align-items: center;
  ${({ hasTitle }) =>
    hasTitle === true ? `padding-left: 25px;` : `padding-left: 10px`}
  border-radius: 50px;
  margin-top: 3px;
  cursor: pointer;
  ${({ hasImage }) => (hasImage ? `padding-left: 2px;` : `padding-left: 10px`)}

  ${({ selected }) =>
    selected === true &&
    `color: #fff;
        background: #5f4a87;`}

    &:hover {
    color: #fff;
    background: #5f4a87;
  }
`;

export const ListItemTitle = styled.div`
  font-family: "Roboto Condensed";
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #5f4a87;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 50px;
  margin-top: 3px;
  font-weight: bold;
  align-items: center;
`;

export const ValueText = styled.div`
  color: #5f4a87;
  display: flex;
  align-items: center;
`;

export const Autocomplete = styled.input`
  display: flex;
  width: 100%;
  ${(props) =>
    props.small
      ? `height: 35px`
      : props.medium
      ? `height: 40px`
      : props.large
      ? `height: 50px`
      : `height: 40px`};
  padding: 10px 16px;
  position: absolute;
  z-index: 1;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #efedf3; */
  border: 1px solid ${({ error }) => (error ? "#C6176E" : "#efedf3")};
  ${({ show }) =>
    show
      ? `border-radius: 15px;border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    `
      : `border-radius: 30px;`}
  color: #a79cbd;
  font-size: 16px;
  outline: none;

  &::placeholder {
    color: #a79cbd;
  }
`;

export const IconClose = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  flex: 20;
  display: flex;
  align-items: center;
`;

export const Text = styled.span``;

export const PhotoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  ${({ data }) => data && `background-image: url("${data}");`}
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;
`;

export const ContainerInput = styled.div``;

export const ContainerList = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  align-items: center;
  ${({ typeList }) =>
    typeList === "column" ? `flex-direction: column;` : `flex-direction: row;`}
`;

export const ListItemOut = styled.div`
  width: auto;
  height: 35px;
  padding: 2px 10px;
  background: #5f4a87;
  display: flex;
  min-width: 80px;
  border-radius: 50px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
`;

export const IconCloseOut = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`;

export const TextContainerOut = styled.div`
  flex: 20;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const TextOut = styled.span`
  color: white;
  font-size: 14px;
`;

export const PhotoOutContainer = styled.div``;

export const PhotoOut = styled.div``;
