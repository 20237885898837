import * as yup from "yup";

const requiredMessage = "Campo de preenchimento obrigatório";

const schema = (requiredFields = []) =>
  yup.object().shape({
    address: yup.object().shape({
      country: yup
        .string()
        .isRequired("country", requiredFields, requiredMessage)
        .nullable(),
      street: yup
        .string()
        .isRequired("street", requiredFields, requiredMessage)
        .nullable(),
      number: yup
        .string()
        .isRequired("number", requiredFields, requiredMessage)
        .isNotZero()
        .nullable(),
      complement: yup
        .string()
        .isRequired("additionalInformation", requiredFields, requiredMessage)
        .isNotZero()
        .nullable(),
      district: yup
        .string()
        .isRequired("district", requiredFields, requiredMessage)
        .nullable(),
      province: yup
        .string()
        .isRequired("state", requiredFields, requiredMessage)
        .nullable(),
      city: yup
        .string()
        .isRequired("city", requiredFields, requiredMessage)
        .nullable(),
      zipCode: yup
        .string()
        .isRequired("zipCode", requiredFields, requiredMessage)
        .when(["country", "province"], (country, province, schema) =>
          schema.test({
            name: "required",
            test: (zipCode) =>
              !(!province && zipCode?.length === 8 && country === "Brasil"),
            message: "CEP não localizado."
          })
        )
        .when(["country"], (country, schema) =>
          schema.test({
            name: "required",
            test: (zipCode) =>
              !zipCode || zipCode?.length === 8 || country !== "Brasil",
            message: "O CEP deve ter 8 dígitos."
          })
        )
        .nullable()
    })
  });

export default schema;
