import React from "react";

import useCurriculum from "@hooks/useCurriculum";
import { useCurriculumModal } from "@hooks/useCurriculumModal";
import { useCurriculumSections } from "@utils/hooks/useCurriculumSections";

import { ReactComponent as IconArrowLeft } from "@assets/svg/arrow-left.svg";

import { Title, Content, Header, GoBack } from "./styles";

export default function CurriculumSectionTemplate({
  title,
  children,
  ...rest
}) {
  const { handleOpenModal } = useCurriculumModal();
  const { handleResetForm } = useCurriculum();
  const {
    getPreviousSection,
    handlePreviousSection,
    changeSection,
    selectedSection
  } = useCurriculumSections();

  const previousSection = () => {
    const isSuccess = handlePreviousSection();
    if (!isSuccess) {
      handleOpenModal("go-back", () => {
        handleResetForm();
        changeSection(getPreviousSection(), false);
      });
    }
  };

  return (
    <section {...rest}>
      <Header>
        <Title>{title}</Title>
        {selectedSection?.key !== "about-you" && (
          <GoBack onClick={previousSection}>
            <IconArrowLeft />
            voltar
          </GoBack>
        )}
      </Header>

      <Content>{children}</Content>
    </section>
  );
}
