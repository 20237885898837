import { useMemo } from "react";
import { useCompany } from "@hooks/useCompany";
import useFetch from "@hooks/useFetch";

function useCompanyCities() {
  const { company } = useCompany();

  const { data: citiesData, loading } = useFetch(
    () =>
      company?.referenceId
        ? `/list-cities-vacancies?reference_id=${company?.referenceId}`
        : null,
    {
      revalidateOnFocus: false
    }
  );

  const data = useMemo(
    () => ({
      cities: (citiesData?.data || []).map((city) => ({
        ...city,
        id: city.cityId,
        value: city.cityId,
        name: city.cityName,
        text: city.cityName
      })),
      loading
    }),
    [citiesData, loading]
  );

  return data;
}

export { useCompanyCities };
