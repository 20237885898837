/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { getIn } from "formik";
import useDebounceCallback from "../../utils/hooks/useDebounceCallback";
import Input from "../input";

import * as Styled from "./styles";

export default function InputField({
  field,
  onChangeField = null,
  onChange = () => {},
  useDebounce = false, // depend on onChangeField
  debounceDelay = 1000,
  form: { touched, errors },
  showMessageError = true,
  showCounter = false,
  maxLength = null,
  form,
  ...props
}) {
  const [error, setError] = useState("");
  const [innerValue, setInnerValue] = useState("");

  useEffect(() => {
    setError(
      getIn(touched, field.name) && getIn(errors, field.name)
        ? getIn(errors, field.name)
        : ""
    );
  }, [touched, errors]);

  const debouncedHandleOnChange = useDebounceCallback(
    (event, ...otherParams) => {
      if (onChangeField) {
        onChangeField(event, ...otherParams);
      } else {
        field.onChange(event, ...otherParams);
      }
    },
    [field],
    debounceDelay
  );

  const formatCpf = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, "");
    const regexFormatter = cpf.replace(/\s+/g, "");
    return regexFormatter.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
  };

  const formatEmail = (email) => {
    const emailFormatter = email
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace("`", "")
      .replace("´", "");

    return emailFormatter;
  };

  const formatRg = (rg) => rg.replace(/[^a-zA-Z0-9]/g, "");

  useEffect(() => {
    setInnerValue(field.value || "");
  }, [field.value]);

  const handleInputChange = useCallback(
    (event, ...otherParams) => {
      if (!["datePicker", "textEditor"].includes(props.type)) {
        event.persist();

        let newValue = event.currentTarget.value;
        if (props.type === "money") {
          newValue = otherParams[0].decimal;
        }

        if (!maxLength || newValue.length <= maxLength) {
          setInnerValue(newValue);
        }
      }

      if (useDebounce) {
        debouncedHandleOnChange(event, ...otherParams);
      } else {
        onChangeField === null
          ? field.onChange(event, ...otherParams)
          : onChangeField(event, ...otherParams);
      }
    },
    [field]
  );

  useEffect(() => {
    let newValue;

    switch (props.label) {
      case "CPF":
        newValue = formatCpf(innerValue);
        break;
      case "RG":
        newValue = formatRg(innerValue);
        break;
      case "E-mail":
      case "Novo E-mail":
      case "Digite o seu e-mail novamente, por favor":
      case "Confirme o novo E-mail":
        newValue = formatEmail(innerValue);
        break;
      default:
        newValue = "";
        break;
    }

    !!newValue && setInnerValue(newValue);
  }, [innerValue]);

  return (
    <Styled.InputContainer {...props}>
      <Input
        {...field}
        {...props}
        value={innerValue}
        showValidateIcon
        onChange={handleInputChange}
        error={!!error}
      />
      <Styled.ContainerMessage>
        {showCounter && !!maxLength && (
          <Styled.Counter>{`${innerValue.length}/${maxLength}`}</Styled.Counter>
        )}
        {!!error && <Styled.ErrorText>{error}</Styled.ErrorText>}
      </Styled.ContainerMessage>
    </Styled.InputContainer>
  );
}
