import styled, { css } from "styled-components";

import { tablet } from "@styles/breakpoints";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  max-width: 540px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  padding: 5.25rem 4.5rem;

  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  text-align: center;
  letter-spacing: -0.1px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;

  ${({ size }) =>
    size === "small" &&
    css`
      max-width: 360px;
      padding: 2.5rem 2rem;
    `}

  @media screen and (max-width: ${tablet}) {
    margin: 2rem;
    padding: 2.75rem 2rem;
    font-size: 0.875rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0;

  @media screen and (max-width: ${tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

export const Description = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin: 1.5rem 0 2.25rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 1.75rem;
  right: 1.375rem;

  border: 0;
  background: none;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${tablet}) {
    top: 1.25rem;
    right: 1rem;

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`;
