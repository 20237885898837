import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import PropTypes from "prop-types";
import * as Styled from "./styles";

const SliderMark = ({ marks, ...props }) => (
  <Styled.Container>
    <Slider
      trackStyle={Styled.trackStyle}
      railStyle={Styled.railStyle}
      dotStyle={Styled.dotStyle}
      handleStyle={Styled.handleStyle}
      marks={marks}
      {...props}
    />
  </Styled.Container>
);

SliderMark.propTypes = {
  marks: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  )
};

export default SliderMark;
