import React from "react";
import * as Styled from "../styles";
import useClickPreventionOnDoubleClick from "../../../utils/hooks/useClickPreventionOnDoubleClick";

const Linkedin = ({
  onClick = () => {},
  onDoubleClick = () => {},
  text,
  isUppercase = true,
  children,
  leftIcon = false,
  ...rest
}) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick
  );

  return (
    <Styled.ContentButton type="button" {...rest}>
      <Styled.ButtonLinkedin
        {...rest}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        isUppercase={isUppercase}
      >
        {leftIcon !== false && (
          <Styled.Icon {...rest}>
            <i className="fab fa-linkedin" />
          </Styled.Icon>
        )}
        {text}
        {children}
      </Styled.ButtonLinkedin>
    </Styled.ContentButton>
  );
};

export default Linkedin;
