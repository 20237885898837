import styled from "styled-components";

export const Container = styled.div`
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #333333;
  padding: 44px 40px 20px 40px;
`;

export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

export const Video = styled.div`
  max-width: 340px;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 1rem;
  margin-top: 30px;
  margin-bottom: 40px;

  iframe {
    border-radius: 1rem;

    /* less than 768px */
    min-height: 158px;

    /* Medium devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      min-height: 168px;
    }
  }
`;

export const Text = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${(props) =>
    props.italic &&
    `
    font-style: italic;
  `}
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Title = styled.span`
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const CarouselContainer = styled.div`
  width: 100%;
`;

export const CompanyName = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin: 0 0 12px;
`;

export const CompanySlogan = styled.p`
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin: 0;
`;
