import { createPromiseAction } from "@adobe/redux-saga-promise";

export function signIn(email, password) {
  return {
    type: "@auth/SIGN_IN_REQUEST",
    payload: { email, password }
  };
}

export function signInLinkedin(code) {
  return {
    type: "@auth/SIGN_IN_LINKEDIN_REQUEST",
    payload: { code }
  };
}

export function logout() {
  return {
    type: "@auth/LOGOUT_REQUEST"
  };
}

export function restoreToken() {
  return {
    type: "@auth/RESTORE_TOKEN_REQUEST"
  };
}

export const forgotPassword = createPromiseAction(
  "@auth/FORGOT_PASSWORD_REQUEST"
);

export function updatePassword(data = {}) {
  return {
    type: "@auth/UPDATE_PASSWORD",
    payload: data
  };
}

export function signUp(data = {}) {
  return {
    type: "@auth/SIGN_UP_REQUEST",
    payload: data
  };
}

export function clearErrorRequestAuth() {
  return {
    type: "@auth/INITIAL_ERROR_REQUEST"
  };
}

export function clearSuccessPasswordReset() {
  return {
    type: "@auth/CLEAN_SUCCESS_PASSWORD_RESET"
  };
}

export function signUpLinkedin(code) {
  return {
    type: "@auth/SIGN_UP_LINKEDIN_REQUEST",
    payload: { code }
  };
}

export function getFacebookLink() {
  return {
    type: "@auth/GET_FACEBOOK_REQUEST"
  };
}

export function signUpOrSignInFacebook(data) {
  return {
    type: "@auth/SIGN_UP_OR_SIGN_IN_FACEBOOK_REQUEST",
    payload: data
  };
}

export function authenticateUserByToken(data) {
  return {
    type: "@auth/AUTHENTICATION_USER_BY_TOKEN",
    payload: data
  };
}
