import React, { useState, useEffect } from "react";
import { notification } from "antd";

import { ReactComponent as CameraBackground } from "@assets/svg/camera-background.svg";
import * as Styled from "./styles";

const ImageFile = ({
  label,
  placeholder,
  onChange,
  value,
  isFormData,
  isFile,
  fallbackImage,
  fallbackDelete,
  ...rest
}) => {
  const [file, setFile] = useState("");

  useEffect(() => {
    setFile(value);
  }, [value]);

  function _handleImageChange(e) {
    const reader = new FileReader();

    const tempFile = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedExtensions = ["jpg", "jpeg", "png"];

    if (
      allowedTypes.includes(tempFile.type) &&
      allowedExtensions.includes(
        tempFile.name.substr(tempFile.name.lastIndexOf(".") + 1)
      )
    ) {
      reader.onloadend = () => {
        const output = document.getElementById("myfile");

        output.src = reader.result;

        setFile(reader.result);

        if (isFormData) {
          const formData = new FormData();
          formData.append("file", tempFile);
          fallbackImage(formData);
        } else if (isFile) {
          fallbackImage(tempFile);
        } else {
          fallbackImage(reader.result);
        }
      };

      reader.readAsDataURL(tempFile);
    } else {
      notification.warn({
        message: "Atenção",
        description: "Formato enviado não permitido."
      });
    }
  }

  function handleElementClick() {
    document.getElementById("myfile").click();
  }

  return (
    <>
      {!!file === false ? (
        <Styled.ImageSvg onClick={() => handleElementClick()} {...rest}>
          <CameraBackground />
          <input
            onChange={_handleImageChange}
            type="file"
            id="myfile"
            style={{
              display: "none"
            }}
            accept=".png,.jpeg,.jpg"
          />
        </Styled.ImageSvg>
      ) : (
        <Styled.ProfilerPhotoContainer showOptions>
          <Styled.ProfilerPhotoUser
            onClick={() => handleElementClick()}
            src={file}
            id="image"
            {...rest}
          >
            <input
              onChange={_handleImageChange}
              type="file"
              id="myfile"
              style={{
                display: "none"
              }}
              accept=".png,.jpeg,.jpg"
            />
          </Styled.ProfilerPhotoUser>
          <Styled.OptionsContainer>
            <Styled.OptionText onClick={() => handleElementClick()}>
              Editar
            </Styled.OptionText>
            |
            <Styled.OptionText color="#C40B0B" onClick={fallbackDelete}>
              Excluir
            </Styled.OptionText>
          </Styled.OptionsContainer>
        </Styled.ProfilerPhotoContainer>
      )}
    </>
  );
};

export default ImageFile;
