/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Loading from "../../assets/loading/load.gif";
import { ReactComponent as CloseList } from "../../assets/svg/close-select-list.svg";
import { ReactComponent as Add } from "../../assets/svg/plusCircle.svg";
import { ReactComponent as Icon } from "../../assets/svg/iconSelect.svg";

import * as Styled from "./styles";

import { getAcronym } from "../../utils/getAcronym";

const defaultProps = {
  placeholder: "",
  data: [
    {
      id: 0,
      text: "",
      img: ""
    }
  ],
  onCreate: () => {},
  onChange: () => {},
  loading: false,
  showInitial: false,
  selected: {},
  clearIsSelected: false,
  canCreate: false,
  error: ""
};

const propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.array,
  onCreate: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  showInitial: PropTypes.bool,
  selected: PropTypes.object,
  clearIsSelected: PropTypes.bool,
  canCreate: PropTypes.bool,
  error: PropTypes.string
};

const SelectView = ({
  placeholder,
  data,
  onCreate,
  onChange,
  loading,
  showInitial,
  selected,
  clearIsSelected,
  canCreate,
  error,
  ...rest
}) => {
  const [_data, _setData] = useState(data);
  const [_show, _setShow] = useState(false);
  const [_selection, _setSelection] = useState({});
  const [_hasLoading, _setHasLoading] = useState(loading);
  const [_valueAutocomplete, _setValueAutocomplete] = useState("");

  useEffect(() => {
    _setHasLoading(loading);
  }, [loading]);

  useEffect(() => {
    const { name } = selected;

    !!name && _setValueAutocomplete(name);
    _setSelection(selected);
  }, [selected]);

  useEffect(() => {
    const newOptions = data
      .filter((item) =>
        item.text.toLowerCase().includes(_valueAutocomplete.toLowerCase())
      )
      .map((item) => ({
        ...item,
        selected: item.value === _selection.value && !!item.isTitle === false
      }));

    _setData(newOptions);
  }, [data, _selection, _valueAutocomplete]);

  const _handleChangeSelect = (item) => {
    const data = { ...item, selected: _selection.value !== item.value };
    const newSelected = _selection.value === data.value ? {} : data;

    _setShow(false);
    onChange(newSelected);
    _setSelection(newSelected);

    !clearIsSelected && _setValueAutocomplete(newSelected.text || "");
  };

  const _handleChangeValue = (e) => {
    const { value } = e.target;
    value !== undefined && _setValueAutocomplete(value);
  };

  const _renderInitialPhoto = (name, showInput) => (
    <Styled.InitialPhoto showInput={showInput}>
      <Styled.InitialPhotoText>{getAcronym(name)}</Styled.InitialPhotoText>
    </Styled.InitialPhoto>
  );

  const _renderPhotoList = (image, showInput) =>
    showInput ? (
      <Styled.PhotoAutocomplete data={image} />
    ) : (
      <Styled.PhotoContainer>
        <Styled.Photo data={image} />
      </Styled.PhotoContainer>
    );

  const showPhoto = (name, image, showInput = false) => {
    const hasPhoto = image !== "" && image !== undefined;
    return showInitial && hasPhoto === false
      ? _renderInitialPhoto(name, showInput)
      : showInitial && hasPhoto
      ? _renderPhotoList(image, showInput)
      : null;
  };

  return (
    <Styled.SelectContent {...rest}>
      <Styled.ContainerEntryData>
        <Styled.ContainerInput
          onBlur={() => _setShow(false)}
          tabIndex={0}
          onFocus={() => _setShow(true)}
        >
          <Styled.ContainerAutocompleteImage>
            {!!_valueAutocomplete &&
              showPhoto(_valueAutocomplete, _selection.img, true)}
            <Styled.Autocomplete
              onChange={_handleChangeValue}
              value={_valueAutocomplete}
              onFocus={() => _setShow(true)}
              onBlur={() => _setShow(false)}
              show={
                (!!_data.length && _show) ||
                (!!_valueAutocomplete && canCreate && _show)
              }
              placeholder="Selecione"
              photo={!!_valueAutocomplete && showInitial}
              error={error}
            />

            <Styled.Icon>
              <Icon />
            </Styled.Icon>
          </Styled.ContainerAutocompleteImage>

          <Styled.ContainerSelectList show={_show} {...rest}>
            {_hasLoading ? (
              <Styled.ListLoading show={_show} {...rest}>
                <img src={Loading} alt="loading" />
              </Styled.ListLoading>
            ) : (
              <Styled.ListContainer
                show={!!_data.length || (!!_valueAutocomplete && canCreate)}
                {...rest}
              >
                <Styled.List show={_show} hasImage {...rest}>
                  {_data.map((item, i) => {
                    const showClose = item.selected;

                    return (
                      <Styled.ListItem
                        key={`list_select2_${i}`}
                        onClick={() => _handleChangeSelect(item)}
                        hasImage={showInitial}
                        selected={item.selected}
                      >
                        {showPhoto(item.text, item.img)}

                        <Styled.TextContainer hasImage={showInitial}>
                          <Styled.Text>{item.text}</Styled.Text>
                        </Styled.TextContainer>

                        {showClose && (
                          <Styled.IconClose>
                            <CloseList />
                          </Styled.IconClose>
                        )}
                      </Styled.ListItem>
                    );
                  })}

                  {canCreate &&
                    _valueAutocomplete !== "" &&
                    _data.filter((e) => e.name === _valueAutocomplete)
                      .length === 0 && (
                      <Styled.Create
                        lenght={_data.length}
                        onClick={() => onCreate(_valueAutocomplete)}
                      >
                        <Styled.CreateIconContainer>
                          <Add />
                        </Styled.CreateIconContainer>
                        <Styled.CreateTextCotainer>
                          <Styled.CreateText>{`adicionar "${_valueAutocomplete}"`}</Styled.CreateText>
                        </Styled.CreateTextCotainer>
                      </Styled.Create>
                    )}
                </Styled.List>
              </Styled.ListContainer>
            )}
          </Styled.ContainerSelectList>

          {!!error && <Styled.InvalidFeedback>{error}</Styled.InvalidFeedback>}
        </Styled.ContainerInput>
      </Styled.ContainerEntryData>
    </Styled.SelectContent>
  );
};

SelectView.defaultProps = defaultProps;
SelectView.propTypes = propTypes;

export default SelectView;
