import API from "../../../services/api";

export const applyVacancy = async (data) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .post("/applicant/apply-job", data)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const getRequiredFields = async (vacancyId) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/vacancy/get-required-field?vagaId=${vacancyId}`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const getVacancy = async (vacancyId) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/vacancy/${vacancyId}`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const getMyApplications = async () =>
  new Promise((resolve, reject) => {
    const api = API();
    api.get("applicant/my-applications").then(resolve).catch(reject);
  });
