import React from "react";
import { Container } from "./styles";

const Label = ({ required, children, ...rest }) => (
  <Container {...rest}>
    {children}
    {required && (
      <>
        * <span className="label-required">(obrigatório)</span>
      </>
    )}
  </Container>
);

export default Label;
