import styled from "styled-components";

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-left: 2px solid rgba(0, 0, 0, 0.15);
  min-height: 48px;
  padding-left: 1.2rem;
  margin-bottom: 0.5rem;

  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;

  /* less than 576px */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ApplicationDate = styled.div`
  display: flex;
  flex: 2;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  margin: 0;

  /* less than 576px */
  div:first-child {
    margin: 0 4px 0 0;
  }

  div:first-child::after {
    margin: 0 0 0 1px;
    content: ":";
  }

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    margin-left: 0.2rem;
    flex-direction: column;
    div:first-child {
      margin: 0;
    }
    div:not(:first-child)::before {
      margin: 0 4px 0 0;
      content: none;
    }
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  :hover {
    color: #333333;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 125%;
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
