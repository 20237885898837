import styled from "styled-components";

export const ContainerOption = styled.div`
  position: relative;
  cursor: pointer;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    margin-bottom: 0px;
  }

  width: 100%;
  margin: 5px 0px;
`;

export const Label = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  border-radius: 50px;
  padding: ${({ styleOptions }) =>
    styleOptions.padding ? styleOptions.padding : `10px 30px 10px 13px`};

  :hover {
    background: #33333388;
    color: #ffffff;
  }

  ${({ selected }) =>
    selected &&
    `background: #333333;
        color: #ffffff;`}

  ${({ showPicture }) => showPicture && `padding-left: 48px;`}
`;

export const Close = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  z-index: 1;
  color: #efedf3;
  line-height: 16px;

  i {
    font-size: 14px;
  }

  ${({ selected }) => selected === false && `display: none;`}
`;
