import React from "react";

import { Container } from "./styles";

const Banner = ({ urlDesktop, urlMobile, defaultBackground = "#333333" }) => (
  <Container
    urlDesktop={urlDesktop}
    urlMobile={urlMobile}
    defaultBackground={defaultBackground}
  />
);

export default Banner;
