import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Typography } from "@components";
import { Label } from "@components/form";
import SliderSkill from "../sliderSkill";
import { LEVEL_SKILL } from "../../utils";

import { Content } from "../../styles";

export const RequiredSkills = () => {
  const { getValues } = useFormContext();
  const listSkills = getValues("skills") || [];

  const requiredSkills = useMemo(
    () => listSkills.filter((skill) => skill.required),
    [listSkills]
  );

  return (
    requiredSkills?.length > 0 && (
      <>
        <Label required>Habilidade Exigidas</Label>
        <Typography>
          Marque o seu nível de experiência com as habilidades exigidas para a
          vaga:
        </Typography>
        <Content style={{ marginTop: "1rem" }}>
          {requiredSkills.map((skill, index) => (
            <SliderSkill
              key={skill.value}
              marks={LEVEL_SKILL}
              skill={skill}
              name={`skills.${index}.level`}
            />
          ))}
        </Content>
      </>
    )
  );
};
