import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_LOGIN_SIGNUP,
  DEFAULT_HOST_LOGIN_FORGOT,
  DEFAULT_HOST_LOGIN_RECOVERY,
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_HIDDEN_VACANCY,
  DEFAULT_LOGIN_ROUTES,
  DEFAULT_HOST_LINKEDIN_CALLBACK,
  DEFAULT_HOST_FACEBOOK_CALLBACK
} from "@services/constants";

import Auth from "@pages/auth";
import LinkedinCallback from "@pages/auth/linkedinCallback";
import FacebookCallback from "@pages/auth/facebookCallback";
import SignIn from "@pages/auth/signIn";
import SignUp from "@pages/auth/signUp";
import ForgotPassword from "@pages/auth/forgotPassword";
import RecoveryPassword from "@pages/auth/recoveryPassword";

import * as cookie from "@utils/cookie";

const AuthRoute = ({ history, slug, token, ...rest }) => {
  const slugVerify = () => {
    const HOST = window.location.href.replace("//www.", "//");
    const { pathname } = window.location;

    const routeVerify =
      HOST.includes(DEFAULT_HOST_LOGIN) ||
      HOST.includes(DEFAULT_HOST_LOGIN_SIGNUP) ||
      HOST.includes(DEFAULT_HOST_LOGIN_FORGOT) ||
      HOST.includes(DEFAULT_HOST_LOGIN_RECOVERY);

    const socialVerify =
      HOST.includes(DEFAULT_HOST_LINKEDIN_CALLBACK) ||
      HOST.includes(DEFAULT_HOST_FACEBOOK_CALLBACK);

    if (token && slug === "login") {
      // verifica se o usuário está autenticado e está tentando acessar novamente a página de login
      // redireciona para a tela default do candidato
      return cookie.redirect(DEFAULT_HOST_CANDIDATE);
    }

    const hasValidRoute = DEFAULT_LOGIN_ROUTES.find((route) => {
      const matchRoute = pathname.replace(route, "");
      const isPasswordReset = route.includes("/passwordReset");
      const routeSplit = pathname.split("/");
      const isValidRouteWithResetPassword =
        matchRoute && isPasswordReset && routeSplit[2];
      const isValidRoute = !matchRoute && !isPasswordReset;

      return isValidRoute || isValidRouteWithResetPassword;
    });

    if (!token && routeVerify && (hasValidRoute || socialVerify)) {
      // verifica se não existe o token e se o usuário está no host de autenticação
      // e se o caminho atual no host está incluso nas rotas disponíveis de autenticação
      // registra as rotas disponíveis para a autenticação.
      return (
        <Auth history={history}>
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/signUp" component={SignUp} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route
              exact
              path="/passwordReset/:token"
              component={RecoveryPassword}
            />
            <Route
              {...rest}
              exact
              path="/auth/linkedin/callback"
              render={() => <LinkedinCallback history={history} />}
              history={history}
            />
            <Route
              {...rest}
              exact
              path="/auth/facebook/callback"
              render={() => <FacebookCallback history={history} />}
              history={history}
            />
          </Switch>
        </Auth>
      );
    }
    if (
      !slug &&
      !token &&
      !HOST.includes(DEFAULT_HOST_LOGIN) &&
      !HOST.includes(DEFAULT_HOST_HIDDEN_VACANCY) &&
      HOST.includes(DEFAULT_HOST_CANDIDATE)
    ) {
      // verifica se o usuário não possui mais o token e o usuário se encontra nas rotas de candidato e não esteja na vaga oculta
      // caso o mesmo esteja será redirecionado para a tela de autenticação
      // neste caso não precisa exibir mensagem de token expirado pois já se encontra no hook de auth (useAuth).
      return cookie.redirect(DEFAULT_HOST_LOGIN);
    }
    // caso não atenda a nenhuma das regras aqui irá carregar as próximas validações
    return null;
  };

  return slugVerify();
};

export default AuthRoute;
