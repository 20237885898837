const INITIAL_STATE = {
  listVacancies: {
    list: [],
    totalPages: 0,
    totalRecords: 0,
    totalAvailablePositions: 0,
    page: 1,
    pagination: 25
  },
  listCities: [],
  vacancy: {},
  errorApply: ""
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@vacancies/LIST_VACANCIES_SUCCESS":
      return {
        ...state,
        listVacancies: action.payload.listVacancies,
        listCities: action.payload.listCities
      };
    case "@vacancies/SEARCH_VACANCIES_BY_COMPANY_SUCCESS":
      return {
        ...state,
        listVacancies: action.payload.listVacancies,
        listCities: action.payload.listCities
      };
    case "@vacancies/LIST_CITIES_SUCCESS":
      return {
        ...state,
        listCities: action.payload.listCities
      };
    case "@vacancies/DESCRIPTION_VACANCY_SUCCESS":
      return {
        ...state,
        vacancy: action.payload.vacancy
      };
    case "@vacancies/CLEAR_LIST_VACANCIES_SUCCESS":
      return {
        ...state,
        vacancy: {}
      };
    default:
      return state;
  }
}
