import React from "react";
import * as Styled from "./styles";

const Loading = ({ content }) => (
  <Styled.ContainerLoading>
    <Styled.Loader>
      <Styled.LoaderDot />
      <Styled.LoaderDot />
      <Styled.LoaderDot />
      <Styled.LoaderDot />
      <Styled.LoaderDot />
      <Styled.LoaderText text={content} />
    </Styled.Loader>
  </Styled.ContainerLoading>
);

export default Loading;
