import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

const Button = ({ type, children, disabled, ...rest }) => (
  <Container disabled={disabled} type={type} {...rest}>
    {children}
  </Container>
);

Button.defaultProps = {
  type: "button"
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Button;
