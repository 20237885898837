import React from "react";

import * as Styled from "./styles";

import Group from "./group";

const Groups = ({
  list = [
    {
      logo: null,
      title: "",
      subTitle: "",
      details: [
        {
          topic: "",
          subTopic: "",
          description: ""
        }
      ]
    },
    {
      logo: null,
      title: "",
      subTitle: "",
      details: [
        {
          topic: "",
          subTopic: "",
          description: ""
        },
        {
          topic: "",
          subTopic: "",
          description: ""
        }
      ]
    }
  ]
}) => (
  <Styled.ContainerGroups>
    {list.map((item) => (
      <Group
        logo={item.logo}
        title={item.title}
        subTitle={item.subTitle}
        details={item.details}
        key={`group_${Math.random(1000)}`}
      />
    ))}
  </Styled.ContainerGroups>
);

export default Groups;
