export function parseObjectToURLEncode(objectData) {
  try {
    const params = Object.keys(objectData).map(
      (key) => `${key}=${objectData[key]}`
    );

    return params.join("&");
  } catch (err) {
    return "";
  }
}

export function parseURLEncodeToObject(url, isEmpty = true) {
  try {
    const objectData = {};
    const data = url.replace("?", "").split("&");

    data.forEach((item, index) => {
      const [key, value] = item.split("=");

      if (key) objectData[key] = value;
    });

    return Object.keys(objectData).length === 0 && isEmpty === false
      ? undefined
      : objectData;
  } catch (err) {
    return undefined;
  }
}
