import React from "react";
import { Route } from "react-router-dom";

import {
  DEFAULT_HOST_HIDDEN_VACANCY,
  DEFAULT_HIDDEN_VACANCY_ROUTES
} from "../../services/constants";

import HiddenVacancy from "../../pages/hiddenVacancy";

const HiddenVacancyRoutes = ({ history, slug, ...rest }) => {
  const slugVerify = () => {
    const HOST = window.location.href.replace("//www.", "//");
    const { pathname } = window.location;
    const hasVacancyId = pathname.replace(DEFAULT_HIDDEN_VACANCY_ROUTES, "");

    // verifica se é vaga oculta pela rota
    // e dentro da página validar via API se é uma vaga oculta mesmo
    if (
      !slug &&
      HOST.includes(DEFAULT_HOST_HIDDEN_VACANCY) &&
      pathname.includes(DEFAULT_HIDDEN_VACANCY_ROUTES) &&
      hasVacancyId
    ) {
      // caso não possua slug da empresa, exista um token ativo e o usuário esteja acessando
      // o host da vaga oculta e se o caminho atual no host está incluso nas rotas disponíveis da vaga oculta
      // então registra as rotas disponíveis para a vaga oculta.
      return (
        <>
          <Route
            {...rest}
            exact
            path="/vacancies/:id"
            render={() => <HiddenVacancy history={history} />}
          />
        </>
      );
    }
    // caso não atenda a nenhuma das regras aqui irá carregar as próximas validações
    return null;
  };

  return slugVerify();
};

export default HiddenVacancyRoutes;
