import styled from "styled-components";

export const ContentButton = styled.button`
  width: ${({ width }) => width || `100%`};
  ${({ width }) => width !== undefined && `width: ${width}`}
  ${({ buttonGroup = false }) => buttonGroup && `width: auto;`}
    ${(props) =>
    props.small
      ? `height: 32px;
          font-size: 12px;
          line-height: 15px;`
      : props.medium
      ? `height: 40px;
          font-size: 14px;
          line-height: 17px;`
      : props.large
      ? `height: 58px;
          font-size: 16px;
          line-height: 19px;`
      : `height: 32px;
        font-size: 12px;
        line-height: 15px;`};

  ${({ blocked }) => !blocked && "cursor: pointer;"}
  ${({ disabled }) => (disabled ? "pointer-events: none;" : "cursor: pointer;")}

  user-select: none;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

export const ButtonCustom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  ${({ borderWidth }) =>
    borderWidth && `border-style: solid; border-width: ${borderWidth};`}
  ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : fontWeight)};

  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"}

  white-space: nowrap;
  text-align: center;
  ${({ isUppercase }) => isUppercase && "text-transform: uppercase;"}
  padding: 0 10px;
  background: ${({ backgroundColor }) => backgroundColor || "#333333"};
  color: ${({ textColor }) => textColor || "#fff"};
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);

  ${(props) =>
    props.small
      ? `font-size: 12px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 14px;
        line-height: 16px;`
      : props.large
      ? `font-size: 16px;
        line-height: 18px;`
      : `font-size: 12px;
      line-height: 14px;`};

  ${({ boxShadow }) =>
    boxShadow &&
    `box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2);`}

  ${({ boxShadowColor }) => boxShadowColor && `box-shadow: ${boxShadowColor};`}

    :hover {
    ${({ backgroundHover }) =>
      backgroundHover && `background: ${backgroundHover};`}
    ${({ textHover }) => textHover && `color: ${textHover};`}
  }

  &.curriculum {
    /* Small devices (desktops, 992px and down) */
    @media (max-width: 991px) {
      border-radius: 0;
      padding: 30px;
      position: fixed;
      bottom: 0;
      left: 0;
      height: 60px;
      font-size: 16px;
    }
  }
`;

export const ButtonLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: "Roboto Condensed";
  font-style: normal;
  white-space: nowrap;
  text-align: center;
  padding: 0 10px;
  background: transparent;

  ${({ isUppercase }) => isUppercase && "text-transform: uppercase;"}
  color: ${({ textColor }) => textColor || "#fff"};
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : fontWeight)};
  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"};

  ${(props) =>
    props.small
      ? `font-size: 14px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 16px;
        line-height: 16px;`
      : props.large
      ? `font-size: 18px;
        line-height: 18px;`
      : `font-size: 14px;
      line-height: 14px;`};

  ${({ boxShadow }) =>
    boxShadow &&
    `box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2);`}

  :hover {
    ${({ backgroundHover }) =>
      backgroundHover && `background: ${backgroundHover};`}
    ${({ textHover }) => textHover && `color: ${textHover};`}
  }
`;

export const ContainerSocial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 42px;
    height: 16px;
  }
`;

export const ButtonSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 2px;
  background: transparent;
  border-style: solid;
  margin: 0 2px;

  border-color: ${({ borderColor, color }) =>
    borderColor || color || "#333333"};
  color: ${({ color }) => color || "#000000"};
  border-width: ${({ borderWidth }) => borderWidth || "2px"};
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  background: ${({ backgroundColor }) => backgroundColor || "transparent"};

  ${Link} {
    path {
      fill: ${({ color }) => color || "#000000"};
    }
  }

  :hover {
    border-color: ${({ borderColor, color }) =>
      color || borderColor || "#000000"};

    background: ${({ color }) => color || "#000000"};

    color: ${({ textHover }) => textHover || "#ffffff"};

    ${Link} {
      path {
        fill: ${({ textHover }) => textHover || "#ffffff"};
      }
    }
  }
`;

export const LeftIcon = styled.div`
  ${({ cursor }) => cursor && "cursor: pointer;"}

  svg {
    path {
      fill: ${({ iconColor }) => iconColor || "#ffffff"};
    }

    ${({ small, medium, large }) =>
      small
        ? `height: 14px; width: 14px;`
        : medium
        ? `height: 18px; width: 25px;`
        : large
        ? `height: 20px; width: 20px;`
        : `height: 16px; width: 16px;`}
  }
`;

export const RightIcon = styled.div`
  ${({ cursor }) => cursor && "cursor: pointer;"}

  position: absolute;
  right: 5%;
  top: auto;
  bottom: auto;

  svg {
    path {
      fill: ${({ iconColor }) => iconColor || "#ffffff"};
    }

    ${({ small, medium, large }) =>
      small
        ? `height: 14px; width: 14px;`
        : medium
        ? `height: 16px; width: 16px;`
        : large
        ? `height: 20px; width: 20px;`
        : `height: 16px; width: 16px;`}
  }
`;

export const ButtonOutlineText = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "bold"};
  white-space: nowrap;
  text-align: center;
  ${({ isUppercase }) => isUppercase && "text-transform: uppercase;"}

  ${({ leftIcon }) => leftIcon && "padding-left: .5rem;"}
  ${({ rightIcon }) => rightIcon && "padding-right: 15px;"}
`;

export const ButtonOutline = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /*auto*/
  height: 100%;

  padding: 0 10px; /* 0 5px */
  background: transparent;
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  border-style: solid;
  border-width: ${({ borderWidth }) => borderWidth || "2px"};
  border-color: ${({ borderColor, textColor }) =>
    borderColor || textColor || "#333333"};
  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"}
  color: ${({ textColor }) => textColor || "#333333"};

  ${(props) =>
    props.small
      ? `font-size: 12px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 14px;
        line-height: 16px;`
      : props.large
      ? `font-size: 16px;
        line-height: 18px;`
      : `font-size: 12px;
      line-height: 14px;`};

  svg {
    path {
      fill: ${({ textColor }) => textColor || "#ffffff"};
    }
  }

  :hover {
    border-color: ${({ borderColor, textColor }) =>
      textColor || borderColor || "#333333"};

    background: ${({ textColor }) => textColor || "#333333"};
    color: ${({ textHover }) => textHover || "#ffffff"};
  }

  &:hover ${RightIcon} {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  &:hover ${LeftIcon} {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

export const ButtonLinkedin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  ${({ borderColor }) => borderColor && `border: 2px solid ${borderColor};`}

  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : fontWeight)};

  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"}

  white-space: nowrap;
  text-align: center;
  ${({ isUppercase }) => isUppercase && "text-transform: uppercase;"}
  padding: 0 10px;
  background: ${({ backgroundColor }) => backgroundColor || "#0073B0"};
  color: ${({ textColor }) => textColor || "#FFFFFF"};

  ${(props) =>
    props.small
      ? `font-size: 14px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 16px;
        line-height: 16px;`
      : props.large
      ? `font-size: 18px;
        line-height: 18px;`
      : `font-size: 14px;
      line-height: 14px;`};

  ${({ boxShadow }) =>
    boxShadow
      ? `box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2);
               text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);`
      : `box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.35), inset 0px -2px 0px rgba(0, 0, 0, 0.2);`}

  svg {
    path {
      fill: ${({ textColor }) => textColor || "#ffffff"};
    }
  }
`;

export const ButtonFacebook = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  ${({ borderColor }) => borderColor && `border: 2px solid ${borderColor};`}

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : fontWeight)};

  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"}

  white-space: nowrap;
  text-align: center;
  ${({ isUppercase }) => isUppercase && "text-transform: uppercase"};
  padding: 0 10px;
  background: ${({ backgroundColor }) => backgroundColor || "#2F80ED"};
  color: ${({ textColor }) => textColor || "#FFFFFF"};

  ${(props) =>
    props.small
      ? `font-size: 14px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 16px;
        line-height: 16px;`
      : props.large
      ? `font-size: 18px;
        line-height: 18px;`
      : `font-size: 14px;
      line-height: 14px;`};

  ${({ boxShadow }) =>
    boxShadow &&
    `box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2);`}
  ${({ boxShadow }) =>
    boxShadow && `text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);`}

  svg {
    path {
      fill: ${({ textColor }) => textColor || "#ffffff"};
    }
  }

  :hover {
    border-color: ${({ borderColor, textColor }) =>
      textColor || borderColor || "#277df1"};
    background: ${({ textColor }) => textColor || "#277df1"};
    color: #fff;
  }

  &:hover ${RightIcon} {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  &:hover ${LeftIcon} {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

export const ButtonGoogle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  ${({ borderColor }) => borderColor && `border: 2px solid ${borderColor}`};

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? "bold" : fontWeight)};

  ${({ blocked }) => blocked && "cursor: no-drop;  opacity: 0.7;"}

  white-space: nowrap;
  text-align: center;
  ${({ isUppercase }) => isUppercase && "text-transform: uppercase;"}
  padding: 0 10px;
  background: ${({ backgroundColor }) => backgroundColor || "#D74149"};
  color: ${({ textColor }) => textColor || "#FFFFFF"};

  ${(props) =>
    props.small
      ? `font-size: 14px;
        line-height: 14px;`
      : props.medium
      ? `font-size: 16px;
        line-height: 16px;`
      : props.large
      ? `font-size: 18px;
        line-height: 18px;`
      : `font-size: 14px;
      line-height: 14px;`};

  ${({ boxShadow }) =>
    boxShadow &&
    `box-shadow: 0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2);`}
  ${({ boxShadow }) =>
    boxShadow && `text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);`}

  svg {
    path {
      fill: ${({ textColor }) => textColor || "#ffffff"};
    }
  }

  :hover {
    border-color: ${({ borderColor, textColor }) =>
      textColor || borderColor || "#277df1"};
    background: ${({ textColor }) => textColor || "#277df1"};
    color: #fff;
  }

  &:hover ${RightIcon} {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  &:hover ${LeftIcon} {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

export const Icon = styled.div`
  ${({ cursor }) => cursor && "cursor: pointer;"}
  margin-right: 15px;

  ${({ small, medium, large }) =>
    small
      ? "font-size: 14px;"
      : medium
      ? "font-size: 22px !important;"
      : large
      ? "font-size: 20px;"
      : "font-size: 16px;"}
`;
