import React from "react";
import Paginate from "../../../../components/paginate";

import { ReactComponent as Inscrito } from "../../../../assets/svg/inscrito.svg";
import * as Styled from "./styles";

const ListVacancies = ({
  listVacancies = [],
  showButton = true,
  pagination = false,
  fallbackVacancyDescription = () => {},
  fallbackPageHandle = () => {}
}) => {
  const statusRenderer = (value) => {
    const status = {
      "In Process": "Vagas em Andamento",
      Finished: "Vaga Concluída",
      Canceled: "Vaga Cancelada"
    };

    return status[value] || "Vaga em Andamento";
  };

  const phaseRenderer = (phase) => {
    const phases = {
      CANDIDATOS: "Análise de currículo",
      CANDIDATES: "Análise de currículo",
      "FIT COMPORTAMENTAL": "Análise comportamental",
      "BEHAVIORAL FIT": "Análise comportamental",
      "ENTREVISTA CHATBOT": "Perguntas customizadas",
      "CHATBOT INTERVIEW": "Perguntas customizadas",
      PROVAS: "Provas",
      TESTS: "Provas",
      HIRED: "Contratado"
    };

    return phases[phase] || capitalize(phase.toLowerCase());
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <Styled.ListVacanciesContainer>
      {listVacancies?.map((vacancy, index) => (
        <Styled.BoxListItem
          key={`vacancy_${index}`}
          onClick={() => {
            !!vacancy.status &&
              vacancy.status === "In process" &&
              fallbackVacancyDescription(vacancy.id);
            window.scroll(0, 0);
          }}
          active={!!vacancy.status && vacancy.status === "In process"}
          cursor={
            !!vacancy.status && vacancy.status === "In process" && "pointer"
          }
        >
          <Styled.BoxListItemCol>
            <Styled.Text>{!!vacancy.vacancy && vacancy.vacancy}</Styled.Text>
            <Styled.Text fontWeight="bold" fontSize="12px">
              {!!vacancy.company && vacancy.company}
            </Styled.Text>
          </Styled.BoxListItemCol>
          <Styled.BoxListItemCol>
            <Styled.Text>
              {!!vacancy.status && statusRenderer(vacancy.status)}
            </Styled.Text>
            <Styled.Text fontWeight="bold" fontSize="12px">
              {!!vacancy.phase && phaseRenderer(vacancy.phase)}
            </Styled.Text>
          </Styled.BoxListItemCol>
          <Styled.BoxListItemCol>
            <Styled.Text>
              {!!vacancy.applyDate && vacancy.applyDate}
            </Styled.Text>
          </Styled.BoxListItemCol>

          {!!showButton && (
            <Styled.BoxListItemCol>
              <Styled.ButtonContainer>
                <Styled.Anchor
                  href="#vacancyDescription"
                  status="inscrito"
                  disabled={!!vacancy.status && vacancy.status !== "In process"}
                  onClick={() => {
                    !!vacancy.status &&
                      vacancy.status === "In process" &&
                      fallbackVacancyDescription(vacancy.id);
                    window.scroll(0, 0);
                  }}
                >
                  <Styled.ButtonText>
                    {!!vacancy.status && vacancy.status === "In process"
                      ? "VER VAGA"
                      : "INSCRITO"}
                  </Styled.ButtonText>
                  <Styled.ButtonIconContainer>
                    <Inscrito />
                  </Styled.ButtonIconContainer>
                </Styled.Anchor>
              </Styled.ButtonContainer>
            </Styled.BoxListItemCol>
          )}
        </Styled.BoxListItem>
      ))}

      {!!pagination && (
        <Styled.BoxPagination>
          <Paginate
            totalPages={listVacancies.totalPages}
            currentPage={listVacancies.page}
            fallbackPageHandle={(e) => fallbackPageHandle(e)}
            color="#333333"
            size={30}
          />
        </Styled.BoxPagination>
      )}
    </Styled.ListVacanciesContainer>
  );
};

export default ListVacancies;
