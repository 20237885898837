import React from "react";

import * as Styled from "./styles";

export const Body = ({ onClose }) => (
  <Styled.ContainerBody>
    <Styled.ContainerContent>
      <Styled.Text onClose={onClose}>
        Olá, <br />
        <br />
        Se você está enfrentando dificuldade de acesso a nossa plataforma do
        Jobs nos desculpe. <br />
        <br />
        Neste momento, a AWS, provedora global de serviços de hospedagem, está
        passando por instabilidades e isso tem afetado diversas marcas em todo
        mundo, muitas aqui no Brasil, <b>desde hoje, 22/12, pela manhã. </b>
        <br />
        <br />
        Todo nosso time de Customer Success e Tecnologia está acompanhando
        minuto a minuto o andar da solução da instabilidade, que demanda ação da
        própria AWS, buscando também meios de manter a usabilidade da nossa
        plataforma a melhor possível. <br />
        <br />
        Agradecemos sua compreensão e parceria! <br />
        <br />
        Time Sólides
      </Styled.Text>
      <Styled.Button onClick={onClose}>Fechar</Styled.Button>
    </Styled.ContainerContent>
  </Styled.ContainerBody>
);
