// import React from "react";
// import ReactDOM from "react-dom";
// import htmlToImage from "html-to-image";
// import html2canvas from "html2canvas";
// import axios from "axios";

// import ReactDOMServer from "react-dom/server";

// import jsPDF from "jspdf";
import uniqueID from "./uniqueID";
// const pxToMm = (px) => {
//     return Math.floor(px / document.getElementById("pdf").offsetHeight);
// };
// const mmToPx = mm => {
//     return document.getElementById("pdf").offsetHeight * mm;
// };
export default async function downloadPdf(
  containerId,
  type = "save",
  filename = uniqueID(),
  reactElement = null
) {
  // download html begin ====================================

  const bodyOriginal = document.getElementById(containerId);
  const bodyElement = bodyOriginal.cloneNode(true);
  const { styleSheets } = document;
  const css = [];
  const cssFragment = document.createDocumentFragment();

  for (let i = 0; i < styleSheets.length; i++) {
    const sheet = styleSheets[i];
    const copyNode = sheet.ownerNode.cloneNode(true);
    css.push(copyNode);
  }

  const htmlDocument = document.implementation.createDocument(
    "http://www.w3.org/1999/xhtml",
    "html",
    null
  );
  const head = document.createElementNS("http://www.w3.org/1999/xhtml", "head");
  const body = document.createElementNS("http://www.w3.org/1999/xhtml", "body");

  const meta = htmlDocument.createElement("meta");
  meta.setAttribute("http-equiv", "Content-Type");
  meta.setAttribute("content", "text/html; charset=utf-8");

  head.appendChild(meta);

  css.forEach((style) => {
    const tempFragment = document.createDocumentFragment();
    tempFragment.appendChild(style);
    cssFragment.appendChild(tempFragment);
  });

  head.appendChild(cssFragment);
  body.appendChild(bodyElement);

  htmlDocument.documentElement.appendChild(head);
  htmlDocument.documentElement.appendChild(body);

  const xml = new XMLSerializer().serializeToString(htmlDocument);
  const encoded = xml
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
  // const encoded = encodeURIComponent(
  //     xml.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
  // );
  // const encoded = new Buffer(
  //     xml.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
  // ).toString("base64");

  const link = document.createElement("a");
  link.setAttribute("download", "page.html");
  link.setAttribute("href", `data:text/html; charset=utf-8,${encoded}`);
  // link.click();

  const myblob = new Blob([encoded], { type: "text/html" });

  const formData = new FormData();
  formData.append("file", myblob);

  // axios
  //     .post(`http://axeldg.com/lab/upload/`, formData)
  //     .then((data) => {
  //         console.log("success", data);
  //     })
  //     .catch((error) => {
  //         console.log("error", error);
  //     });

  // download html end ====================================

  // // const input = document.getElementById(containerId);
  // // const inputHeightMm = pxToMm(input.offsetHeight);
  // // await html2canvas(input).then(canvas => {
  // //     const imgData = canvas.toDataURL("image/jpg");
  // //     const pdf = new jsPDF();
  // //     // pdf.addImage(imgData, "PNG", 0, 0);
  // //     console.log("imgData", imgData);
  // //     pdf.addImage(imgData, "JPEG", 0, 0, 210, inputHeightMm);
  // //     pdf.save("download.pdf");
  // // });
  // // let container = "";
  // // if (reactElement === null) {
  // //     container = document.getElementById(containerId);
  // // } else {
  // //     container = ReactDOM.hydrate(
  // //         reactElement,
  // //         document.getElementById("pdf")
  // //     );
  // // }
  // // const inputHeightMm = pxToMm(container.offsetHeight);
  // // const a4WidthMm = 210; //210
  // // const a4HeightMm = 297;
  // // // const a4HeightPx = mmToPx(a4HeightMm);
  // // // const numPages =
  // // //     inputHeightMm <= a4HeightMm
  // // //         ? 1
  // // //         : Math.floor(inputHeightMm / a4HeightMm) + 1;
  // // let pdf = new jsPDF();
  // // // console.log("a4WidthMm", a4WidthMm);
  // // // console.log("inputHeightMm", inputHeightMm);
  // // // if (inputHeightMm > a4HeightMm) {
  // // //     pdf = new jsPDF("p", "mm", [a4WidthMm, inputHeightMm + 16]);
  // // // }
  // // setTimeout(async () => {
  // //     const height = parseInt(container.offsetHeight);
  // //     console.log("container.offsetHeight", height);
  // //     const image = await htmlToImage.toPng(container, {
  // //         height: height,
  // //     });
  // //     console.log("imgData", image);
  // //     pdf.addImage(image, "PNG", 0, 0, a4WidthMm, inputHeightMm);
  // //     if (type === "save") {
  // //         return pdf.save(`${filename}.pdf`);
  // //     } else {
  // //         return pdf.output("blob");
  // //     }
  // // }, 2000);
  // // setTimeout(async () => {
  // const container = document.getElementById(containerId);
  // const height = parseInt(container.offsetHeight);
  // const imgOriginal = await htmlToImage.toCanvas(container, {
  //     height: height,
  // });
  // const canvas = document.createElement("canvas"), // In memory canvas
  //     ctx = canvas.getContext("2d"),
  //     // parts = [], // to push into oud base64 strings
  //     img = new Image();
  // const imgData = imgOriginal.toDataURL("image/png"),
  //     imgWidth = 793, //210,
  //     pageHeight = 1077, //285,
  //     imgHeight = (imgOriginal.height * imgWidth) / imgOriginal.width;
  // let heightLeft = imgHeight;
  // const doc = new jsPDF("p", "mm");
  // img.onload = function () {
  //     while (heightLeft >= 0) {
  //         const y = heightLeft >= pageHeight ? 0 : -pageHeight;
  //         canvas.width = imgWidth;
  //         canvas.height = pageHeight;
  //         ctx.drawImage(img, 0, y, imgWidth, imgHeight); // img, x, y, w, h
  //         // parts.push(canvas.toDataURL()); // ("image/jpeg") for jpeg
  //         if (heightLeft < pageHeight) doc.addPage();
  //         doc.addImage(canvas.toDataURL(), "PNG", 0, 10);
  //         heightLeft -= pageHeight;
  //     }
  //     return doc.save(`${filename}.pdf`);
  // };
  // img.src = imgData;
  // // let imgData = canvas.toDataURL("image/png");
  // // let imgWidth = 210;
  // // let pageHeight = 297;
  // // let imgHeight = (canvas.height * imgWidth) / canvas.width;
  // // let heightLeft = imgHeight;
  // // let doc = new jsPDF("p", "mm");
  // // let position = 0; // give some top padding to first page
  // // doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  // // heightLeft -= pageHeight;
  // // while (heightLeft >= 0) {
  // //     position += heightLeft - imgHeight; // top padding for other pages
  // //     doc.addPage();
  // //     doc.fromHTML("<div></div>", 0, 20);
  // //     doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  // //     heightLeft -= pageHeight;
  // // }
  // // return doc.save(`${filename}.pdf`);
  // // }, 2000);
}
