import API from "@services/api";

export const getDescriptionVacancy = async (vacancyId) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .get(`/vacancy/${vacancyId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const setChangeEmail = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .post(`/user/change-email-login`, json)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const setChangePassword = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api
      .post(`/user/change-password`, json)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteUser = async () =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .delete(`/user/destroy`, {
        headers: {
          jToken: "ABC"
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
