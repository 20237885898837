import React, { useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import useCurriculum from "@hooks/useCurriculum";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { useCurriculumSections } from "@hooks/useCurriculumSections";

import {
  experiencesSchema,
  MAX_LENGTH_TEXTAREA
} from "@utils/formValidations/curriculumV2";

import { Textarea, Label } from "@components/form";
import { CurriculumSectionTemplate, Typography } from "@components";
import { CourseExperience } from "./components/courseExperience";
import { ProfessionalExperience } from "./components/professionalExperience";

import { Row, Col, Form } from "../styles";

function Experiences({ onSave, children }) {
  const { requiredFields, isApplyVacancy } = useApplyVacancy();
  const { registerForm, handleSubmit } = useCurriculum();
  const { sectionOptions } = useCurriculumSections();

  useEffect(() => {
    const resolver = yupResolver(experiencesSchema(requiredFields));

    registerForm({
      schema: { resolver },
      handleSubmit: { onSubmit }
    });
  }, [requiredFields, sectionOptions]);

  const formattedDates = (value) => {
    const { startYear, startMonth, endYear, endMonth } = value;
    const startDate = `${startYear}-${startMonth.padStart(2, "0")}-01`;
    const endDate =
      !!endYear && !!endMonth
        ? `${endYear}-${endMonth.padStart(2, "0")}-01`
        : "";

    return { startDate, endDate };
  };

  const curriculumCourses = ({ courses }) => {
    if (!courses) return [];

    return courses.map((course) => ({
      college: course.college,
      course: course.course,
      level: course.level,
      current: course.current === "on",
      ...formattedDates(course)
    }));
  };

  const curriculumExperiences = ({ experiences }) => {
    if (!experiences) return [];

    return experiences.map((experience) => ({
      occupation: experience.occupation,
      company: experience.company,
      description: experience.description,
      current: experience.current === "on",
      ...formattedDates(experience)
    }));
  };

  const onSubmit = useCallback(
    (data, nextPath) => {
      const formattedData = {
        about: data.about || "",
        experiences: curriculumExperiences(data),
        courses: curriculumCourses(data)
      };

      onSave(formattedData, nextPath);
    },
    [sectionOptions]
  );

  return (
    <CurriculumSectionTemplate title="Experiências">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Label required={requiredFields.includes("about_me")}>
              Sobre suas experiências
            </Label>
            <Typography>
              Fale um pouco sobre você, suas experiências e objetivos de
              carreira.
            </Typography>
            <Typography>
              Uma breve descrição sobre os maiores projetos que você já
              trabalhou e as habilidades em que você se destaca será valorizada
              pelos recrutadores!
            </Typography>

            <Textarea
              rows={8}
              name="about"
              disabledResize
              placeholder="Digite aqui."
              limitWarning
              maxLength={MAX_LENGTH_TEXTAREA}
            />
          </Col>
        </Row>

        <ProfessionalExperience
          defaultBox={isApplyVacancy}
          required={requiredFields.includes("professionalExperiences")}
        />

        <CourseExperience
          defaultBox={isApplyVacancy}
          required={requiredFields.includes("academicEducations")}
        />

        {children}
      </Form>
    </CurriculumSectionTemplate>
  );
}

export default React.memo(Experiences);
