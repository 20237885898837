import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.75rem;

  width: 100%;
  overflow-y: auto;
  position: relative;
  padding: 0 3.375rem 1.5rem;

  h2,
  button {
    font-weight: bold;
    font-family: "Roboto Condensed", arial, sans-serif;
  }

  p,
  h2 {
    margin: 0;
    text-align: center;
  }

  > p {
    font-size: 1rem;
    line-height: 2.25rem;
  }

  button {
    width: 100%;
    max-width: 200px;
    height: 2.5rem;
  }

  @media (max-width: 600px) {
    gap: 1.5rem;
    padding: 0 1.25rem;
    margin-top: -1rem;

    > p {
      line-height: 1.75rem;
    }
  }
`;

export const AcceptContent = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.purple};

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`;
