export const getGenders = () => [
  {
    value: "female",
    label: "Feminino"
  },
  {
    value: "male",
    label: "Masculino"
  },
  {
    value: "not_binary",
    label: "Não Binário"
  }
];

export const getWhatsAppOptions = () => [
  {
    value: "yes",
    label: "Sim"
  },
  {
    value: "no",
    label: "Não"
  }
];

export const getMaritalStatus = () => [
  {
    value: "casado",
    label: "Casado(a)"
  },
  {
    value: "solteiro",
    label: "Solteiro(a)"
  },
  {
    value: "uniao_estavel",
    label: "União estável"
  },
  {
    value: "divorciado",
    label: "Divorciado(a)"
  },
  {
    value: "viuvo",
    label: "Viúvo(a)"
  }
];
