import { call, put, select, all, takeLatest, take } from "redux-saga/effects";
import { notification } from "antd";
import { resolvePromiseAction } from "@adobe/redux-saga-promise";

import { getKeysExistVal } from "@utils/searchArray";
import * as cookie from "@utils/cookie";

import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_COMPANY
} from "@services/constants";

import message from "@components/message";

import * as api from "./repository";

export function* getInitialCurriculumSaga() {
  try {
    yield put({
      type: "@authentication/SET_STATUS_AUTH",
      payload: true
    });

    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    yield put({
      type: "@curriculum/CHANGE_SUBMIT_BUTTON_VISIBILITY",
      payload: { visibility: true }
    });

    const [{ data: dataLanguages }] = yield all([
      call(api.getDynamicOptions, "languages")
    ]);

    const languagesOptions = dataLanguages.data.map((item) => ({
      ...item,
      value: item.id,
      text: item.name,
      selected: false
    }));

    languagesOptions.sort((a, b) => a.name.localeCompare(b.name));

    yield put({
      type: "@curriculum/LANGUAGES_SUCCESS",
      payload: { languages: languagesOptions }
    });
  } catch (error) {
    yield put({
      type: "@curriculum/CURRICULUM_FAILURE",
      payload: {
        error: error.response
          ? error.response.data
          : "Erro ao buscar todos os dados do formulário."
      }
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });

    yield put({
      type: "@utilities/SHOW_ALERT",
      payload: {
        show: false
      }
    });
  }
}

export function* calculatePercentageSaga({ payload }) {
  const fieldsToCount = [
    "name",
    "nationality",
    "naturality",
    "gender",
    "birthday",
    "maritalStatus",
    // "picture",
    "seniority",
    "salaryExpectation",
    "about",
    "videoUrl",
    "phone",
    "secondaryEmail",
    "linkedin",
    "youtube",
    "instagram",
    "facebook",
    "twitter",
    "skype",
    "whatsapp",
    "zipCode",
    "street",
    "number",
    "district",
    "city",
    "province",
    "country",
    "cpf",
    // "reservist",
    "rg",
    "driverLicense",
    "driversLicenseCategory",
    "driversLicenseEmissionDate",
    "driversLicenseFirstDate",
    "driversLicenseUf",
    "electoralNumber",
    "electoralZone",
    "electoralSection",
    "pis",
    "ctpsNum",
    "ctpsSerie",
    "languages",
    "skills",
    "courses",
    "experiences"
  ];

  let percentage = 0;

  try {
    const size = getKeysExistVal(payload, fieldsToCount).length;
    percentage = (size * 100) / fieldsToCount.length;
    percentage = (percentage * 50) / 100;
  } catch (err) {
    percentage = 0;
  }

  const profiler = yield select((state) => state.perfilCandidate?.profiler);
  if (profiler?.perfil) percentage += 50;

  yield put({
    type: "@curriculum/PERCENTAGE",
    payload: percentage
  });
}

export function* setSkillCurriculumSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const { data: dataNewSkill } = yield call(api.setDynamicOption, payload);

    if (dataNewSkill.data.id) {
      const skills = yield select((state) => state.curriculum.skills);
      const newSkill = {
        id: dataNewSkill.data.id,
        value: dataNewSkill.data.id,
        text: dataNewSkill.data.name,
        name: dataNewSkill.data.name
      };
      const skillsOptions = [...skills, newSkill];

      yield put({
        type: "@curriculum/SKILLS_SUCCESS",
        payload: { skills: skillsOptions }
      });
      yield put({
        type: "@curriculum/NEW_SKILL_SUCCESS",
        payload: { newSkill }
      });
    } else {
      notification.warn({
        message: "Atenção",
        description: "Não foi possível adicionar a habilidade! Tente novamente."
      });
    }
  } catch (error) {
    yield put({
      type: "@curriculum/CURRICULUM_FAILURE",
      payload: { error: error.response.data }
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* getAddressByZipCodeSaga(action) {
  try {
    let address = {};
    const cep = action.payload.replace(/\D/g, "");

    if (cep.length === 8) {
      yield put({
        type: "@utilities/LOADING",
        payload: { loading: true }
      });

      const { data: dataAddress } = yield call(api.getAddress, cep);

      dataAddress.localidade =
        dataAddress.localidade && dataAddress.localidade.replace("'", "`");

      address = dataAddress;
    }

    yield call(resolvePromiseAction, action, address);
  } catch (err) {
    yield call(resolvePromiseAction, action, {});
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* setUserPictureSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const {
      data: { link, message }
    } = yield call(api.newPicture, payload);

    notification.success({
      message: "Sucesso",
      description: message
    });

    yield put({
      type: "@curriculum/USER_PICTURE_SUCCESS",
      payload: { userPicture: link }
    });
  } catch (error) {
    yield put({
      type: "@curriculum/CURRICULUM_FAILURE",
      payload: { error: error.response.data }
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* updateUserPictureSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const {
      data: { link, message }
    } = yield call(api.changePicture, payload);

    notification.success({
      message: "Sucesso",
      description: message
    });

    yield put({
      type: "@curriculum/USER_PICTURE_SUCCESS",
      payload: { userPicture: link }
    });
  } catch (error) {
    yield put({
      type: "@curriculum/CURRICULUM_FAILURE",
      payload: { error: error.response.data }
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* clearUserCurriculumFileSaga() {
  yield put({
    type: "@curriculum/USER_CURRICULUM_SUCCESS",
    payload: { userCurriculumFile: {} }
  });
}

function* getListCountriesSaga() {
  try {
    let listCountries = [];
    const { data: dataCountry } = yield call(api.getCountryList);

    listCountries = dataCountry.data.map((country) => ({
      ...country,
      value: country.name,
      text: country.name
    }));

    yield put({
      type: "@curriculum/LIST_COUNTRIES_SUCCESS",
      payload: {
        listCountries
      }
    });
  } catch (error) {
    yield put({
      type: "@curriculum/LIST_COUNTRIES_SUCCESS",
      payload: {
        listCountries: []
      }
    });
  }
}

function* getListStatesSaga() {
  try {
    let listStates = [];
    const { data: dataStates } = yield call(api.getStatesList);

    listStates = dataStates.data.map((state) => ({
      ...state,
      value: state.code,
      text: state.name
    }));

    yield put({
      type: "@curriculum/LIST_STATES_SUCCESS",
      payload: {
        listStates
      }
    });
  } catch (error) {
    yield put({
      type: "@curriculum/LIST_STATES_SUCCESS",
      payload: {
        listStates: []
      }
    });
  }
}

function* getListCitiesSaga(action) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    let listCities = [];
    const { data: dataCities } = yield call(api.getCitiesList, action.payload);

    listCities = dataCities.data.map((city) => ({
      ...city,
      value: city.name,
      label: city.name
    }));

    yield call(resolvePromiseAction, action, listCities);
  } catch (error) {
    yield call(resolvePromiseAction, action, []);
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* syncCurriculumSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const {
      data: { message }
    } = yield call(api.syncCurriculum);

    if (payload.showMessages !== false) {
      message.success({
        title: "Atenção",
        content: message
      });
    }
  } catch (error) {
    if (payload.showMessages !== false) {
      message.warn({
        title: "Atenção",
        content:
          "Não foi possível sincronizar o currículo com todas as empresas em que está inscrito."
      });
    }
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });

    yield put({
      type: "@curriculum/SYNC_CURRICULUM_FINISH"
    });
  }
}

function* applyCandidateSaga() {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const data = cookie.getJSON();
    const tab = data[window.name];
    const { vacancy } = tab;

    yield put({
      type: "@vacancy/APPLY_VACANCY_REQUEST",
      payload: {
        vagaId: vacancy.id,
        origin: vacancy?.origin
      }
    });

    yield take("@vacancy/APPLY_VACANCY_FINISH");
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* applyTalentBaseSaga() {
  const data = cookie.getJSON();
  const tab = data[window.name];
  const { company } = tab;
  const COMPANY_HOST = DEFAULT_HOST_COMPANY(company.slug);

  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    if (company?.slug) {
      yield put({
        type: "@company/APPLICANT_CANDIDATE_TALENT_BASE",
        payload: { company, showMessage: false }
      });
      yield take("@company/APPLY_TALENT_BASE_FINISH");
    } else {
      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate#company`);
    }
  } catch (err) {
    const content =
      err.response && err.response.data
        ? err.response.data.message
        : "Não foi possível se aplicar ao banco de talentos no momento. Por favor, tente novamente mais tarde.";

    message.error({
      title: "Atenção",
      content
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });

    cookie.redirect(`${COMPANY_HOST}/talentBankStatus`);
  }
}

function* saveCurriculumSaga(action) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  let responseApi;

  try {
    const { curriculum, hasProfiler } = action.payload;
    const { data: response } = yield call(api.saveCurriculum, curriculum);

    responseApi = response?.data;

    const { [window.name]: tab } = cookie.getJSON();
    const isEditingProfile =
      hasProfiler && !tab?.applyingVacancy && !tab?.applyingTalentBase;

    if (isEditingProfile) {
      message.success({
        title: "Edição salva com sucesso",
        content: `Continue editando ou <a href="${DEFAULT_HOST_CANDIDATE}">volte para o perfil.</a>`
      });
    }
  } catch (err) {
    const content =
      err.response && err.response.data
        ? err.response.data.message
        : "Não foi possível salvar o Currículo no momento. Por favor, tente novamente mais tarde.";

    message.error({
      title: "Atenção",
      content
    });

    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });

    yield call(resolvePromiseAction, action, {});
  }

  yield put({
    type: "@curriculum/SYNC_CURRICULUM",
    payload: { showMessages: false }
  });

  yield take("@curriculum/SYNC_CURRICULUM_FINISH");
  yield call(resolvePromiseAction, action, responseApi || {});

  yield put({
    type: "@utilities/LOADING",
    payload: { loading: false }
  });
}

function* answerProfilerSaga() {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/profiler`);
}

export function* changeUserPictureSaga(action) {
  try {
    const { type, payload } = action.payload;

    const formData = new FormData();
    formData.append("file", payload);

    const {
      data: { link, message: content }
    } =
      type === "new"
        ? yield call(api.newPicture, formData)
        : yield call(api.changePicture, formData);

    yield call(resolvePromiseAction, action, link || "");

    message.success({
      title: "Atenção",
      content
    });
  } catch (error) {
    message.error({
      title: "Atenção",
      content: "Não foi possível alterar a Foto."
    });
  }
}

export function* deleteUserPictureSaga(action) {
  try {
    const {
      data: { message: content }
    } = yield call(api.deletePicture);

    message.success({
      title: "Atenção",
      content
    });
  } catch (error) {
    message.error({
      title: "Atenção",
      content: "Não foi possível salvar a Foto."
    });
  } finally {
    yield call(resolvePromiseAction, action, "");
  }
}

export default all([
  takeLatest("@curriculum/INITIAL_CURRICULUM", getInitialCurriculumSaga),
  takeLatest("@curriculum/CURRICULUM_PERCENTAGE", calculatePercentageSaga),
  takeLatest("@curriculum/SET_SKILLS", setSkillCurriculumSaga),
  takeLatest("@curriculum/SET_PICTURE", setUserPictureSaga),
  takeLatest("@curriculum/UPDATE_PICTURE", updateUserPictureSaga),
  takeLatest("@curriculum/DELETE_PICTURE", deleteUserPictureSaga),
  takeLatest("@curriculum/CLEAR_CURRICULUM_FILE", clearUserCurriculumFileSaga),
  takeLatest("@curriculum/GET_COUNTRIES", getListCountriesSaga),
  takeLatest("@curriculum/GET_STATES", getListStatesSaga),
  takeLatest(
    "@curriculum/GET_ADDRESS_BY_ZIP_CODE.TRIGGER",
    getAddressByZipCodeSaga
  ),
  takeLatest("@curriculum/GET_CITIES.TRIGGER", getListCitiesSaga),
  takeLatest("@curriculum/SYNC_CURRICULUM", syncCurriculumSaga),
  takeLatest("@curriculum/APPLY_CANDIDATE", applyCandidateSaga),
  takeLatest("@curriculum/APPLY_TALENT_BASE", applyTalentBaseSaga),
  takeLatest("@curriculum/ANSWER_PROFILER", answerProfilerSaga),
  takeLatest("@curriculum/SAVE_CURRICULUM.TRIGGER", saveCurriculumSaga),
  takeLatest("@curriculum/CHANGE_USER_PICTURE.TRIGGER", changeUserPictureSaga),
  takeLatest("@curriculum/DELETE_USER_PICTURE.TRIGGER", deleteUserPictureSaga)
]);
