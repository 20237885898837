import styled from "styled-components";
import { input } from "../../styles/variable";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  .react-datepicker-popper {
    z-index: 10;
  }
`;

export const LeftIcon = styled.div`
  ${({ cursor }) => cursor && "cursor: pointer;"}

  position: absolute;
  left: 15px;

  ${({ small, medium, large }) =>
    small
      ? `top: 23px;`
      : medium
      ? `top: 24px;`
      : large
      ? `top: 30px;`
      : `top: 25px;`}

  svg {
    path {
      fill: ${({ iconColor }) => iconColor && iconColor};
    }
    g path {
      fill: ${({ iconColor }) => iconColor && iconColor};
    }

    ${({ small, medium, large }) =>
      small
        ? `width: 20px; height: 18px;`
        : medium
        ? `width: 23px; height: 21px;`
        : large
        ? `width: 28px; height: 26px;`
        : `width: 20px; height: 18px;`}
  }
`;

export const RightIcon = styled.div`
  ${({ cursor }) => cursor && "cursor: pointer;"}

  position: absolute;
  right: 15px;

  ${({ small, medium, large }) =>
    small
      ? `top: 23px;`
      : medium
      ? `top: 24px;`
      : large
      ? `top: 30px;`
      : `top: 25px;`}

  svg {
    path {
      fill: ${({ iconColor }) => iconColor && iconColor};
    }

    g path {
      fill: ${({ iconColor }) => iconColor && iconColor};
    }

    ${({ small, medium, large }) =>
      small
        ? `width: 20px; height: 18px;`
        : medium
        ? `width: 23px; height: 21px;`
        : large
        ? `width: 28px; height: 26px;`
        : `width: 20px; height: 18px;`}
  }

  ${(props) =>
    props.show &&
    `
    svg {
      path {
        fill-opacity: 0.7;
      }
    }
  `}
`;

export const LabelDate = styled.label`
  /* ${({ valueInput }) =>
    valueInput !== null ? `display: none;` : `display: block;`} */

  color: ${input.label.color};
  font-family: ${input.label.fontFamily};
  pointer-events: none;
  position: absolute;
  top: -5px !important;
  left: 0;
  margin-left: 20px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;

  ${({ focus }) =>
    focus &&
    `
            color: ${input.label.color};
            font-size: ${input.label.fontSize};
            margin-top: 0px !important;
            font-weight: bold;
        `}
`;

export const InputWithLabel = styled.div`
  position: relative;
  margin-top: 5px;
  padding-top: 14px;
  width: 100%;

  ${({ small }) => small && `max-width: 250px;`}
  ${({ disabled }) => disabled && `cursor: no-drop;`}

  input {
    width: 100%;
    font-family: "Roboto Condensed";
    font-style: normal;
    ${({ center }) => center && "text-align: center;"}
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    outline: none;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-appearance: none;
    background: #fff;

    border: ${({ type }) =>
      type === "search"
        ? "2px solid rgba(0, 0, 0, 0.15)"
        : "1px solid rgba(0, 0, 0, 0.15)"};
    ${({ error }) => error && "border-color: #dc3545;"}

    border-radius: ${({ semiBorder }) =>
      semiBorder ? "12px 24px 24px 12px" : "24px"};
    box-sizing: border-box;

    padding-left: ${({ iconLeft, leftIcon }) =>
      iconLeft || leftIcon ? 40 : 20}px;

    padding-right: ${({ iconRight, rightIcon }) =>
      iconRight || rightIcon ? 40 : 20}px;

    :focus {
      border: ${({ type }) =>
        type === "search"
          ? "2px solid rgba(0, 0, 0, 0.25)"
          : "1px solid #333333aa"};
      ${({ error }) => error && "border-color: #dc3545;"}
      background: #fff;
    }

    :required:invalid + label {
      color: red;
    }

    :focus + label,
    :not(:placeholder-shown) + label {
      margin-top: -5px;
      font-weight: bold;
      margin-left: 15px;
      ${({ colorFocus }) => colorFocus && `color: ${colorFocus}`}
    }

    ${({ focusLabel }) =>
      focusLabel &&
      `
      + label {
        margin-top: -5px;
        font-weight: bold;
        margin-left: 15px;
        ${({ colorFocus }) => colorFocus && `color: ${colorFocus}`}
      }
    `}

    ${({ small, medium, large, textArea }) =>
      small
        ? `height: 36px;`
        : medium
        ? `height: 40px;`
        : large
        ? `height: 56px;`
        : textArea
        ? `height: 490px`
        : `height: 40px;`}

    ${(props) =>
      props.disabled === true &&
      `
      pointer-events: none;
      font-style: italic;
      outline: none;
      background: rgba(0, 0, 0, 0.05);
    `}
  }

  textarea {
    resize: none;
    padding-top: 13px;
    padding-left: 20px;
    line-height: 15px;
    outline: none;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-appearance: none;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    ${({ small, medium, large }) =>
      small
        ? `max-height: 100px;`
        : medium
        ? `max-height: 200px;`
        : large
        ? `max-height: 300px;`
        : `max-height: 100px;`}

    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #dad8df;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 3px #dddddd;
    border-radius: 10px;
    color: #333333;
    ${({ error }) => error && "border-color: #dc3545;"}

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.1px;

    &::placeholder {
      color: transparent;
    }

    :focus {
      border: 1px solid #333333aa;
      background: #fff;
      ${({ error }) => error && "border-color: #dc3545;"}
    }

    :required:invalid + label {
      color: red;
    }

    :focus + label,
    :not(:placeholder-shown) + label {
      margin-top: -5px;
      font-family: "Roboto Condensed";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.1px;
      color: #333333;
    }
  }

  label {
    ${({ type }) =>
      type === "search"
        ? `font-weight: normal;font-size: 16px;line-height: 19px;`
        : `font-weight: bold;font-size: 14px;line-height: 20px;`}

    font-family: "Roboto Condensed";
    font-style: normal;
    letter-spacing: -0.1px;
    color: #333333;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;

    margin-left: ${({ iconLeft, leftIcon }) =>
      iconLeft || leftIcon ? 40 : 15}px;

    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    ${({ small, medium, large }) =>
      small
        ? `margin-top: 23px;`
        : medium
        ? `margin-top: 25px;`
        : large
        ? `margin-top: 33px;`
        : `margin-top: 25px;`}
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .max-length {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #333;
    float: right;
  }
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin: 0.25rem 10px -0.5rem 10px;
  font-size: 80%;
  color: #dc3545;
  /* position: absolute; */
`;

export const InputBlocked = styled.div`
  position: relative;
  margin-top: 5px;
  padding-top: 14px;
  width: 100%;
  cursor: no-drop;

  ${({ small }) => small && `max-width: 250px;`}

  input {
    width: 100%;
    pointer-events: none;
    font-family: "Roboto Condensed";
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-appearance: none;
    background: rgba(0, 0, 0, 0.05);
    color: #333333;
    border: 1px solid #dad8df;
    border-radius: 24px;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 3px #dddddd;

    padding-left: ${({ iconLeft, leftIcon }) =>
      iconLeft || leftIcon ? 40 : 20}px;

    padding-right: ${({ iconRight, rightIcon }) =>
      iconRight || rightIcon ? 40 : 20}px;

    &::placeholder {
      color: transparent;
    }

    :focus {
      border: ${({ type }) =>
        type === "search"
          ? "2px solid rgba(0, 0, 0, 0.25)"
          : "1px solid #333333aa"};
      ${({ error }) => error && "border-color: #dc3545;"}
      background: #fff;
    }

    :required:invalid + label {
      color: red;
    }

    :focus + label,
    :not(:placeholder-shown) + label {
      margin-top: -5px;
      font-weight: bold;
      margin-left: 15px;
    }

    ${({ small, medium, large, textArea }) =>
      small
        ? `height: 36px;`
        : medium
        ? `height: 40px;`
        : large
        ? `height: 56px;`
        : textArea
        ? `height: 490px`
        : `height: 40px;`}
  }

  label {
    ${({ type }) =>
      type === "search"
        ? `font-weight: normal;font-size: 16px;line-height: 19px;`
        : `font-weight: bold;font-size: 14px;line-height: 20px;`}

    font-family: "Roboto Condensed";
    font-style: normal;
    letter-spacing: -0.1px;
    color: #333333;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;

    margin-left: ${({ iconLeft, leftIcon }) =>
      iconLeft || leftIcon ? 40 : 15}px;

    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    ${({ small, medium, large }) =>
      small
        ? `margin-top: 23px;`
        : medium
        ? `margin-top: 25px;`
        : large
        ? `margin-top: 33px;`
        : `margin-top: 25px;`}
  }
`;

export const IconDateContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 15px;

  svg path {
    fill: #333333;
  }
`;
export const ContainerTextEditor = styled.div`
  .second-toolbar {
    display: none;
  }

  .fr-box.fr-basic .fr-wrapper {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-color: #cccccc;
  }
`;

export const ContainerValidate = styled.div`
  svg {
    left: initial !important;
  }
`;
