import React from "react";
import FroalaEditor from "react-froala-wysiwyg";

import { ContainerTextEditor } from "./styles";

import InputText from "./components/text";
import InputPassword from "./components/password";
import InputEmail from "./components/email";
import InputNumber from "./components/number";
import InputDate from "./components/date";
import InputMask from "./components/mask";
import InputIcon from "./components/inputIcon";
import InputBlocked from "./components/blocked";
import InputArea from "./components/textArea";
import InputMoney from "./components/money";
import InputImageFile from "./components/imageFile";

const InputCustom = ({
  type,
  onChange,
  value,
  onBlur,
  label,
  style = {},
  checked,
  leftIcon,
  rightIcon,
  iconColor,
  isFile,
  isFormData,
  showValidateIcon = false,
  fallbackImage,
  fallbackDelete,
  ...rest
}) => {
  const renderInput = () => {
    switch (type) {
      case "text":
        return (
          <InputText
            label={label}
            onChange={onChange}
            value={value}
            {...rest}
          />
        );

      case "password":
        return (
          <InputPassword
            label={label}
            onChange={onChange}
            value={value}
            type="password"
            rightIcon={rightIcon}
            {...rest}
          />
        );

      case "email":
        return (
          <InputEmail
            label={label}
            onChange={onChange}
            value={value}
            {...rest}
          />
        );

      case "number":
        return (
          <InputNumber
            label={label}
            style={style}
            onChange={onChange}
            {...rest}
          />
        );

      case "datePicker":
        return (
          <InputDate
            onChange={onChange}
            value={value}
            label={label}
            style={style}
            {...rest}
          />
        );

      case "mask":
        return (
          <InputMask
            leftIcon={leftIcon || null}
            rightIcon={rightIcon || null}
            label={label}
            value={value}
            onChange={onChange}
            placeholder=" "
            style={style}
            iconColor={iconColor}
            {...rest}
          />
        );

      case "icon":
        return (
          <InputIcon
            leftIcon={leftIcon || null}
            rightIcon={rightIcon || null}
            iconColor={iconColor || null}
            label={label}
            value={value}
            onChange={onChange}
            {...rest}
          />
        );

      case "search":
        return (
          <InputIcon
            type={type}
            leftIcon={leftIcon || null}
            rightIcon={rightIcon || null}
            iconColor={iconColor || null}
            label={label}
            value={value}
            onChange={onChange}
            {...rest}
          />
        );

      case "blocked":
        return <InputBlocked label={label} value={value} {...rest} />;

      case "textArea":
        return (
          <InputArea
            label={label}
            value={value}
            onChange={onChange}
            {...rest}
          />
        );

      case "textEditor":
        return (
          <ContainerTextEditor>
            <FroalaEditor
              tag="textarea"
              config={{
                placeholderText: "Ex: Auxiliar Administrativo",
                charCounterCount: true
              }}
              onModelChange={onChange}
              {...rest}
            />
          </ContainerTextEditor>
        );

      case "imageFile":
        return (
          <InputImageFile
            label={label}
            value={value}
            onChange={onChange}
            fallbackImage={fallbackImage}
            fallbackDelete={fallbackDelete}
            isFormData={isFormData}
            isFile={isFile}
            {...rest}
          />
        );

      case "money":
        return (
          <InputMoney
            label={label}
            value={value}
            onChange={onChange}
            {...rest}
          />
        );

      default:
        return (
          <InputText
            label={label}
            onChange={onChange}
            value={value}
            {...rest}
          />
        );
    }
  };
  return renderInput();
};

export default InputCustom;
