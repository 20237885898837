import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;

  ${({ defaultBackground }) =>
    defaultBackground && `background-color: ${defaultBackground};`}
  ${({ urlDesktop }) => urlDesktop && `background-image: url("${urlDesktop}");`}

    @media screen and (max-width: 900px) {
    ${({ urlMobile }) => urlMobile && `background-image: url("${urlMobile}");`}
  }

  @media screen and (max-width: 450px) {
    height: 300px;
  }
`;
