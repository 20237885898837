import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  margin-right: 15px;
`;

export const ContainerRadio = styled.div`
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  background: #333333;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
`;

export const Radio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #333333;
`;

export const Text = styled.span`
  margin-left: 5px;
  padding-top: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
`;
