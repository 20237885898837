import styled from "styled-components";

export const SocialButton = styled.a`
  width: 100%;
  button {
    font-size: 18px !important;
  }

  :nth-child(n + 2) {
    margin-top: 20px;
  }
  b {
    text-transform: capitalize;
  }
`;
