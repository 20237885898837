import aboutYouSchema from "./aboutYouSchema";
import experiencesSchema, { MAX_LENGTH_TEXTAREA } from "./experiencesSchema";
import skillsSchema, { MAX_LENGTH_INPUT_SKILL } from "./skillsSchema";
import documentsSchema from "./documentsSchema";
import personalDataSchema from "./personalDataSchema";
import addressSchema from "./addressSchema";

function merge(...schemas) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
}

const schema = (requiredFields = [], vacancy = {}) =>
  merge(
    aboutYouSchema(requiredFields),
    experiencesSchema(requiredFields),
    skillsSchema(requiredFields, vacancy),
    documentsSchema(requiredFields),
    personalDataSchema(requiredFields),
    addressSchema(requiredFields)
  );

export {
  aboutYouSchema,
  experiencesSchema,
  MAX_LENGTH_TEXTAREA,
  personalDataSchema,
  addressSchema,
  skillsSchema,
  MAX_LENGTH_INPUT_SKILL,
  documentsSchema,
  schema
};
