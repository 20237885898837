import React from "react";
import * as Styled from "./styles";

const Skills = ({ list = [] }) => (
  <>
    {list &&
      list.map((item, index) => (
        <Styled.Container key={`item_${index}`}>
          <Styled.Title>{item.title}</Styled.Title>

          {item.data.map((subItem, subIndex) => (
            <Styled.ItemContainer key={`data_${subIndex}`}>
              <Styled.Text>{subItem.text}</Styled.Text>

              {subItem.level && (
                <Styled.Level>
                  <Styled.ContainerProgress>
                    <Styled.TitleProgressStep>
                      {subItem.level}
                    </Styled.TitleProgressStep>
                    <Styled.ContainerProgressStep>
                      <Styled.CurrentProgressStep
                        percentage={subItem.percent}
                      />
                    </Styled.ContainerProgressStep>
                  </Styled.ContainerProgress>
                </Styled.Level>
              )}
            </Styled.ItemContainer>
          ))}
        </Styled.Container>
      ))}
  </>
);

export default Skills;
