import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-gap: 30px;
  align-items: flex-start;
`;

export const Details = styled.div``;
