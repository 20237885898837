import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

import Carousel from "@components/carousel";
import Button from "@components/button";
import SanitizeHTML from "@components/sanitize";

import * as Styled from "./styles";

const Company = ({ company }) => {
  const [photos, setPhotos] = useState([]);
  const [socialOptions, setSocialOptions] = useState([]);

  useEffect(() => {
    if (company.social) {
      setSocialOptions(
        company.social.map((item) => ({
          type: item.type,
          link: item.url.includes("http") ? item.url : `https://${item.url}`,
          borderColor: "#D0CCC8",
          color: company.colorCompany ? company.colorCompany : "#333333"
        }))
      );
    }

    if (company.photos) {
      setPhotos(company.photos.map((item) => ({ photo: item })));
    }
  }, [company]);

  return (
    <Styled.Container>
      {(!!company.urlVideo || !!company.description || !!company.social) && (
        <Styled.BoxContainer>
          {!!company.name && (
            <Styled.CompanyName>{company.name}</Styled.CompanyName>
          )}
          {!!company.slogan && (
            <Styled.CompanySlogan>{company.slogan}</Styled.CompanySlogan>
          )}
          {!!company.urlVideo && (
            <Styled.Video>
              <ReactPlayer
                url={company.urlVideo}
                width="100%"
                height="100%"
                controls={company.urlVideo.includes("vimeo.com")}
              />
            </Styled.Video>
          )}

          {!!company.description && (
            <Styled.Text>
              <SanitizeHTML html={company.description} />
            </Styled.Text>
          )}

          {!!socialOptions.length && (
            <Styled.ButtonsContainer>
              <Button type="social" options={socialOptions} />
            </Styled.ButtonsContainer>
          )}
        </Styled.BoxContainer>
      )}

      {!!photos.length && (
        <Styled.BoxContainer>
          <Styled.Title>Fotos</Styled.Title>
          <Styled.CarouselContainer>
            <Carousel
              list={photos}
              initialPosition={0}
              arrows
              imgType="rounded"
              imgSize={180}
              color={
                company.colorCompany
                  ? company.colorCompany.toString()
                  : "#333333"
              }
            />
          </Styled.CarouselContainer>
        </Styled.BoxContainer>
      )}

      {!!company.testimonials && (
        <Styled.BoxContainer>
          <Styled.Title>Depoimentos</Styled.Title>
          <Styled.CarouselContainer>
            <Carousel
              list={company.testimonials}
              initialPosition={0}
              arrows
              caption
              imgType="roundedCircle"
              imgSize={200}
              autoplaySpeed={5000}
              color={
                company.colorCompany
                  ? company.colorCompany.toString()
                  : "#333333"
              }
            />
          </Styled.CarouselContainer>
        </Styled.BoxContainer>
      )}
    </Styled.Container>
  );
};

export default Company;
