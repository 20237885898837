/* Attribution: http://techslides.com/how-to-parse-and-search-json-in-javascript */

// return an array of objects according to key, value, or key and value matching
function getObjects(obj, key, val) {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] === "object") {
      objects = objects.concat(getObjects(obj[i], key, val));
    }
    // if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    else if ((i === key && obj[i] === val) || (i === key && val === "")) {
      //
      objects.push(obj);
    } else if (obj[i] === val && key === "") {
      // only add if the object is not already in the array
      if (objects.lastIndexOf(obj) === -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
}

// return an array of values that match on a certain key
function getValues(obj, key) {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] === "object") {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i === key) {
      objects.push(obj[i]);
    }
  }
  return objects;
}

// return an array of keys that match on a certain value
function getKeys(obj, val) {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] === "object") {
      objects = objects.concat(getKeys(obj[i], val));
    } else if (obj[i] === val) {
      objects.push(i);
    }
  }
  return objects;
}

// return an array of keys that value exists
function getKeysExistVal(obj, checkKeys) {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;

    if (checkKeys.includes(i)) {
      if (
        !!obj[i] &&
        (obj[i].length || obj[i] instanceof Date || typeof obj[i] === "number")
      ) {
        objects.push(i);
      }
    }

    // eslint-disable-next-line valid-typeof
    if (typeof obj[i] === "object" || typeof obj[i] === "array") {
      objects = objects.concat(getKeysExistVal(obj[i], checkKeys));
    }
  }
  return [...new Set(objects)];
}

function filterDeepObject(obj, arrayKeys) {
  const res = {};

  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const value = obj[key];

    if (typeof value === "object" || Array.isArray(value)) {
      res[key] = filterDeepObject(value, arrayKeys);
    } else if (!!arrayKeys && arrayKeys.includes(key)) {
      res[key] = value;
    }
  }

  return res;
}

export { getObjects, getValues, getKeys, getKeysExistVal, filterDeepObject };
