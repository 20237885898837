import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import resolve from "@utils/resolve";

import { Label, MessageError } from "@components/form";

import * as Styled from "./styles";

const Checkbox = ({ name, label, style, defaultValue, ...rest }) => {
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const { message: error } = resolve(name, errors);

  useEffect(() => {
    const isCheck = defaultValue === true || defaultValue === "on";
    setValue(name, isCheck ? "on" : "off");
  }, []);

  return (
    <Styled.Container style={style}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Styled.InputCheckbox
              {...rest}
              type="checkbox"
              checked={field.value === "on"}
            />
            <span
              className="container-checkbox"
              onClick={() =>
                field.onChange(field.value === "off" ? "on" : "off")
              }
            />
          </>
        )}
      />
      {label && <Label>{label}</Label>}
      {error && <MessageError message={error} />}
    </Styled.Container>
  );
};

Checkbox.defaultProps = {
  label: "",
  style: {},
  defaultValue: false
};

Checkbox.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired
};

export const CheckboxNormal = ({ label, style, onClick, error, ...rest }) => (
  <Styled.Container style={style} onClick={onClick}>
    <Styled.InputCheckbox {...rest} type="checkbox" />
    <span />
    {label && <Label>{label}</Label>}
    <MessageError message={error} />
  </Styled.Container>
);

export default Checkbox;
