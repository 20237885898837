import React from "react";
import PropTypes from "prop-types";

import { Button } from "@components/form";
import { Title, Description, ModalFooter } from "../styles";

export const ApplyVacancy = ({ onClose, action }) => (
  <>
    <Title>Seu currículo está pronto para ser enviado!</Title>
    <Description>
      Clique em Confirmar para finalizar a candidatura ou em Revisão se deseja
      editar alguma informação.
    </Description>

    <ModalFooter>
      <Button onClick={onClose} theme="link">
        Revisão
      </Button>

      <Button onClick={action} theme="dark" maxWidth="200px">
        Confirmar
      </Button>
    </ModalFooter>
  </>
);

ApplyVacancy.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};
