import { createPromiseAction } from "@adobe/redux-saga-promise";

export function getInitialCurriculum(payload) {
  return {
    type: "@curriculum/INITIAL_CURRICULUM",
    payload
  };
}

export function curriculumFailure(error) {
  return {
    type: "@curriculum/CURRICULUM_FAILURE",
    payload: { error }
  };
}

export function calculatePercentage(payload) {
  return {
    type: "@curriculum/CURRICULUM_PERCENTAGE",
    payload
  };
}

export function setSkillCurriculum(payload) {
  return {
    type: "@curriculum/SET_SKILLS",
    payload
  };
}

export function setUserPicture(payload) {
  return {
    type: "@curriculum/SET_PICTURE",
    payload
  };
}

export function updateUserPicture(payload) {
  return {
    type: "@curriculum/UPDATE_PICTURE",
    payload
  };
}

export function clearUserCurriculumFile() {
  return {
    type: "@curriculum/CLEAR_CURRICULUM_FILE"
  };
}

export function getListCountries() {
  return {
    type: "@curriculum/GET_COUNTRIES"
  };
}

export function getListStates() {
  return {
    type: "@curriculum/GET_STATES"
  };
}

export const getAddressByZipCode = createPromiseAction(
  "@curriculum/GET_ADDRESS_BY_ZIP_CODE"
);

export const getListCities = createPromiseAction("@curriculum/GET_CITIES");

export function applyCandidate() {
  return {
    type: "@curriculum/APPLY_CANDIDATE"
  };
}

export function applyTalentBase() {
  return {
    type: "@curriculum/APPLY_TALENT_BASE"
  };
}

export const saveCurriculum = createPromiseAction(
  "@curriculum/SAVE_CURRICULUM"
);

export const changeUserPicture = createPromiseAction(
  "@curriculum/CHANGE_USER_PICTURE"
);

export const deleteUserPicture = createPromiseAction(
  "@curriculum/DELETE_USER_PICTURE"
);

export function answerProfiler() {
  return {
    type: "@curriculum/ANSWER_PROFILER"
  };
}

export function unblockButtons() {
  return {
    type: "@curriculum/UNBLOCK_BUTTONS"
  };
}
