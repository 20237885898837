const INITIAL_STATE = {
  updateProfiler: false,
  blockedButtons: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@profiler/UPDATE_PROFILER_SUCCESS":
      return {
        ...state,
        updateProfiler: action.payload
      };

    case "@profiler/BLOCK_BUTTONS":
      return {
        ...state,
        blockedButtons: true
      };

    case "@profiler/UNBLOCK_BUTTONS":
      return {
        ...state,
        blockedButtons: false
      };
    default:
      return state;
  }
}
