import React from "react";
import PropTypes from "prop-types";

import { Button } from "@components/form";
import { Description, ModalFooter } from "../styles";

export const DeleteCourseExperience = ({ onClose, action }) => (
  <>
    <Description>
      Tem certeza que deseja excluir este grau de escolaridade?
    </Description>

    <ModalFooter>
      <Button onClick={onClose} theme="link">
        Cancelar
      </Button>
      <Button onClick={action} theme="dark">
        Excluir
      </Button>
    </ModalFooter>
  </>
);

DeleteCourseExperience.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};
