import React from "react";
import { Link } from "react-router-dom";

import * as Styled from "./styles";

function LinkRegister() {
  return (
    <Styled.Container>
      Não tem conta?
      <Link to="/signUp">
        <Styled.Link>Criar conta</Styled.Link>
      </Link>
    </Styled.Container>
  );
}

function LinkLogin() {
  return (
    <Styled.Container>
      Já tem uma conta?
      <Link to="/">
        <Styled.Link>Fazer login.</Styled.Link>
      </Link>
    </Styled.Container>
  );
}

export { LinkRegister, LinkLogin };
