export const verifyMinimumCharacters = (value, minimum = 6) =>
  value.length >= minimum;

export const verifyContentNumber = (value) => {
  const hasNumber = new RegExp(/\d/);
  return hasNumber.test(value);
};

export const verifyContentLetter = (value) => {
  const hasLetter = new RegExp(/[A-Za-z]/);
  return hasLetter.test(value);
};

export const verifyContentSpecialCharacters = (value) => {
  const hasSpecialCharacter = new RegExp(
    /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/
  );
  return hasSpecialCharacter.test(value);
};
