import styled from "styled-components";

export const Container = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #1787e0;
`;
