import API from "../../../services/api";

export const signIn = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api.post("/user/sign-in", json).then(resolve).catch(reject);
  });

export const signInLinkedin = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api.post("/user/sign-in-linkedin", json).then(resolve).catch(reject);
  });

export const getFacebookLink = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(false);
    api.post("/user/auth-facebook", json).then(resolve).catch(reject);
  });

export const signUpOrSignInFacebook = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(false);
    api.get("/user/process-auth-facebook", json).then(resolve).catch(reject);
  });

export const logout = async () =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api.post("/user/sign-out").then(resolve).catch(reject);
  });

export const restoreToken = async () =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api.post("/user/refresh-token-user").then(resolve).catch(reject);
  });

export const forgotPassword = async (email) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api.post("/user/recovery-password", email).then(resolve).catch(reject);
  });

export const updatePassword = async (json) =>
  new Promise((resolve, reject) => {
    const api = API(true);
    api.post("/user/update-password", json).then(resolve).catch(reject);
  });

export const currentUserData = async (hasLogged = true, configHeaders = {}) =>
  new Promise((resolve, reject) => {
    const api = API(hasLogged);
    api
      .get("/applicant", { headers: configHeaders })
      .then(resolve)
      .catch(reject);
  });

export const signUp = async (json) =>
  new Promise((resolve, reject) => {
    const api = API();
    api.post("/user/sign-up", json).then(resolve).catch(reject);
  });

export const getProfiler = async (hasLogged = true, configHeaders = {}) =>
  new Promise((resolve, reject) => {
    const api = API(hasLogged);
    api
      .get("/profiler", { headers: configHeaders })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const updateTermUse = async (json) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .post("/user/update-term-use", json)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });

export const signUpLinkedin = async (json) =>
  new Promise((resolve, reject) => {
    const api = API();
    api.post("/user/sign-up-linkedin", json).then(resolve).catch(reject);
  });

export const getMyApplications = async (configHeaders = {}) =>
  new Promise((resolve, reject) => {
    const api = API(false);
    api
      .get("applicant/my-applications", { headers: configHeaders })
      .then(resolve)
      .catch(reject);
  });
