import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SelectContainer = styled.div`
  width: 97px;
  position: absolute;
  left: 0;
  bottom: 2px;
  z-index: 2;

  > div > div > div {
    background: #dedede;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 0.125rem;

  input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: ${({ $isBlocked }) => ($isBlocked ? "transparent" : "#fff")};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 100%;
    border-radius: 51px;
    outline: none;
    padding: 0.675rem 0 0.675rem 6.5rem;

    &::placeholder {
      color: #a0a0a0;
    }

    ${({ $hasLeftIcon, $prefix }) =>
      $hasLeftIcon && !$prefix && "padding-left: calc(1rem + 1.5rem);"}

    ${({ $hasLeftIcon, $prefix, $prefixWidth }) =>
      $hasLeftIcon &&
      $prefix &&
      `padding-left: calc(1rem + 1.5rem + ${$prefixWidth}px);`}

    ${({ $prefix, $prefixWidth }) =>
      $prefix && `padding-left: calc(1rem + 1.5rem + ${$prefixWidth}px);`}

    ${({ $hasError, theme }) =>
      $hasError
        ? `border-color: ${theme.colors.red}`
        : `padding-right: calc(1rem + 1.25rem)`}
  }

  i {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 18px;
    width: 1rem;
    height: 1.3rem;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);

    width: ${({ $isBlocked }) => ($isBlocked ? "1.125rem" : "0.875rem")};
    height: ${({ $isBlocked }) => ($isBlocked ? "1.6875rem" : "1.3125rem")};

    *,
    svg {
      fill: ${({ $isBlocked }) => ($isBlocked ? "#333333" : "#177727")};
    }
  }
`;
