import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
`;

export const ContainerForm = styled.form``;

export const Field = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "15px")};

  ${({ center }) => center && "text-align: center;"}
`;

export const TermContainer = styled.div`
  display: flex;
  position: relative;
  ${({ error }) =>
    error &&
    `
        ::after {
            content: "${error}";
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: left;
            font-size: 80%;
            color: #dc3545;
            margin: 0.25rem 10px -0.5rem 10px;
        }
    `}
`;

export const TextTerms = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;

export const Term = styled.a`
  color: #ce2d50;
  cursor: pointer;
  font-weight: bold;
  color: ${({ textColor }) => textColor || "#333333"};
  &:hover {
    color: ${({ textColor }) => textColor || "#333333"};
    text-decoration: underline;
  }
`;

export const ContainerSocialAuth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
`;

export const ContainerAuth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
