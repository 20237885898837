import React, { useState, useEffect } from "react";

import { PasswordContainer } from "../styles";

const PasswordFormat = ({ onChange, value, showValidateIcon, ...rest }) => {
  const [password, setPassword] = useState(value);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setPassword(value);
  }, [value]);

  const getIcon = visible ? (
    <i className="fas fa-eye" onClick={() => setVisible(false)} />
  ) : (
    <i className="fas fa-eye-slash" onClick={() => setVisible(true)} />
  );

  return (
    <PasswordContainer
      hasValue={!!password}
      showValidateIcon={showValidateIcon}
    >
      <input
        {...rest}
        type={visible ? "text" : "password"}
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
          onChange(event);
        }}
      />

      {getIcon}
    </PasswordContainer>
  );
};

export default PasswordFormat;
