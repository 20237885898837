import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";

import useAddress from "@hooks/useAddress";
import useCurriculum from "@hooks/useCurriculum";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCurriculumSections } from "@hooks/useCurriculumSections";

import { Label, Select } from "@components/form";
import { CurriculumSectionTemplate } from "@components";
import { addressSchema } from "@utils/formValidations/curriculumV2";

import {
  getListCities,
  getAddressByZipCode
} from "@store/modules/curriculum/actions";

import * as Styled from "../styles";

function Address({ onSave, children }) {
  const { countries, states } = useAddress();
  const { registerForm, handleSubmit } = useCurriculum();
  const { requiredFields, isRequiredField } = useApplyVacancy();
  const { setValue, setError, watch, getValues, clearErrors } =
    useFormContext();
  const { selectedSection, sectionOptions } = useCurriculumSections();
  const [cityOptions, setCityOptions] = useState([]);

  const [countryWatch, stateWatch, cityWatch, zipCodeWatch] = watch([
    "address.country",
    "address.province",
    "address.city",
    "address.zipCode"
  ]);
  const isBrazil = countryWatch === "Brasil";

  const dispatch = useDispatch();

  const stateOptions = useMemo(
    () => states.map((state) => ({ value: state.name, label: state.name })),
    [states]
  );

  useEffect(() => {
    const schema = addressSchema(requiredFields);
    const resolver = yupResolver(schema);

    registerForm({
      schema: { resolver },
      handleSubmit: { onSubmit }
    });
  }, [requiredFields, sectionOptions]);

  useEffect(() => {
    if (stateWatch) {
      const province = states.find((state) => state.name === stateWatch);
      if (province?.code) getCities(province);
    }
  }, [stateWatch, states]);

  const getCities = async (province) => {
    const data = await dispatch(getListCities(province.code));
    setCityOptions(data);

    return data;
  };

  const resetFields = (cleanZipCode = true) => {
    if (cleanZipCode) setValue("address.zipCode", "");
    setValue("address.street", "");
    setValue("address.district", "");
    setValue("address.province", "");
    setValue("address.city", "");
    setValue("address.number", "");
    setValue("address.complement", "");
  };

  const handleGetAddress = async (zipCode) => {
    if (zipCode?.length === 8) {
      const address = await dispatch(getAddressByZipCode(zipCode));
      if (!address?.erro) {
        setError("address.zipCode", {});
        const province = states.find((state) => state.code === address.uf);

        if (province) {
          const cities = await getCities(province);
          const city = cities.find((city) => city.name === address.localidade);

          if (city?.name) setValue("address.city", city.name);
        }
        clearErrors("address");
        setValue("address.street", address.logradouro);
        setValue("address.district", address.bairro);
        setValue("address.province", province?.name || "");
      } else {
        setError("address.zipCode", {
          type: "manual",
          message: "CEP não localizado."
        });

        resetFields(false);
      }
    } else if (zipCode?.length === 0) {
      resetFields(false);
    }
  };

  const onSubmit = useCallback(
    (data, nextPath) => {
      const { address } = data;
      onSave({ address }, nextPath);
    },
    [sectionOptions]
  );

  const changeCountry = () => {
    setTimeout(() => {
      const newCountry = getValues("address.country");
      if (newCountry !== countryWatch) resetFields();
    }, 300);
  };

  return (
    <CurriculumSectionTemplate title="Endereço" onSubmit={handleSubmit}>
      <Styled.Form>
        <Styled.Row>
          <Styled.Col>
            <Label required={isRequiredField("country")}>País</Label>
            <Select
              name="address.country"
              options={countries}
              required={isRequiredField("country")}
              onMenuClose={() => changeCountry()}
            />
          </Styled.Col>
          <Styled.Col>
            {isBrazil ? (
              <Styled.Input
                type="number"
                name="address.zipCode"
                label="CEP"
                required={isRequiredField("zipCode")}
                placeholder="_____-__"
                format="#####-###"
                onChange={handleGetAddress}
              />
            ) : (
              <Styled.Input
                type="number"
                name="address.zipCode"
                label="CEP"
                required={isRequiredField("zipCode")}
                maxLength={10}
              />
            )}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              name="address.street"
              label="Logradouro"
              maxLength={255}
              placeholder="Nome da rua ou avenida"
              required={isRequiredField("street")}
            />
          </Styled.Col>
          <Styled.Col flex={0.5} style={{ minWidth: "208px" }}>
            <Styled.Input
              name="address.number"
              label="Número"
              maxLength={10}
              required={isRequiredField("number")}
            />
          </Styled.Col>
          <Styled.Col>
            <Styled.Input
              name="address.complement"
              label="Complemento"
              placeholder="Apartamento, Bloco, Quadra"
              maxLength="100"
              required={isRequiredField("additionalInformation")}
            />
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              name="address.district"
              label="Bairro"
              placeholder="Nome do bairro"
              required={isRequiredField("district")}
            />
          </Styled.Col>
          <Styled.Col>
            {isBrazil ? (
              <>
                <Label required={isRequiredField("state")}>Estado</Label>
                <Select
                  name="address.province"
                  options={stateOptions}
                  required={isRequiredField("state")}
                  onInputChange={() => setValue("address.city", "")}
                  isDisabled={
                    !!zipCodeWatch && zipCodeWatch.length === 8 && !!stateWatch
                  }
                />
              </>
            ) : (
              <Styled.Input
                name="address.province"
                label="Estado"
                maxLength="100"
                required={isRequiredField("state")}
              />
            )}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col flex={0.5}>
            {isBrazil ? (
              <>
                <Label required={isRequiredField("city")}>Cidade</Label>
                <Select
                  name="address.city"
                  options={cityOptions}
                  required={isRequiredField("city")}
                  isDisabled={
                    !!zipCodeWatch && zipCodeWatch.length === 8 && !!cityWatch
                  }
                />
              </>
            ) : (
              <Styled.Input
                name="address.city"
                label="Cidade"
                maxLength="100"
                required={isRequiredField("city")}
              />
            )}
          </Styled.Col>
        </Styled.Row>
        {children}
      </Styled.Form>
    </CurriculumSectionTemplate>
  );
}

export default React.memo(Address);
