import React, { useEffect, useState } from "react";
import moment from "moment";

import { formatArrayToText } from "@utils/formatArrayToText";

import * as Styled from "./styles";

const Documents = ({ person = {} }) => {
  const [cnh, setCnh] = useState("");
  const [driversLicenseEmissionDate, setDriversLicenseEmissionDate] =
    useState("");
  const [driversLicenseFirstDate, setDriversLicenseFirstDate] = useState("");

  useEffect(() => {
    const categories = (person.documents?.driversLicenseCategory || []).map(
      (item) => item.value
    );

    const categoryName =
      categories.length > 1
        ? `categorias ${formatArrayToText(categories)}`
        : `categoria ${formatArrayToText(categories)}`;

    if (
      !!person.documents?.driverLicense &&
      !!categories.length &&
      !!person.documents?.driversLicenseUf
    ) {
      setCnh(
        `${person.documents?.driverLicense} / ${categoryName} / ${person.documents?.driversLicenseUf}`
      );
    } else if (!!person.documents?.driverLicense && !!categories.length) {
      setCnh(`${person.documents?.driverLicense} / ${categoryName}`);
    } else if (person.documents?.driverLicense) {
      setCnh(person.documents?.driverLicense);
    }

    const emissionDate = moment(person.documents?.driversLicenseEmissionDate);
    setDriversLicenseEmissionDate(
      emissionDate.isValid() ? emissionDate.format("DD/MM/YYYY") : ""
    );

    const firstEmissionDate = moment(person.documents?.driversLicenseFirstDate);
    setDriversLicenseFirstDate(
      firstEmissionDate.isValid() ? firstEmissionDate.format("DD/MM/YYYY") : ""
    );
  }, [person]);

  return (
    <Styled.ContainerAdditionalInformation>
      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>CPF</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.cpf ? `italic` : `normal`}
          >
            {person.documents?.cpf
              ? person.documents?.cpf
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>RG</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.rg ? `italic` : `normal`}
          >
            {person.documents?.rg
              ? person.documents?.rg
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox />
      </Styled.AdditionalInformationRow>

      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>CNH</Styled.BoxTitle>
          <Styled.BoxDescription fontStyle={!cnh ? `italic` : `normal`}>
            {cnh || `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Data emissão CNH</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!driversLicenseEmissionDate ? `italic` : `normal`}
          >
            {driversLicenseEmissionDate || `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Primeira CNH</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!driversLicenseFirstDate ? `italic` : `normal`}
          >
            {driversLicenseFirstDate || `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
      </Styled.AdditionalInformationRow>

      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>CTPS Num / Série</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.ctpsNum ? `italic` : `normal`}
          >
            {person.documents?.ctpsNum
              ? person.documents?.ctpsSerie
                ? `${person.documents?.ctpsNum} / ${person.documents?.ctpsSerie}`
                : person.documents?.ctpsNum
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>PIS</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.pis ? `italic` : `normal`}
          >
            {person.documents?.pis
              ? person.documents?.pis
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Reservista</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.reservist ? `italic` : `normal`}
          >
            {person.documents?.reservist
              ? person.documents?.reservist
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
      </Styled.AdditionalInformationRow>

      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>Título de eleitor</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.electoralNumber ? `italic` : `normal`}
          >
            {person.documents?.electoralNumber
              ? person.documents?.electoralNumber
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Zona eleitoral</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.documents?.electoralZone ? `italic` : `normal`}
          >
            {person.documents?.electoralZone
              ? person.documents?.electoralZone
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Seção eleitoral</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={
              !person.documents?.electoralSection ? `italic` : `normal`
            }
          >
            {person.documents?.electoralSection
              ? person.documents?.electoralSection
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
      </Styled.AdditionalInformationRow>
    </Styled.ContainerAdditionalInformation>
  );
};

export default Documents;
