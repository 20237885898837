import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import SignIn from "@pages/auth/signIn";
import * as cookie from "@utils/cookie";
import { DEFAULT_HOST_LOGIN } from "@services/constants";
import { parseURLEncodeToObject } from "@utils/urlParse";
import * as authActions from "@store/modules/auth/actions";
import * as utilitiesActions from "@store/modules/utilities/actions";

function FacebookCallback() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(utilitiesActions.showLoading(true));
    return () => dispatch(utilitiesActions.showLoading(false));
  }, []);

  useEffect(() => {
    const response = parseURLEncodeToObject(window.location.search);
    dispatch(authActions.signUpOrSignInFacebook(response));
  }, [window.location.search]);

  return <SignIn />;
}

export default FacebookCallback;
