import React, { createContext, useContext, useState } from "react";
import { getHost, getSlug } from "../getHost";

const DefaultContext = createContext({});

export const DefaultProvider = ({ children }) => {
  const [host] = useState(getHost());
  const [slug] = useState(getSlug());

  return (
    <DefaultContext.Provider
      value={{
        host,
        slug
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export function useDefaultProvider() {
  const context = useContext(DefaultContext);

  if (!context) {
    throw new Error(
      "useDefaultProvider must be used within an DefaultProvider."
    );
  }

  return context;
}
