import React, { createContext, useEffect, useMemo, useState } from "react";

import useApplyVacancy from "@hooks/useApplyVacancy";
import useCurriculum from "@hooks/useCurriculum";
import * as schemas from "@utils/formValidations/curriculumV2/index";

const CurriculumValidationContext = createContext({});

function CurriculumValidationProvider({ children }) {
  const [validationsMenu, setValidationsMenu] = useState();
  const { requiredFields, isApplyVacancy, isApplyTalentBase, vacancy } =
    useApplyVacancy();
  const { formMethods, userData } = useCurriculum();
  const [fieldsNeedingToBeFilledAgain, setFieldsNeedingToBeFilledAgain] =
    useState([]);
  const applicantId = formMethods.getValues()?.applicantId;

  const handleCustomStatus = (error) => {
    const fields = [...fieldsNeedingToBeFilledAgain];

    switch (error.type) {
      case "needs-to-fill-again":
        if (!fields.some((field) => field === error.path))
          fields.push(error.path);

        setFieldsNeedingToBeFilledAgain(fields);
        return { type: "warning", error: error.type };

      case "required":
      case "is-required":
        return isApplyVacancy || isApplyTalentBase
          ? { type: "error", error: "is-required" }
          : null;

      default:
        return null;
    }
  };

  const validationSection = async (functionName) => {
    const schema = schemas[functionName](requiredFields, vacancy);
    const result = schema
      .validate(formMethods.getValues())
      .then(() => ({ isValid: true }))
      .catch((err) => ({ isValid: false, status: handleCustomStatus(err) }));
    return result;
  };

  const validations = async () => {
    setFieldsNeedingToBeFilledAgain([]);

    let successes = {
      aboutYou: await validationSection("aboutYouSchema"),
      experiences: await validationSection("experiencesSchema"),
      skills: await validationSection("skillsSchema"),
      address: await validationSection("addressSchema"),
      personal: await validationSection("personalDataSchema"),
      documents: await validationSection("documentsSchema")
    };

    if (isApplyVacancy) {
      const { defaultRequireds } = userData;
      if (defaultRequireds?.skills || defaultRequireds?.languages) {
        successes = {
          ...successes,
          skills: {
            isValid: false,
            status: { type: "error", error: "is-required" }
          }
        };
      }
    }

    setValidationsMenu(successes);
  };

  useEffect(() => {
    if (applicantId && userData?.id) validations();
  }, [applicantId, userData, vacancy]);

  const value = useMemo(
    () => ({
      validationsMenu,
      setValidationsMenu,
      fieldsNeedingToBeFilledAgain
    }),
    [validationsMenu, setValidationsMenu, fieldsNeedingToBeFilledAgain]
  );

  return (
    <CurriculumValidationContext.Provider value={value}>
      {children}
    </CurriculumValidationContext.Provider>
  );
}

export { CurriculumValidationContext, CurriculumValidationProvider };
