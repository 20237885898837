import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { promiseMiddleware } from "@adobe/redux-saga-promise";

import createStore from "./createStore";
import reducers from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";
import history from "../services/history";

const sagaMiddleware = createSagaMiddleware({});
const _routerMiddleware = routerMiddleware(history);

const middlewares = [promiseMiddleware, sagaMiddleware, _routerMiddleware];

const curriculumBlackList = createTransform(
  null,
  (state) => ({
    ...state,
    listCountries: [],
    listStates: [],
    listCities: [],
    userAddress: {}
  }),
  {
    whitelist: ["curriculum"]
  }
);

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blackList: ["utilities"],
  transforms: [curriculumBlackList]
};

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
