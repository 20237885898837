import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";

import useFetch from "./useFetch";
import { useAuth } from "./useAuth";

import * as candidateActions from "../../store/modules/perfilCandidate/actions";

import * as cookie from "../cookie";

const CandidateContext = createContext({});

export const CandidateProvider = ({ children }) => {
  const { token } = useAuth();
  const [profiler, setProfiler] = useState({});

  const { data: profilerData, loading: loadingProfiler } = useFetch(
    () => (token ? "/profiler" : null),
    {
      revalidateOnFocus: false
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // exibe uma notificação para o candidato caso seja necessário
    // na tela de autenticação quando iniciada
    const { candidate_message } = cookie.getJSON();
    if (candidate_message) {
      const { type, message, description } = candidate_message;

      if (type && description) {
        notification[type]({
          message,
          description
        });
      }

      cookie.set("candidate_message", "");
    }
  }, []);

  useEffect(() => {
    const data = profilerData?.passaport || {};
    setProfiler(data);
    dispatch(candidateActions.setProfiler(data));
  }, [dispatch, profilerData]);

  return (
    <CandidateContext.Provider
      value={{
        profiler,
        loadingProfiler
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export function useCandidate() {
  const context = useContext(CandidateContext);

  if (!context) {
    throw new Error("useCandidate must be used within an CandidateProvider.");
  }

  return context;
}
