import { useEffect } from "react";
import { useDispatch } from "react-redux";

import * as utilitiesActions from "@store/modules/utilities/actions";

const CookiesWarning = ({ display, callbackWarningCookies }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const type = ![false, "false"].includes(display);

    if (type) {
      dispatch(
        utilitiesActions.showModal({
          show: true,
          type: "cookiesWarning",
          options: {
            position: "middle",
            size: "medium",
            hasBgShadow: true,
            bgShadow: "",
            showClose: false,
            closeOnOverlay: false
          },
          dataParams: {
            callbackWarningCookies
          }
        })
      );
    }
  }, [display]);

  return null;
};

export default CookiesWarning;
