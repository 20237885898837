import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as CloseSVG } from "@assets/svg/closeModal.svg";
import { ReactComponent as CheckSVG } from "@assets/svg/check.svg";
import { Button, Typography } from "@components/";
import { Input } from "@components/form";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as authActions from "@store/modules/auth/actions";

import {
  verifyMinimumCharacters,
  verifyContentNumber,
  verifyContentLetter,
  verifyContentSpecialCharacters
} from "@utils/rulesPassword";

import schema from "./schema";

import * as Styled from "./styles";

function PasswordResetForm() {
  const [isFocusPassword, setIsFocusPassword] = useState(false);
  const [rulesPassword, setRulesPassword] = useState({
    minimumCharacters: false,
    contentNumber: false,
    contentLetter: false,
    contentSpecialCharacters: false
  });

  const dispatch = useDispatch();
  const { token } = useParams();
  const propsButton = {
    buttonType: "submit",
    backgroundColor: ({ theme }) => theme.colors.purple,
    fontWeight: "bold",
    boxShadowColor: ({ theme }) => theme.colors.shadow
  };

  const formMethods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
      token
    }
  });

  const [newPassword, confirmNewPassword] = formMethods?.watch([
    "newPassword",
    "confirmNewPassword"
  ]);

  const handleIconRule = (isSuccess) =>
    isSuccess ? (
      <CheckSVG className="success" />
    ) : (
      <CloseSVG className="error" />
    );

  const onSubmit = (values) => {
    dispatch(authActions.updatePassword(values));
  };

  useEffect(() => {
    setRulesPassword({
      minimumCharacters: verifyMinimumCharacters(newPassword),
      contentNumber: verifyContentNumber(newPassword),
      contentLetter: verifyContentLetter(newPassword),
      contentSpecialCharacters: verifyContentSpecialCharacters(newPassword)
    });

    const isError = confirmNewPassword && confirmNewPassword !== newPassword;
    const messageError = {
      type: "manual",
      message: "As senhas não coincidem. Tente novamente."
    };
    formMethods.setError("confirmNewPassword", isError ? messageError : {});
  }, [newPassword]);

  return (
    <FormProvider {...formMethods}>
      <Typography type="title">Redefina sua senha</Typography>
      <Typography>
        Crie sua nova senha, seguindo as regras para uma senha segura.
      </Typography>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Styled.Row>
          <Input
            label="Nova Senha"
            type="password"
            name="newPassword"
            data-testid="newPassword"
            onFocus={() => setIsFocusPassword(true)}
            required
          />
          {Object.values(rulesPassword).includes(false) && isFocusPassword && (
            <Styled.SectionRulesPassword>
              <p>A senha deve ter:</p>
              <ul>
                <li>
                  Mínimo de 6 caracteres
                  {handleIconRule(rulesPassword.minimumCharacters)}
                </li>
                <li>
                  Pelo menos uma letra
                  {handleIconRule(rulesPassword.contentLetter)}
                </li>
                <li>
                  Pelo menos um número
                  {handleIconRule(rulesPassword.contentNumber)}
                </li>
                <li>
                  Pelo menos um caractere especial
                  {handleIconRule(rulesPassword.contentSpecialCharacters)}
                </li>
              </ul>
            </Styled.SectionRulesPassword>
          )}
          <Input
            label="Confirme a nova senha"
            type="password"
            name="confirmNewPassword"
            data-testid="confirmNewPassword"
            required
          />
          <Button text="SALVAR NOVA SENHA" medium {...propsButton} />
        </Styled.Row>
      </form>
    </FormProvider>
  );
}

export { PasswordResetForm };
