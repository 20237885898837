import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import moment from "moment";
import { notification } from "antd";

import {
  Tip,
  ProgressBar,
  Button,
  Header,
  Banner,
  FloatingFooter
} from "@components/index";

import * as cookie from "@utils/cookie";

import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import JobsBannerDesktopCandidate from "@assets/svg/banner_candidate_jobs_desktop.svg";
import JobsBannerMobileCandidate from "@assets/svg/banner_candidate_jobs_mobile.svg";

import * as profilerActions from "@store/modules/profiler/actions";
import * as actionAuth from "@store/modules/auth/actions";

import { useAuth } from "@hooks/useAuth";
import { useCandidate } from "@hooks/useCandidate";

import { DEFAULT_HOST_CANDIDATE } from "@services/constants";
import * as StyledGlobal from "@styles/global";

import QuizStep from "./steps/quizStep";
import * as Styled from "./styles";

function Profiler({ percentage, blockedButtons, history }) {
  const dispatch = useDispatch();
  const [step, setStep] = useState("iAmChosenAdjectives");
  const [quiz, setQuiz] = useState({
    testStartTime: "",
    testEndTime: "",
    iAmChosenAdjectives: [],
    shouldBeChosenAdjectives: []
  });
  const [blocked, setBlocked] = useState(true);
  const [newPercentage, setNewPercentage] = useState(0);
  const [tempPercentage, setTempPercentage] = useState(0);
  const [vacancy, setVacancy] = useState({});
  const { token, user } = useAuth();
  const { profiler } = useCandidate();
  const [isApplyVacancy, setIsApplyVacancy] = useState(false);
  const { hasProfileExpired } = cookie.getJSON();

  const MIN_WORD_PER_SELECT = 5;

  useEffect(() => {
    const { [window.name]: tab } = cookie.getJSON();

    setVacancy(tab?.vacancy || {});
    setIsApplyVacancy(!!tab?.applyingVacancy);

    dispatch(profilerActions.unblockButtons());
  }, [dispatch]);

  useEffect(() => {
    if (profiler?.perfil && !hasProfileExpired) {
      notification.warn({
        message: "Atenção",
        description: "O profiler já se encontra preenchido."
      });

      history.push("/curriculum");
    }
  }, [history, profiler]);

  useEffect(() => {
    setNewPercentage(percentage);
  }, [percentage]);

  useEffect(() => {
    const starter = {
      testStartTime: "",
      testEndTime: "",
      iAmChosenAdjectives: [],
      shouldBeChosenAdjectives: []
    };
    starter.testStartTime = moment().format("DD/MM/YYYY hh:mm");

    setQuiz(starter);
  }, []);

  useEffect(() => {
    let currentPercentage = 0;
    const isBLocked =
      quiz[step] === undefined ||
      (quiz[step] && quiz[step].length < MIN_WORD_PER_SELECT);

    if (!isBLocked && step === "iAmChosenAdjectives" && tempPercentage < 25) {
      currentPercentage = newPercentage + 25;
      setNewPercentage(currentPercentage);
      setTempPercentage(25);
    } else if (
      isBLocked &&
      step === "iAmChosenAdjectives" &&
      tempPercentage === 25
    ) {
      currentPercentage = newPercentage - 25;
      setNewPercentage(currentPercentage);
      setTempPercentage(0);
    }

    if (
      !isBLocked &&
      step === "shouldBeChosenAdjectives" &&
      tempPercentage < 50
    ) {
      currentPercentage = newPercentage + 25;
      setNewPercentage(currentPercentage);
      setTempPercentage(50);
    } else if (
      isBLocked &&
      step === "shouldBeChosenAdjectives" &&
      tempPercentage === 50
    ) {
      currentPercentage = newPercentage - 25;
      setNewPercentage(currentPercentage);
      setTempPercentage(25);
    }

    setBlocked(isBLocked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quiz]);

  const saveAndNextStepHandle = () => {
    if (!blocked) {
      if (step === "shouldBeChosenAdjectives") {
        const data = {
          ...quiz,
          testEndTime: moment().format("DD/MM/YYYY hh:mm")
        };
        setQuiz(data);
        submitHandle(data);
      }
      if (step === "iAmChosenAdjectives") {
        const data = { ...quiz, shouldBeChosenAdjectives: [] };
        setQuiz(data);
        setStep("shouldBeChosenAdjectives");
      }

      setBlocked(true);
    }
  };

  const submitHandle = (data) => {
    dispatch(profilerActions.sendAnswersProfiler(data));
  };

  const renderDescriptionByApply = useMemo(() => {
    const isFirstApply = !user?.myApplications?.length;
    return (
      isFirstApply &&
      vacancy?.title && (
        <Styled.ContainerText txtAlign="center" marginBottom="40">
          <Styled.TextPrimary>
            Preencha o profiler para se candidatar à vaga{" "}
            <strong>{vacancy.title}</strong>.
          </Styled.TextPrimary>
        </Styled.ContainerText>
      )
    );
  }, [user, vacancy.title]);

  const floatingFooterButtons = useMemo(
    () =>
      [
        step === "iAmChosenAdjectives" &&
          isApplyVacancy && {
            text: "Revisar currículo",
            onClick: () =>
              (window.location.href = `${DEFAULT_HOST_CANDIDATE}/curriculum`)
          },
        {
          text:
            step === "iAmChosenAdjectives"
              ? "Salvar e continuar"
              : isApplyVacancy
              ? "Candidatar"
              : "Finalizar",
          onClick: saveAndNextStepHandle,
          isPrimary: true,
          blocked: blockedButtons || blocked
        }
      ].filter(Boolean),
    [blockedButtons, blocked, saveAndNextStepHandle, step]
  );

  return (
    <StyledGlobal.Main>
      <Header
        logo={JobsLogo}
        urlLogo={DEFAULT_HOST_CANDIDATE}
        token={token}
        user={user}
        onPerfil={() => cookie.redirect(DEFAULT_HOST_CANDIDATE)}
        onLogout={() => dispatch(actionAuth.logout())}
      />

      <Banner
        urlDesktop={JobsBannerDesktopCandidate}
        urlMobile={JobsBannerMobileCandidate}
      />

      <StyledGlobal.Container
        id="container_pdf"
        two={step === "iAmChosenAdjectives" && isApplyVacancy}
      >
        <StyledGlobal.BodyMain>
          <StyledGlobal.BodyContent>
            <Styled.ContainerBody>
              <StyledGlobal.ContainerWizard>
                <StyledGlobal.ProgressBar progress={75} />
                {renderDescriptionByApply}
                <StyledGlobal.WizardList>
                  <StyledGlobal.WizardListItem>
                    <StyledGlobal.WizardListItemText>
                      Cadastro de currículo e dados pessoais
                    </StyledGlobal.WizardListItemText>
                    <StyledGlobal.WizardListItemDot completed />
                  </StyledGlobal.WizardListItem>
                  <StyledGlobal.WizardListItem>
                    <StyledGlobal.WizardListItemText bold>
                      Questionário comportamental Profiler
                    </StyledGlobal.WizardListItemText>
                    <StyledGlobal.WizardListItemDot active />
                  </StyledGlobal.WizardListItem>
                </StyledGlobal.WizardList>
              </StyledGlobal.ContainerWizard>

              <QuizStep
                step={step}
                fallbackSelectItem={(items) =>
                  setQuiz({ ...quiz, [step]: items })
                }
              />
            </Styled.ContainerBody>
          </StyledGlobal.BodyContent>
          <Styled.Aside>
            <Styled.ContainerFilter>
              <StyledGlobal.Desktop>
                <Styled.FilterItem>
                  <ProgressBar
                    percentage={newPercentage}
                    minHeight="28px"
                    progressBackground="#999999"
                    progressColor="#333333"
                    descriptionTitle="Encontrabilidade: "
                    description="Preencha todos os dados para aumentar as chances de encontrar sua vaga ideal."
                  />
                </Styled.FilterItem>
                <Styled.FilterItem id="tip">
                  <Tip
                    title="Dica"
                    description={`Escolha ao menos ${MIN_WORD_PER_SELECT} palavras para prosseguir.`}
                  />
                </Styled.FilterItem>
              </StyledGlobal.Desktop>

              <StyledGlobal.Mobile>
                <StyledGlobal.ProgressBarMobile
                  two={step === "iAmChosenAdjectives" && isApplyVacancy}
                >
                  <ProgressBar
                    minHeight="20px"
                    percentage={newPercentage}
                    hasShadown={false}
                    progressColor="#333333"
                    progressBackground="rgba(196, 196, 196, 0.7)"
                  />
                </StyledGlobal.ProgressBarMobile>
              </StyledGlobal.Mobile>

              <Styled.ContainerButton
                two={step === "iAmChosenAdjectives" && isApplyVacancy}
              >
                <Button
                  type="custom"
                  buttonId={
                    step === "iAmChosenAdjectives"
                      ? "saveDataToNextStep"
                      : "saveFinalize"
                  }
                  text={
                    step === "iAmChosenAdjectives"
                      ? "Salvar dados e ir para próxima etapa"
                      : isApplyVacancy
                      ? "Salvar dados e me candidatar"
                      : "Salvar dados e finalizar"
                  }
                  backgroundColor="#333333"
                  isUppercase={false}
                  isTooltip
                  description={`Selecione ao menos ${MIN_WORD_PER_SELECT} atributos para poder prosseguir.`}
                  medium
                  onClick={() => saveAndNextStepHandle()}
                  blocked={blockedButtons || blocked}
                  className="curriculum"
                />
              </Styled.ContainerButton>
              {step === "iAmChosenAdjectives" && isApplyVacancy && (
                <Styled.ContainerButton>
                  <Button
                    type="custom"
                    buttonId="curriculumReview"
                    text="Revisar currículo"
                    backgroundColor="#333333"
                    isUppercase={false}
                    isTooltip
                    medium
                    onClick={() =>
                      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`)
                    }
                    className="curriculum"
                  />
                </Styled.ContainerButton>
              )}
            </Styled.ContainerFilter>
          </Styled.Aside>

          <StyledGlobal.Mobile>
            <FloatingFooter buttons={floatingFooterButtons}>
              <ProgressBar
                minHeight="20px"
                percentage={percentage}
                hasShadown={false}
                progressColor="#333333"
                progressBackground="rgba(196, 196, 196, 0.7)"
              />
            </FloatingFooter>
          </StyledGlobal.Mobile>
        </StyledGlobal.BodyMain>
      </StyledGlobal.Container>
    </StyledGlobal.Main>
  );
}

const mapStateToProps = (state) => ({
  percentage: state.curriculum.percentage,
  blockedButtons: state.profiler.blockedButtons
});

export default compose(withRouter, connect(mapStateToProps, null))(Profiler);
