import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border: 2px solid ${({ borderColor }) => borderColor || "#ffba00"};
  border-radius: 5px;
  padding: 20px;
  text-align: center;
`;

export const Title = styled.h3`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 3px;
  text-transform: uppercase;
  color: ${({ titleColor }) => titleColor || "#333333"};
`;

export const Description = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: ${({ descriptionColor }) => descriptionColor || "#333333"};
`;
