import styled from "styled-components";

export const ImageSvg = styled.div`
  cursor: pointer;
  height: ${(props) => props.height}px;
  width: ${(props) => props.height}px;
  svg {
    max-height: ${(props) => props.height}px;
    max-width: ${(props) => props.height}px;
    border-radius: 50%;
    border: 16px solid #efedf3;
  }
`;

export const ProfilerPhotoUser = styled.div`
  cursor: pointer;
  height: ${(props) => props.height}px;
  width: ${(props) => props.height}px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  border-radius: 50%;
  border: 16px solid #efedf3;
`;

export const OptionsContainer = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 5px;
`;

export const OptionText = styled.span`
  cursor: pointer;
  padding: 0 5px;
  color: ${({ color }) => color || "#333333"};
`;

export const ProfilerPhotoContainer = styled.div`
  position: relative;
  text-align: center;
  padding-bottom: 5px;
  :hover ${OptionsContainer} {
    ${({ showOptions }) => (showOptions ? "display: block" : "display: none")}
  }
`;
