import React from "react";
import PropTypes from "prop-types";

import { Button } from "@components/form";
import { Title, Description, ModalFooter } from "../styles";

export const FinishedRegistration = ({ action }) => (
  <>
    <Title>Seu currículo está pronto para ser enviado!</Title>
    <Description>
      Agora continue para o profiler, nossa etapa de perfil comportamental. Os
      recrutadores analisam o profiler para ter um match perfeito nas vagas.
    </Description>

    <ModalFooter>
      <Button onClick={action} theme="dark" maxWidth="200px">
        Ir para o profiler
      </Button>
    </ModalFooter>
  </>
);

FinishedRegistration.propTypes = {
  action: PropTypes.func.isRequired
};
