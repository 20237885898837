import styled from "styled-components";

export const Container = styled.section`
  h1 {
    font-size: 1.5rem;
  }
  > p {
    font-size: 14px;
    margin: 32px 0 12px;
  }
  button {
    margin: 20px 0 32px;
  }
`;
