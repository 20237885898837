import React from "react";
import sanitizeHtml from "sanitize-html";

const SanitizeHTML = ({ html, options }) => {
  const defaultOptions = {
    allowedTags: [
      "div",
      "span",
      "p",
      "a",
      "li",
      "ol",
      "ul",
      "b",
      "strong",
      "i",
      "u",
      "small",
      "br"
    ],
    allowedAttributes: {
      a: ["href", "name", "target"]
    },
    allowedSchemesAppliedToAttributes: ["href"],
    allowedSchemes: ["mailto", "http", "https"]
  };

  const sanitize = (dirty, options) => {
    const newOptions = { ...defaultOptions, ...options };
    return {
      __html: sanitizeHtml(dirty, newOptions)
    };
  };

  return <div dangerouslySetInnerHTML={sanitize(html, options)} />;
};

export default SanitizeHTML;
