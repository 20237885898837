import * as yup from "yup";
import { addHours } from "date-fns";

import { validateCPF } from "@utils/validations";

const schema = (requiredFields = []) => {
  yup.addMethod(yup.string, "validateCPF", validateCPF);

  return yup.object().shape({
    documents: yup.object().shape({
      cpf: yup
        .string()
        .required("Campo de preenchimento obrigatório")
        .validateCPF("CPF inválido"),
      rg: yup
        .number()
        .typeError("Somente números")
        .isRequired(
          "indentify",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isNotZero()
        .nullable()
    }),
    contacts: yup.object().shape({
      email: yup
        .string()
        .isValidEmail()
        .required("Campo de preenchimento obrigatório")
        .nullable(),
      secondaryEmail: yup
        .string()
        .isRequired(
          "secondaryEmail",
          requiredFields,
          "Campo de preenchimento obrigatório"
        )
        .isValidEmail()
        .notOneOf(
          [yup.ref("email")],
          "O e-mail secundário não pode ser o mesmo e-mail de login."
        )
        .nullable(),
      isWhatsapp: yup.string().nullable(),
      phone: yup
        .string()
        .required("Campo de preenchimento obrigatório")
        .test(
          "count-characteres",
          "O número de celular deve ter 11 dígitos.",
          (value) => value && value.toString().length === 11
        ),
      whatsapp: yup
        .string()
        .when(["isWhatsapp", "phone"], (isWhatsapp, phone, schema) =>
          schema.test({
            name: "required",
            test: (value) =>
              (isWhatsapp === "yes" && (!value || value === phone)) ||
              (isWhatsapp === "no" && (!value || value !== phone)),
            message: "Este número não pode ser igual ao celular."
          })
        )
        .test(
          "count-characteres",
          "O número de celular deve ter 11 dígitos.",
          (value) => !value || value.toString().length === 11
        )
        .nullable()
    }),
    birthday: yup
      .date()
      .required("Campo de preenchimento obrigatório")
      .typeError("Data inválida")
      .min(
        new Date("1930-01-01"),
        "Data deve ser igual ou superior a 01/01/1930"
      )
      .max(
        addHours(new Date(), 1),
        "A data não pode ser maior do que a data de hoje"
      )
      .nullable(),
    gender: yup
      .string()
      .required("Campo de preenchimento obrigatório")
      .nullable(),
    maritalStatus: yup
      .string()
      .isRequired(
        "maritalStatus",
        requiredFields,
        "Campo de preenchimento obrigatório"
      ),
    isDisabledPerson: yup.bool(),
    disabledPersonDescription: yup
      .array()
      .isRequired(
        "specialNeedOrDisability",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .when(["isDisabledPerson"], (isDisabledPerson, schema) =>
        schema.test(
          "needs-to-fill-again",
          "Esse campo deve ser preenchido novamente.",
          (disabledPersonDescription) =>
            !isDisabledPerson || disabledPersonDescription !== null
        )
      )
      .nullable()
  });
};

export default schema;
