import { CurriculumValidationContext } from "@contexts/curriculumValidationProvider";
import { useContext } from "react";

export function useCurriculumValidation() {
  const curriculumValidation = useContext(CurriculumValidationContext);

  if (!curriculumValidation) {
    throw new Error(
      "CurriculumValidation should be used within a CurriculumValidationProvider"
    );
  }

  return curriculumValidation;
}
