import styled from "styled-components";
import { tablet } from "@styles/breakpoints";

export const AddNewSkill = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 1rem;
  margin: 2rem 0 1.5rem;

  p {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: ${tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const NewDinamicSkill = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-top: 1.5rem;
    max-width: 150px;
  }
`;

export const Count = styled.p`
  text-align: right;
  font-size: 0.8rem;
  color: #666a6d;
`;

export const FieldSkill = styled.div`
  input {
    ${({ disabled }) => disabled && "border-color: #EF3D4E "}
  }

  @media (max-width: 1094px) {
    margin-top: 1.375rem;
  }

  @media (max-width: 920px) {
    margin-top: 0;
  }
`;

export const Error = styled.span`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #ef3d4e;
`;

export const DetailFieldSkill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
