import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        overflow: ${({ modalOpen }) => (modalOpen ? "hidden" : "auto")};
        /* padding-right: ${({ modalOpen }) =>
          modalOpen ? "17px" : "auto"}; */
    }
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1001;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
`;

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
`;

export const ModalDialog = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1002;

  display: flex;

  ${({ scrollable }) =>
    scrollable &&
    `
		max-height: calc(100% - 1rem);
		${ModalBody} {
			overflow-y: auto;

			::-webkit-scrollbar {
				width: 6px;
			}

			::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				background-color: #333333;
			}
		}
    `}

  ${({ position }) =>
    position === "topLeft"
      ? "justify-content: flex-start;"
      : position === "topRight"
      ? "justify-content: flex-end;"
      : position === "topCenter"
      ? "justify-content: center;"
      : position === "middle"
      ? "justify-content: center; align-items: center;"
      : position === "middleLeft"
      ? "justify-content: flex-start; align-items: center;"
      : position === "middleRight"
      ? "justify-content: flex-end; align-items: center;"
      : position === "bottomLeft"
      ? "justify-content: flex-start; align-items: flex-end;"
      : position === "bottomRight"
      ? "justify-content: flex-end; align-items: flex-end;"
      : position === "bottomCenter"
      ? "justify-content: center; align-items: flex-end;"
      : "justify-content: center; align-items: center;"}
`;

export const ModalOverlay = styled.div`
  ${({ hasBgShadow, bgShadow }) =>
    hasBgShadow &&
    `
	  	position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		min-height: 100%;
		background-color: ${bgShadow || "#000"};
		z-index: 1000;
		opacity: 0.5;
	`}
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || "#fff"};
  border-radius: 10px;
  z-index: 1003;
  box-shadow: ${({ boxShadow }) =>
    boxShadow || " 0px 4px 10px rgba(0, 0, 0, 0.25)"};

  /* less than 576px */
  width: 100%;
  margin: 110px 0;

  @media screen and (max-width: 554px) {
    width: 95%;
    min-width: 300px !important;
  }

  /* Medium devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    ${({ size }) =>
      size === "fullsize"
        ? "width: calc(100% - 40px);"
        : size === "small"
        ? "width: 300px;"
        : size === "medium"
        ? "width: 600px;"
        : size === "large"
        ? "width: 900px;"
        : "width: auto;"}

    ${({ position }) =>
      position === "topLeft"
        ? "margin: 20px 0 20px 20px;"
        : position === "topRight"
        ? "margin: 20px 20px 20px 0;"
        : position === "topCenter"
        ? "margin: 20px 0;"
        : position === "middleLeft"
        ? "margin: 0 0 0 20px;"
        : position === "middleRight"
        ? "margin: 0 20px 0 0;"
        : position === "bottomLeft"
        ? "margin: 0 0 20px 20px;"
        : position === "bottomRight"
        ? "margin-bottom: 20px; margin-right: 20px; margin: 0 20px 20px 0;"
        : position === "bottomCenter"
        ? "margin-bottom: 20px;"
        : position === "middleCenter"
        ? "margin: 190px"
        : "margin: 1rem"}
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
`;

export const ButtonClose = styled.div`
  visibility: ${({ showClose }) => (showClose ? "visible" : "hidden")};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
    fill: ${({ color }) => color || "#333333"};
    cursor: pointer;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
`;
