import React from "react";
import * as Styled from "../styles";
// import { ReactComponent as GoogleIcon } from "../../../assets/svg/google.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/svg/google_transparent.svg";

const Google = ({
  onClick,
  text,
  isUppercase = true,
  children,
  leftIcon = false,
  rightIcon = false,
  ...rest
}) => (
  <Styled.ContentButton {...rest}>
    <Styled.ButtonGoogle {...rest} onClick={onClick} isUppercase={isUppercase}>
      {leftIcon !== false && (
        <Styled.LeftIcon {...rest}>
          <GoogleIcon />
        </Styled.LeftIcon>
      )}

      {text}
      {children}

      {rightIcon !== false && (
        <Styled.RightIcon {...rest}>
          <GoogleIcon />
        </Styled.RightIcon>
      )}
    </Styled.ButtonGoogle>
  </Styled.ContentButton>
);

export default Google;
