import { call, put, all, takeLatest } from "redux-saga/effects";
import { notification } from "antd";

import * as api from "./repository";
import * as cookie from "../../../utils/cookie";

import { DEFAULT_HOST_LOGIN } from "../../../services/constants";

function* descriptionVacancySaga({ payload }) {
  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: vacancy } = yield call(
      api.getDescriptionVacancy,
      payload.vacancyId
    );

    const tempVacancy = {
      ...vacancy.data,
      status: vacancy.data.applied ? vacancy.data.applied : false
    };

    yield put({
      type: "@perfilCandidate/DESCRIPTION_VACANCY_SUCCESS",
      payload: {
        vacancy: tempVacancy
      }
    });
  } catch (error) {
    if (error.response && error.response.data) {
      notification.warn({
        message: "Atenção",
        description: error.response.data.message
      });
    }
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

function* getInitialVacancySaga() {
  yield put({
    type: "@perfilCandidate/DESCRIPTION_VACANCY_SUCCESS",
    payload: { vacancy: {} }
  });
}

export function* setChangeEmailSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const {
      data: { message }
    } = yield call(api.setChangeEmail, payload);

    yield put({
      type: "@authentication/SET_PREFERENCES",
      payload
    });

    yield put({
      type: "@utilities/MODAL",
      payload: { modal: { show: false } }
    });

    notification.success({
      message: "Sucesso",
      description: message
    });

    yield put({
      type: "@utilities/MODAL",
      payload: { modal: { show: false } }
    });
  } catch (error) {
    notification.warn({
      message: "Atenção",
      description: error.response.data.message
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* setChangePasswordSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const {
      data: { message }
    } = yield call(api.setChangePassword, payload);

    notification.success({
      message: "Sucesso",
      description: message
    });

    yield put({
      type: "@utilities/MODAL",
      payload: { modal: { show: false } }
    });
  } catch (error) {
    notification.warn({
      message: "Atenção",
      description: error.response.data.message
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export function* deleteUserSaga() {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true }
    });

    const {
      data: { message }
    } = yield call(api.deleteUser);

    notification.success({
      message: "Sucesso",
      description: message
    });

    yield put({
      type: "@utilities/MODAL",
      payload: { modal: { show: false } }
    });

    yield put({
      type: "@authentication/LOGOUT_REQUEST"
    });

    // remoção dos dados da autenticação
    cookie.set("token", "");
    cookie.set("token_expiration", "");

    const auth_message = {
      type: "success",
      message: "Atenção",
      description: "Conta excluída com sucesso!"
    };

    cookie.set("auth_message", auth_message);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  } catch (error) {
    notification.warn({
      message: "Atenção",
      description: "Não foi possível concluir a operação."
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest("@perfilCandidate/DESCRIPTION_VACANCY", descriptionVacancySaga),
  takeLatest("@perfilCandidate/INITIAL_VACANCY", getInitialVacancySaga),
  takeLatest("@perfilCandidate/DELETE_USER", deleteUserSaga),
  takeLatest("@perfilCandidate/CHANGE_EMAIL", setChangeEmailSaga),
  takeLatest("@perfilCandidate/CHANGE_USER_PASSWORD", setChangePasswordSaga)
]);
