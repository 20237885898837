import React from "react";

export const RADIOS_OPTIONS = [
  { value: "0", label: <strong>Nenhuma</strong> },
  { value: "", label: "" },
  {
    value: "1",
    label: (
      <>
        <strong>A1 - Iniciante:</strong> Você está iniciando agora os estudos do
        idioma.
      </>
    )
  },
  {
    value: "4",
    label: (
      <>
        <strong>B2 - Avançado:</strong> Você tem um conhecimento grande do
        idioma e consegue conversar sobre qualquer assunto.
      </>
    )
  },
  {
    value: "2",
    label: (
      <>
        <strong>A2 - Básico:</strong> Você entende, escreve e fala frases
        simples do idioma.
      </>
    )
  },
  {
    value: "5",
    label: (
      <>
        <strong>C1 - Fluente:</strong> Você tem alto conhecimento de gramática e
        conversa e entende assuntos complexos.
      </>
    )
  },
  {
    value: "3",
    label: (
      <>
        <strong>B1 - Intermediário:</strong> Você já tem conhecimento de
        gramática e vocabulário.
      </>
    )
  },
  {
    value: "6",
    label: (
      <>
        <strong>C2 - Domínio pleno:</strong> Você se comunica no idioma como um
        nativo.
      </>
    )
  }
];

export const RADIOS_OPTIONS_MOBILE = [
  { value: "0", label: <strong>Nenhuma</strong> },
  { value: "", label: "" },
  {
    value: "1",
    label: (
      <>
        <strong>A1 - Iniciante:</strong> Você está iniciando agora os estudos do
        idioma.
      </>
    )
  },
  {
    value: "2",
    label: (
      <>
        <strong>A2 - Básico:</strong> Você entende, escreve e fala frases
        simples do idioma.
      </>
    )
  },
  {
    value: "3",
    label: (
      <>
        <strong>B1 - Intermediário:</strong> Você já tem conhecimento de
        gramática e vocabulário.
      </>
    )
  },
  {
    value: "4",
    label: (
      <>
        <strong>B2 - Avançado:</strong> Você tem um conhecimento grande do
        idioma e consegue conversar sobre qualquer assunto.
      </>
    )
  },
  {
    value: "5",
    label: (
      <>
        <strong>C1 - Fluente:</strong> Você tem alto conhecimento de gramática e
        conversa e entende assuntos complexos.
      </>
    )
  },
  {
    value: "6",
    label: (
      <>
        <strong>C2 - Domínio pleno:</strong> Você se comunica no idioma como um
        nativo.
      </>
    )
  }
];
