export const LEVEL_SKILL = {
  0: { label: "Nenhum", level: 0 },
  33: { label: "Básico", level: 1 },
  66: { label: "Intermediário", level: 2 },
  100: { label: "Avançado", level: 3 }
};

export const MARK_LEVEL_OTHER_SKILL = {
  0: { label: "Básico", level: 1 },
  50: { label: "Intermediário", level: 2 },
  100: { label: "Avançado", level: 3 }
};
