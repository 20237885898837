/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  InputWithLabel,
  LeftIcon,
  RightIcon,
  InvalidFeedback
} from "../../styles";

export default function InputSearch({
  label,
  leftIcon = null,
  rightIcon = null,
  iconColor = null,
  onChange = () => {},
  value,
  error,
  placeholder = " ",
  ...rest
}) {
  return (
    <InputWithLabel
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      error={error}
      {...rest}
    >
      {leftIcon !== null && (
        <LeftIcon {...rest} iconColor={iconColor}>
          {leftIcon}
        </LeftIcon>
      )}

      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />

      <label {...rest}>{label}</label>

      {rightIcon !== null && (
        <RightIcon {...rest} iconColor={iconColor}>
          {rightIcon}
        </RightIcon>
      )}

      {!!error && <InvalidFeedback>{error}</InvalidFeedback>}
    </InputWithLabel>
  );
}
