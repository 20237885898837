export function getDescriptionVacancy(payload) {
  return {
    type: "@perfilCandidate/DESCRIPTION_VACANCY",
    payload
  };
}

export function getInitialVacancy() {
  return {
    type: "@perfilCandidate/INITIAL_VACANCY"
  };
}

export function setChangeEmail(payload) {
  return {
    type: "@perfilCandidate/CHANGE_EMAIL",
    payload
  };
}

export function setChangePassword(payload) {
  return {
    type: "@perfilCandidate/CHANGE_USER_PASSWORD",
    payload
  };
}

export function deleteUser() {
  return {
    type: "@perfilCandidate/DELETE_USER"
  };
}

export function setProfiler(profiler) {
  return {
    type: "@perfilCandidate/SET_PROFILER_SUCCESS",
    payload: { profiler }
  };
}
