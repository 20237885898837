import React from "react";
import PropTypes from "prop-types";

import { Button } from "@components/form";
import { Title, Description, ModalFooter } from "../styles";

export const GoBackSection = ({ onClose, action }) => (
  <>
    <Title>Deseja voltar para a etapa anterior?</Title>
    <Description>
      Ao confirmar você perderá o que já foi digitado nesta etapa.
    </Description>

    <ModalFooter>
      <Button onClick={onClose} theme="link">
        Cancelar
      </Button>
      <Button onClick={action} theme="dark">
        Sim, voltar
      </Button>
    </ModalFooter>
  </>
);

GoBackSection.propTypes = {
  action: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
