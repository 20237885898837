const INITIAL_STATE = {
  error: {},
  resetPasswordSuccess: false
};

export default function reducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@auth/INITIAL_ERROR_REQUEST":
      return {
        ...state,
        error: {}
      };
    case "@auth/CLEAN_SUCCESS_PASSWORD_RESET":
      return {
        ...state,
        resetPasswordSuccess: false
      };
    case "@auth/SUCCESS_PASSWORD_RESET":
      return {
        ...state,
        resetPasswordSuccess: action.payload.resetPasswordSuccess
      };
    case "@auth/ERROR_REQUEST":
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}
