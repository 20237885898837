import styled from "styled-components";

export const ContentBadge = styled.span`
  width: 100%;
  margin-top: 5px;
`;

export const Badge = styled.span`
  position: relative;
  font-family: "Roboto", arial, sans-serif;
  display: inline-block;
  background-color: #eeeeee;
  color: #333333;
  padding: 0.4em 0.8em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  ${(props) =>
    props.pill &&
    `
    border-radius: 1rem;
  `}
`;

export const Caption = styled.span`
  font-family: "Roboto", arial, sans-serif;
  font-size: 75%;
  display: inline-block;
  background-color: #f8f9fa;
  color: #212529;
  padding: 0.25em 0.4em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  position: relative;
  top: -1px;
  margin-left: 0.25em;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  position: relative;
  bottom: -1px;
  margin-left: 0.75em;

  svg {
    path:nth-of-type(1) {
      fill: ${({ iconColor }) => iconColor || "#ffffff"};
    }

    ${({ small, medium, large }) =>
      small
        ? `height: 10px; width: 10px;`
        : medium
        ? `height: 12px; width: 12px;`
        : large
        ? `height: 14px; width: 14px;`
        : `height: 10px; width: 10px;`}
  }
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Indicators = styled.button`
  font-family: "Roboto", arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  width: 35px;
  height: 35px;

  border: 2px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 24px;
  margin: 0 1px;

  background-color: transparent;
  cursor: pointer;
  color: #ce2d50;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.active &&
    `
    color: #FFFFFF;
    border: 2px solid #CE2D50;
    background-color: #CE2D50;
  `}

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}

  :hover {
    color: #ffffff;
    border: 2px solid #ce2d50;
    background-color: #ce2d50;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  svg {
    height: 10px;
    path {
      fill: #ce2d50;
    }
  }
`;
