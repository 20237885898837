import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Header } from "@components/index";
import { useAuth } from "@hooks/useAuth";
import * as actionAuth from "@store/modules/auth/actions";
import { DEFAULT_HOST_CANDIDATE } from "@services/constants";
import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import * as cookie from "@utils/cookie";

function PageHeader() {
  const { token, user } = useAuth();
  const dispatch = useDispatch();

  return (
    <Header
      logo={JobsLogo}
      urlLogo={DEFAULT_HOST_CANDIDATE}
      token={token}
      user={user}
      background="#fff"
      onPerfil={() => cookie.redirect(DEFAULT_HOST_CANDIDATE)}
      onLogout={() => dispatch(actionAuth.logout())}
    />
  );
}

export default React.memo(PageHeader);
