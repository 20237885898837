import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactPlayer from "react-player";

import diffDate from "../../../utils/getDiffDate";
import capitalizeString from "../../../utils/capitalizeString";

import * as Styled from "../styles";

import Groups from "../components/groups";
import AdditionalInformation from "../components/additionalInformation";
import Documents from "../components/documents";
import Advice from "../../../components/advice";
import { SkeletonCurriculum } from "../components/skeleton";

const Curriculum = ({ candidate = {}, loading }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [courses, setCourses] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);

  moment.locale("pt-br");

  useEffect(() => {
    !!candidate.videoUrl &&
      setVideoUrl(candidate.videoUrl.replace("watch?v=", "v/"));

    let tempExperiences = [];
    const tempCourses = [];
    const tempLanguages = [];
    const tempSkills = [];

    if (candidate.experiences)
      tempExperiences = transformExperiences(candidate.experiences);

    candidate?.courses?.map((course) => {
      let beginDate = "";
      let endDate = "";
      let totalTime = "";

      if (course.startDate) {
        beginDate = moment(course.startDate);
        course.startMonth = beginDate.format("MMM");
        course.startYear = beginDate.year();
      }

      if (course.endDate) {
        endDate = moment(course.endDate);
        course.endMonth = endDate.format("MMM");
        course.endYear = endDate.year();
      }

      if (!!course.startDate && !!course.current) {
        endDate = new moment();
        const duration = moment.duration(endDate.diff(beginDate));
        const numOfMonths = duration.asMonths();
        totalTime = diffDate(numOfMonths);
      } else if (!!course.startDate && !!course.endDate) {
        const duration = moment.duration(endDate.diff(beginDate));
        const numOfMonths = duration.asMonths();
        totalTime = diffDate(numOfMonths);
      }

      return tempCourses.push({
        logo: null,
        title: course.college,
        subTitle: totalTime,
        details: [
          {
            topic: course.course,
            subTopic: `${capitalizeString(course.startMonth)}/${
              course.startYear
            } até ${
              course.current === true
                ? "o momento"
                : `${capitalizeString(course.endMonth)}/${course.endYear}`
            } (${totalTime})`,
            description: course.description
          }
        ]
      });
    });

    let levelName = "";
    if (candidate.languages) {
      candidate.languages
        .filter((language) => language.level && language.level !== "0")
        .map((language) => {
          switch (language.level) {
            case "2":
              levelName = "Básico";
              break;
            case "3":
              levelName = "Intermediário";
              break;
            case "4":
              levelName = "Avançado";
              break;
            case "5":
              levelName = "Fluente";
              break;
            case "6":
              levelName = "Domínio pleno";
              break;
            default:
              levelName = "Iniciante";
              break;
          }

          return tempLanguages.push({
            totalAdvice: "",
            text: language.name,
            description: "",
            level: language.level,
            totalLevel: 6,
            levelName
          });
        });
    }

    if (candidate.skills) {
      candidate.skills
        .filter((skill) => skill.level && skill.level !== "0")
        .map((skill) => {
          switch (skill.level) {
            case "2":
              levelName = "Intermediário";
              break;
            case "3":
              levelName = "Avançado";
              break;
            default:
              levelName = "Básico";
              break;
          }

          return tempSkills.push({
            totalAdvice: "",
            text: skill.name,
            description: "",
            level: skill.level,
            totalLevel: 3,
            levelName
          });
        });
    }

    setExperiences(tempExperiences);
    setCourses(tempCourses);
    setLanguages(tempLanguages);
    setSkills(tempSkills);
  }, [candidate]);

  const transformExperiences = (experienceList) => {
    const newArr = [];
    const companies = {};
    let i;
    let experience;
    let totalTime;
    const j = experienceList.length;

    for (i = 0; i < j; i++) {
      experience = experienceList[i];

      if (!(experience.company in companies)) {
        companies[experience.company] = {
          logo: null,
          title: experience.company,
          subTitle: "",
          details: []
        };
        newArr.push(companies[experience.company]);
        totalTime = 0;
      }

      let beginDate = "";
      let endDate = "";
      let experienceTime = "";

      if (experience.startDate) {
        beginDate = moment(experience.startDate);
        experience.startMonth = beginDate.format("MMM");
        experience.startYear = beginDate.year();
      }

      if (experience.endDate) {
        endDate = moment(experience.endDate);
        experience.endMonth = endDate.format("MMM");
        experience.endYear = endDate.year();
      }

      if (!!experience.startDate && !!experience.current) {
        endDate = new moment();
        const duration = moment.duration(endDate.diff(beginDate));
        const numOfMonths = duration.asMonths();
        experienceTime = diffDate(numOfMonths);
        totalTime += numOfMonths;
      } else if (!!experience.startDate && !!experience.endDate) {
        const duration = moment.duration(endDate.diff(beginDate));
        const numOfMonths = duration.asMonths();
        experienceTime = diffDate(numOfMonths);
        totalTime += numOfMonths;
      }

      companies[experience.company].details.push({
        topic: experience.occupation,
        subTopic: `${capitalizeString(experience.startMonth)}/${
          experience.startYear
        } até ${
          experience.current === true
            ? "o momento"
            : `${capitalizeString(experience.endMonth)}/${experience.endYear}`
        } (${experienceTime})`,
        description: experience.description
      });

      companies[experience.company].subTitle = diffDate(totalTime);
    }

    return newArr;
  };

  const renderAdvice = (advices) =>
    advices.map((item, index) => (
      <Advice data={item} key={`advice_${index}`} />
    ));

  return loading ? (
    <SkeletonCurriculum />
  ) : (
    <Styled.ContainerCurriculum>
      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip01">Sobre Você</Styled.CurriculumTitle>
        {!!videoUrl && (
          <Styled.ContainerMedia>
            <Styled.ContainerVideo>
              <ReactPlayer
                url={videoUrl}
                width="100%"
                height="100%"
                controls={videoUrl.includes("vimeo.com")}
              />
            </Styled.ContainerVideo>
          </Styled.ContainerMedia>
        )}
        <Styled.CurriculumDescription>
          {!!candidate.about && candidate.about}
          {!candidate.about && !videoUrl && (
            <Styled.NotFound>Informação não cadastrada</Styled.NotFound>
          )}
        </Styled.CurriculumDescription>
      </Styled.ContainerField>

      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip02">EXPERIÊNCIA</Styled.CurriculumTitle>
        {!!experiences && !!experiences.length > 0 ? (
          <Styled.Groups>
            <Groups list={experiences} />
          </Styled.Groups>
        ) : (
          <Styled.NotFound>Informação não cadastrada</Styled.NotFound>
        )}
      </Styled.ContainerField>

      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip03">Formação</Styled.CurriculumTitle>
        {!!courses && !!courses.length > 0 ? (
          <Styled.Groups>
            <Groups list={courses} />
          </Styled.Groups>
        ) : (
          <Styled.NotFound>Informação não cadastrada</Styled.NotFound>
        )}
      </Styled.ContainerField>

      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip04">Idiomas</Styled.CurriculumTitle>
        {!!languages && !!languages.length > 0 ? (
          renderAdvice(languages)
        ) : (
          <Styled.NotFound>Informação não cadastrada</Styled.NotFound>
        )}
      </Styled.ContainerField>

      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip05">Habilidades</Styled.CurriculumTitle>
        {!!skills && !!skills.length > 0 ? (
          renderAdvice(skills)
        ) : (
          <Styled.NotFound>Informação não cadastrada</Styled.NotFound>
        )}
      </Styled.ContainerField>

      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip06">
          Informações adicionais
        </Styled.CurriculumTitle>
        <AdditionalInformation person={candidate} />
      </Styled.ContainerField>

      <Styled.ContainerField>
        <Styled.CurriculumTitle id="tip07">Documentos</Styled.CurriculumTitle>
        <Documents person={candidate} />
      </Styled.ContainerField>
    </Styled.ContainerCurriculum>
  );
};

export default Curriculum;
