export const input = {
  fontSize: "16px",
  background: "#fafafa",
  color: "#5f4a87",
  border: "1px solid #bfbac8",
  fontFamily: "Roboto",
  focusBorderColor: "#5f4a87",
  label: {
    fontSize: "13px",
    color: "#5f4a87",
    fontFamily: "Roboto"
  }
};

export const checkbox = {
  color: "#333333",
  colorEffect: "#333333",
  borderColor: "#333333",
  background: "#333333",
  colorIcon: "white",
  label: {
    color: "#333333",
    fontSize: "16px"
  }
};

export const select = {};
