import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";

const CurrencyFormat = React.forwardRef(
  ({ onChange, value, defaultValue, ...rest }, ref) => {
    const [currency, setCurrency] = useState(value);

    useEffect(() => {
      setCurrency(value);
    }, [value]);

    return (
      <NumberFormat
        {...rest}
        ref={ref}
        value={currency}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        onValueChange={(target) => {
          setCurrency(target.floatValue);
          onChange(target.value);
        }}
        isNumericString
        prefix="R$ "
        allowNegative={false}
      />
    );
  }
);

export default CurrencyFormat;
