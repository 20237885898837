import * as cookie from "./cookie";

import {
  DEFAULT_HOST_COMPANY,
  DEFAULT_HOST_HIDDEN_VACANCY,
  DEFAULT_HOST_CANDIDATE
} from "../services/constants";

export const getUserHistoric = () => {
  const { pathname } = window.location;
  const { [window.name]: tab } = cookie.getJSON();
  let breadcrumbList = [];

  if (tab?.company?.slug) {
    breadcrumbList.push({
      id: "companyVacancies",
      text: tab.company.name,
      isAnchor: true,
      onClick: () => {
        const companyHost = DEFAULT_HOST_COMPANY(tab.company.slug);
        cookie.redirect(companyHost);
      }
    });
  }

  if (tab?.applyingVacancy && tab?.vacancy) {
    breadcrumbList.push({
      id: "vacancies",
      text: tab.vacancy.title,
      isAnchor: true,
      onClick: () => {
        const companyHost = tab.company
          ? `${DEFAULT_HOST_COMPANY(tab.company.slug)}/vacancies`
          : DEFAULT_HOST_HIDDEN_VACANCY;
        cookie.redirect(`${companyHost}/${tab.vacancy.id}`);
      }
    });
  }

  if (pathname.includes("curriculum")) {
    breadcrumbList = [
      ...breadcrumbList,
      {
        id: "vacancies",
        text: "Currículo",
        isAnchor: true,
        onClick: () => cookie.redirect(DEFAULT_HOST_CANDIDATE)
      },
      {
        text: "Editar",
        isClick: false
      }
    ];
  } else if (
    breadcrumbList.length > 0 &&
    (pathname === "/" || pathname.includes("perfilCandidate"))
  ) {
    breadcrumbList = [
      ...breadcrumbList,
      {
        text: "Currículo",
        isClick: false
      }
    ];
  }

  return breadcrumbList;
};
