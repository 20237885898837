import { useContext } from "react";
import { ApplyVacancyContext } from "@contexts/index";

export default function useApplyVacancy() {
  const applyVacancy = useContext(ApplyVacancyContext);

  if (!applyVacancy) {
    throw new Error(
      "useApplyVacancy should be used within a ApplyVacancyProvider"
    );
  }

  return applyVacancy;
}
