import styled from "styled-components";
import { mobileLarge } from "@styles/breakpoints";

export const Container = styled.span`
  position: absolute;
  left: 0.125rem;
  bottom: -1.25rem;

  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.red};

  @media (max-width: 690px) {
    bottom: -2rem;
  }

  @media (max-width: 640px) {
    bottom: -1.25rem;
  }

  @media (max-width: 478px) {
    bottom: -2rem;
  }

  @media (max-width: ${mobileLarge}) {
    line-height: 1rem;
    position: relative;
    bottom: 0;
    padding-top: 0.125rem;
    margin-bottom: -1.125rem;
  }
`;
