import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import useApplyVacancy from "@hooks/useApplyVacancy";
import { useCurriculumModal } from "@hooks/useCurriculumModal";

import { Typography, Icon } from "@components/";
import scrollToElementById from "@utils/scrollToElement";
import { Cardboard } from "@pages/curriculumV2/components/cardboard";
import { Input, Textarea, Checkbox, Button } from "@components/form";

import { Container } from "./styles";
import { Row, Col } from "../../../styles";
import ExperienceDateRange from "../experienceDateRange";

const DEFAULT_FIELDS_EXPERIENCE = {
  occupation: "",
  company: "",
  startMonth: "",
  startYear: "",
  endMonth: "",
  endYear: "",
  description: "",
  current: false
};

// 'Jovem aprendiz', 'Estágio', 'Trainee'
const SENIORITIES_VACANCY = [1, 2, 3];

export const ProfessionalExperience = ({ required, defaultBox }) => {
  const { vacancy } = useApplyVacancy();
  const { handleOpenModal } = useCurriculumModal();
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences"
  });
  const [hasRemoving, setHasRemoving] = useState(false);

  const isExperienceHidden = useMemo(() => {
    if (vacancy.seniority && vacancy.seniority.length) {
      const isHidden = vacancy.seniority.some((seniority) =>
        SENIORITIES_VACANCY.includes(seniority.id)
      );
      return isHidden;
    }

    return false;
  }, [vacancy?.seniority]);

  useEffect(() => {
    const isNewField =
      (!isExperienceHidden || required) && !hasRemoving && defaultBox;

    if (isNewField && fields.length === 0) {
      append(DEFAULT_FIELDS_EXPERIENCE, { shouldFocus: false });
    }
  }, [isExperienceHidden, required, fields]);

  const handleNewCard = () => {
    append(DEFAULT_FIELDS_EXPERIENCE);
    const elementId = "professional-experience-".concat(fields.length);
    setTimeout(() => scrollToElementById(elementId, -150), 200);
  };

  const handleRemoveCard = (id) => {
    if (id === 0 && required && fields.length === 1) return;

    setHasRemoving(true);
    const { experiences } = getValues();
    const isDirty = Object.values(experiences[id]).some(
      (value) => value && value !== "off"
    );

    if (isDirty) {
      handleOpenModal("delete-experience", () => remove(id), "small");
    } else {
      remove(id);
    }
  };

  return (
    <Container>
      <Typography type="subtitle">Experiência profissional</Typography>

      {fields.map((field, index) => (
        <Cardboard
          key={field.id}
          disableClose={index === 0 && required && fields.length === 1}
          handleClose={() => handleRemoveCard(index)}
          elementId={"professional-experience-".concat(index)}
        >
          <Row>
            <Col>
              <Input
                required
                label="Cargo"
                placeholder="Nome do cargo"
                name={`experiences.${index}.occupation`}
              />
            </Col>
            <Col>
              <Input
                required
                label="Empresa"
                placeholder="Nome da empresa"
                name={`experiences.${index}.company`}
              />
            </Col>
          </Row>

          <ExperienceDateRange groupName="experiences" fieldId={index} />

          <Checkbox
            label="Este é meu cargo atual"
            style={{ marginBottom: "1rem" }}
            name={`experiences.${index}.current`}
            defaultValue={fields[index].current}
          />

          <Textarea
            rows={6}
            label="Descrição"
            disabledResize
            limitWarning
            maxLength={1000}
            name={`experiences.${index}.description`}
            placeholder="Descreva suas atividades nessa empresa"
          />
        </Cardboard>
      ))}

      <Button onClick={handleNewCard}>
        <Icon className="fas fa-plus-circle" color="#333" size="1rem" />
        Adicionar experiência
      </Button>
    </Container>
  );
};

ProfessionalExperience.propTypes = {
  required: PropTypes.bool,
  defaultBox: PropTypes.bool
};
