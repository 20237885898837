import { call, put, all, takeLatest, take } from "redux-saga/effects";
import { notification } from "antd";

import * as cookie from "@utils/cookie";
import {
  DEFAULT_HOST_CANDIDATE,
  DEFAULT_HOST_COMPANY
} from "@services/constants";

import * as api from "./repository";

const { hasProfileExpired } = cookie.getJSON();

function* sendAnswersProfilerSaga({ payload }) {
  yield put({
    type: "@profiler/BLOCK_BUTTONS"
  });

  yield put({
    type: "@utilities/LOADING",
    payload: { loading: true }
  });

  try {
    const { data: dataProfiler } = yield call(api.getProfiler);
    // verifica se o profiler já foi respondido
    if (!dataProfiler?.passaport || hasProfileExpired) {
      const { [window.name]: tab } = cookie.getJSON();

      yield call(api.sendAnswersProfiler, payload);

      if (tab?.applyingVacancy) {
        yield put({
          type: "@vacancy/APPLY_VACANCY_REQUEST",
          payload: { vagaId: tab?.vacancy?.id }
        });
      } else if (tab?.applyingTalentBase && tab?.company) {
        // checa se tentou aplicar ao banco de talentos
        yield put({
          type: "@company/APPLICANT_CANDIDATE_TALENT_BASE",
          payload: { company: tab?.company, showMessage: false }
        });
        yield take("@company/APPLY_TALENT_BASE_FINISH");
      } else if (
        !tab?.applyingVacancy &&
        !tab?.applyingTalentBase &&
        tab?.company?.slug
      ) {
        cookie.set(
          window.name,
          {
            ...tab,
            vacancy: null,
            statusApply: "success",
            applied: {}
          },
          { expires: 1 }
        );

        const COMPANY_HOST = DEFAULT_HOST_COMPANY(tab.company.slug);
        cookie.redirect(`${COMPANY_HOST}/profilerStatus`);
      } else {
        // tomar cuidado com o destino final
        const { destination } = cookie.getJSON();

        // limpa o campo de destino
        cookie.set("destination", "");

        const candidate_message = {
          type: "success",
          message: "Parabéns!",
          description: "Profiler respondido com sucesso."
        };

        cookie.set("candidate_message", candidate_message);
        cookie.redirect(destination || DEFAULT_HOST_CANDIDATE); // perfil do candidato por padrão
      }
    } else {
      const candidate_message = {
        type: "warn",
        message: "Atenção",
        description:
          "Não foi possível salvar o Profiler pois este usuário já respondeu o Profiler."
      };

      cookie.set("candidate_message", candidate_message);
      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
    }
    yield cookie.set("hasProfileExpired", false);
  } catch (err) {
    yield put({
      type: "@profiler/UNBLOCK_BUTTONS"
    });

    const description =
      err.response && err.response.data
        ? err.response.data.message
        : "Não foi possível salvar o Profiler no momento. Por favor, tente novamente mais tarde.";

    notification.error({
      message: "Atenção",
      description
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest("@profiler/SEND_ANSWERS_PROFILER", sendAnswersProfilerSaga)
]);
