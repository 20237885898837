import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import * as Styled from "./styled";
import "antd/dist/antd.css";

import { ReactComponent as Arrow } from "../../assets/svg/iconSelect.svg";

const Dropdown = ({
  text,
  leftArrow,
  isTooltip = false,
  description = "",
  leftPhoto = false,
  uppercase,
  options,
  children,
  upwardItem = false,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const [_options, _setOptions] = useState([]);
  const [getNode, setNode] = useState(Node.ELEMENT_NODE);

  useEffect(() => {
    _setOptions(options);
  }, [options]);

  const handleClick = () => {
    if (!show) document.addEventListener("click", handleOutsideClick, false);
    else document.removeEventListener("click", handleClick, false);
    setShow((prevState) => !prevState);
  };

  const handleOutsideClick = (event) =>
    !getNode.contains(event.target) && setShow(false);

  const renderDropdown = () => (
    <Styled.ContentButton
      ref={(node) => setNode(node)}
      {...rest}
      upward={upwardItem}
      id="dropdownHeader"
    >
      <Styled.ButtonOutline
        {...rest}
        uppercase={uppercase}
        onClick={() => handleClick()}
        show={show}
      >
        {!!leftPhoto && (
          <Styled.LeftPhoto {...rest}>{leftPhoto}</Styled.LeftPhoto>
        )}

        {text}
        {children}
        <Styled.IconArrow arrow={leftArrow}>
          <Arrow />
        </Styled.IconArrow>
      </Styled.ButtonOutline>
      <Styled.DropdownMenu show={show}>
        {_options.map((e, index) => (
          <Styled.DropdownMenuItem
            key={index.toString()}
            onClick={() => {
              e.onClick() || setShow(false);
            }}
          >
            {e.text}
          </Styled.DropdownMenuItem>
        ))}
      </Styled.DropdownMenu>
    </Styled.ContentButton>
  );

  return isTooltip ? (
    <Tooltip placement="top" title={description}>
      {renderDropdown()}
    </Tooltip>
  ) : (
    <>{renderDropdown()}</>
  );
};

export default Dropdown;
