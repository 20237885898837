import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as cookie from "@utils/cookie";
import JobsLogo from "@assets/svg/candidateJobsLogo.svg";
import JobsBannerDesktopDefault from "@assets/svg/banner_default_jobs_desktop.svg";
import JobsBannerMobileDefault from "@assets/svg/banner_default_jobs_mobile.svg";

import Header from "@components/header";
import Banner from "@components/banner";
import Vacancy from "@components/vacancy";
import Footer from "@components/footer";

import { useAuth } from "@utils/hooks/useAuth";
import { useCandidate } from "@utils/hooks/useCandidate";
import useFetch from "@utils/hooks/useFetch";
import { schema } from "@utils/formValidations/curriculumV2/index";

import * as utilitiesActions from "@store/modules/utilities/actions";
import * as vacancyActions from "@store/modules/vacancy/actions";
import * as authActions from "@store/modules/auth/actions";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_CANDIDATE
} from "@services/constants";
import * as Styled from "./styles";

const HiddenVacancy = () => {
  const vacancyId = window.location.pathname.replace("/vacancies/", "");

  const { token, user } = useAuth();
  const { profiler } = useCandidate();
  const [vacancy, setVacancy] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const dispatch = useDispatch();
  const { hasProfileExpired } = cookie.getJSON();

  const requiredFieldsData = useFetch(() =>
    vacancyId ? `/vacancy/get-required-field?vagaId=${vacancyId}` : null
  );
  const { loading, data: vacancyData } = useFetch(() =>
    vacancyId ? `/vacancy/${vacancyId}` : null
  );

  useEffect(() => {
    const data = vacancyData?.data || {};
    if (data?.visibility === "hidden") {
      const isSubscribed = user?.myApplications?.find(
        (item) => item.id === data.id
      );

      setSubscribed(!!isSubscribed?.id);
      !loading && setVacancy(data);
    }
  }, [loading, user.myApplications, vacancyData]);

  const applyVacancyHandle = useCallback(async () => {
    if (requiredFieldsData) {
      const { data: requiredFields } = requiredFieldsData?.data || {
        data: []
      };

      const vacancyApply = {
        id: vacancy.id,
        title: vacancy.title
      };
      const dataApply = {
        applyingVacancy: true,
        vacancy: vacancyApply
      };

      // adicionar a aba no apply vacancy
      if (token && user.id) {
        const schemaValidations = schema(requiredFields, vacancy);
        schemaValidations.isValid(user).then((valid) => {
          // guarda a referencia da vaga a ser aplicada na janela atual após a autenticação
          cookie.set(window.name, dataApply, { expires: 1 });

          if (valid) {
            if (profiler?.perfil && !hasProfileExpired) {
              dispatch(
                utilitiesActions.showModal({
                  show: true,
                  type: "applyVacancy",
                  options: {
                    position: "center",
                    size: "small",
                    hasBgShadow: true,
                    showClose: true,
                    styles: {
                      minWidth: "590px",
                      marginTop: "10%"
                    }
                  },
                  dataParams: {
                    onApplyVacancy: () => {
                      dispatch(
                        vacancyActions.applyVacancy({ vagaId: vacancy.id })
                      );
                    },
                    onRedirectToCurriculum: () =>
                      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`)
                  }
                })
              );
            } else if (profiler?.perfil && hasProfileExpired) {
              dispatch(
                utilitiesActions.showModal({
                  show: true,
                  type: "profileExpired",
                  options: {
                    position: "middleCenter",
                    size: "medium",
                    hasBgShadow: true,
                    bgShadow: "",
                    showClose: false,
                    closeOnOverlay: false
                  },
                  dataParams: {
                    redirect: true
                  }
                })
              );
            } else {
              // redireciona para o preenchimento do profiler
              cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/profiler`);
            }
          } else {
            // redireciona para o curriculo
            cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
          }
        });
      } else {
        // guarda a referencia da vaga a ser aplicada na janela atual após a autenticação
        cookie.set(window.name, dataApply, { expires: 1 });
        cookie.redirect(DEFAULT_HOST_LOGIN);
      }
    }
  }, [requiredFieldsData, vacancy, token, user, dispatch]);

  const authenticateHandle = useCallback((destination = "") => {
    cookie.set("destination", destination);
    cookie.redirect(DEFAULT_HOST_LOGIN);
  }, []);

  const goToPerfilCandidateHandle = useCallback(() => {
    const data = {
      company: null
    };

    cookie.set(window.name, data, { expires: 1 });
    cookie.redirect(DEFAULT_HOST_CANDIDATE);
  }, []);

  const goBackNotFoundHandle = useCallback(() => {
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/perfilCandidate#vacancy`);
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={vacancy.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={`Vaga disponível para ${vacancy.title}. Faça sua inscrição on-line para fazer parte do processo seletivo.`}
        />
        <meta property="og:image" content="/images/talent-recruiter.png" />
        <meta
          property="og:url"
          content={`http://solides.jobs/vacancies/${vacancy.id}`}
        />
        <meta property="og:site_name" content={`Vaga para ${vacancy.title}`} />

        <title>{vacancy.title}</title>
        <meta
          name="description"
          content={`Vaga disponível para ${vacancy.title}. Faça sua inscrição on-line para fazer parte do processo seletivo.`}
        />
      </Helmet>
      <Styled.BodyContainer>
        <Header
          logo={JobsLogo}
          urlLogo={DEFAULT_HOST_CANDIDATE}
          token={token}
          user={user}
          onAuthenticate={authenticateHandle}
          onPerfil={goToPerfilCandidateHandle}
          onLogout={() => dispatch(authActions.logout())}
        />

        <Banner
          urlDesktop={JobsBannerDesktopDefault}
          urlMobile={JobsBannerMobileDefault}
        />

        <Styled.Container>
          <Styled.DetailsVacancy>
            <Vacancy
              loading={loading}
              subscribed={subscribed}
              vacancy={vacancy}
              onApplyVacancy={applyVacancyHandle}
              onGoBackNotFound={goBackNotFoundHandle}
            />
          </Styled.DetailsVacancy>
        </Styled.Container>
        <Footer />
      </Styled.BodyContainer>
    </>
  );
};

export default withRouter(HiddenVacancy);
