import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { withRouter, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";

import { ReactComponent as Close } from "@assets/svg/closeModal.svg";

import Body from "@components/vacancy";
import { useWindowTab } from "@hooks/useWindowTab";
import { useAuth } from "@hooks/useAuth";
import { useCandidate } from "@hooks/useCandidate";
import useFetch from "@hooks/useFetch";
import { schema } from "@utils/formValidations/curriculumV2/index";
import * as cookie from "@utils/cookie";
import { parseURLEncodeToObject } from "@utils/urlParse";

import * as utilitiesActions from "@store/modules/utilities/actions";
import * as vacancyActions from "@store/modules/vacancy/actions";

import {
  DEFAULT_HOST_LOGIN,
  DEFAULT_HOST_CANDIDATE
} from "@services/constants";
import * as Styled from "./styles";

const Vacancy = ({
  company,
  callbackDefaultHost,
  callbackCloseDescription
}) => {
  const { token, user } = useAuth();
  const { profiler } = useCandidate();
  const { id } = useParams();
  const [vacancy, setVacancy] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const { hasProfileExpired } = cookie.getJSON();
  const { name } = useWindowTab();

  // forcing update of window.name as defined in hook
  window.name = name;

  const { data: vacancyData, loading } = useFetch(() =>
    id ? `/vacancy/${id}` : null
  );
  const requiredFieldsData = useFetch(() =>
    id ? `/vacancy/get-required-field?vagaId=${id}` : null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let data = vacancyData?.data || {};
    if (data.visibility === "hidden") data = {};

    const isSubscribed = user?.myApplications?.find(
      (item) => item.id === data.id
    );

    setSubscribed(!!isSubscribed?.id);
    setVacancy(data);
  }, [user.myApplications, vacancyData]);

  const applyVacancyHandle = useCallback(async () => {
    if (requiredFieldsData) {
      const query = parseURLEncodeToObject(window.location.search);
      const { data: requiredFields } = requiredFieldsData?.data || {
        data: []
      };

      const dataApply = {
        applyingVacancy: true,
        vacancy: {
          id: vacancy.id,
          title: vacancy.title,
          origin: query?.origem || ""
        },
        company: {
          referenceId: company.referenceId,
          slug: company.slug,
          name: company.name
        }
      };
      // guarda a referencia da vaga a ser aplicada na janela atual após a autenticação
      cookie.set(window.name, dataApply, { expires: 1 });

      // adicionar a aba no apply vacancy
      if (token && user.id) {
        const schemaValidations = schema(requiredFields, vacancy);
        schemaValidations.isValid(user).then((valid) => {
          if (valid) {
            if (profiler?.perfil && !hasProfileExpired) {
              dispatch(
                utilitiesActions.showModal({
                  show: true,
                  type: "applyVacancy",
                  options: {
                    position: "center",
                    size: "small",
                    hasBgShadow: true,
                    showClose: true,
                    styles: {
                      minWidth: "590px",
                      marginTop: "10%"
                    }
                  },
                  dataParams: {
                    onApplyVacancy: () => {
                      const dataAction = {
                        vagaId: vacancy.id,
                        origin: dataApply.vacancy.origin
                      };
                      dispatch(vacancyActions.applyVacancy(dataAction));
                    },
                    onRedirectToCurriculum: () =>
                      cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`)
                  }
                })
              );
            } else if (profiler?.perfil && hasProfileExpired) {
              dispatch(
                utilitiesActions.showModal({
                  show: true,
                  type: "profileExpired",
                  options: {
                    position: "middleCenter",
                    size: "medium",
                    hasBgShadow: true,
                    bgShadow: "",
                    showClose: false,
                    closeOnOverlay: false
                  },
                  dataParams: {
                    redirect: true
                  }
                })
              );
            } else {
              // redireciona para o preenchimento do profiler
              cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/profiler`);
            }
          } else {
            // redireciona para o curriculo
            cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/curriculum`);
          }
        });
      } else {
        cookie.redirect(DEFAULT_HOST_LOGIN);
      }
    }
  }, [
    requiredFieldsData,
    vacancy,
    company,
    token,
    user,
    dispatch,
    window.location
  ]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={vacancy?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            !!company.name && vacancy?.visibility !== "hidden"
              ? `Vaga de ${vacancy?.title} para trabalhar na empresa ${company.name}. Faça sua inscrição on-line para fazer parte do processo seletivo.`
              : `Vaga disponível para ${vacancy?.title}. Faça sua inscrição on-line para fazer parte do processo seletivo.`
          }
        />
        <meta
          property="og:image"
          content={
            !!company.logo && vacancy?.visibility !== "hidden"
              ? company.logo
              : `/images/talent-recruiter.png`
          }
        />
        <meta
          property="og:url"
          content={
            !!company.slug && vacancy?.visibility !== "hidden"
              ? `http://${company.slug}.solides.jobs/vacancies/${vacancy?.id}`
              : `http://solides.jobs/vacancies/${vacancy?.id}`
          }
        />
        <meta property="og:site_name" content={`Vaga para ${vacancy?.title}`} />

        <title>{vacancy?.title}</title>
        <meta
          name="description"
          content={
            !!company.name && vacancy?.visibility !== "hidden"
              ? `Vaga de ${vacancy?.title} para trabalhar na empresa ${company.name}. Faça sua inscrição on-line para fazer parte do processo seletivo.`
              : `Vaga disponível para ${vacancy?.title}. Faça sua inscrição on-line para fazer parte do processo seletivo.`
          }
        />
      </Helmet>
      <Styled.Container>
        <Styled.Close>
          <Tooltip placement="top" title="Fechar">
            <Close onClick={callbackCloseDescription} />
          </Tooltip>
        </Styled.Close>
        <Body
          loading={loading}
          subscribed={subscribed}
          vacancy={vacancy}
          color={
            !!company && !!company.colorCompany
              ? company.colorCompany.toString()
              : "#333333"
          }
          onApplyVacancy={applyVacancyHandle}
          onGoBackNotFound={callbackDefaultHost}
        />
      </Styled.Container>
    </>
  );
};

export default withRouter(Vacancy);
