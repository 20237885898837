import React, { useEffect, useState } from "react";
import * as Styled from "./styles";

import { ReactComponent as LeftArrow } from "../../assets/svg/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/svg/right-arrow.svg";

const Paginate = ({
  totalPages = 4,
  currentPage = 1,
  pageNeighbours = 1,
  color = "#333333",
  size = 35,
  fallbackPageHandle
}) => {
  const [range, setRange] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setRange(numbersIndicators(page));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const numbersIndicators = (newPage) => {
    const indicators = [];
    let lastItem = "";

    if (totalPages > 1) {
      for (let numPage = 1; numPage <= totalPages; numPage++) {
        if (numPage === 1 || numPage === totalPages || numPage === newPage) {
          lastItem = numPage;
          indicators.push(lastItem);
        } else {
          const existsDotsLeft =
            numPage < newPage && numPage < newPage - pageNeighbours;
          const existsDotsRight =
            numPage > newPage && numPage > newPage + pageNeighbours;

          if ((existsDotsLeft || existsDotsRight) && lastItem !== "...") {
            lastItem = "...";
            indicators.push(lastItem);
          }

          if (!existsDotsLeft && !existsDotsRight) {
            lastItem = numPage;
            indicators.push(lastItem);
          }
        }
      }
    }

    return indicators;
  };

  const changeHandler = (newPage) => {
    newPage = newPage >= totalPages ? totalPages : newPage <= 1 ? 1 : newPage;

    if (newPage !== page) {
      setPage(newPage);
      setRange(numbersIndicators(newPage));
      fallbackPageHandle(newPage);
    }
  };

  return (
    <Styled.PaginateMain>
      <Styled.IndicatorsContainer>
        {!!range.length && (
          <>
            <Styled.Indicators
              disabled={page === 1}
              onClick={() => changeHandler(page - 1)}
              color={color}
              size={size}
            >
              <LeftArrow />
            </Styled.Indicators>

            {range.map((item, index) => (
              <Styled.Indicators
                key={index}
                active={item === page}
                disabled={item === "..." || item === page}
                onClick={() => changeHandler(item)}
                color={color}
                size={size}
              >
                {item}
              </Styled.Indicators>
            ))}

            <Styled.Indicators
              disabled={page === totalPages}
              onClick={() => changeHandler(page + 1)}
              color={color}
              size={size}
            >
              <RightArrow />
            </Styled.Indicators>
          </>
        )}
      </Styled.IndicatorsContainer>
    </Styled.PaginateMain>
  );
};

export default Paginate;
