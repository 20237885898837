import React from "react";

import * as Styled from "../styles";
import { SkeletonProfiler } from "../components/skeleton";

const Profiler = ({ profiler, loading }) => (
  <Styled.ContainerProfiler>
    <Styled.ContainerField>
      {loading ? (
        <SkeletonProfiler />
      ) : (
        <>
          {!!profiler && (
            <>
              <Styled.FieldTitle>Sobre você</Styled.FieldTitle>
              <Styled.FieldDescription>{profiler.text}</Styled.FieldDescription>
            </>
          )}
        </>
      )}
    </Styled.ContainerField>
  </Styled.ContainerProfiler>
);

export default Profiler;
