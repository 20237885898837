import styled, { keyframes } from "styled-components";

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1020;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`;

export const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const KeyframesDot = keyframes`  
    15% { transform: translateX(0); }
    45% { transform: translateX(230px); }
    65% { transform: translateX(230px); }
    95% { transform: translateX(0); }
`;

export const LoaderDot = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 1px solid white;

  animation: ${KeyframesDot} 3s ease-in-out 0s infinite;

  &:first-child {
    background-color: #d74149;
    animation-delay: 0.5s;
  }
  &:nth-child(2) {
    background-color: #20a135;
    animation-delay: 0.4s;
  }
  &:nth-child(3) {
    background-color: #ffba00;
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    background-color: #00c9db;
    animation-delay: 0.2s;
  }
  &:nth-child(5) {
    background-color: #2f0a77;
    animation-delay: 0.1s;
  }
`;

const KeyframesText = (text) => keyframes`  
    0% { content: "${text}"; }
    25% { content: "${text}.";  }
    50% { content: "${text}.."; }
    75% { content: "${text}..."; }
`;

export const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  color: #f1f1f1;

  font-family: "Roboto";
  font-size: 16px;

  &:after {
    content: "${({ text }) => text || "Carregando"}";
    font-weight: bold;
    animation: ${({ text }) => KeyframesText(text || "Carregando")} 3s infinite;
  }
`;
