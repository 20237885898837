import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 10;
  max-width: 1200px;
  padding-bottom: 50px;
  margin: 0 auto;
  margin-top: -340px;

  @media screen and (max-width: 1260px) {
    padding: 0 30px 100px 30px;
  }

  @media screen and (max-width: 992px) {
    margin-top: -420px;

    ${({ two }) => two && `padding-bottom: 170px;`}
  }

  @media screen and (max-width: 450px) {
    margin-top: -220px;
    padding: 0 15px 100px 15px;

    ${({ two }) => two && `padding-bottom: 170px;`}
  }
`;

export const Main = styled.main`
  width: 100%;
  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  background: ${(props) => props.background || "#f4f0eb"};
  min-height: 100vh;
  position: relative;

  @media screen and (max-width: 640px) {
    padding-top: 68px;
  }
`;

export const HeaderMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  ${({ hasBackground }) => hasBackground && "background: #F9F7F7"};
`;

//  header
export const HeaderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  height: 100px;
  margin: 0 auto;
  max-width: 1100px;
`;

export const LogoContainer = styled.div`
  width: 200px;
  max-width: 200px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Logo = styled.div`
  height: 80px;
  width: 100%;
  margin-left: 45px;
  max-width: 160px;
  background-image: url("${(props) => props.urlImage}");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: ${({ cursor }) => cursor || "default"};
  background-position: center;
  @media (max-width: 1024px) {
    background-position: initial;
    max-width: 100px;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 596px;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ marginFlex }) => marginFlex}px;
  margin-top: -14px;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth}px;
  @media (max-width: 992px) {
    margin-top: 15px;
    margin-left: 0px;
  }
`;

export const ButtonGroupContainer = styled.div`
  width: auto;
  margin-left: auto;
`;

export const SpaceBoxContainer = styled.div`
  width: 100%;
  max-width: 475px;
  @media (max-width: 992px) {
    margin: 18px 27px 0px 0px;
  }

  :last-child {
    margin-left: 15px;
  }
`;

export const TextButtonContainer = styled.span`
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 576px) {
    flex-wrap: nowrap;
    display: initial;
    justify-content: center;
    align-items: center;
  }
`;

export const TextButton = styled.span`
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "2px")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

// body
export const BodyMain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const BodyMarginContainer = styled.div`
  width: 100%;
`;

export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  position: relative;
  flex-direction: row;
  @media (max-width: 992px) {
    flex-direction: column;
    height: auto;
  }
`;

export const BodyContent = styled.div`
  width: 100%;
  display: flex;
  flex: 2;
  align-items: normal;
  flex-direction: column;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 100%;
  margin-bottom: auto;
`;

export const BreadcrumbContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 1.125rem 1.4375rem;
`;

export const BoxContainer = styled.div`
  padding: 50px 30px 40px 30px;

  @media (max-width: 575px) {
    padding: 50px 15px 40px 15px;
  }

  @media (min-width: 992px) {
    padding: 84px 64px 52px 70px;
  }
`;

export const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
`;

export const BoxHeaderFilter = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #333333;
`;

export const FilterContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 15px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    margin-left: 0px !important;
    margin-top: 20px;

    ${SpaceBoxContainer} {
      max-width: 100%;
      margin-left: 0px !important;
      margin-right: 0px;
    }
  }
`;

export const BoxBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Aside = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  position: ${({ position }) => position || "sticky"};
  height: ${({ position }) => (position ? "auto" : "100%")};
  top: 0;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media (min-width: 992px) {
    z-index: 1;
    box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0px 10px 10px 0px;
  }

  @media screen and (max-width: 992px) {
    ${({ hideMobile }) => hideMobile && "display: none;"}
  }
`;

export const BodyImageBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  background-color: ${({ color }) => color || "#333333"};
  /*background-image: url('data:image/png;base64,${(props) =>
    props.urlImage}');*/
  background-image: url("${({ urlImage }) => urlImage || "none"}");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`;

export const ImageBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  background-color: ${({ color }) => color || "#333333"};
  background-image: url("${({ urlImage }) => urlImage || "none"}");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }

  :after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

// scrollspy
export const Section = styled.div`
  width: 100%;
  margin-bottom: 50px;
  ${({ position }) =>
    position === "center" &&
    `
        display: flex;
        flex-direction: column;
        align-items: center;
    `}
`;

export const SectionError = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  color: #dc3545;
`;

export const ScrollSpyContainer = styled.div`
  width: 100%;
  margin-top: 18px;
  margin-left: 25px;
  display: none;
  @media (min-width: 200px) {
    display: block;
  }
`;

export const ScrollSpyElement = styled.a`
  width: 100%;
  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  color: ${({ error }) => (error ? "#dc3545" : "#333333")};

  :hover {
    opacity: 0.75;
    color: #333333;
  }

  &.active {
    font-weight: bold;
  }
`;

// wizard
export const ContainerWizard = styled.div`
  width: 100%;
  max-width: 498px;
  margin: 0 auto;
  margin-bottom: 58px;
  position: relative;
`;
export const ProgressBar = styled.div`
  width: 100%;
  height: 9px;
  background: #f2f2f2;
  border-radius: 10px;
  position: absolute;
  bottom: 16px;

  ::before {
    content: " ";
    background: #c4c4c4;
    z-index: 2;
    display: block;
    height: 100%;
    border-radius: 10px;
    width: ${({ progress }) => (progress ? `${progress}%` : "0%")};
  }
`;
export const WizardList = styled.div`
  width: 100%;
  display: flex;
`;
export const WizardListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const WizardListItemText = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: #333333;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  max-width: 154px;

  @media (min-width: 992px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 164px;
  }
`;
export const WizardListItemDot = styled.div`
  width: 42px;
  height: 42px;
  background: #f2f2f2;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100%;
  z-index: 1;
  ::before {
    content: " ";
    border: 10px solid #c4c4c4;
    z-index: 2;
    display: ${({ active, completed }) =>
      active || completed ? "block" : "none"};
    border-radius: 100%;
    height: 100%;
    width: 100%;

    ${({ completed }) =>
      completed &&
      `
            background: #c4c4c4;
        `};
  }
`;

export const Desktop = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

export const Mobile = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const ProgressBarMobile = styled.div`
  position: absolute;
  bottom: ${({ two }) => (two ? "115px" : "53px")};
  left: -30px;
  width: 100%;

  @media screen and (max-width: 450px) {
    width: calc(100% + 30px);
  }
`;
