import React from "react";
import { useDispatch } from "react-redux";

import { Button } from "@components";
import { DEFAULT_HOST_LINKEDIN_CALLBACK } from "@services/constants";
import * as authActions from "@store/modules/auth/actions";
import * as Styled from "./styles";

const URL_AUTH_SOCIAL = {
  linkedin: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78pp350a2v5dvv&redirect_uri=${DEFAULT_HOST_LINKEDIN_CALLBACK}&state=foobar&scope=r_emailaddress`
};

const SocialNetworks = ({ socialType, TextButton }) => {
  const dispatch = useDispatch();

  return socialType === "facebook" ? (
    <Styled.SocialButton
      onClick={() => dispatch(authActions.getFacebookLink())}
    >
      <Button
        type={socialType}
        boxShadow
        leftIcon
        medium
        isUppercase={false}
        disabled
      >
        {TextButton || (
          <>
            Entrar com &nbsp;
            <b>{socialType}</b>
          </>
        )}
      </Button>
    </Styled.SocialButton>
  ) : (
    <Styled.SocialButton href={URL_AUTH_SOCIAL[socialType]}>
      <Button
        type={socialType}
        boxShadow
        leftIcon
        medium
        isUppercase={false}
        disabled
      >
        {TextButton || (
          <>
            Entrar com &nbsp;
            <b>{socialType}</b>
          </>
        )}
      </Button>
    </Styled.SocialButton>
  );
};

export default React.memo(SocialNetworks);
