import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { LinkRegister } from "@components/linkAuth";

import { Label, Input, MessageError } from "@components/form";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Typography, Button } from "@components/";

import * as authActions from "@store/modules/auth/actions";

import * as Styled from "./styles";

import schema from "./schema";

function ForgotPassword({ callbackSignUp }) {
  const dispatch = useDispatch();
  const [displayForgotFormView, setDisplayForgotFormView] = useState(true);
  const [recoveryByEmail, setRecoveryByEmail] = useState(true);

  useEffect(() => {
    if (window.location.hash !== "#CPF") {
      setRecoveryByEmail(true);
    }
  }, [window.location.hash]);

  const formMethods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      type: "email",
      value: "",
      recaptcha: false
    }
  });

  const onSubmit = async (values) => {
    const data = await dispatch(
      authActions.forgotPassword({ [values.type]: values.value })
    );
    setDisplayForgotFormView(data.displayForgotFormView);
  };

  const onChange = (value) => {
    formMethods.setValue("recaptcha", !!value);
    formMethods.trigger("recaptcha");
  };

  const changeTypeRecovery = () => {
    formMethods.setValue("type", "cpf");
    formMethods.setValue("value", "");
    setRecoveryByEmail(false);
  };

  return (
    <FormProvider {...formMethods}>
      {displayForgotFormView && (
        <Styled.ResponseRequest>
          <Typography type="title">Esqueci minha senha</Typography>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Styled.Row>
              {recoveryByEmail ? (
                <>
                  <Label required>E-mail</Label>
                  <Styled.Description>
                    Informe o endereço de e-mail cadastrado
                  </Styled.Description>
                  <Input
                    placeholder="nome@email.com"
                    name="value"
                    data-testid="email"
                    required
                  />
                </>
              ) : (
                <>
                  <Typography>Informe seu CPF:</Typography>
                  <Input
                    id="CPF"
                    type="number"
                    label="CPF"
                    placeholder="___.___.___-__"
                    format="###.###.###-##"
                    name="value"
                    data-testid="cpf"
                    required
                  />
                </>
              )}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITEKEY_GOOGLE}
                onChange={onChange}
              />
              {formMethods.formState.errors.recaptcha && (
                <MessageError
                  message={formMethods.formState.errors.recaptcha.message}
                />
              )}
            </Styled.Row>
            <Button
              buttonType="submit"
              backgroundColor="#583F99"
              textColor="#fff"
              fontWeight="bold"
              boxShadowColor="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
              medium
            >
              ENVIAR
            </Button>
          </form>
          {!!recoveryByEmail && (
            <Styled.Reset>
              Não lembra os dados cadastrados?
              <br />
              <Styled.Link href="#CPF" onClick={changeTypeRecovery}>
                Redefinir senha com o CPF
              </Styled.Link>
            </Styled.Reset>
          )}
          <LinkRegister />
        </Styled.ResponseRequest>
      )}
      {!displayForgotFormView && (
        <Styled.ResponseRequest>
          <Typography type="title">
            Link de redefinição de senha enviado!
          </Typography>
          <Typography className="top">
            Caso exista um cadastro no Jobs para o &nbsp;
            {formMethods.control._formValues.type === "email" ? (
              <>
                email <b>{formMethods.control._formValues.value}</b>, enviaremos
                um link para ele.
              </>
            ) : (
              <>
                CPF informado, enviaremos um link para o e-mail cadastrado nele.
              </>
            )}
            <br />
            <br />
            Verifique sua caixa de e-mail e o spam.
          </Typography>
          <Styled.Divider />
          <Typography>
            Se você não tem mais acesso ao e-mail cadastrado, entre em&nbsp;
            contato <strong> com o suporte pelo chat.</strong>
          </Typography>
        </Styled.ResponseRequest>
      )}
    </FormProvider>
  );
}

export default ForgotPassword;
