import styled from "styled-components";

export const Container = styled.div`
  max-width: 1100px;
  padding-bottom: 50px;

  /* less than 992px */
  margin: 0 auto;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding-top: 42px;
  }
`;

export const ContainerProfiler = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 100px;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 20px 0px;

  /* less than 992px */
  flex-direction: column;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const HeaderItem = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  display: none;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    display: block;
  }
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-size: 16px;
  line-height: 125%;
  color: #333333;
`;

export const HeaderPrimary = styled.h4`
  width: 100%;
  font-weight: normal;
`;

export const HeaderSecondary = styled.h4`
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  min-height: 100px;
  padding: 60px;

  /* less than 992px */
  padding: 50px 20px 30px;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 60px;
  }
`;

export const ContainerFilter = styled.div`
  padding: 30px;
`;

export const FilterItem = styled.div`
  margin-bottom: 15px;

  &#tip {
    /* less than 992px */
    display: none;

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      display: block;
    }
  }
`;

export const ContainerButton = styled.div`
  padding-top: 20px;

  @media screen and (max-width: 992px) {
    .curriculum {
      ${({ two }) =>
        two && `bottom: 60px !important; border-bottom: 2px white solid;`}
    }
  }
`;

export const BodyContent = styled.div`
  width: 100%;
  display: flex;
  flex: 2;
  align-items: normal;
  flex-direction: column;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 2;
  height: 100%;

  /* less than 992px */
  margin-bottom: 100px;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    margin-bottom: auto;
  }
`;

// aside
export const Aside = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  /* height: 10%; */

  /* less than 992px */
  background: transparent;
  box-shadow: none;
  border-radius: 10px;

  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 2;
  margin: 40px 0 0 0;
  height: auto;

  @media (max-width: 991px) {
    display: none;
  }
  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    top: 0;
    bottom: auto;
    margin: 40px 0;
    height: ${({ position }) => (position ? "auto" : "100%")};
    position: ${({ position }) => position || "sticky"};
    z-index: 1;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0px 10px 10px 0px;
  }
`;

export const ContainerText = styled.div`
  width: 100%;
  font-family: "Roboto", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: ${({ txtAlign }) => txtAlign || "initial"};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "25px"};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "0")};
  color: #333333;
  margin-left: auto;
  margin-right: auto;
  line-height: 10px;
`;

export const TextPrimary = styled.p`
  font-weight: ${({ weight }) => weight || "normal"};
  font-size: 16px;
  line-height: 20px;
  margin: 0;
`;
