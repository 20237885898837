import React from "react";
import * as Styled from "../styles";

import useClickPreventionOnDoubleClick from "../../../utils/hooks/useClickPreventionOnDoubleClick";

const Link = ({
  onClick = () => {},
  onDoubleClick = () => {},
  text,
  isUppercase = true,
  children,
  id,
  ...rest
}) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick
  );

  return (
    <Styled.ContentButton {...rest}>
      <Styled.ButtonLink
        {...rest}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        isUppercase={isUppercase}
        id={id}
      >
        {text}
        {children}
      </Styled.ButtonLink>
    </Styled.ContentButton>
  );
};

export default Link;
