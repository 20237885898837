import { createPromiseAction } from "@adobe/redux-saga-promise";

export const applyVacancy = (vacancy) => ({
  type: "@vacancy/APPLY_VACANCY_REQUEST",
  payload: vacancy
});

export const getRequiredFields = createPromiseAction(
  "@vacancy/GET_REQUIRED_FIELDS_REQUEST"
);

export const getVacancy = createPromiseAction("@vacancy/GET_VACANCY_REQUEST");
