import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 7px;

  /* less than 992px */
  flex-direction: column;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const ContainerCircle = styled.div`
  max-width: 50px;
  margin-left: 15px;
`;

export const Circle = styled.div`
  ${({ disabled }) =>
    disabled ? "background: #FFF9F3; opacity: 0.5;" : "background: #ffffff;"}

  border: 1px solid #efedf3;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333333;
  width: 50px;
  height: 50px;
`;

export const ContainerColumn = styled.div`
  flex: 3;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Roboto";
  font-style: normal;
  color: #333333;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  text-align: left;
  padding: 0 15px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Description = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

export const ContainerProgress = styled.div`
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  min-width: 100px;
`;

export const TitleProgressStep = styled.span`
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.1px;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  text-transform: uppercase;
`;

export const ContainerProgressStep = styled.div`
  background: transparent;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  height: 10px;
`;

export const CurrentProgressStep = styled.div`
  width: ${({ percentage }) => percentage && percentage}%;
  height: 100%;
  border-radius: 100px;
  background: #333333;
  box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0.25);
`;
