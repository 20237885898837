import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";

import * as Styled from "./styles";

import SanitizeHTML from "../../../../components/sanitize";
import Skills from "../../../../components/skills";
import { ReactComponent as Close } from "../../../../assets/svg/closeModal.svg";

const Vacancy = ({ vacancy, fallBackCloseDescription }) => {
  const [listDescription, setListDescription] = useState({});

  const getData = (key, title) => {
    const options = [];

    if (key === "salaryStart") {
      let salary = "";
      if (vacancy.salaryStart.length > 0 && vacancy.salaryEnd.length > 0) {
        salary = `${vacancy.salaryStart} - ${vacancy.salaryEnd}`;
      } else if (vacancy.salaryEnd.length > 0) {
        salary = vacancy.salaryEnd;
      } else if (vacancy.salaryStart.length > 0) {
        salary = vacancy.salaryStart;
      }

      options.push({
        text: salary
      });
    } else if (key === "languages") {
      const levelLangText = (level) => {
        switch (level) {
          case 1:
            return "Iniciante";
          case 2:
            return "Básico";
          case 3:
            return "Intermediário";
          case 4:
            return "Avançado";
          case 5:
            return "Fluente";
          case 6:
            return "Domínio pleno";
          default:
            return "Iniciante";
        }
      };
      const levelLangPercent = (level) => {
        switch (level) {
          case 1:
            return 0;
          case 2:
            return 20;
          case 3:
            return 40;
          case 4:
            return 60;
          case 5:
            return 80;
          case 6:
            return 100;
          default:
            return 0;
        }
      };

      vacancy[key].map((item) =>
        options.push({
          text: item.name,
          level: levelLangText(item.level),
          percent: levelLangPercent(item.level)
        })
      );
    } else if (key === "skills") {
      const levelSkillText = (level) => {
        switch (level) {
          case 1:
            return "Básico";
          case 2:
            return "Intermediário";
          case 3:
            return "Avançado";
          default:
            return "Básico";
        }
      };
      const levelSkillPercent = (level) => {
        switch (level) {
          case 1:
            return 0;
          case 2:
            return 50;
          case 3:
            return 100;
          default:
            return 0;
        }
      };

      vacancy[key].map((item) =>
        options.push({
          text: item.name,
          level: levelSkillText(item.level),
          percent: levelSkillPercent(item.level)
        })
      );
    } else {
      vacancy[key].map((item) =>
        options.push({
          text: item.name,
          level: item.level,
          percent: item.percent
        })
      );
    }

    const data = {
      title,
      data: options
    };

    return data;
  };

  useEffect(() => {
    const proposal = [];
    const requirements = [];

    !!vacancy.salaryStart &&
      vacancy.salaryStart.length > 0 &&
      proposal.push(getData("salaryStart", "Salário"));
    !!vacancy.benefits && proposal.push(getData("benefits", "Benefícios"));

    !!vacancy.languages && requirements.push(getData("languages", "Idiomas"));
    !!vacancy.skills &&
      requirements.push(getData("skills", "Habilidades Específicas"));

    setListDescription({ proposal, requirements });

    // eslint-disable-next-line
  }, [vacancy]);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Close>
          <Tooltip placement="top" title="Fechar">
            <Close onClick={fallBackCloseDescription} />
          </Tooltip>
        </Styled.Close>
        <Styled.Title>{vacancy.title}</Styled.Title>
        <Styled.SubTitle>
          {!!vacancy.department && vacancy.department}
          {!!vacancy.department && !!vacancy.city && <> - </>}
          {!!vacancy.city && (
            <Styled.SubTitleSpan> {vacancy.city.name} </Styled.SubTitleSpan>
          )}
          {!!vacancy.city && !!vacancy.state && <> / </>}
          {!!vacancy.state && vacancy.state.acronym}
        </Styled.SubTitle>
      </Styled.Header>
      <Styled.Body>
        <Styled.Description>
          {!!vacancy.description && <SanitizeHTML html={vacancy.description} />}
          {!!vacancy.pcdDescription && (
            <SanitizeHTML html={vacancy.pcdDescription} />
          )}
        </Styled.Description>
        <Styled.DetailsContainer>
          {!!listDescription.proposal && !!listDescription.proposal.length && (
            <Styled.DetailsBox>
              <Styled.DetailsBoxHeader>
                <Styled.DetailsBoxTitle>Proposta</Styled.DetailsBoxTitle>
              </Styled.DetailsBoxHeader>
              <Styled.DetailsBoxBody>
                <Skills list={listDescription.proposal} />
              </Styled.DetailsBoxBody>
            </Styled.DetailsBox>
          )}

          {!!listDescription.requirements &&
            !!listDescription.requirements.length && (
              <Styled.DetailsBox>
                <Styled.DetailsBoxHeader>
                  <Styled.DetailsBoxTitle>Requisitos</Styled.DetailsBoxTitle>
                </Styled.DetailsBoxHeader>
                <Styled.DetailsBoxBody>
                  <Skills list={listDescription.requirements} />
                </Styled.DetailsBoxBody>
              </Styled.DetailsBox>
            )}
        </Styled.DetailsContainer>
      </Styled.Body>
    </Styled.Container>
  );
};

export default Vacancy;
