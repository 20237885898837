import styled, { css } from "styled-components";
import * as breakpoints from "@styles/breakpoints";

export const isMenuItemSelected = ({ $isSelected }) =>
  $isSelected &&
  css`
    font-weight: bold;
  `;

export const MenuMobileContainer = styled.div`
  background: #333;
  height: 3.4375rem;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  scroll-behavior: smooth;
  z-index: 100;
  font-family: "Roboto Condensed", "Roboto", Arial, sans-serif;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media (min-width: 1025px) {
    display: none;
  }
  @media (device-width: ${breakpoints.tablet}) {
    top: 90px;
    padding-left: calc(0.5625rem + 5.375rem);
  }
  @media (device-width: 768px) {
    top: 71px;
  }
`;

export const MenuDesktopContainer = styled.div`
  min-height: calc(100vh - 10px);
  float: right;
  width: 100%;
  max-width: 340px;
  align-items: flex-start;

  @media (max-device-width: ${breakpoints.laptop}) {
    display: none;
  }

  @media (max-width: ${breakpoints.laptop}) {
    display: none;
  }

  @media screen and (min-width: 1025px) and (max-width: 1181px) {
    max-width: 280px !important;
  }
  right: 0;
`;

export const ContainerSticky = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  height: auto;
`;

export const MenuDesktopBox = styled.div`
  background: #fbfbfb;
  box-shadow: -2px 0px 5px rgba(51, 51, 51, 0.3);
  height: 100%;
  width: 100%;
  max-width: 340px;
  font-family: "Roboto Condensed", "Roboto", Arial, sans-serif;
  right: 0;

  @media (max-device-width: ${breakpoints.tablet}) {
    display: none;
  }

  @media screen and (max-device-width: 1023px) and (min-device-width: 769px) {
    display: none;
  }

  @media screen and (min-width: 1025px) and (max-width: 1181px) {
    max-width: 280px !important;
  }
`;

export const MenuMobileItem = styled.button`
  height: 100%;
  padding: 0 0.5625rem;
  background: transparent;
  border: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  outline: none;

  ${isMenuItemSelected}

  &:first-of-type {
    padding-left: calc(0.5625rem + 0.375rem);
  }

  &:last-of-type {
    padding-right: calc(0.5625rem + 0.375rem);
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

export const MenuDesktopItem = styled.button`
  padding: 0 2.5rem 0 3rem;
  background: transparent;
  border: 0;
  white-space: nowrap;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Roboto Condensed", Arial, sans-serif;
  outline: none;

  ${isMenuItemSelected}

  &:last-of-type {
    padding-right: calc(0.5625rem + 0.375rem);
    ${({ $isSelected }) =>
      !$isSelected &&
      `
      margin-left: -11px;
  `}
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

export const MenuDesktopTitleStep = styled.div`
  width: 100%;
  background-color: #333333;
  padding: 0 0.5625rem 0 2rem;
  margin-bottom: 30px;
  color: #ffffff;
  height: 50px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  span {
    font-family: "Roboto Condensed", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding: 0.85rem 0;
  }
`;

export const MenuMobileStick = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 0;
  background: #fff;
  transition: all 0.35s ease;
`;

export const MenuDesktopStep = styled.div`
  float: center;
`;

export const MenuDesktopStepRounder = styled.div`
  position: relative;
  float: center;
  width: 100px;
  height: 50px;

  .connect {
    position: absolute;
    margin-top: -10px;
    top: 6px;
    left: 35px;
    width: 3px;
    height: 30px;
    display: ${({ actived }) => (actived ? "block" : "none")};
    background-color: #333333;
    transform: rotate(180deg);
  }
`;

export const ContainerIcon = styled.span`
  margin-left: 12px;
`;
