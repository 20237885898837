import React from "react";

import * as Styled from "./styles";

const Group = ({
  logo = null,
  title = "",
  subTitle = "",
  details = [
    {
      topic: "",
      subTopic: "",
      description: ""
    },
    {
      topic: "",
      subTopic: "",
      description: ""
    }
  ]
}) => {
  const renderTopic = details.map((item) => (
    <Styled.ContainerDescription key={`group_item_${Math.random(1000)}`}>
      <Styled.Topic>{item.topic}</Styled.Topic>
      <Styled.SubTopic>{item.subTopic}</Styled.SubTopic>
      <Styled.Description>{item.description}</Styled.Description>
    </Styled.ContainerDescription>
  ));

  return (
    <Styled.ContainerGroup>
      {!!logo && <Styled.ContainerLogo>{logo}</Styled.ContainerLogo>}
      <Styled.ContainerColumn>
        <Styled.ContainerDescription>
          <Styled.Title>{title}</Styled.Title>
          <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        </Styled.ContainerDescription>
        {renderTopic}
      </Styled.ContainerColumn>
    </Styled.ContainerGroup>
  );
};

export default Group;
