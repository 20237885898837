import styled from "styled-components";

export const Container = styled.div`
  padding: 25px 0px 16px 0px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;

  a {
    font-size: 16px;
    padding-left: 4px;
  }
`;

export const Link = styled.a`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.purple};

  :hover {
    color: inherit;
    text-decoration: underline;
  }
`;
