import * as yup from "yup";

export const MAX_LENGTH_TEXTAREA = 2000;

const isEmptyFields = (fields, type) => {
  const commonFields =
    !fields.startYear ||
    !fields.startMonth ||
    (fields.current === "off" && (!fields.endYear || !fields.endMonth));

  if (type === "courses") {
    return !fields.level || !fields.college || commonFields;
  }
  return !fields.occupation || !fields.company || commonFields;
};

const validateFields = (values, required, type) => {
  if (!values) return true;

  // O campo é obrigatório e os valores estão vazios
  if (values.length === 0 && required) return false;

  const typeError = values.reduce((accumulator, item) => {
    if (isEmptyFields(item, type) || accumulator === "default-errors")
      return "default-errors";

    const startDate = new Date(item?.startYear, item?.startMonth - 1);
    const endDate = new Date(item?.endYear, item?.endMonth - 1);

    if (["off", "false"].includes(item.current) && startDate > endDate)
      return "date-errors";

    return accumulator;
  }, "");

  return typeError === "date-errors" ? false : [];
};

export const LEVELS_HIDDEN = [
  "fundamental_incompleto",
  "fundamental_completo",
  "medio_incompleto",
  "medio_completo"
];

const schema = (requiredFields = []) => {
  const requiredCourses =
    requiredFields && requiredFields.includes("academicEducations");
  const requiredExperiences =
    requiredFields && requiredFields.includes("professionalExperiences");

  return yup.object().shape({
    about: yup
      .string()
      .isRequired(
        "about_me",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .nullable(),
    experiences: yup
      .array()
      .of(
        yup.object().shape({
          current: yup.string(),
          occupation: yup
            .string()
            .trim()
            .typeError("Campo de preenchimento obrigatório")
            .required("Campo de preenchimento obrigatório"),
          company: yup
            .string()
            .trim()
            .typeError("Campo de preenchimento obrigatório")
            .required("Campo de preenchimento obrigatório"),
          startMonth: yup
            .string()
            .required("Campo de preenchimento obrigatório")
            .nullable(),
          startYear: yup
            .string()
            .required("Campo de preenchimento obrigatório")
            .nullable(),
          endMonth: yup
            .string()
            .when("current", {
              is: (current) => current === "off",
              then: yup.string().required("Campo de preenchimento obrigatório")
            })
            .nullable(),
          endYear: yup
            .string()
            .when("current", {
              is: (current) => current === "off",
              then: yup.string().required("Campo de preenchimento obrigatório")
            })
            .nullable(),
          description: yup.string().nullable()
        })
      )
      .test({
        name: "required",
        test: (values) =>
          validateFields(values, requiredExperiences, "experiences"),
        message: "Campo de preenchimento obrigatório"
      })
      .nullable(),
    courses: yup
      .array()
      .of(
        yup.object().shape({
          current: yup.string(),
          level: yup
            .string()
            .typeError("Campo de preenchimento obrigatório")
            .required("Campo de preenchimento obrigatório"),
          college: yup
            .string()
            .trim()
            .typeError("Campo de preenchimento obrigatório")
            .required("Campo de preenchimento obrigatório"),
          course: yup
            .string()
            .when("level", {
              is: (level) => !LEVELS_HIDDEN.includes(level),
              then: yup.string().required("Campo de preenchimento obrigatório")
            })
            .nullable(),
          startMonth: yup
            .string()
            .required("Campo de preenchimento obrigatório")
            .nullable(),
          startYear: yup
            .string()
            .required("Campo de preenchimento obrigatório")
            .nullable(),
          endMonth: yup
            .string()
            .when("current", {
              is: (current) => current === "off",
              then: yup.string().required("Campo de preenchimento obrigatório")
            })
            .nullable(),
          endYear: yup.string().when("current", {
            is: (current) => current === "off",
            then: yup.string().required("Campo de preenchimento obrigatório")
          })
        })
      )
      .test({
        name: "required",
        test: (values) => validateFields(values, requiredCourses, "courses"),
        message: "Campo de preenchimento obrigatório"
      })
      .nullable()
  });
};

export default schema;
