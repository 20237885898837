const INITIAL_STATE = {
  vacancy: {},
  profiler: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@perfilCandidate/DESCRIPTION_VACANCY_SUCCESS":
      return {
        ...state,
        vacancy: action.payload.vacancy
      };

    case "@perfilCandidate/SET_PROFILER_SUCCESS":
      return {
        ...state,
        profiler: action.payload.profiler
      };

    default:
      return state;
  }
}
