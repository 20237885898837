import styled, { css } from "styled-components";

export const Breadcrumb = styled.div`
  color: #333333;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  display: block;

  svg {
    margin-right: 8px;
    width: 0.75rem;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const BreadcrumbItem = styled.span`
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
  font-weight: bold;
  line-height: inherit;

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${({ isAnchor }) =>
    !isAnchor &&
    css`
      text-decoration: initial;
      cursor: initial;
      font-weight: normal;
    `}
`;
