import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.25rem;
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.red : theme.colors.border};
  background: ${({ theme }) => theme.colors.white};
`;

export const Content = styled.textarea`
  border: 0;
  outline: 0;
  width: 100%;
  margin-bottom: -5px;
  padding: 0.75rem 1rem;
  color: ${({ theme }) => theme.colors.primary};
  resize: ${({ disabledResize }) => (disabledResize ? "none" : "vertical")};

  &::placeholder {
    color: #b8bdc6;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  position: relative;

  span,
  small {
    font-size: 0.75rem;
  }

  span {
    margin-left: 2px;
    bottom: 0;
  }

  small {
    margin-left: auto;
  }
`;
