import React from "react";

import Skeleton from "react-loading-skeleton";

import * as Styled from "./styles";

const SkeletonProfiler = () => (
  <Styled.Container>
    <Styled.Title>
      <Skeleton width={500} height={20} />
    </Styled.Title>
    <Styled.Description>
      <Skeleton width={600} height={10} />
      <Skeleton width={600} height={10} />
      <Skeleton width={600} height={10} />
      <Skeleton width={600} height={10} />
    </Styled.Description>
  </Styled.Container>
);

const SkeletonVacancies = () => (
  <Styled.Container>
    <Styled.Description>
      <Skeleton width={800} height={40} style={{ marginTop: 10 }} />
      <Skeleton width={800} height={40} style={{ marginTop: 10 }} />
      <Skeleton width={800} height={40} style={{ marginTop: 10 }} />
    </Styled.Description>
  </Styled.Container>
);

const SkeletonCompanies = () => (
  <Styled.Container>
    <Styled.Description>
      <Skeleton width={800} height={40} style={{ marginTop: 10 }} />
      <Skeleton width={800} height={40} style={{ marginTop: 10 }} />
      <Skeleton width={800} height={40} style={{ marginTop: 10 }} />
    </Styled.Description>
  </Styled.Container>
);

const SkeletonCurriculum = () => (
  <Styled.Container>
    <Skeleton width={600} height={20} />
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
    <Skeleton width={600} height={20} style={{ marginTop: 25 }} />
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
    <Skeleton width={600} height={20} style={{ marginTop: 25 }} />
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
    <Skeleton width={600} height={20} style={{ marginTop: 25 }} />
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
    <Styled.Description>
      <Skeleton width={600} height={100} style={{ marginTop: 25 }} />
    </Styled.Description>
  </Styled.Container>
);

export {
  SkeletonProfiler,
  SkeletonVacancies,
  SkeletonCompanies,
  SkeletonCurriculum
};
