import moment from "moment";
import { isInclusivelyBeforeDay } from "react-dates";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect } from "react";

import { getCNHCategories } from "@utils/getCNHCategories";
import { documentsSchema } from "@utils/formValidations/curriculumV2";

import { Select, DatePicker } from "@components/form";
import { CurriculumSectionTemplate } from "@components";

import useCurriculum from "@hooks/useCurriculum";
import useApplyVacancy from "@hooks/useApplyVacancy";
import { useCurriculumSections } from "@utils/hooks/useCurriculumSections";

import * as Styled from "../styles";

const MAX_LENGTH_ELECTORAL_ZONE = 8;
const MAX_LENGTH_ELECTORAL_SECTION = 8;
const MAX_LENGTH_CTPS_NUMBER = 8;
const MAX_LENGTH_CTPS_SERIES = 8;
const MAX_LENGTH_RESERVIST = 12;
const MAX_LENGTH_PIS = 11;

function Documents({ onSave, lastSection, children }) {
  const { registerForm, handleSubmit } = useCurriculum();
  const { sectionOptions } = useCurriculumSections();
  const { requiredFields, isRequiredField } = useApplyVacancy();
  const CNHCATEGORIES = getCNHCategories();

  const handleFormattedDate = (date) => {
    if (date) return moment(date).format("YYYY-MM-DD");
    return null;
  };

  const onSubmit = useCallback(
    (data, nextPath) => {
      const formattedData = {
        documents: {
          ...data.documents,
          driversLicenseEmissionDate: handleFormattedDate(
            data.documents.driversLicenseEmissionDate
          ),
          driversLicenseFirstDate: handleFormattedDate(
            data.documents.driversLicenseFirstDate
          )
        }
      };
      onSave(formattedData, nextPath);
    },
    [sectionOptions, lastSection]
  );

  useEffect(() => {
    const schema = documentsSchema(requiredFields);
    const resolver = yupResolver(schema);

    registerForm({
      schema: { resolver },
      handleSubmit: { onSubmit }
    });
  }, [requiredFields, sectionOptions, lastSection]);

  return (
    <CurriculumSectionTemplate title="Documentos">
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              data-testid="driverLicense"
              name="documents.driverLicense"
              label="CNH"
              maxlength="11"
              type="number"
              placeholder="Número da carteira de habilitação"
              required={isRequiredField("drivers_license")}
            />
          </Styled.Col>
          <Styled.Col>
            <Select
              label="Categoria da CNH"
              name="documents.driversLicenseCategory"
              data-testid="driversLicenseCategory"
              options={CNHCATEGORIES}
              isMulti
              closeMenuOnSelect={false}
              placeholder="Selecione"
              valueSelected=""
              required={isRequiredField("drivers_license_category")}
            />
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <DatePicker
              dataTestid="driversLicenseEmissionDate"
              name="documents.driversLicenseEmissionDate"
              label="Data de emissão da CNH"
              placeholder="dd/mm/aaaa"
              id="documents.driversLicenseEmissionDate"
              openDirection="down"
              numberOfMonths={1}
              maxDate={new Date()}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              noBorder
              required={isRequiredField("drivers_license_issuance_date")}
            />
          </Styled.Col>
          <Styled.Col>
            <DatePicker
              dataTestid="driversLicenseFirstDate"
              name="documents.driversLicenseFirstDate"
              label="Data da primeira habilitação"
              placeholder="dd/mm/aaaa"
              id="documents.driversLicenseFirstDate"
              openDirection="down"
              numberOfMonths={1}
              maxDate={new Date()}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              noBorder
              required={isRequiredField("drivers_license_first_date")}
            />
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              data-testid="driversLicenseUf"
              name="documents.driversLicenseUf"
              label="Estado de emissão da CNH"
              placeholder="Digite aqui"
              maxlength="30"
              required={isRequiredField("drivers_license_state")}
            />
          </Styled.Col>
          <Styled.Col>
            <Styled.Input
              data-testid="electoralNumber"
              type="number"
              format="#### #### ####"
              name="documents.electoralNumber"
              label="Título de eleitor"
              placeholder="Número do título de eleitor"
              required={isRequiredField("electoral_number")}
            />
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              data-testid="electoralZone"
              type="number"
              name="documents.electoralZone"
              label="Zona eleitoral"
              placeholder="Número da zona eleitoral"
              required={isRequiredField("electoral_zone")}
              maxlength={MAX_LENGTH_ELECTORAL_ZONE}
            />
          </Styled.Col>
          <Styled.Col>
            <Styled.Input
              data-testid="electoralSection"
              type="number"
              name="documents.electoralSection"
              label="Seção eleitoral"
              placeholder="Número da seção eleitoral"
              required={isRequiredField("pulling_station")}
              maxlength={MAX_LENGTH_ELECTORAL_SECTION}
            />
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              data-testid="pis"
              type="number"
              name="documents.pis"
              label="PIS"
              placeholder="Número do PIS"
              required={isRequiredField("social_integration_program")}
              maxlength={MAX_LENGTH_PIS}
            />
          </Styled.Col>
          <Styled.Col>
            <Styled.Input
              data-testid="ctpsNum"
              type="number"
              name="documents.ctpsNum"
              label="CTPS número"
              placeholder="Número da carteira de trabalho"
              required={isRequiredField("work_permit_num")}
              maxlength={MAX_LENGTH_CTPS_NUMBER}
            />
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col>
            <Styled.Input
              data-testid="ctpsSerie"
              type="number"
              name="documents.ctpsSerie"
              label="CTPS série"
              placeholder="Número da série da carteira de trabalho"
              required={isRequiredField("work_permit_serie")}
              maxlength={MAX_LENGTH_CTPS_SERIES}
            />
          </Styled.Col>
          <Styled.Col>
            <Styled.Input
              data-testid="reservist"
              type="number"
              name="documents.reservist"
              label="Reservista"
              placeholder="Número do certificado de reservista"
              required={isRequiredField("reservist")}
              maxlength={MAX_LENGTH_RESERVIST}
            />
          </Styled.Col>
        </Styled.Row>
        {children}
      </Styled.Form>
    </CurriculumSectionTemplate>
  );
}

export default React.memo(Documents);
