import styled, { css } from "styled-components";
import { mobileLarge } from "@styles/breakpoints";

export const Container = styled.nav`
  align-self: stretch;
  background: #fff;

  border-radius: 30px;
  padding: 0.75rem 1rem;
  margin-bottom: 1.25rem;
  display: block;

  svg {
    margin-right: 0.25rem;
    width: 0.75rem;
    display: inline-block;
    vertical-align: middle;
    fill: #333;
  }

  @media screen and (max-width: ${mobileLarge}) {
    margin-bottom: 2rem;
  }
`;

export const Item = styled.a`
  display: inline;
  font-size: 0.875rem;
  line-height: 1.3125rem;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }

  &,
  &:hover,
  &:visited {
    color: #333;
    ${({ isCompany }) =>
      isCompany &&
      css`
        color: #1787e0;
        text-decoration: underline;
      `}
  }

  ${({ isAnchor }) => isAnchor && `font-weight: 700;`}
`;
