import styled from "styled-components";

export const TagsSkill = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;

  margin: 1rem 0 1rem;
`;

export const TagSkill = styled.span`
  background: ${({ theme, selected }) =>
    selected ? theme.colors.secondary : "transparent"};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: ${({ theme, selected }) =>
      selected ? "transparent" : theme.colors.secondary};
    transition: ease-in-out;
  }

  p {
    font-style: normal;
    font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 0px;
    padding: 0.6rem 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
