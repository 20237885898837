import { CurriculumSectionContext } from "@contexts/curriculumSectionProvider";
import { useContext } from "react";

export function useCurriculumSections() {
  const curriculumSection = useContext(CurriculumSectionContext);

  if (!curriculumSection) {
    throw new Error(
      "CurriculumSection should be used within a CurriculumSectionProvider"
    );
  }

  return curriculumSection;
}
