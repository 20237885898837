import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";

import { ReactComponent as Close } from "@assets/svg/closeModal.svg";

import { validCPF } from "@utils/validations";

import {
  signIn,
  signUp,
  recoverPass
} from "@utils/formValidations/auth/schema";

import { notification } from "antd";

import API from "@services/api";

import { downloadFile } from "@utils/downloadFile";
import { showLoading, showModal } from "@store/modules/utilities/actions";

import { DEFAULT_HOST_CANDIDATE } from "@services/constants";
import * as cookie from "@utils/cookie";

import * as authActions from "@store/modules/auth/actions";
import * as Models from "./components";
import * as Styled from "./styles";

function Modal({ onClose, modal, company }) {
  const [_signInValues, _setSignInValues] = useState({});
  const [_signUpValues, _setSignUpValues] = useState({});
  const [_recEmailValue, _setRecEmailValue] = useState({});
  const [_formErrors, _setFormErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(true);
  const [termChecked, setTermChecked] = useState(false);

  const dispatch = useDispatch();

  const hasOpenCookiesWarningKey =
    modal?.dataParams &&
    !!Object.keys(modal.dataParams).find(
      (option) => option === "openCookiesWarning"
    );

  useEffect(() => {
    if (modalOpen === false) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const _handleChangeModal = (type, dataOptions = {}) => {
    const data = { ...modal, show: true, type, options: dataOptions };

    dispatch(showModal(data));
  };

  const _handleFormSignIn = (data) => {
    if (!!data.user && validCPF(data.user)) {
      data.type = "cpf";
    } else {
      data.type = "email";
    }

    _setSignInValues(data);
  };

  const _handleFormSignUp = (data) => {
    if (!!data.user && validCPF(data.user)) {
      data.type = "cpf";
    } else {
      data.type = "email";
    }

    _setSignUpValues(data);
  };

  const _handleFormRecover = (data) => {
    const tempRecovery = {
      ...data,
      redirectUri: window.location.href
    };
    _setRecEmailValue(tempRecovery);
  };

  const _handleValidateSignIn = () => {
    const schema = signIn();

    schema
      .validate(_signInValues, { abortEarly: false })
      .then((valid) => {
        _handleSubmitSignIn();
      })
      .catch((err) => {
        let errors = {};

        if (err.inner) {
          err.inner.map(
            (item) =>
              (errors = {
                ...errors,
                [item.path]: item.message
              })
          );
          _setFormErrors(errors);
        }
      });
  };

  const _handleValidateSignUp = () => {
    const schema = signUp();

    schema
      .validate(_signUpValues, { abortEarly: false })
      .then((valid) => {
        delete _signUpValues.confirmPassword;
        _handleSubmitSignUp();
      })
      .catch((err) => {
        let errors = {};

        if (err.inner) {
          err.inner.map(
            (item) =>
              (errors = {
                ...errors,
                [item.path]: item.message
              })
          );
          _setFormErrors(errors);
        }
      });
  };

  const _handleValidateRecover = () => {
    const schema = recoverPass();
    schema
      .validate(_recEmailValue, { abortEarly: false })
      .then((valid) => {
        delete _recEmailValue.type;
        _handleSubmitRecover();
      })
      .catch((err) => {
        let errors = {};

        if (err.inner) {
          err.inner.map(
            (item) =>
              (errors = {
                ...errors,
                [item.path]: item.message
              })
          );
          _setFormErrors(errors);
        }
      });
  };

  const _handleSubmitSignIn = () => {
    // dispatch(actionModal.signInRequest(_signInValues));
  };

  const _handleSubmitSignUp = () => {
    // dispatch(actionModal.signUpRequest(_signUpValues));
  };

  const _handleSubmitSignInLinkedin = (code) => {
    // dispatch(
    //     actionModal.signInLinkedinRequest({
    //         code,
    //         redirectUri: `${linkedinBaseUrl}/auth/linkedin/callback`,
    //     })
    // );
  };

  const _handleSubmitSignUpLinkedin = (code) => {
    // dispatch(
    //     actionModal.signUpLinkedinRequest({
    //         code,
    //         redirectUri: `${linkedinBaseUrl}/auth/linkedin/callback`,
    //     })
    // );
  };

  const _handleSubmitRecover = () => {
    // dispatch(actionModal.recoverPassRequestRequest(_recEmailValue));
  };

  const _handleCheckTermLinkedin = (onClick) => {
    let errors = { ..._formErrors };
    const schema = signUp();

    schema
      .validateAt("userTerm", { userTerm: _signUpValues.userTerm })
      .then((valid) => {
        delete errors.userTerm;
        _setFormErrors(errors);
        onClick();
      })
      .catch((err) => {
        errors = { ..._formErrors, userTerm: err.message };
        _setFormErrors(errors);
      });
  };

  const acceptUpdateTermsHandle = useCallback(
    (dataParams) => {
      dispatch(authActions.authenticateUserByToken(dataParams));
    },
    [dispatch]
  );

  const handleHasProfileExpired = () => {
    cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/profiler`);
    // if (dataParams?.redirect === true) {
    //   cookie.redirect(`${DEFAULT_HOST_CANDIDATE}/profiler`);
    // } else {
    //   dispatch(
    //     curriculumActions.saveCurriculumAndAnswerProfiler({
    //       curriculum: dataParams.data
    //     })
    //   );
    // }
  };

  const handleDownloadCurriculum = async () => {
    onClose();
    const api = API();

    try {
      dispatch(showLoading(true, "Baixando..."));
      const { data } = await api.get("/applicant/generate-applicant-pdf");
      if (data && data.link) downloadFile(data.link);
    } catch {
      notification.error({
        message: "Atenção",
        description:
          "Não foi possível prosseguir com sua solicitação. Por favor, tente novamente."
      });
    } finally {
      dispatch(showLoading(false));
    }
  };

  const renderBody = () => {
    const { type } = modal;
    switch (type) {
      case "login":
        return (
          <Models.BodyLogin
            changeModal={(type, dataOptions) =>
              _handleChangeModal(type, dataOptions)
            }
            fallbackFormInputHandle={_handleFormSignIn}
            fallbackFormSubmitHandle={_handleValidateSignIn}
            data={{}}
            type={type}
            errors={_formErrors}
            color={
              !!company && company.colorCompany
                ? company.colorCompany
                : "#333333"
            }
          />
        );
      case "forgotPassword":
        return (
          <Models.BodyLogin
            changeModal={(type, dataOptions) =>
              _handleChangeModal(type, dataOptions)
            }
            fallbackFormInputHandle={_handleFormRecover}
            data={{}}
            type={type}
            errors={_formErrors}
          />
        );
      case "registerUser":
        return (
          <Models.BodyRegisterUser
            changeModal={(type, dataOptions) =>
              _handleChangeModal(type, dataOptions)
            }
            fallbackFormInputHandle={_handleFormSignUp}
            fallbackTermChecked={(value) => setTermChecked(value)}
            data={{}}
            type={type}
            errors={_formErrors}
            color={
              !!company && company.colorCompany
                ? company.colorCompany
                : "#333333"
            }
          />
        );
      case "termsOfUse":
        return (
          <Models.BodyTermsOfUse
            changeModal={(e) => _handleChangeModal(e)}
            data={{}}
            type={type}
          />
        );
      case "updateTerms":
        return (
          <Models.BodyUpdateTerms
            onAccept={() => acceptUpdateTermsHandle(modal.dataParams)}
            changeModal={(e) => _handleChangeModal(e)}
            data={{}}
            type={type}
          />
        );
      case "settings":
        return (
          <Models.BodySettings
            data={modal.dataParams.currentUser}
            fallbackDelete={modal.dataParams.fallbackDelete}
            fallbackChangeEmail={modal.dataParams.fallbackChangeEmail}
            fallbackChangePassword={modal.dataParams.fallbackChangePassword}
          />
        );
      case "video":
        return <Models.BodyVideo data={modal.dataParams.videoUrl} />;
      case "applyVacancy":
        return <Models.BodyApplyVacancy />;
      case "applyTalentBank":
        return <Models.BodyApplyTalentBank />;
      case "cookiesWarning":
        return (
          <Models.BodyCookiesWarning
            callbackWarningCookies={() =>
              modal.dataParams.callbackWarningCookies()
            }
          />
        );
      case "profileExpired":
        return <Models.BodyHasProfileExpired />;
      case "solidesFreeCourse":
        return (
          <Models.BodySolidesFreeCourse
            onClose={() => {
              onClose();
              cookie.set("marketing_modal", true, { expires: 2 });
            }}
          />
        );
      case "newCvCancelEditing":
        return (
          <Models.BodyNewCvCancelEditing
            stateOfEditing={modal.dataParams.stateOfEditing}
          />
        );
      case "solidesServiceUnavailable":
        return (
          <Models.BodySolidesServiceUnavailable
            onClose={() => {
              cookie.set("service_unavailable", true, { expires: 1 });
              onClose();
            }}
          />
        );
      case "curriculumDownload":
        return <Models.BodyCurriculumDownload />;
      default:
        break;
    }
  };

  const renderFooter = () => {
    const { type } = modal;
    switch (type) {
      case "login":
        return (
          <Models.FooterLogin
            changeModal={(type, dataOptions) =>
              _handleChangeModal(type, dataOptions)
            }
            fallbackFormSubmitHandle={_handleValidateSignIn}
            fallbackSignInLinkedin={(code) => _handleSubmitSignInLinkedin(code)}
            data={{}}
            type={type}
            color={
              !!company && company.colorCompany
                ? company.colorCompany
                : "#333333"
            }
          />
        );
      case "forgotPassword":
        return (
          <Models.FooterLogin
            changeModal={(type, dataOptions) =>
              _handleChangeModal(type, dataOptions)
            }
            fallbackRecoverPassHandle={_handleValidateRecover}
            data={{}}
            type={type}
            color={
              !!company && company.colorCompany
                ? company.colorCompany
                : "#333333"
            }
          />
        );
      case "registerUser":
        return (
          <Models.FooterRegisterUser
            changeModal={(type, dataOptions) =>
              _handleChangeModal(type, dataOptions)
            }
            fallbackFormSubmitHandle={_handleValidateSignUp}
            fallbackSignUpLinkedin={(code) => _handleSubmitSignUpLinkedin(code)}
            fallbackUserTermLinkedin={(onClick) =>
              _handleCheckTermLinkedin(onClick)
            }
            data={{}}
            type={type}
            color={
              !!company && company.colorCompany
                ? company.colorCompany
                : "#333333"
            }
            termChecked={termChecked}
          />
        );
      case "termsOfUse":
        return (
          <Models.FooterTermsOfUse
            changeModal={(e) => _handleChangeModal(e)}
            data={{}}
            type={type}
            onClose={() => {
              onClose();

              if (hasOpenCookiesWarningKey) {
                modal.dataParams.openCookiesWarning();
              }
            }}
          />
        );
      case "applyVacancy":
        return (
          <Models.FooterApplyVacancy
            onApplyVacancy={() => modal.dataParams.onApplyVacancy()}
            onRedirectToCurriculum={() =>
              modal.dataParams.onRedirectToCurriculum()
            }
          />
        );
      case "applyTalentBank":
        return (
          <Models.FooterApplyTalentBank
            onApplyTalentBank={() => modal.dataParams.onApplyTalentBank()}
            onRedirectToCurriculum={() =>
              modal.dataParams.onRedirectToCurriculum()
            }
          />
        );
      case "cookiesWarning":
        return (
          <Models.FooterCookiesWarning
            onClose={() => {
              modal.dataParams.callbackWarningCookies();
              onClose();
            }}
          />
        );
      case "profileExpired":
        return (
          <Models.FooterHasProfileExpired
            onClose={() => handleHasProfileExpired()}
          />
        );
      case "newCvCancelEditing":
        return (
          <Models.FooterNewCvCancelEditing
            closing={onClose}
            quitAndRedirect={() => modal.dataParams.quitAndRedirect()}
          />
        );
      case "curriculumDownload":
        return (
          <Models.FooterCurriculumDownload
            onClose={onClose}
            onClick={handleDownloadCurriculum}
          />
        );
      default:
        break;
    }
  };

  const handleOnClickOverlay = () => {
    let defaultModalOpen = false;

    const checkOverlayKey =
      modal?.options &&
      !!Object.keys(modal.options).find(
        (option) => option === "closeOnOverlay"
      );

    if (checkOverlayKey) {
      defaultModalOpen = !modal.options.closeOnOverlay;
    }
    if (modal?.type === "solidesFreeCourse") {
      cookie.set("marketing_modal", true, { expires: 2 });
    }
    if (modal?.type === "solidesServiceUnavailable") {
      cookie.set("service_unavailable", true, { expires: 1 });
    }
    setModalOpen(defaultModalOpen);
  };

  const handleOnClose = () => {
    if (modal?.type === "termsOfUse") {
      onClose();
      if (hasOpenCookiesWarningKey) {
        modal.dataParams.openCookiesWarning();
      }
    } else if (modal?.type === "solidesFreeCourse") {
      cookie.set("marketing_modal", true, { expires: 2 });
      onClose();
    } else if (modal?.type === "solidesServiceUnavailable") {
      cookie.set("service_unavailable", true, { expires: 1 });
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <>
      <Styled.GlobalStyle modalOpen={modalOpen} />
      <Styled.ModalContainer>
        <Styled.ModalDialog
          position={modal.options.position}
          scrollable={modal.options.scrollable || true}
        >
          <Styled.ModalContent
            position={modal.options.position}
            size={modal.options.size}
            backgroundColor={modal.type === "video" && "transparent"}
            boxShadow={modal.type === "video" && "none"}
            style={modal.options.styles}
          >
            <Styled.ModalHeader>
              <Styled.ButtonClose
                showClose={modal.options.showClose}
                color={modal.type === "video" && "#ffffff"}
              >
                <Close onClick={handleOnClose} />
              </Styled.ButtonClose>
            </Styled.ModalHeader>
            <Styled.ModalBody>{renderBody()}</Styled.ModalBody>
            <Styled.ModalFooter>{renderFooter()}</Styled.ModalFooter>
          </Styled.ModalContent>
          <Styled.ModalOverlay
            hasBgShadow={modal.options.hasBgShadow}
            bgShadow={modal.options.bgShadow}
            onClick={handleOnClickOverlay}
          />
        </Styled.ModalDialog>
      </Styled.ModalContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  modal: state.utilities.modal,
  company: state.company.data
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
