import React from "react";
import PropTypes from "prop-types";

import Icon from "@components/icon";
import { Container, ButtonClose } from "./styles";

export const Cardboard = ({
  elementId,
  disableClose,
  handleClose,
  children
}) => (
  <Container id={elementId}>
    {!disableClose && (
      <ButtonClose type="button" onClick={handleClose}>
        <Icon className="fas fa-times" color="#ABABAB" size="1.25rem" />
      </ButtonClose>
    )}
    {children}
  </Container>
);

Cardboard.defaultProps = {
  elementId: "",
  disableClose: false
};

Cardboard.propTypes = {
  elementId: PropTypes.string,
  disableClose: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
