import React, { createContext, useContext, useEffect, useState } from "react";

import { getWindowUniqueId } from "../generateUniqueId";

const WindowTabContext = createContext({});

export const WindowTabProvider = ({ children }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    // adiciona um id único para a aba atual no navegador
    // isso ajuda a identificar a vaga na hora da aplicação do candidato
    setName(getWindowUniqueId());
  }, [window.name]);

  return (
    <WindowTabContext.Provider
      value={{
        name
      }}
    >
      {children}
    </WindowTabContext.Provider>
  );
};

export function useWindowTab() {
  const context = useContext(WindowTabContext);

  if (!context) {
    throw new Error("useWindowTab must be used within an WindowTabProvider.");
  }

  return context;
}
