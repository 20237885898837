import React from "react";

import * as Styled from "../styles";
import * as StyledGlobal from "../../../styles/global";
import Card from "../components/card";
import { SkeletonCompanies } from "../components/skeleton";

const Company = ({ talentBases, loading }) => (
  <Styled.ContainerCompany>
    <StyledGlobal.BoxHeader>
      <StyledGlobal.TextContainer>Empresas</StyledGlobal.TextContainer>
    </StyledGlobal.BoxHeader>
    {loading ? (
      <SkeletonCompanies />
    ) : (
      talentBases?.map((item, index) => (
        <Card
          company={item.company}
          applicationDate={item.applyDate}
          slug={item.slug}
          key={`company_${index}`}
        />
      ))
    )}
  </Styled.ContainerCompany>
);

export default Company;
