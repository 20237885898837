import React, { useCallback, useEffect, useRef } from "react";

import { useAuth } from "@hooks/useAuth";
import { useCurriculumSections } from "@utils/hooks/useCurriculumSections";

import * as Styled from "./styles";

const Mobile = () => {
  const { loadingApplicant } = useAuth();
  const { sectionOptions, changeSection, selectedSection } =
    useCurriculumSections();

  const containerRef = useRef(null);
  const menuStickRef = useRef(null);

  const handleMenuItemClick = useCallback(
    ({ target }) => {
      if (!loadingApplicant) {
        const key = target.getAttribute("data-key");
        changeSection(key);
      }
    },
    [changeSection, loadingApplicant]
  );

  useEffect(() => {
    const container = containerRef?.current;
    const stick = menuStickRef?.current;

    if (!container || !stick) return;

    const selectedButton = container.querySelector(
      `[data-key="${selectedSection?.key}"]`
    );

    if (!selectedButton) return;

    const width = selectedButton.clientWidth;
    const offsetX = selectedButton.offsetLeft;

    stick.style.width = `${width}px`;
    stick.style.transform = `translateX(${offsetX}px)`;

    container.scrollLeft = offsetX - 30 || 0;
  }, [menuStickRef, containerRef, selectedSection]);

  return (
    <Styled.MenuMobileContainer ref={containerRef}>
      {sectionOptions.map(({ key, text }) => (
        <Styled.MenuMobileItem
          key={key}
          data-key={key}
          disabled={loadingApplicant}
          onClick={handleMenuItemClick}
          $isSelected={selectedSection?.key === key}
        >
          {text}
        </Styled.MenuMobileItem>
      ))}
      <Styled.MenuMobileStick ref={menuStickRef} />
    </Styled.MenuMobileContainer>
  );
};

export default React.memo(Mobile);
