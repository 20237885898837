import React from "react";

import * as Styled from "../styles";

import useClickPreventionOnDoubleClick from "../../../utils/hooks/useClickPreventionOnDoubleClick";

const Outline = ({
  onClick = () => {},
  onDoubleClick = () => {},
  text,
  children,
  leftIcon = false,
  rightIcon = false,
  id,
  ...rest
}) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick
  );

  return (
    <Styled.ContentButton {...rest} id={id}>
      <Styled.ButtonOutline
        {...rest}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        {!!leftIcon && <Styled.LeftIcon {...rest}>{leftIcon}</Styled.LeftIcon>}

        <Styled.ButtonOutlineText
          {...rest}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
        >
          {text}
          {children}
        </Styled.ButtonOutlineText>

        {!!rightIcon && (
          <Styled.RightIcon {...rest}>{rightIcon}</Styled.RightIcon>
        )}
      </Styled.ButtonOutline>
    </Styled.ContentButton>
  );
};

export default Outline;
