import * as cookie from "./cookie";

export function getWindowUniqueId() {
  if (!window.name) {
    const { redirectCurrentWindow } = cookie.getJSON();

    if (redirectCurrentWindow) {
      window.name = redirectCurrentWindow;
      cookie.set("redirectCurrentWindow", "", { expires: 1 });
    } else {
      window.name = generateUuid();
    }
  } else {
    cookie.set("redirectCurrentWindow", "", { expires: 1 });
  }

  return window.name;
}

// https://www.ietf.org/rfc/rfc4122.txt
// http://stackoverflow.com/a/2117523
export function generateUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
