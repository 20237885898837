import * as cookie from "@utils/cookie";
import {
  DEFAULT_HOST_COMPANY,
  DEFAULT_HOST_HIDDEN_VACANCY
} from "@services/constants";

export default function getBreadcrumbItems() {
  const { pathname } = window.location;
  const { [window?.name]: tab } = cookie.getJSON();
  const company = tab?.company;
  const vacancy = tab?.vacancy;

  return [
    company?.name && {
      text: company.name,
      isAnchor: true,
      isCompany: true,
      onClick: () => {
        const companyHost = DEFAULT_HOST_COMPANY(company.slug);
        cookie.redirect(companyHost);
      }
    },
    vacancy?.title && {
      text: vacancy.title,
      isAnchor: true,
      onClick: () => {
        const companyHost = company
          ? `${DEFAULT_HOST_COMPANY(company.slug)}/vacancies`
          : DEFAULT_HOST_HIDDEN_VACANCY;
        cookie.redirect(`${companyHost}/${vacancy.id}`);
      }
    },
    pathname &&
      pathname.includes("curriculum") && {
        text: "Currículo"
      }
  ].filter(Boolean);
}
