/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ComponentInputMask from "react-input-mask";

import {
  InputWithLabel,
  LeftIcon,
  RightIcon,
  InvalidFeedback
} from "../../styles";

export default function InputMask({
  value,
  leftIcon = null,
  rightIcon = null,
  onChange,
  mask,
  style,
  label,
  iconColor,
  error,
  ...rest
}) {
  return (
    <InputWithLabel leftIcon={leftIcon} rightIcon={rightIcon} error={error}>
      {leftIcon !== null && (
        <LeftIcon iconColor={iconColor} {...rest}>
          {leftIcon}
        </LeftIcon>
      )}

      <ComponentInputMask
        {...rest}
        value={value}
        onChange={onChange}
        placeholder=" "
        mask={mask}
        style={style}
      />
      <label>{label}</label>

      {rightIcon !== null && (
        <RightIcon iconColor={iconColor} {...rest}>
          {rightIcon}
        </RightIcon>
      )}
      {!!error && <InvalidFeedback>{error}</InvalidFeedback>}
    </InputWithLabel>
  );
}
