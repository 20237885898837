import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import getThumb from "video-thumbnail-url";

import Icon from "@components/icon";
import { Label } from "@components/form";
import { Tooltip } from "@components/index";

import Description from "../description";
import TextLink from "../link";
import { TooltipVideoApresentation } from "../tooltips";
import Required from "../required";

import Thumbnail from "./components/thumbnail";

import { Container, Details } from "./styles";

const VideoApresentation = ({ name, required, children }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const { [name]: link } = useWatch();

  useEffect(() => {
    loadThumbnailUrl(link);
  }, [link]);

  const loadThumbnailUrl = (link) => {
    let mounted = true;

    getThumb(link)
      .then((thumbUrl) => {
        if (mounted) {
          setThumbnailUrl(thumbUrl);
        }
      })
      .catch((err) => setThumbnailUrl(""));

    return () => (mounted = false);
  };

  return (
    <>
      <Container>
        <Details>
          <Label>
            Vídeo de apresentação
            {required && <Required />}
          </Label>
          <Description>
            Publique o vídeo no youtube e cole o link aqui.
            <br />
            <Tooltip close content={TooltipVideoApresentation}>
              <TextLink>Onde está o link?</TextLink>{" "}
              <Icon className="fas fa-question-circle" />
            </Tooltip>
          </Description>
        </Details>
        <Thumbnail link={thumbnailUrl} />
      </Container>

      {children}
    </>
  );
};
export default VideoApresentation;
