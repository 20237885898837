import { all } from "redux-saga/effects";

import company from "./company/sagas";
import vacancies from "./vacancies/sagas";
import auth from "./auth/sagas";
import curriculum from "./curriculum/sagas";
import profiler from "./profiler/sagas";
import perfilCandidate from "./perfilCandidate/sagas";
import utilities from "./utilities/sagas";
import vacancy from "./vacancy/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    company,
    vacancies,
    curriculum,
    profiler,
    perfilCandidate,
    utilities,
    vacancy
  ]);
}
