import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from "react";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import * as cookie from "../cookie";
import * as authActions from "../../store/modules/auth/actions";
import useFetch from "./useFetch";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { token, token_expiration } = Cookies.getJSON();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [curriculumIsFilled, setCurriculumIsFilled] = useState(false);

  const { data: userData, loading: loadingApplicant } = useFetch(
    () => (token ? "/applicant" : null),
    {
      revalidateOnFocus: false
    }
  );
  const { data: myTalentBases, loading: loadingMyTalentBank } = useFetch(
    () => (token ? "/applicant/my-talent-bases" : null),
    {
      revalidateOnFocus: false
    }
  );
  const { data: myApplications, loading: loadingMyApplications } = useFetch(
    () => (token ? "/applicant/my-applications" : null),
    {
      revalidateOnFocus: false
    }
  );

  const formattedUserDates = useCallback(
    (dates) => {
      if (!dates) return [];
      return dates.map((item) => {
        const startDate = new Date(item.startDate?.replace(" ", "T"));
        const endDate = new Date(item.endDate?.replace(" ", "T"));

        return {
          ...item,
          startMonth: startDate.getMonth() + 1,
          startYear: startDate.getFullYear(),
          endMonth: item.endDate ? endDate.getMonth() + 1 : "",
          endYear: item.endDate ? endDate.getFullYear() : ""
        };
      });
    },
    [userData]
  );

  const filledUserFields = (userFields) => {
    const defaultFieldsUser = ["name", "gender", "birthday"];
    const isFilled = defaultFieldsUser.every((field) => !!userFields[field]);
    return isFilled;
  };

  const filledUserContactFields = (userContactFields) => {
    const defaultFieldsUserContact = ["email", "phone", "secondaryEmail"];
    const isFilled = defaultFieldsUserContact.every(
      (field) => !!userContactFields[field]
    );

    return isFilled;
  };

  const setUserData = (data) => {
    data.courses = formattedUserDates(data.courses);
    data.experiences = formattedUserDates(data.experiences);

    const userIsFilled = filledUserFields(data);
    const userContactIsFilled = filledUserContactFields(data.contacts || {});

    setCurriculumIsFilled(userIsFilled && userContactIsFilled);
    setUser(data);
  };

  useEffect(() => {
    const data = userData?.data || { contacts: {} };
    setUserData(data);
  }, [userData]);

  useEffect(() => {
    if (token_expiration) {
      try {
        // pega o tempo de expiração via jwt passado na hora da autenticação
        const { expires } = jwt.verify(
          token_expiration,
          process.env.REACT_APP_PRIVATE_KEY
        );

        if (expires) {
          const ONE_HOUR = 60 * 60 * 1000; /* ms */
          const currentTime = new Date();
          const expiresTime = new Date(expires);
          const time = expiresTime - currentTime;

          // se for menor que zero redireciona para a tela de login e exibe o motivo
          if (time < ONE_HOUR && time <= 0) {
            const auth_message = {
              type: "error",
              message: "Atenção",
              description: "Sua sessão expirou, por favor faça login novamente."
            };

            cookie.set("auth_message", auth_message);
            dispatch(authActions.logout());
          }
        }
      } catch (error) {
        cookie.set("token", "");
        cookie.set("token_expiration", "");
      }
    } else {
      cookie.set("token_expiration", "");
      cookie.set("token", "");
    }
  }, [dispatch, token_expiration]);

  return (
    <AuthContext.Provider
      value={{
        token,
        user: {
          ...user,
          myApplications: myApplications?.data || [],
          myTalentBases: myTalentBases?.data || []
        },
        curriculumIsFilled,
        loadingApplicant,
        loadingMyTalentBank,
        loadingMyApplications,
        setUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}
