import { useContext } from "react";
import { AddressContext } from "@contexts/index";

export default function useAddress() {
  const curriculum = useContext(AddressContext);

  if (!curriculum) {
    throw new Error("useAddress should be used within a CurriculumProvider");
  }

  return curriculum;
}
