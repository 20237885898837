import React from "react";
import ComponentInputMask from "react-input-mask";
import { InputBlocked } from "../../styles";

export default function Blocked({
  label,
  value,
  isValid,
  styleInput = {},
  mask,
  error = false,
  ...rest
}) {
  return (
    <InputBlocked error={error} {...rest}>
      {mask ? (
        <ComponentInputMask
          value={value || ""}
          placeholder={label}
          mask={mask}
          {...rest}
        />
      ) : (
        <input
          style={styleInput}
          type="text"
          placeholder={label}
          value={value || ""}
          {...rest}
        />
      )}
      <label>{label}</label>
    </InputBlocked>
  );
}
