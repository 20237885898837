import { useContext } from "react";

import { AlertModalContext } from "@contexts/index";

export default function useAlertModal() {
  const context = useContext(AlertModalContext);

  if (!context) {
    throw new Error("useAlertModal must be used within AlertModalProvider");
  }

  return context;
}
