import styled from "styled-components";

export const ContainerProgress = styled.div`
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  min-width: 100px;

  @media screen and (max-width: 992px) {
    border-radius: 0px;
  }
`;

export const TitleProgressStep = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.1px;
  color: ${({ titleColor }) => titleColor || "#333333"};
  opacity: 0.65;
  padding: 0px 3px;

  ${({ textTopUppercase }) => textTopUppercase && "text-transform: uppercase;"}

  /* less than 992px */
    display: none;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 200px) {
    display: block;
  }
`;

export const ContainerProgressStep = styled.div`
  background: ${({ progressBackground }) => progressBackground || "#999999"};

  ${({ borderColor }) => borderColor && `border: 1px solid ${borderColor};`};

  width: 100%;
  ${({ hasShadown }) =>
    hasShadown && "box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0.25);"}

  /* less than 992px */
    position: fixed;
  top: 0;
  left: 0;
  height: 14px;
  min-height: 14px;
  border-radius: 100px;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 200px) {
    position: relative;
    height: 100%;
    min-height: ${({ minHeight }) => minHeight && minHeight};
  }

  @media screen and (max-width: 992px) {
    border-radius: 0px;
    display: flex;
    align-items: center;
    padding: 0 3px;
  }
`;

export const CurrentProgressStep = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ percentage, progressColor, progressBackground }) =>
    percentage &&
    `linear-gradient(90deg, ${progressColor} 0%, ${progressColor} ${percentage}%, ${progressBackground} ${percentage}%)`};
  ${({ hasShadown }) =>
    hasShadown && "box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0.25);"}

  border-radius: 100px;
  min-height: ${({ minHeight }) => minHeight && minHeight};

  @media screen and (max-width: 992px) {
    min-height: 10px;
    border-radius: 20px;
    width: ${({ percentage }) => `${percentage || 0}%`};
    background: ${({ progressColor }) => progressColor || "#333333"};
  }
`;

export const ContainerRetina = styled.div`
  position: absolute;
  width: calc(100% - 10px);
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px 12px 6px 6px;
  min-height: 12px;
  z-index: 1001;
  top: 2px;
  margin-right: 5px;
  margin-left: 5px;

  /* less than 992px */
  display: none;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 200px) {
    display: block;
  }

  @media screen and (max-width: 992px) {
    background: transparent;
  }
`;

export const ContainerDescription = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  letter-spacing: -0.05em;

  & > *:first-child {
    margin-top: 7px;
  }

  /* less than 992px */
  display: none;

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 200px) {
    display: block;
  }
`;

export const Description = styled.div`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ descriptionColor }) => descriptionColor || "#666666"};
`;
