import styled from "styled-components";
import { tablet } from "@styles/breakpoints";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media screen and (max-width: ${tablet}) {
    grid-template-columns: 1fr;
  }
`;
