export const PORT = window.location.port || process.env.REACT_APP_PORT;
export const DOMAIN = PORT
  ? `${process.env.REACT_APP_DOMAIN}:${PORT}`
  : process.env.REACT_APP_DOMAIN;

export const PROTOCOL =
  window.location.protocol || process.env.REACT_APP_PROTOCOL;

export const DEFAULT_HOST_LOGIN = `${PROTOCOL}//login.${DOMAIN}`;
export const DEFAULT_HOST_LOGIN_SIGNUP = `${PROTOCOL}//login.${DOMAIN}/signUp`;
export const DEFAULT_HOST_LOGIN_FORGOT = `${PROTOCOL}//login.${DOMAIN}/forgotPassword`;
export const DEFAULT_HOST_LOGIN_RECOVERY = (TOKEN) =>
  `${PROTOCOL}//login.${DOMAIN}/passwordReset/${TOKEN}`;
export const DEFAULT_HOST_LINKEDIN_CALLBACK = `${PROTOCOL}//login.${DOMAIN}/auth/linkedin/callback`;
export const DEFAULT_HOST_FACEBOOK_CALLBACK = `${PROTOCOL}//login.${DOMAIN}/auth/facebook/callback`;
export const DEFAULT_HOST_CANDIDATE = `${PROTOCOL}//${DOMAIN}`;
export const DEFAULT_HOST_HIDDEN_VACANCY = `${PROTOCOL}//${DOMAIN}/vacancies`;
export const DEFAULT_HOST_COMPANY = (slug) => `${PROTOCOL}//${slug}.${DOMAIN}`;

export const DEFAULT_LOGIN_ROUTES = [
  "/",
  "/signUp",
  "/forgotPassword",
  "/passwordReset"
];

export const DEFAULT_CANDIDATE_ROUTES = [
  "/",
  "/perfilCandidate",
  "/curriculum",
  "/profiler",
  "/applicationStatus",
  "/pdf/curriculum"
];

export const DEFAULT_COMPANY_ROUTES = [
  "/",
  "/vacancies",
  "/vacancies/",
  "/applicationStatus",
  "/talentBankStatus",
  "/profilerStatus"
];

export const DEFAULT_HIDDEN_VACANCY_ROUTES = "/vacancies/";

export const CURRENT_TERMS_OF_USE = "2021";
export const TYPES_SOCIAL_NETWORKS = ["linkedin", "facebook"];
