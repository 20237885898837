import API from "../../../services/api";

export const applyTalenteBase = async (json) =>
  new Promise((resolve, reject) => {
    const api = API();
    api
      .post(`/applicant/apply-job`, json)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getTalentBases = async () =>
  new Promise((resolve, reject) => {
    const api = API();
    api.get("/applicant/my-talent-bases").then(resolve).catch(reject);
  });
