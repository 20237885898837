import React, { useEffect, useState } from "react";
import moment from "moment";

import { formatArrayToText } from "@utils/formatArrayToText";
import { getDisabilities } from "@utils/getDisabilities";

import * as Styled from "./styles";

const AdditionalInformation = ({ person = {} }) => {
  const [address, setAddress] = useState("");
  const [birthday, setBirthday] = useState("");

  const getBrazilianAddressFormatted = () => {
    const addressFormatted = [];

    const getSeparatorCharacter = (character) =>
      addressFormatted.length > 0 ? character : "";

    if (person?.address?.street)
      addressFormatted.push(`${person.address.street}`);

    if (person?.address?.number)
      addressFormatted.push(
        `${getSeparatorCharacter(",")} Nº ${person.address.number}`
      );

    if (person?.address?.complement)
      addressFormatted.push(
        ` ${getSeparatorCharacter("/")}  ${person.address.complement}`
      );

    if (person?.address?.district)
      addressFormatted.push(` Bairro ${person.address.district}`);

    if (person?.address?.city)
      addressFormatted.push(
        `${getSeparatorCharacter(",")} ${person.address.city}`
      );

    if (person?.address?.province)
      addressFormatted.push(
        ` ${getSeparatorCharacter("/")} ${person.address.province}`
      );

    if (person?.address?.zipCode)
      addressFormatted.push(` CEP ${person.address.zipCode}`);

    if (person?.address?.country)
      addressFormatted.push(
        ` ${getSeparatorCharacter("/")} ${person.address.country}`
      );

    return addressFormatted;
  };

  const getForeignAddress = () => {
    const addressForeign = [];
    if (person.address?.street) addressForeign.push(person.address?.street);
    if (person.address?.number) addressForeign.push(person.address?.number);
    if (person.address?.complement)
      addressForeign.push(person.address?.complement);
    if (person.address?.district) addressForeign.push(person.address?.district);
    if (person.address?.city) addressForeign.push(person.address?.city);
    if (person.address?.province) addressForeign.push(person.address?.province);
    if (person.address?.zipCode) addressForeign.push(person.address?.zipCode);
    if (person.address?.country) addressForeign.push(person.address?.country);

    return addressForeign;
  };

  const getPcdFormatted = () => {
    const disabilities = getDisabilities();
    const pcdFormatted = [];

    person.disabledPersonDescription.forEach((disability) => {
      const option = disabilities.find(
        (disabilityOption) => disabilityOption.value === disability
      );

      if (option) {
        pcdFormatted.push(option.label);
      }
    });

    return formatArrayToText(pcdFormatted);
  };

  useEffect(() => {
    const isBrazil = person.address?.country?.toLowerCase() === "brasil";

    if (isBrazil) {
      const brazilianAddress = getBrazilianAddressFormatted();

      setAddress(brazilianAddress.join(""));
    } else {
      const foreignAddress = getForeignAddress();

      setAddress(foreignAddress.join(", "));
    }

    if (person.birthday) {
      setBirthday(moment(person.birthday).format("DD/MM/YYYY"));
    }
  }, [person]);

  const maritalStatusText = (value) => {
    switch (value) {
      case "casado":
        return "Casado";
      case "solteiro":
        return "Solteiro";
      case "uniao_estavel":
        return "União Estável";
      case "divorciado":
        return "Divorciado";
      case "viuvo":
        return "Viúvo";
      case "separado":
        return "Separado";
      default:
        return "Informação não cadastrada";
    }
  };

  const seniorityText = (value) => {
    switch (value) {
      case "internship":
        return "Estágio";
      case "trainee":
        return "Trainee";
      case "operational":
        return "Operacional";
      case "supervision":
        return "Supervisão";
      case "management":
        return "Gerência";
      case "directors":
        return "Diretoria";
      default:
        return "Informação não cadastrada";
    }
  };

  return (
    <Styled.ContainerAdditionalInformation>
      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>Endereço</Styled.BoxTitle>
          <Styled.BoxDescription fontStyle={!address ? `italic` : `normal`}>
            {address || `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Gênero</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.gender ? `italic` : `normal`}
          >
            {person.gender
              ? person.gender === "male"
                ? "Masculino"
                : person.gender === "female"
                ? "Feminino"
                : "Não Binário"
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Nascimento</Styled.BoxTitle>
          <Styled.BoxDescription fontStyle={!birthday ? `italic` : `normal`}>
            {birthday || `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
      </Styled.AdditionalInformationRow>

      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>E-mail secundário</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.contacts?.secondaryEmail ? `italic` : `normal`}
          >
            {person.contacts?.secondaryEmail
              ? person.contacts.secondaryEmail
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Estado Civil</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.maritalStatus ? `italic` : `normal`}
          >
            {person.maritalStatus
              ? maritalStatusText(person.maritalStatus)
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
        <Styled.ContainerBox>
          <Styled.BoxTitle>Pretensão Salarial</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={!person.salaryExpectation ? `italic` : `normal`}
          >
            {person.salaryExpectation
              ? new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(person.salaryExpectation)
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
        </Styled.ContainerBox>
      </Styled.AdditionalInformationRow>

      <Styled.AdditionalInformationRow>
        <Styled.ContainerBox flex={3}>
          <Styled.BoxTitle>Portador de Deficiência</Styled.BoxTitle>
          <Styled.BoxDescription
            fontStyle={
              !person.isDisabledPerson ||
              !!person.disabledPersonDescription === false
                ? `italic`
                : `normal`
            }
          >
            {!!person.isDisabledPerson &&
            person.disabledPersonDescription?.length > 0
              ? getPcdFormatted()
              : `Informação não cadastrada`}
          </Styled.BoxDescription>
          {person?.disabledPersonAdaptation && (
            <Styled.BoxDescription>
              {person?.disabledPersonAdaptation}
            </Styled.BoxDescription>
          )}
        </Styled.ContainerBox>
      </Styled.AdditionalInformationRow>
    </Styled.ContainerAdditionalInformation>
  );
};

export default AdditionalInformation;
