import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
`;

export const ContainerForm = styled.form``;

export const Field = styled.div`
  margin-bottom: 15px;

  ${({ center }) => center && "text-align: center;"}
`;

export const ForgotPassword = styled.a`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: ${({ textColor }) => textColor || "#333333"};
  &:hover {
    color: ${({ textColor }) => textColor || "#333333"};
    text-decoration: underline;
  }
`;

export const ContainerSocialAuth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const ContainerAuth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
