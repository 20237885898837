import styled from "styled-components";
import * as breakpoints from "@styles/breakpoints";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-family: "Roboto Condensed", "Roboto", Arial, sans-serif;
  font-weight: bold;
  margin: 0;
  line-height: 2.25rem;
  color: #333333;

  @media screen and (min-width: ${breakpoints.tablet}) {
    font-size: 36px;
  }
`;

export const GoBack = styled.button`
  border: 0;
  background: transparent;
  border-bottom: 1px solid #1787e0;
  color: #1787e0;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1rem;

  svg {
    fill: #1787e0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
