import Cookies from "js-cookie";

import { DOMAIN as domain, PORT, PROTOCOL } from "@services/constants";

const DOMAIN = domain.replace(`:${PORT}`, "");

// const { protocol } = window.location;

const DEFAULT_OPTIONS = {
  path: "/",
  domain: DOMAIN,
  expires: 2, // 1 dia
  secure: PROTOCOL === "https:",
  SameSite: PROTOCOL === "https:" ? "None" : "Lax"
};

const get = (key) => Cookies.get(key);

const getJSON = () => {
  const data = Cookies.getJSON();

  // adicioando essa validação por conta do tempo que leva para pegar o valor na criação da página
  if (!window.name && data?.redirectCurrentWindow) {
    window.name = data.redirectCurrentWindow;
  }

  return data;
};

const remove = (key) => {
  const neededAttributes = {
    // Here you pass the same attributes that were used when the cookie was created
    // and are required when removing the cookie
    path: "/",
    domain: DOMAIN
  };

  Cookies.remove(key, neededAttributes);

  if (DOMAIN.startsWith("local.") || DOMAIN.startsWith("homol.")) {
    const envNeededAttributes = {
      path: "/",
      domain: DOMAIN.replace("local.", "").replace("homol.", "")
    };

    Cookies.remove(key, envNeededAttributes);
  }
};

const set = (key, value, options = {}) =>
  Cookies.set(key, value, { ...DEFAULT_OPTIONS, ...options });

const redirect = (url) => {
  // adicioando essa validação por conta do iphone trocar a key da aba
  set("redirectCurrentWindow", window.name, {
    expires: 1
  });
  window.location.href = url;
};

export { get, getJSON, set, remove, redirect, DOMAIN };
