import React from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import resolve from "@utils/resolve";
import { Label, MessageError } from "@components/form";

import { Container, Content, Footer } from "./styles";

function Textarea({
  name,
  label,
  required,
  disabledResize,
  maxLength,
  limitWarning,
  ...rest
}) {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const value = useWatch({ control, name }) || "";
  const { message: error } = resolve(name, errors);

  const maxLengthFormat = (value) =>
    new Intl.NumberFormat("pt-BR", { style: "decimal" }).format(value);

  return (
    <div>
      {label && <Label required={required}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field }) => (
          <Container hasError={!!error}>
            <Content
              maxLength={maxLength}
              disabledResize={disabledResize}
              {...rest}
              {...field}
            />
          </Container>
        )}
      />

      <Footer>
        {!error && limitWarning && maxLength === value.length && (
          <span>Você atingiu o limite de caracteres!</span>
        )}
        <MessageError message={error} />
        {maxLength && (
          <small>
            {maxLengthFormat(value.length)}/{maxLengthFormat(maxLength)}
          </small>
        )}
      </Footer>
    </div>
  );
}

Textarea.defaultProps = {
  label: "",
  required: false,
  limitWarning: false,
  maxLength: 100,
  disabledResize: false
};

Textarea.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  limitWarning: PropTypes.bool,
  disabledResize: PropTypes.bool,
  name: PropTypes.string.isRequired
};

export default Textarea;
