import React from "react";
import PropTypes from "prop-types";

import Portal from "@components/portal";
import { ReactComponent as IconClose } from "@assets/svg/closeModal.svg";
import {
  ApplyVacancy,
  CancelRegistration,
  DeleteCourseExperience,
  DeleteProfessionalExperience,
  FinishedRegistration,
  GoBackSection
} from "./templates";

import { Container, Content, ButtonClose } from "./styles";

export const ModalCurriculum = ({ action, onClose, template, size }) => {
  const hanldeActionAndCloseModal = () => {
    action();
    onClose();
  };

  const templates = {
    finished: <FinishedRegistration action={hanldeActionAndCloseModal} />,
    "go-back": (
      <GoBackSection onClose={onClose} action={hanldeActionAndCloseModal} />
    ),
    "delete-experience": (
      <DeleteProfessionalExperience
        onClose={onClose}
        action={hanldeActionAndCloseModal}
      />
    ),
    "delete-course": (
      <DeleteCourseExperience
        onClose={onClose}
        action={hanldeActionAndCloseModal}
      />
    ),
    cancel: (
      <CancelRegistration
        onClose={onClose}
        action={hanldeActionAndCloseModal}
      />
    ),
    "apply-vacancy": (
      <ApplyVacancy onClose={onClose} action={hanldeActionAndCloseModal} />
    )
  };

  return (
    <Portal>
      <Container>
        <Content size={size}>
          <ButtonClose onClick={onClose}>
            <IconClose />
          </ButtonClose>

          {template !== "" && templates[template]}
        </Content>
      </Container>
    </Portal>
  );
};

ModalCurriculum.defaultProps = {
  size: "normal",
  template: ""
};

ModalCurriculum.propTypes = {
  size: PropTypes.string,
  template: PropTypes.string,
  action: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
