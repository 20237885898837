import React, { useState, forwardRef } from "react";

import DatePicker from "react-datepicker";
import ComponentInputMask from "react-input-mask";

import { ptBR } from "date-fns/locale";
import {
  InputWithLabel,
  IconDateContainer,
  LabelDate,
  ContainerValidate
} from "../../styles";

import { ReactComponent as ErrorIcon } from "../../../../assets/svg/validationError.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/svg/validationSuccess.svg";
import { ReactComponent as IconDate } from "../../../../assets/svg/calendar.svg";

export default function InputDate({
  label,
  onChange = () => {},
  error = false,
  showValidateIcon = false,
  maxDate,
  ...rest
}) {
  const [dateValue, setDateValue] = useState(new Date());
  const InputCustom = forwardRef(({ value, onClick, onChange }, ref) => (
    <InputWithLabel type="datePicker" error={error}>
      <IconDateContainer>
        <IconDate />
      </IconDateContainer>

      <ComponentInputMask
        defaultValue={value}
        onChange={(e) => onChange(e)}
        onClick={onClick}
        mask="99/99/9999"
        strictparsing="true"
      />

      <LabelDate focus={!!value}>{label}</LabelDate>
      <ContainerValidate>
        {showValidateIcon && error ? (
          <ErrorIcon />
        ) : showValidateIcon && !!value ? (
          <SuccessIcon />
        ) : null}
      </ContainerValidate>
    </InputWithLabel>
  ));

  return (
    <DatePicker
      locale={ptBR}
      todayButton="Hoje"
      selected={dateValue}
      isClearable={false}
      onChange={(date, event) => {
        setDateValue(date);
        onChange(event, date);
      }}
      dateFormat="dd/MM/yyyy"
      showYearDropdown
      customInput={<InputCustom />}
      maxDate={maxDate}
      {...rest}
    />
  );
}
