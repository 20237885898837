import styled from "styled-components";

export const BodyContainer = styled.section`
  background: #f4f0eb;
  min-height: 100vh;
  padding-bottom: 65px;

  @media screen and (max-width: 450px) {
    padding-bottom: 20px;
    padding-top: 68px;
  }
`;

export const Container = styled.div`
  width: 100%;
  font-family: "Roboto Condensed", arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #333333;
  max-width: 1200px;
  position: relative;
  z-index: 10;
  margin-top: -340px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1260px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 736px) {
    margin-top: -420px;
  }

  @media screen and (max-width: 450px) {
    margin-top: -220px;
    padding: 0 15px;
  }
`;

export const DetailsVacancy = styled.div`
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 70px 65px;

  @media screen and (max-width: 450px) {
    padding: 47px 20px;
  }
`;

export const Header = styled.div`
  margin-bottom: 35px;

  @media screen and (max-width: 450px) {
    margin-bottom: 30px;
  }
`;

export const Body = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
