import styled from "styled-components";

export const Row = styled.div`
  margin-top: 32px;

  label {
    font-size: 16px;
    min-height: auto;
    span {
      display: none;
    }
  }
  > div {
    margin: 28px 0;
    :not(:first-child) {
      margin-top: 16px;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    margin-left: 4px;
    &.success {
      fill: #177727;
    }
    &.error {
      width: 12px;
      height: 12px;
      fill: #ef3d4e;
    }
  }
`;

export const SectionRulesPassword = styled.section`
  padding: 16px 0;
  animation: show 300ms linear;

  ul {
    margin: 0;
    padding-left: 24px;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
