import * as yup from "yup";

export const MAX_LENGTH_INPUT_SKILL = 50;

const schema = (requiredFields = [], vacancy = {}) =>
  yup.object().shape({
    skills: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().nullable(),
          name: yup.string().nullable(),
          level: yup.string().nullable()
        })
      )
      .isRequired(
        "hardSkills",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .hasItems(
        true,
        vacancy?.skills || [],
        "Campo de preenchimento obrigatório"
      )
      .nullable(),
    languages: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().nullable(),
          name: yup.string().nullable(),
          level: yup.string().nullable()
        })
      )
      .isRequired(
        "language",
        requiredFields,
        "Campo de preenchimento obrigatório"
      )
      .hasItems(
        true,
        vacancy?.languages || [],
        "Campo de preenchimento obrigatório"
      )
      .nullable()
  });

export default schema;
