import React, { useState } from "react";

import { Typography, Button } from "@components/index";
import { DEFAULT_HOST_LOGIN } from "@services/constants";
import { CheckboxNormal } from "@components/form/checkbox";

import { Container, AcceptContent } from "./styles";

export const Body = ({ onAccept }) => {
  const [acceptUserTerm, setAcceptUserTerm] = useState(false);

  return (
    <Container>
      <Typography type="subtitle">
        Aceitar os termos de uso e política de privacidade
      </Typography>

      <Typography>
        Para prosseguir é necessário aceitar os termos de uso e política de
        privacidade, eles garantem a proteção e privacidade dos seus dados.
      </Typography>

      <AcceptContent>
        <CheckboxNormal
          name="userTerm"
          checked={acceptUserTerm}
          onClick={() => setAcceptUserTerm((state) => !state)}
        />

        <p>
          Li e concordo com os{" "}
          <a
            href={`${DEFAULT_HOST_LOGIN}/policyTerms`}
            target="_blank"
            rel="noreferrer"
          >
            termos de uso e política de privacidade.
          </a>
        </p>
      </AcceptContent>

      <Button
        large
        type="custom"
        text="CONTINUAR"
        backgroundColor={acceptUserTerm ? "#333333" : "#BFBAC8"}
        textColor="#F2F2F2"
        disabled={!acceptUserTerm}
        boxShadow="0px 2px 6px rgba(47, 10, 119, 0.35), inset 0px -3px 0px rgba(0, 0, 0, 0.2)"
        isUppercase
        onClick={() => onAccept()}
      />
    </Container>
  );
};
