import React, { useEffect, useState, useCallback, useMemo } from "react";
import ReactPlayer from "react-player";
import { Tooltip } from "antd";
import Parser from "html-react-parser";
import copy from "copy-to-clipboard";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

import { ReactComponent as CopyIcon } from "../../assets/svg/copy-regular.svg";
import { ReactComponent as Inscrever } from "../../assets/svg/inscrever.svg";
import { ReactComponent as Inscrito } from "../../assets/svg/inscrito.svg";
import { ReactComponent as AccessibleIcon } from "../../assets/svg/accessible-icon.svg";

import Skills from "../skills";
import Button from "../button";
import SanitizeHTML from "../sanitize";

import Skeleton from "./components/skeleton";
import NotFound from "./components/notFound";

import * as Styled from "./styles";

import { useCompany } from "../../utils/hooks/useCompany";

const Body = ({
  loading,
  subscribed = false,
  vacancy,
  color = "#333333",
  onApplyVacancy,
  onGoBackNotFound
}) => {
  const [listDescription, setListDescription] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [colorCompany, setColorCompany] = useState("#333333");
  const { company } = useCompany();

  useEffect(() => {
    setColorCompany(company?.colorCompany || "#333333");
  }, [company]);

  useEffect(() => {
    if (vacancy?.id) {
      const proposal = [];
      const requirements = [];

      if (vacancy?.salaryStart?.length > 0) {
        proposal.push(getFormatVacancyData("salaryStart", "Salário"));
      } else if (vacancy?.salaryEnd?.length > 0) {
        proposal.push(getFormatVacancyData("salaryEnd", "Salário"));
      }

      vacancy?.benefits &&
        proposal.push(getFormatVacancyData("benefits", "Benefícios"));

      vacancy?.languages &&
        requirements.push(getFormatVacancyData("languages", "Idiomas"));
      vacancy?.skills &&
        requirements.push(
          getFormatVacancyData("skills", "Habilidades Específicas")
        );

      setListDescription({ proposal, requirements });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  const _copyHandle = () => {
    const text = window.location.href;
    setIsCopied(copy(text));
  };

  const getFormatVacancyData = (key, title) => {
    const options = [];

    if (key === "salaryStart" || key === "salaryEnd") {
      let salary = "";
      if (
        !!vacancy.salaryStart &&
        vacancy.salaryStart.length > 0 &&
        !!vacancy.salaryEnd &&
        vacancy.salaryEnd.length > 0
      ) {
        salary = `${vacancy.salaryStart} - ${vacancy.salaryEnd}`;
      } else if (!!vacancy.salaryEnd && vacancy.salaryEnd.length > 0) {
        salary = vacancy.salaryEnd;
      } else if (!!vacancy.salaryStart && vacancy.salaryStart.length > 0) {
        salary = vacancy.salaryStart;
      }

      options.push({
        text: salary
      });
    } else if (key === "languages") {
      const levelLangText = (level) => {
        switch (level) {
          case 1:
            return "Iniciante";
          case 2:
            return "Básico";
          case 3:
            return "Intermediário";
          case 4:
            return "Avançado";
          case 5:
            return "Fluente";
          case 6:
            return "Domínio pleno";
          default:
            return "Iniciante";
        }
      };
      const levelLangPercent = (level) => {
        switch (level) {
          case 1:
            return 0;
          case 2:
            return 20;
          case 3:
            return 40;
          case 4:
            return 60;
          case 5:
            return 80;
          case 6:
            return 100;
          default:
            return 0;
        }
      };

      vacancy[key].map((item) =>
        options.push({
          text: item.name,
          level: levelLangText(item.level),
          percent: levelLangPercent(item.level)
        })
      );
    } else if (key === "skills") {
      const levelSkillText = (level) => {
        switch (level) {
          case 1:
            return "Básico";
          case 2:
            return "Intermediário";
          case 3:
            return "Avançado";
          default:
            return "Básico";
        }
      };
      const levelSkillPercent = (level) => {
        switch (level) {
          case 1:
            return 0;
          case 2:
            return 50;
          case 3:
            return 100;
          default:
            return 0;
        }
      };

      vacancy[key].map((item) =>
        options.push({
          text: item.name,
          level: levelSkillText(item.level),
          percent: levelSkillPercent(item.level)
        })
      );
    } else {
      vacancy[key].map((item) =>
        options.push({
          text: item.name,
          level: item.level,
          percent: item.percent
        })
      );
    }

    const data = {
      title,
      data: options
    };

    return data;
  };

  const accessDescription = useCallback(
    () => (
      <>
        {Parser("<br><br>")}
        <AccessibleIcon />
        Todas as nossas vagas são elegíveis para pessoas com deficiência e/ou
        reabilitadas.
      </>
    ),
    []
  );

  const renderAvailablePositions = useMemo(
    () =>
      vacancy?.availablePositions > 0 && (
        <Styled.AvailablePositions color={color}>
          <i className="fas fa-briefcase" /> {vacancy?.availablePositions}{" "}
          {vacancy?.availablePositions > 1 ? "vagas" : "vaga"}
        </Styled.AvailablePositions>
      ),
    [vacancy, color]
  );

  if (loading) {
    return <Skeleton />;
  }

  if (!vacancy?.id) {
    return <NotFound colorCompany={colorCompany} onGoBack={onGoBackNotFound} />;
  }

  return (
    <>
      <Styled.Header>
        <Styled.ContainerTitle>
          <Styled.Title hasRightSpace={vacancy?.availablePositions > 0}>
            {vacancy?.title}
          </Styled.Title>
          {renderAvailablePositions}
        </Styled.ContainerTitle>
        <Styled.SubTitle>
          {vacancy?.department}
          {vacancy?.department && vacancy?.city && <> - </>}
          {vacancy?.city && (
            <Styled.SubTitleSpan> {vacancy.city?.name} </Styled.SubTitleSpan>
          )}
          {vacancy?.city && vacancy?.state && <> / </>}
          {vacancy?.state?.acronym}
        </Styled.SubTitle>
        <Styled.ShareContainer>
          <Styled.ShareText>COMPARTILHAR</Styled.ShareText>
          <Styled.ShareButtonsContainer>
            <Styled.ShareButton color={colorCompany}>
              <FacebookShareButton
                url={window.location.href}
                quote="Vaga aberta para: "
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Styled.ShareButton>
            <Styled.ShareButton color={colorCompany}>
              <LinkedinShareButton url={window.location.href}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </Styled.ShareButton>
            <Styled.ShareButton color={colorCompany}>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </Styled.ShareButton>
            <Styled.ShareButton color={colorCompany}>
              <EmailShareButton
                url={window.location.href}
                subject="Vaga aberta para: "
                body="body do email"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </Styled.ShareButton>
            <Styled.ShareButton color={colorCompany}>
              <WhatsappShareButton url={window.location.href} separator=":: ">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </Styled.ShareButton>
            <Styled.ShareButton color={colorCompany}>
              <TelegramShareButton url={window.location.href}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </Styled.ShareButton>
            <Styled.ShareButton>
              {isCopied ? (
                <Tooltip
                  placement="top"
                  title="Copiado"
                  defaultVisible
                  onVisibleChange={(visible) => setIsCopied(visible)}
                >
                  <Button
                    small
                    type="outline"
                    text="Copiar Link"
                    backgroundColor="transparent"
                    borderColor="#D0CCC8"
                    borderWidth="2px"
                    textColor={colorCompany}
                    isUppercase
                    leftIcon={<CopyIcon />}
                    onClick={() => _copyHandle()}
                    isTooltip
                    visible
                  />
                </Tooltip>
              ) : (
                <Button
                  small
                  type="outline"
                  text="Copiar Link"
                  backgroundColor="transparent"
                  borderColor="#D0CCC8"
                  borderWidth="2px"
                  textColor="#333333"
                  isUppercase
                  leftIcon={<CopyIcon />}
                  onClick={() => _copyHandle()}
                />
              )}
            </Styled.ShareButton>
          </Styled.ShareButtonsContainer>
        </Styled.ShareContainer>
      </Styled.Header>
      <Styled.Body>
        <Styled.Description>
          {!!vacancy.description && <SanitizeHTML html={vacancy.description} />}
          {!!vacancy.pcdDescription && accessDescription()}
        </Styled.Description>

        {!!vacancy.videoUrl && (
          <Styled.DescriptionVideoContainer>
            <Styled.DescriptionVideo>
              <ReactPlayer
                url={vacancy.videoUrl}
                width="100%"
                height="100%"
                controls={vacancy.videoUrl.includes("vimeo.com")}
              />
            </Styled.DescriptionVideo>
          </Styled.DescriptionVideoContainer>
        )}
        <Styled.DetailsContainer
          hasTwo={
            !!listDescription?.proposal?.length &&
            !!listDescription?.requirements?.length
          }
          isHidden={
            !listDescription?.proposal?.length &&
            !listDescription?.requirements?.length
          }
        >
          <Styled.DetailsBox show={!!listDescription?.proposal?.length}>
            <Styled.DetailsBoxHeader>
              <Styled.DetailsBoxTitle>Proposta</Styled.DetailsBoxTitle>
            </Styled.DetailsBoxHeader>
            <Styled.DetailsBoxBody>
              <Skills list={listDescription?.proposal} />
            </Styled.DetailsBoxBody>
          </Styled.DetailsBox>

          <Styled.DetailsBox show={!!listDescription?.requirements?.length}>
            <Styled.DetailsBoxHeader>
              <Styled.DetailsBoxTitle>Requisitos</Styled.DetailsBoxTitle>
            </Styled.DetailsBoxHeader>
            <Styled.DetailsBoxBody>
              <Skills list={listDescription?.requirements} />
            </Styled.DetailsBoxBody>
          </Styled.DetailsBox>
        </Styled.DetailsContainer>
      </Styled.Body>
      <Styled.Footer>
        <Styled.ButtonContainer>
          {subscribed ? (
            <Button
              medium
              type="outline"
              text="INSCRITO"
              borderColor="#333333"
              textColor="#333333"
              rightIcon={<Inscrito />}
              blocked
            />
          ) : (
            <Button
              blocked={!vacancy?.id}
              medium
              type="custom"
              text="Quero me candidatar"
              backgroundColor={colorCompany}
              textColor="#FFFFFF"
              isUppercase={false}
              rightIcon={<Inscrever />}
              onClick={onApplyVacancy}
            />
          )}
        </Styled.ButtonContainer>
      </Styled.Footer>
    </>
  );
};

export default Body;
