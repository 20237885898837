import styled from "styled-components";

export const Container = styled.div`
  width: 95px;
  height: 95px;
  background: #666666;
  border: 12px solid #e5e5e5;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: -12px;
    left: -12px;
    width: 95px;
    height: 95px;
    border-radius: 100px;
    border: 2px solid
      ${({ isError, theme }) => (isError ? theme.colors.red : "transparent")};
  }
`;

export const Image = styled.img`
  width: 95px;
  height: 95px;
  border: 12px solid #e5e5e5;
  border-radius: 100px;
  object-fit: cover;
`;
