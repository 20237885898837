import React from "react";
import * as Styled from "../styles";

import useClickPreventionOnDoubleClick from "../../../utils/hooks/useClickPreventionOnDoubleClick";

const Custom = ({
  onClick = () => {},
  onDoubleClick = () => {},
  text,
  isUppercase = true,
  children,
  buttonType = "button",
  id,
  ...rest
}) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick
  );

  return (
    <Styled.ContentButton id={id} {...rest} type={buttonType}>
      <Styled.ButtonCustom
        {...rest}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        id={id}
        isUppercase={isUppercase}
      >
        {text}
        {children}
      </Styled.ButtonCustom>
    </Styled.ContentButton>
  );
};

export default Custom;
