import styled from "styled-components";

export const LanguageOptions = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 1.5rem;
  }
`;

export const LanguageSelect = styled.div`
  max-width: 362px;
  height: 170px;

  p {
    margin-bottom: 0.5rem;
  }
`;
