import styled, { css } from "styled-components";

export const ContainerDates = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  position: relative;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;

    ${(props) =>
      props.isError &&
      css`
        > div > div > div {
          border-color: ${({ theme }) => theme.colors.red} !important;
        }
      `}
  }
`;
