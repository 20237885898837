import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: ${({ isDropdown }) => (isDropdown ? "4" : "3")}px;
  left: ${({ isDropdown }) => (isDropdown ? "4" : "2")}px;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efedf3;
`;

export const InitialPhoto = styled.div``;

export const InitialPhotoText = styled.div`
  text-transform: uppercase;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5f4a87;
`;

export const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Photo = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ data }) => data && `background-image: url("${data}");`}
`;
