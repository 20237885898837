import * as yup from "yup";

const changeEmailSchema = (formInputs = null) => {
  let emailField;

  if (formInputs) {
    emailField = formInputs.newEmail
      ? formInputs.newEmail
      : yup.ref("newEmail");
  } else {
    emailField = yup.ref("newEmail");
  }

  return yup.object().shape({
    newEmail: yup
      .string()
      .email("Email em formato inválido")
      .required("Campo obrigatório")
      .nullable(),
    confirmNewEmail: yup
      .string()
      .email("Email em formato inválido")
      .oneOf([emailField, null], "Emails não coincidem")
      .required("Campo obrigatório")
      .nullable()
      .when("email", {
        is: (val) => !!val !== false,
        then: yup.string().required("Campo obrigatório")
      }),
    password: yup
      .mixed()
      .typeError("Senha em formato inválido")
      .required("Campo obrigatório")
      .nullable()
  });
};

const changePasswordSchema = (formInputs = null) => {
  let passwordField;

  if (formInputs) {
    passwordField = formInputs.newPassword
      ? formInputs.newPassword
      : yup.ref("newPassword");
  } else {
    passwordField = yup.ref("newPassword");
  }

  return yup.object().shape({
    actualPassword: yup
      .mixed()
      .typeError("Senha em formato inválido")
      .required("Campo obrigatório")
      .nullable(),
    newPassword: yup
      .string()
      .matches(/(?=.*?[0-9])(?=.*?[A-Za-z]).+/, {
        excludeEmptyString: true,
        message: "A senha deve conter ao menos uma letra e um número"
      })
      .min(6, "A senha deve conter mais que 6 dígitos")
      .max(20, "A senha deve conter menos que 20 dígitos")
      .required("Campo obrigatório")
      .nullable()
      .when("actualPassword", {
        is: (val) => !!val !== false,
        then: yup.string().required("Campo obrigatório")
      }),
    confirmNewPassword: yup
      .string()
      .matches(/(?=.*?[0-9])(?=.*?[A-Za-z]).+/, {
        excludeEmptyString: true,
        message: "A senha deve conter ao menos uma letra e um número"
      })
      .min(6, "A senha deve conter mais que 6 dígitos")
      .max(20, "A senha deve conter menos que 20 dígitos")
      .oneOf([passwordField, null], "Senhas não coincidem")
      .required("Campo obrigatório")
      .nullable()
      .when("newPassword", {
        is: (val) => !!val !== false,
        then: yup.string().required("Campo obrigatório")
      })
  });
};
export { changeEmailSchema, changePasswordSchema };
